import {doc, initializeFirestore, updateDoc, query, getDocs, collection, where, addDoc, serverTimestamp, getDoc, deleteDoc, orderBy, documentId } from './libs/firebase-firestore.js'
import {ref,getDownloadURL,getBlob,getStorage,deleteObject,uploadBytesResumable } from './libs/firebase-storage.js'
import { db } from './database/remote'


// const firebaseConfig = {
//   apiKey: "AIzaSyDO8DJSoIjCSXtVDT4abTr0zq-XqzL4g1I",
//   authDomain: "iso-easy.firebaseapp.com",
//   projectId: "iso-easy",
//   storageBucket: "iso-easy.appspot.com",
//   messagingSenderId: "534920369536",
//   appId: "1:534920369536:web:6d8e0d711b824e1c1b1d65",
//   measurementId: "G-7GQ0D4K8DW"
// }
// const firebaseApp = initializeApp(firebaseConfig)
// const db = initializeFirestore(firebaseApp, {})
const firebaseStorage = getStorage();

const progress = (arg)=> false && console.log(arg)
const complete = (arg)=> false && console.info(arg)
const fail = (arg)=> false && console.error(arg)

export const DATAMODELOLD = {
  init(){
  },
  async _storageDeleteFile(url){
    try {
      const fileRef = ref(firebaseStorage, url);
      await deleteObject(fileRef)
    } catch (error) {
      if (error.code !== 'storage/object-not-found') {
        throw error
      }
    }
  },
  async _storageUploadFile(blob,url){
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(ref(firebaseStorage, url), blob);
      uploadTask.on('state_changed',
        (snapshot) => {
          progress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        (error)=>{
          reject(error)
        },
        async ()=> {
          progress(1)
          let jsonFileUrl = await getDownloadURL(uploadTask.snapshot.ref)
          resolve(jsonFileUrl)
        }
      )
    })
  },
  _blob(data){
    return new Blob([JSON.stringify(data)], { type: "application/json" })
  },
  _getDrawingVersionDataURL(id, version){
    return version === -1 ? `drawings/${id}/canvasJson` : `drawings/${id}/canvasJson-${version}`
  },
  getDrawingVersionImageURL(id, version){
    return version === -1 ? `drawings/${id}/image` :`drawings/${id}/image-${version}`
  },
  async projects(){
    let projects = []

    const colRef = collection(db, 'Projects')
    const q = query(colRef)
    const docRefs = await getDocs(q)
    const newProjects = []
    docRefs.forEach((project) => {
      newProjects.push({
        ...project.data(),
        id: project.id
      })
    })
    projects = newProjects.sort((a, b) => a.name?.localeCompare(b?.name)).map(project => ({
      createdAt: project.createdAt.seconds,
      description: project.description,
      name: project.name,
      id: project.id,
    }))

    return projects
  },
  async project(projectId){
    let project
    const docRef = doc(db, "Projects", projectId)
    const docSnap = await getDoc(docRef)
    project = docSnap.data()


    if(project){
      project.drawings = await this.drawings(projectId)
    }
    return project
  },
  async drawings(projectId){
    let drawings

    const colRef = collection(db, 'Drawings')
    const q = query(colRef, where("project", "==", projectId))
    const docRefs = await getDocs(q)
    let result = []
    docRefs.forEach((drawing) => {
      let drawingData = drawing.data()
      result.push({ ...drawingData,id: drawing.id })
    })


    drawings = result.sort((a, b) => a.name.localeCompare(b.name)).map(d => ({
      createdAt: d.createdAt?.seconds || d.createdAt || serverTimestamp() ,
      updatedAt: d.updatedAt?.seconds || d.updatedAt ||  serverTimestamp(),
      updatedBy: d.updatedBy || '',
      createdBy: d.createdBy || '',
      description: d.description || '',
      versions: d.versions,
      project: d.project,
      drawingVersion: d.drawingVersion !== undefined ? (d.drawingVersion === 0 ? (d.versions && this.getLastVersion(d.versions) || 0 ): d.drawingVersion)  : -1,
      name: d.name,
      id: d.id
    }))

    return drawings
  },
  async drawing(drawingId) {
    let drawing
    const colRef = collection(db, 'Drawings')
    const q = query(colRef, where(documentId(), '==', drawingId))
    const docRefs = await getDocs(q)

    let d
    docRefs.forEach((obj) => {
      d = obj.data()
    })
    if(!d){
      return null
    }
    d.createdAt = d.createdAt?.seconds || d.createdAt
    d.updatedAt = d.updatedAt?.seconds || d.updatedAt

    let versions = []
    if(d.versions){
      for(let i = d.versions.length;i--;){
        let versionItem = d.versions[i]
        if(!versions.find(v => v.version === versionItem.version)){
          versions.unshift(versionItem);
        }
      }
      d.versions = versions
    }
    drawing = d

    if(drawing.drawingVersion === undefined){
      if(drawing.versions){
        drawing.drawingVersion = this.getLastVersion(drawing.versions)
      }
      else{
        drawing.drawingVersion = -1
        drawing.versions = [{
          createdAt: drawing.createdAt,
          version: -1
        }]
      }
    }
    return drawing
  },
  getLastVersion(versions){
    if(!versions){
      return 0
    }
    return versions.reduce((max, obj) =>  obj.version > max ? obj.version : max, 0)
  },
  async version(drawingId,drawingVersion = 0) {
    let data
    if(drawingVersion === 0){
      data = {}
    }
    else{
      let jsonURL = this._getDrawingVersionDataURL(drawingId, drawingVersion)
      const oldJsonStorageRef = ref(firebaseStorage, jsonURL);
      let blob = await getBlob(oldJsonStorageRef)
      let jsonText = await blob.text()
      data = JSON.parse(jsonText)
    }
    return data
  },
  async _loadDataBlob(key){
    let blob = await this._loadBlob(key)
    let text = await blob.text()
    return JSON.parse(text)
  },
  async _loadBlob(key){
    const imageRef = ref(firebaseStorage, key);
    return await getBlob(imageRef)
  },
  async thumbnailBlob(drawing,version  ){
    return this._loadBlob(version === -1 ? `drawings/${drawing}/image` : `drawings/${drawing}/image-${version}`)
  },
  async thumbnailURL(drawing,version){
    let blob = await this.thumbnailBlob(drawing,version)
    return blob && URL.createObjectURL(blob);
  },
  async deleteDrawingVersion(id,version = 0) {
    await this._storageDeleteFile(this._getDrawingVersionDataURL(id, version))
    await this._storageDeleteFile(this.getDrawingVersionImageURL(id, version))

    let drawingInfoSaved = await this.drawing(id)
    if(!drawingInfoSaved) {
      return null
    }

    let versions = drawingInfoSaved.versions
    let versionItem = versions.find(el => el.version === version)
    versions.splice(versions.indexOf(versionItem), 1)
    let drawingData = {
      versions,
      drawingVersion: 0
    }
    await this.editDrawingInfo(id, drawingData)
    return drawingData
  },
  async deleteDrawing(id) {
    return await deleteDoc(doc(db, "Drawings", id))
  },
  async deleteProject(id) {
    let projectInfoSaved = await this.project(id)
    while(projectInfoSaved?.drawings?.length){
      let drawing = projectInfoSaved.drawings.shift()
      await this.deleteDrawing(drawing.id)
    }
    return await deleteDoc(doc(db, "Projects", id))
  },
  async addDrawingVersion(id, data, thumbnail) {
    if(DATAMODELOLD.onSync)DATAMODELOLD.onSync()
    let drawingInfoSaved = await this.drawing(id)
    if(!drawingInfoSaved.versions){
      drawingInfoSaved.versions = []
    }
    if(drawingInfoSaved.versions.length > 9){
      let version = drawingInfoSaved.versions.shift()
      await this.deleteDrawingVersion(id, version.version)
    }
    let version = drawingInfoSaved.versions.reduce((max, obj) =>  obj.version > max ? obj.version : max, 0) + 1

    await this._storageUploadFile(this._blob(data), this._getDrawingVersionDataURL(id, version), v => progress(v * 0.5))
    if(thumbnail){
      await this._storageUploadFile(thumbnail, this.getDrawingVersionImageURL(id, version), v => progress(v * 0.5 + 0.5))
    }
    let document = await this.editDrawingInfo(id, {
      versions: [...drawingInfoSaved.versions,{ version }],
      drawingVersion: version
    })
    if (DATAMODELOLD.onSyncEnd) DATAMODELOLD.onSyncEnd()
    return document
  },
  async editDrawingInfo(id, data) {
    return await updateDoc(doc(db, "Drawings", id), { ...data, updatedAt : serverTimestamp() })
  },
  async editProjectInfo(id, data) {
    console.log({ ...data, updatedAt : serverTimestamp() })
    return await updateDoc(doc(db, "Projects", id), { ...data, updatedAt : serverTimestamp() })
  },
  async createDrawing( projectId, data) {
    let newDrawingData = {
      description: "No Description",
      name: "No Name",
      ...data,
      project: projectId,
      createdBy: 'userId 1',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      drawingVersion: 0
    }
    let document = await addDoc(collection(db, 'Drawings'), newDrawingData)
    // let project = await this.project(projectId)
    // let drawings = [...project.drawings, {id: document.id, ...newDrawingData}]
    // await this.editProjectInfo(projectId,{ drawings })
    return document
  },
  async createProject(options) {
    return await addDoc(collection(db, 'Projects'), {
      description: "No Description",
      name: "No Name",
      ...options,
      createdBy: 'userId 1',
      createdAt: serverTimestamp(),
      drawings: []
    })
  },
  async duplicateDrawing(drawingId, projectId, drawingOptions) {
    let duplicatedDrawingInfo = await this.drawing(drawingId)
    let versionNumber = duplicatedDrawingInfo.drawingVersion
    duplicatedDrawingInfo.versions = []
    duplicatedDrawingInfo.drawingVersion = 0
    let drawing = await this.createDrawing(projectId,Object.assign(duplicatedDrawingInfo, drawingOptions))
    let version
    if(versionNumber !== 0){
      let  thumbnail = await this.thumbnailBlob(drawingId,versionNumber)
      let duplicatedDrawingData = await this.version(drawingId,versionNumber)
      version = await this.addDrawingVersion(drawing.id,duplicatedDrawingData,thumbnail)
    }
    return drawing
  },
  async shapes() {
    let shapes = []
    const docRefs = await getDocs(query(collection(db, 'Shapes'), orderBy('createdAt')))
    for (let i = 0; i < docRefs.docs.length; i++) {
      const doc = docRefs.docs[i].data();
      let id = docRefs.docs[i].id
      if (doc.sizesUrl) {
        const response = await fetch(doc.sizesUrl);
        doc.sizes = await response.json();
      }
      if (doc.imageUrl) {
        const response = await fetch(doc.imageUrl);
        doc.svg = (await response.json()).image;
      }
      if (!doc.sizes) {
        try{
          doc.sizes = await this._loadDataBlob( `shapes/${id}/shapeJson`)
        }
        catch(e){
          console.log(doc,e)
        }
      }
      shapes.push({ id: docRefs.docs[i].id, ...doc })
    }
    return shapes
  },
  async createShape(options) {
    let sizesData = options.sizes
    delete options.sizes
    let document = await addDoc(collection(db, 'Shapes'), { ...options, createdAt: serverTimestamp()})
    await this._storageUploadFile(this._blob(sizesData), `shapes/${document.id}/shapeJson`)
    return document
  },
  async deleteShape(id) {
    return await deleteDoc(doc(db, 'Shapes', id))
  },
  async editShape(id, options) {
    return await updateDoc(doc(db, "Shapes", id), { ...options, updatedAt: serverTimestamp() })
  },
  async loadCategories() {
    let cats = []
    const docRefs = await getDocs(collection(db, 'Categories'))
    docRefs.forEach((category) => {
      let { name } = category.data()
      cats.push({ key: category.id, id: category.id, value: name, label: name })
    })
    return cats.sort((a, b) => a.value.localeCompare(b.value))
  },
  async loadSubcategories() {
    let cats= []
    const docRefs = await getDocs(collection(db, 'Subcategories'))
    docRefs.forEach((category) => {
      let { name, parentId , parentName} = category.data()
      cats.push({ key: category.id, id: category.id, parentId, parentName, value: name, label: name })
    })
    return cats.sort((a, b) => a.value.localeCompare(b.value))
  },
  async loadSubSubcategories() {
    let cats = []
    const docRefs = await getDocs(collection(db, 'SubSubcategories'))
    docRefs.forEach((category) => {
      let { name, parentCategoryName, parentCategoryId, parentSubcategoryName, parentSubcategoryId} = category.data()
      cats.push({ key: category.id, id: category.id, parentCategoryName, parentCategoryId, parentSubcategoryName, parentSubcategoryId, value: name, label: name })
    })
    return cats.sort((a, b) => a.value.localeCompare(b.value))
  },
  async updateCategory(id,options) {
    return await updateDoc(doc(db, 'Categories', id),{ ...options, updatedAt: serverTimestamp() })
  },
  async updateSubCategory(id, options) {
    return await updateDoc(doc(db, 'Subcategories', id), { ...options, updatedAt: serverTimestamp() })
  },
  async updateSubSubCategory(id,  options) {
    return await updateDoc(doc(db, 'SubSubcategories', id),{ ...options, updatedAt: serverTimestamp() })
  },
  async deleteCategory(id) {
    return await deleteDoc(doc(db, 'Categories', id))
  },
  async deleteSubCategory(id) {
    return await deleteDoc(doc(db, 'Subcategories', id))
  },
  async deleteSubSubCategory(id) {
    return await deleteDoc(doc(db, 'SubSubcategories', id))
  },
  async createCategory(options) {
    return await addDoc(collection(db, 'Categories'), { ...options, createdAt: serverTimestamp() })
  },
  async createSubCategory(options) {
    return await addDoc(collection(db, 'Subcategories'), { ...options, createdAt: serverTimestamp() })
  },
  async createSubSubCategory(options) {
    return await addDoc(collection(db, 'SubSubcategories'),{ ...options, createdAt: serverTimestamp() })
  }
}

let dbOperations = [
  'deleteDrawingVersion',
  'deleteDrawing',
  'deleteProject',
  'addDrawingVersion',
  'editDrawingInfo',
  'editProjectInfo',
  'createDrawing',
  'createProject',
  'duplicateDrawing',
  'createShape',
  'deleteShape',
  'editShape',
  'updateCategory',
  'updateSubCategory',
  'updateSubSubCategory',
  'deleteCategory',
  'deleteSubCategory',
  'deleteSubSubCategory',
  'createCategory',
  'createSubCategory',
  'createSubSubCategory'
]
//
// DATAMODEL.activeOperations = []
// for(let operation of dbOperations){
//   let op = DATAMODEL[operation]
//   let currentOperations = 0
//
//   DATAMODEL[operation] = async function (){
//     let currentOperation = ++currentOperations
//     console.log("+"+operation)
//     if(!DATAMODEL.activeOperations.length){
//       if(DATAMODEL.onSync)DATAMODEL.onSync()
//       console.log("sync")
//     }
//     DATAMODEL.activeOperations.push(currentOperation)
//
//     //skip offline saving
//     let result = await op.apply(DATAMODEL,arguments)
//
//     DATAMODEL.activeOperations.push(currentOperation)
//     console.log(DATAMODEL.activeOperations)
//
//     DATAMODEL.activeOperations.splice(DATAMODEL.activeOperations.indexOf(currentOperation),1)
//
//     console.log(DATAMODEL.activeOperations)
//
//     if(!DATAMODEL.activeOperations.length) {
//       if (DATAMODEL.onSyncEnd) DATAMODEL.onSyncEnd()
//       console.log("sync end")
//     }
//     console.log("-"+operation)
//
//     return result
//   }
// }