import Observable from "./onfire.js";

/***********************************************************************************************************************
 ** DPHistory
 ***********************************************************************************************************************/


let DPHistory = function (editor, initAction) {
    this.editor = editor;
    this.totalActions = 0;
    this.clear(initAction);
};

DPHistory.prototype.stackSize = 100
DPHistory.prototype.last = function () {
    return this.stack[this.stack.length - 1];
};
DPHistory.prototype.count = function () {
    return this.stack.length;
};

DPHistory.prototype.setStack = function (stack, current) {
    this.canUndo = stack.length;
    this.canRedo = false;

    if (!stack.length) {
        stack = [{
            type: 'initialized',
            id: 0,
            text: 'initialized'
        }]
    }
    this.stack = stack;
    // this.length = this.stack.length ;
    this.current = current === undefined ? stack.length - 1 : current;
    this.activeAction = this.stack[this.current];
    this.fire("changed", {action: this.activeAction});
    return this;
};

DPHistory.prototype.restore = function () {
    this.setStack(this.saved.stack, this.saved.current);
    return this;
};

DPHistory.prototype.save = function () {
    this.saved = {
        current: this.current,
        stack: JSON.parse(JSON.stringify(this.stack))
    };
    return this;
};

DPHistory.prototype.clear = function (initAction) {
    if (initAction) {
        initAction.id = 0;
    } else {
        initAction = {
            type: 'initialized',
            id: 0,
            text: 'initialized'
        }
    }
    this.stack = [initAction];
    this.current = 0;
    this.canUndo = false;
    this.canRedo = false;
    this.activeAction = this.stack[this.current];
    this.fire("changed", {action: this.activeAction});
    return this;
};

DPHistory.prototype.transactionEnd = function (options) {
    if(options){
        Object.assign(this.transaction,options)
    }
    delete this.transaction
}
DPHistory.prototype.transactionStart = function (options) {
    let action= {
        ...options,
        actions: [],
        redo: (a) => {
            for(let i = 0 ;i< a.actions.length; i++){
                a.actions[i].redo.call(this.editor, a.actions[i]);
            }
            a?.redoCallback?.call(this.editor, a);
        },
        undo: (a) => {
            for(let i = a.actions.length ;i--;){
                a.actions[i].undo.call(this.editor, a.actions[i]);
            }
            a?.undoCallback?.call(this.editor, a);
        }
    }
    this.add(action)
    this.transaction = action
}
DPHistory.prototype.add = function (action) {
    if (!this.enabled || this.processing) {
        return false;
    }
    this.canUndo = true;
    this.canRedo = false;
    this.stack.splice(this.current + 1);

    if(this.transaction){
        this.transaction.actions.push(action)
    }
    else{
        this.totalActions++;
        action.moment = new Date().getTime();
        action.id = this.totalActions
        action.text = action.type || action.text;
        this.stack.push(action);
        if(this.stack.length > this.stackSize){
            this.stack.shift()
        }
        this.current = this.stack.length - 1;
        this.activeAction = this.stack[this.current];
        this.fire("changed", {action: action});
    }


    return this;
};

DPHistory.prototype.disable = function () {
    this.enabled = false;
    return this;
};

DPHistory.prototype.enable = function () {
    this.enabled = true;
    return this;
};

DPHistory.prototype.getCurrent = function () {
    return this.stack[this.current]
}
DPHistory.prototype.undo = function (noFire) {
    if (!this.canUndo) return
    this.canRedo = true;
    let _action = this.stack[this.current];
    this.current--;
    this.processing = true;
    _action.undo.call(this.editor, _action);
    this.processing = false;
    if (this.current === 0) {
        this.canUndo = false;
    }
    if (!noFire) {
        this.activeAction = this.stack[this.current];
        this.fire("changed", {action: _action});
    }
    return this;
};

DPHistory.prototype.goto = function (index) {
    if (index === this.current) return;
    if (index < this.current) {
        for (let i = this.current - index; i--;) {
            this.undo(true);
        }
    }
    if (index > this.current) {
        for (let i = index - this.current; i--;) {
            this.redo(true);
        }
    }
    this.activeAction = this.stack[this.current];
    this.fire("changed", {action: this.activeAction});
    return this;
};

DPHistory.prototype.redo = function (noFire) {
    if (!this.canRedo) return
    this.processing = true;
    this.canUndo = true;
    this.current++;
    let _action = this.stack[this.current];

    _action.redo.call(this.editor, _action);

    if (this.current === this.stack.length - 1) {
        this.canRedo = false;
    }
    this.processing = false;
    if (!noFire) {
        this.activeAction = this.stack[this.current];
        this.fire("changed", {action: _action});
    }
    return this;
};

Object.assign(DPHistory.prototype, Observable)
// module.exports = DPHistory;
export default DPHistory;
