import fabric from './fabric.base.js'
import {makeFromObject} from "./object.ext.js";

const tableOwnProperties = [
  "textVerticalAlign"
]

fabric.IText.prototype.setFontFamily = function(value){
    this.fontFamily = value;
    this.initDimensions()
    this.dirty = true
    this.canvas?.renderAll()
}
fabric.IText.prototype.setFontSize = function(value){
    this.fontSize = value;
    this.initDimensions()
    this.dirty = true
    this.canvas?.renderAll()
}
fabric.IText.prototype.setText = function(value){
    this.text = value;
    this.initDimensions()
    this.dirty = true
    this.canvas?.renderAll()
}


/**
 * Enters editing state
 */
fabric.IText.prototype.enterEditing = function(e) {
    if (this.isEditing || !this.editable) {
        return;
    }
    if (this.canvas) {
        this.canvas.calcOffset();
        this.canvas.textEditingManager.exitTextEditing();
    }
    this.isEditing = true;
    this.initHiddenTextarea();
    this.hiddenTextarea.focus();
    this.hiddenTextarea.value = this.text;
    this._updateTextarea();
    this._saveEditingProps();
    this._setEditingProps();
    this._textBeforeEdit = this.text;
    this.__originalState = {
        text: this.text,
        styles: this.getExtra("styles")
    };

    this._tick();
    this.fire('editing:entered', { e });
    this._fireSelectionChanged();
    if (this.canvas) {
        // @ts-expect-error in reality it is an IText instance
        this.canvas.fire('text:editing:entered', {
            target: this,
            e
        });
        this.canvas.requestRenderAll();
    }
}


export class TextboxExt extends fabric.Textbox {
    static defaults = {
        alwaysActive: false,
        fixedSize: false,
        maxLines: 0,
        maxWidth: 0,
        fixedWidth: false,
        maxHeight: 0,
        textVerticalAlign: "top",
        hyphenation: false,
        autoFit: false,
        fill: "#000000",
        fontFamily: "Arial",
        lineHeight: 1.16,
        splitByGrapheme: false,
        strokeUniform: false,
        styles: [],
        textAlign: "left"
    }

    /**
     * Enters editing state
     */
    enterEditing(e) {
        if (this.isEditing || !this.editable) {
            return;
        }
        if (this.canvas) {
            this.canvas.calcOffset();
            this.canvas.textEditingManager.exitTextEditing();
        }

        this.isEditing = true;

        this.initHiddenTextarea();
        this.hiddenTextarea.focus();
        this.hiddenTextarea.value = this.text;
        this._updateTextarea();
        this._saveEditingProps();
        this._setEditingProps();
        this._textBeforeEdit = this.text;
        this._dataBeforeEdit = this.toObject();

        this._tick();
        this.fire('editing:entered', { e });
        this._fireSelectionChanged();
        if (this.canvas) {
            // @ts-expect-error in reality it is an IText instance
            this.canvas.fire('text:editing:entered', { target: this, e });
            this.canvas.requestRenderAll();
        }
    }

    exitEditing() {
        let _dataAfterEdit = this.toObject();
        let original =  this._dataBeforeEdit
        const isTextChanged = JSON.stringify(original) !== JSON.stringify(_dataAfterEdit)
       // const isTextChanged = this._textBeforeEdit !== this.text;

        this.selectionEnd = this.selectionStart;
        this._exitEditing();
        this._restoreEditingProps();
        if (this._forceClearCache) {
            this.initDimensions();
            this.setCoords();
        }
        this.fire('editing:exited',{original});
        isTextChanged && this.fire('modified',{original});
        if (this.canvas) {
            // @ts-expect-error in reality it is an IText instance
            this.canvas.fire('text:editing:exited', { target: this ,original});
            isTextChanged && this.canvas.fire('object:modified', { target: this ,original});
        }
        delete this._dataBeforeEdit
        return this;
    }
    onCopy(){
        return !!this.isEditing;

    }
    onPaste(){
        return !!this.isEditing;

    }
    onKeyDown (e) {
        if (!this.isEditing) {
            return;
        }
        this.fire("keydown",e)
        const keyMap = this.direction === 'rtl' ? this.keysMapRtl : this.keysMap;
        if (e.keyCode in keyMap) {
            this[keyMap[e.keyCode]](e);
        } else if (e.keyCode in this.ctrlKeysMapDown && (e.ctrlKey || e.metaKey)) {
            this[this.ctrlKeysMapDown[e.keyCode]](e);
        } else {
            return;
        }
        e.stopImmediatePropagation();
        e.preventDefault();
        if (e.keyCode >= 33 && e.keyCode <= 40) {
            // if i press an arrow key just update selection
            this.inCompositionMode = false;
            this.clearContextTop();
            this.renderCursorOrSelection();
        } else {
            this.canvas && this.canvas.requestRenderAll();
        }
    }
    initDimensions () {
        if (!this.initialized) {
            return;
        }
        this.isEditing && this.initDelayedCursor();
        this._clearCache();
        // clear dynamicMinWidth as it will be different after we re-wrap line
        this.dynamicMinWidth = 0;
        // wrap lines
        this._styleMap = this._generateStyleMap(this._splitText());
        // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
        if(!this.fixedSize) {
            if (this.dynamicMinWidth > this.width) {
                this._set('width', this.dynamicMinWidth);
            }
        }
        if (this.textAlign.includes('justify')) {
            // once text is measured we need to make space fatter to make justified text.
            this.enlargeSpaces();
        }


        // clear cache and re-calculate height
        this._textHeight = this.calcTextHeight();

        if(!this.fixedSize){
            // clear cache and re-calculate height
            this.height = this.calcTextHeight();

            //@edited
            if(!this.textVerticalAlign || this.height < this._textHeight){
                this.height = this._textHeight;
            }
        }
    }
    // mouseUpHandler (options) {
    //     this.__isMousedown = false;
    //     if (!this.editable || (!this.alwaysActive && this.group) ||
    //         (options.transform && options.transform.actionPerformed) ||
    //         (options.e.button && options.e.button !== 1)) {
    //         return;
    //     }
    //
    //     if (!this.alwaysActive && this.canvas?._activeObject !== this) {
    //         return;
    //     }
    //
    //     if ((this.alwaysActive || this.__lastSelected) && !this.__corner) {
    //         this.selected = false;
    //         this.__lastSelected = false;
    //         this.enterEditing(options.e);
    //         if (this.selectionStart === this.selectionEnd) {
    //             this.initDelayedCursor(true);
    //         }
    //         else {
    //             this.renderCursorOrSelection();
    //         }
    //     }
    //     else {
    //         this.selected = true;
    //     }
    // }
    // _mouseDownHandler(options) {
    //     if (!this.canvas || !this.editable || (options.e.button && options.e.button !== 1)) {
    //         return;
    //     }
    //
    //     this.__isMousedown = true;
    //
    //     if(this.alwaysActive){
    //         this.selected = true
    //         this.canvas.setActiveObject(this)
    //         this.enterEditing()
    //     }
    //
    //     if (this.selected) {
    //         this.inCompositionMode = false;
    //         this.setCursorByClick(options.e);
    //     }
    //
    //     if (this.isEditing) {
    //         this.__selectionStartOnMouseDown = this.selectionStart;
    //         if (this.selectionStart === this.selectionEnd) {
    //             this.abortCursorAnimation();
    //         }
    //         this.renderCursorOrSelection();
    //     }
    // }
    //
    // mouseMoveHandler (options) {
    //     if (!this.__isMousedown || !this.isEditing) {
    //         return;
    //     }
    //
    //     // regain focus
    //     document.activeElement !== this.hiddenTextarea && this.hiddenTextarea.focus();
    //
    //     var newSelectionStart = this.getSelectionStartFromPointer(options.e),
    //         currentStart = this.selectionStart,
    //         currentEnd = this.selectionEnd;
    //     if (
    //         (newSelectionStart !== this.__selectionStartOnMouseDown || currentStart === currentEnd)
    //         &&
    //         (currentStart === newSelectionStart || currentEnd === newSelectionStart)
    //     ) {
    //         return;
    //     }
    //     if (newSelectionStart > this.__selectionStartOnMouseDown) {
    //         this.selectionStart = this.__selectionStartOnMouseDown;
    //         this.selectionEnd = newSelectionStart;
    //     }
    //     else {
    //         this.selectionStart = newSelectionStart;
    //         this.selectionEnd = this.__selectionStartOnMouseDown;
    //     }
    //     if (this.selectionStart !== currentStart || this.selectionEnd !== currentEnd) {
    //         this.restartCursorIfNeeded();
    //         this._fireSelectionChanged();
    //         this._updateTextarea();
    //         this.renderCursorOrSelection();
    //     }
    // },

    // __inititalize(){
    //     // this._longLines: []
    //     // _textHeight: 0
    //     this.on({
    //         "changed"(){
    //             if(this.autoFit){
    //                 this.fitText();
    //             }
    //         },
    //         "resized"(){
    //             if(this.autoFit){
    //                 this.fitText();
    //             }
    //         },
    //         // "unlocked"(){
    //         //     this.maxWidth = 0;
    //         //     this.maxHeight = 0;
    //         // },
    //         // "locked"(){
    //         //     this.maxWidth = this.width;
    //         //     this.maxHeight = this.height;
    //         // }
    //     })
    // }
    // _splitTextIntoLines(text) {
    //     let newLines = fabric.Text.prototype._splitTextIntoLines.call(this, text);
    //     let ___textLines = this._textLines;
    //     let graphemeLines = this._wrapText(newLines.lines, this.width);
    //     let lines = new Array(graphemeLines.length);
    //     this._textLines = [];
    //     let lineHeight, height = 0;
    //     for (let i = 0; i < graphemeLines.length; i++) {
    //         this._textLines[i] = lines[i] = graphemeLines[i].join('');
    //         if(this.maxHeight){
    //             lineHeight = this.getHeightOfLine(i);
    //             let smallHeight = lineHeight / this.lineHeight;
    //             if(height + smallHeight > this.maxHeight){
    //                 graphemeLines.length = i;
    //                 lines.length = i;
    //                 break;
    //             }
    //             height += lineHeight
    //         }
    //     }
    //     newLines.lines = lines;
    //     newLines.graphemeLines = graphemeLines;
    //     this._textLines = ___textLines;
    //     return newLines;
    // }
    setTextVerticalAlign(value){
        if(!value){
            delete this.textVerticalAlign
        }
        else{

            this.textVerticalAlign = value

            this.dirty = true
            this.fire("modified", {});
            if (this.canvas) {
                this.canvas.fire("object:modified", {target: this});
                this.canvas.renderAll();
            }
        }
    }
    _getVerticalOffset(){
        let h2 = -this.height / 2;
        let offset = this.height - this._textHeight;
        switch (this.textVerticalAlign) {
            default:
            case "top":
                return h2 + ( 2 - this.fontSize * 0.21)
            case "middle":
                return h2 + offset / 2;
            case "bottom":
                return h2 + offset - (0     - this.fontSize * 0.21)
        }
    }
    _getSVGLeftTopOffsets() {
        let textTop = this._getVerticalOffset()
        return {
            textLeft: -this.width / 2,
            textTop: textTop,
            lineTop: this.getHeightOfLine(0)
        };
    }
    _getTopOffset() {
       // let offset = 0;
        let h2 = -this.height / 2;
        if(!this.textVerticalAlign) {
            return h2;
        }
        return this._getVerticalOffset()
    }
    // //overwritten
    // getSelectionStartFromPointer(e) {
    //     var mouseOffset = this.getLocalPointer(e),
    //       prevWidth = 0,
    //       width = 0,
    //       height = this._getTopOffset() + this.height / 2,//overwritten
    //       charIndex = 0,
    //       lineIndex = 0,
    //       lineLeftOffset,
    //       line;
    //
    //     for (var i = 0, len = this._textLines.length; i < len; i++) {
    //         if (height <= mouseOffset.y) {
    //             height += this.getHeightOfLine(i) * this.scaleY;
    //             lineIndex = i;
    //             if (i > 0) {
    //                 charIndex += this._textLines[i - 1].length + this.missingNewlineOffset(i - 1);
    //             }
    //         }
    //         else {
    //             break;
    //         }
    //     }
    //     lineLeftOffset = this._getLineLeftOffset(lineIndex);
    //     width = lineLeftOffset * this.scaleX;
    //     line = this._textLines[lineIndex];
    //     for (var j = 0, jlen = line.length; j < jlen; j++) {
    //         prevWidth = width;
    //         width += this.__charBounds[lineIndex][j].kernedWidth * this.scaleX;
    //         if (width <= mouseOffset.x) {
    //             charIndex++;
    //         }
    //         else {
    //             break;
    //         }
    //     }
    //     return this._getNewSelectionStartFromOffset(mouseOffset, prevWidth, width, charIndex, jlen);
    // }
    // /**
    // initDimensions () {
    //     if (this.__skipDimension) {
    //         return;
    //     }
    //     this.isEditing && this.initDelayedCursor();
    //     this.clearContextTop();
    //     this._clearCache();
    //     // clear dynamicMinWidth as it will be different after we re-wrap line
    //     this.dynamicMinWidth = 0;
    //     // wrap lines
    //     this._styleMap = this._generateStyleMap(this._splitText());
    //     // if after wrapping, the width is smaller than dynamicMinWidth, change the width and re-wrap
    //     if (this.dynamicMinWidth > this.width) {
    //         this._set('width', this.dynamicMinWidth);
    //     }
    //     if (this.textAlign.indexOf('justify') !== -1) {
    //         // once text is measured we need to make space fatter to make justified text.
    //         this.enlargeSpaces();
    //     }
    //     // clear cache and re-calculate height
    //
    //     this._textHeight = this.calcTextHeight();
    //
    //     //@edited
    //     if(!this.textVerticalAlign || this.height < this._textHeight){
    //         this.height = this._textHeight;
    //     }
    //     this.saveState({propertySet: '_dimensionAffectingProps'});
    // }
    // /**
    //  * @private
    //  * Divides text into lines of text and lines of graphemes.
    //  */
    // _splitText() {
    //     let newLines = this._splitTextIntoLines(this.text);
    //
    //     this.textLines = newLines.lines;
    //     this._textLines = newLines.graphemeLines;
    //
    //     if(this.maxHeight){
    //         let lineHeight, height = 0;
    //         for (let i = 0; i < newLines.lines.length; i++) {
    //             lineHeight = this.getHeightOfLine(i);
    //             height += (i === newLines.lines.length - 1 ? lineHeight / this.lineHeight : lineHeight);
    //             if(height > this.maxHeight){
    //                 newLines.lines.length = i;
    //                 newLines.graphemeLines.length = i;
    //                 this.textLines = newLines.lines;
    //                 this._textLines = newLines.graphemeLines;
    //                 break;
    //             }
    //         }
    //     }
    //
    //     this._unwrappedTextLines = newLines._unwrappedLines;
    //     this._text = newLines.graphemeText;
    //     return newLines;
    // }
    // _renderTextOversize (ctx) {
    //     let lineHeight = 0;
    //     for (let i = 0, len = this._textLines.length; i < len; i++) {
    //         let lineWidth = this.measureLine(i).width;
    //         let lineLeftOffset = this._getLineLeftOffset(i);
    //         let heightOfLine = this.getHeightOfLine(i);
    //         if (this._longLines[i]) {
    //             ctx.fillRect(this._getLeftOffset() + lineLeftOffset + lineWidth + 2, this._getTopOffset() + lineHeight + heightOfLine / 2 - 1, 5, this.fontSize / 15);
    //         }
    //         lineHeight += heightOfLine;
    //     }
    // }
    // _renderText (ctx) {
    //     if (this.paintFirst === "stroke") {
    //         this._renderTextStroke(ctx);
    //         this._renderTextFill(ctx);
    //     } else {
    //         this._renderTextFill(ctx);
    //         this._renderTextStroke(ctx);
    //     }
    //     if (this.hyphenation) {
    //         this._renderTextOversize(ctx);
    //     }
    // }
    // _getNewSelectionStartFromOffset (mouseOffset, prevWidth, width, index, jlen) {
    //     var distanceBtwLastCharAndCursor = mouseOffset.x - prevWidth,
    //       distanceBtwNextCharAndCursor = width - mouseOffset.x,
    //       offset = distanceBtwNextCharAndCursor > distanceBtwLastCharAndCursor
    //       || distanceBtwNextCharAndCursor < 0 ? 0 : 1,
    //       newSelectionStart = index + offset;
    //
    //     if (this.flipX) {
    //         newSelectionStart = jlen - newSelectionStart;
    //     }
    //     // the index passed into the function is padded by the amount of lines from _textLines (to account for \n)
    //     // we need to remove this padding, and pad it by actual lines, and / or spaces that are meant to be there
    //     var tmp = 0,
    //       removed = 0,
    //       _long = 0; //modified @den.ponomarev
    //
    //     // account for removed characters
    //     for (var i = 0; i < this._textLines.length; i++) {
    //         tmp += this._textLines[i].length;
    //         if (tmp + removed >= newSelectionStart) {
    //             break;
    //         }
    //         //modified @den.ponomarev
    //         if (this._longLines[i]) {
    //             newSelectionStart--;
    //             _long++;
    //         }
    //         if (this.text[tmp + removed] === '\n' || this.text[tmp + removed] === ' ') {
    //             removed++;
    //         }
    //     }
    //     if (newSelectionStart > this.text.length) {
    //         newSelectionStart = this.text.length;
    //     }
    //     //modified @den.ponomarev
    //     return newSelectionStart - i + removed + _long;
    //     //return newSelectionStart + _long;
    // }
    // _wrapText(lines, desiredWidth) {
    //     let wrapped = [], i;
    //     this.isWrapping = true;
    //     this._longLines = []; //added @ponomarevtlt
    //     for (i = 0; i < lines.length; i++) {
    //         wrapped = wrapped.concat(this._wrapLine(lines[i], i, desiredWidth));
    //     }
    //     this.isWrapping = false;
    //     return wrapped;
    // }
    // _wrapLine (_line, lineIndex, desiredWidth) {
    //
    //     let lineWidth = 0,
    //       graphemeLines = [],
    //       line = [],
    //       words = _line.split(this._reSpaceAndTab),
    //       word = "",
    //       offset = 0,
    //       infix = " ",
    //       wordWidth = 0,
    //       infixWidth = 0,
    //       largestWordWidth = 0,
    //       lineJustStarted = true,
    //       additionalSpace = this._getWidthOfCharSpacing();
    //
    //
    //     //todo desiredWidth
    //     let _maxWidth = this.maxWidth || this.fixedWidth && this.width;
    //     let isLongWord = false;
    //     let i;
    //     for (i = 0; i < words.length; i++) {
    //         word = fabric.util.string.graphemeSplit(words[i]);
    //         wordWidth = this._measureWord(word, lineIndex, offset);
    //
    //         if (!this.breakWords) {
    //             let _isLong = _maxWidth && wordWidth > _maxWidth;
    //
    //             if (_isLong) {
    //                 if (line.length !== 0) {
    //                     graphemeLines.push(line);
    //                     this._longLines.push(isLongWord);
    //                     isLongWord = false;
    //                     lineWidth = 0;
    //                     line = [];
    //                 }
    //
    //                 let _hypheSize = 0;
    //                 let _bigWordWidth = 0;// lineWidth + infixWidth;
    //                 let k = 0, len = word.length;
    //                 while (k < len && _bigWordWidth <= _maxWidth - _hypheSize) {
    //                     _bigWordWidth += this._measureWord(word[k], lineIndex, k + offset);
    //                     _hypheSize = this._measureWord("-", lineIndex, k + offset);
    //                     k++
    //                 }
    //                 let new_word = word.splice(0, k - 1);
    //                 isLongWord = true;
    //                 words.splice(i, 1, new_word.join(""), word.join(""));
    //                 i--;
    //                 lineJustStarted = true;
    //                 continue;
    //             }
    //         }
    //         lineWidth += infixWidth + wordWidth - additionalSpace;
    //
    //         if (lineWidth >= this.width) {
    //
    //             if (this.breakWords) {
    //                 lineWidth -= wordWidth;
    //                 line.push(infix);
    //                 let wordLetters = word.splice(0);
    //
    //                 while (wordLetters.length) {
    //                     if (lineWidth + letterWidth > this.width) {
    //                         graphemeLines.push(line);
    //                         this._longLines.push(true);
    //                         line = [];
    //                         lineWidth = 0;
    //                     }
    //                     line.push(wordLetters.shift());
    //                     offset++;
    //                     lineWidth += letterWidth;
    //                 }
    //             } else if (!lineJustStarted) {
    //                 graphemeLines.push(line);
    //                 this._longLines.push(isLongWord);
    //                 isLongWord = false;
    //                 line = [];
    //                 lineWidth = wordWidth;
    //                 lineJustStarted = true;
    //             }
    //         }
    //         else {
    //             lineWidth += additionalSpace;
    //         }
    //         offset += word.length;
    //
    //         if (!lineJustStarted) {
    //             line.push(infix);
    //         }
    //         line = line.concat(word);
    //
    //         infixWidth = this._measureWord(infix, lineIndex, offset);
    //         offset++;
    //
    //         // keep track of largest word
    //         if (wordWidth > largestWordWidth) {
    //             largestWordWidth = wordWidth;
    //         }
    //         lineJustStarted = false;
    //     }
    //
    //     i && graphemeLines.push(line);
    //     this._longLines.push(false);
    //
    //     if (this.breakWords) {
    //         this.dynamicMinWidth = 0;
    //     } else if (largestWordWidth > this.dynamicMinWidth) {
    //         this.dynamicMinWidth = largestWordWidth - additionalSpace;
    //     }
    //     return graphemeLines;
    // }
    // /**
    //  * @private
    //  */
    // _getSVGLeftTopOffsets() {
    //     return {
    //         textLeft: -this.width / 2,
    //         textTop: this._getTopOffset(),//edited
    //         lineTop: this.getHeightOfLine(0)
    //     };
    // }
    // fitText (){
    //     // this.__lineHeights = [];
    //     this._clearCache ();
    //     // this._textLines = this._splitTextIntoLines();
    //     this._textLines = this._splitTextIntoLines(this.text);
    //
    //     this.initDimensions();
    //
    //
    //     while(this.calcTextHeight() < this.height ){
    //         this.fontSize++;
    //         this._clearCache ();
    //         this.initDimensions();
    //     }
    //
    //     while(this.calcTextHeight() > this.height || this.dynamicMinWidth > this.width ){
    //         if(this.fontSize === 1)break;
    //         this.fontSize--;
    //         this._clearCache ();
    //         this.initDimensions();
    //     }
    //     //this.lineHeight = (this.height / this._textLines.length) / this.fontSize / this._fontSizeMult;
    // }
}
TextboxExt.cacheProperties = [...fabric.Textbox.cacheProperties || [], ...tableOwnProperties];
TextboxExt.storeProperties = [...fabric.Textbox.storeProperties || [], ...tableOwnProperties];
TextboxExt.stateProperties = [...fabric.Textbox.stateProperties || [], ...tableOwnProperties ];
fabric.IText.cacheProperties = [...fabric.Textbox.cacheProperties || [], ...tableOwnProperties];
fabric.IText.storeProperties = [...fabric.Textbox.storeProperties || [], ...tableOwnProperties];
fabric.IText.stateProperties = [...fabric.Textbox.stateProperties || [], ...tableOwnProperties ];


Object.assign(TextboxExt.prototype,TextboxExt.defaults)
fabric.TextboxExt = TextboxExt;
makeFromObject(TextboxExt)
fabric.classRegistry.setClass(TextboxExt,"textbox");
fabric.classRegistry.setClass(TextboxExt,"Textbox");
fabric.classRegistry.setClass(TextboxExt,"TextBox");