import fabric from "./fabric.base.js";
import { PDFDocument } from "../libs/pdfkit-web.js";
import { getProportions } from "./util.size.js";
// import pdfjsLib from "../libs/pdf.js";
import * as pdfjsLib from "pdfjs-dist/webpack";
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;




import { blob } from '../libs/blob.js'
import { DATAMODELOLD } from '../--database'
import { DATAMODEL } from '../database/database'

// import PDFDocumentt from './../utils/pdfkit.standalone.js'
// fabric.PDFKit = pdfkit
fabric.PDFDocument = PDFDocument

function downloadFile (blob, fileName) {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

fabric.Canvas.prototype.toBlob = function() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const { format = 'png', quality = 1, multiplier = 1, enableRetinaScaling = false } = options;
    const finalMultiplier = multiplier * (enableRetinaScaling ? this.getRetinaScaling() : 1);
    return new Promise((resolve,reject)=>{
        let canvasEl = this.toCanvasElement(finalMultiplier, options)
        canvasEl.toBlob(resolve,"image/".concat(format), quality)
    })
}

fabric.Canvas.prototype.export = function({ multiplier, width, height }) {
    let exportCanvas = new fabric.Canvas()
    let originalSize = this.getOriginalSize()
    this.skipOffscreen = false
    this.exporting = true
    exportCanvas.setDimensions(originalSize)
    exportCanvas._objects = [...this._objects]
    if (this.backgroundObject) {
        exportCanvas._objects.unshift(this.backgroundObject)
    }

    if (this.backgroundObject) {
        exportCanvas._objects.unshift(this.backgroundObject)
    }
    // exportCanvas.renderAll()

    let dataURL
    if (width && height) {
        let proportions = getProportions(originalSize, { width, height }, "contain")
        dataURL = exportCanvas.toDataURL({ multiplier: proportions.scaleX });
    } else {
        dataURL = exportCanvas.toDataURL({ multiplier });
    }


    this.skipOffscreen = true
    delete this.exporting
    return dataURL
}


fabric.Canvas.prototype.exportSVG = function() {
    let exportCanvas = new fabric.Canvas()
    let { width, height } = this.getOriginalSize()
    this.skipOffscreen = false
    this.exporting = true
    exportCanvas.setDimensions({ width, height })
    exportCanvas._objects = [...this._objects]
    if (this.backgroundObject) {
        exportCanvas._objects.unshift(this.backgroundObject)
    }
    // exportCanvas.renderAll()
    // let dataURL = exportCanvas.toDataURL({ multiplier });

    let svg = exportCanvas.toSVG().replace(/\r?\n|\r/g, '').trim()


    this.skipOffscreen = true
    delete this.exporting
    return svg
}


fabric.Canvas.prototype.downloadAsPDF = async function(filename) {
    let doc = this.makeDocument()
    let blob = await doc.toBlob()
    downloadFile(blob, filename);
}

fabric.Canvas.prototype.makeDocument = function() {
    let svg = this.exportSVG();
    let size = this.getOriginalSize()
    let ptUnit = 1;//fabric.util.parseUnit("1pt");{assumePt: ptUnit});
    const doc = new PDFDocument({
        // font: false,
        compress: false,
        size: [size.width / ptUnit, size.height / ptUnit]
    });
    // fabric.pdf.svgOptions.assumePt = ptUnit;
    doc.svg(svg, 0, 0, { assumePt: ptUnit });
    doc.end()
    return doc;
}


pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`

const  Base64Prefix = 'data:application/pdf;base64,'

fabric.createProjectPDF = async (name,drawings) => {

    let width = drawings[0].originalWidth, height = drawings[0].originalHeight
    let size =  [width , height]
    const doc = new fabric.PDFDocument({
        font: false,
        compress: false,
        size
    });

    for (let pageNumber = 1; pageNumber <= drawings.length; pageNumber++) {
        let drawing = drawings[pageNumber - 1]
        if (pageNumber > 1) {
            doc.addPage({size});
        }
        const canvas = new fabric.Canvas();
        if(!drawing.drawingVersion && drawing.versions){
            drawing.drawingVersion = drawing.versions[drawing.versions.length - 1].version
        }
        const data = await DATAMODEL.version(drawing.id,drawing.drawingVersion)
        canvas.setDimensions({width, height}, {backstoreOnly: true})
        await canvas.loadFromJSON(data)
        let svg = canvas.exportSVG();
        let ptUnit = 1;// 1.39;//fabric.util.parseUnit("1pt"); 1.39    //432 × 279 o мм 600ч388
        // fabric.pdf.svgOptions.assumePt = ptUnit;
        doc.svg(svg, 0, 0, {assumePt: ptUnit});

    }
    doc.end()
    let blob = await doc.toBlob()
    downloadFile(blob, name + '.pdf');
    return doc;
}

export const printPDF = async (pdfData, pages) => {
    pdfData = pdfData instanceof Blob ? await blob(pdfData) : pdfData
    const data = atob(pdfData.startsWith(Base64Prefix) ? pdfData.substring(Base64Prefix.length) : pdfData)

    // Using DocumentInitParameters object to load binary data.
    const loadingTask = pdfjsLib.getDocument({ data })
    return loadingTask.promise.then((pdf) => {
        const numPages = pdf.numPages
        return new Array(numPages).fill(0).map((__, i) => {
            const pageNumber = i + 1
            if (pages && pages.indexOf(pageNumber) === -1) {
                return null
            }
            return pdf.getPage(pageNumber).then((page) => {
                //  retina scaling
                const scale = 2 * window.devicePixelRatio; // You can adjust this value as needed
                const viewport = page.getViewport({ scale });
                // Prepare canvas using PDF page dimensions
                const canvas = document.createElement('canvas')
                const context = canvas.getContext('2d')
                canvas.height = viewport.height
                canvas.width = viewport.width
                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                }
                const renderTask = page.render(renderContext)
                return renderTask.promise.then(() => canvas)
            })
        })
    })
}

export async function pdfToImage(pdfData, canvas) {
    const zoom = canvas.getZoom()
    const pdfPages = await printPDF(pdfData)
    return pdfPages.map(async (c) => {
        if (c) {
            const canvasImg = await c;
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // Calculate scale based on PDF page dimensions and canvas dimensions
            const horizontalScale = canvasWidth / canvasImg.width;
            const verticalScale = canvasHeight / canvasImg.height;
            let scale = Math.min(horizontalScale, verticalScale);

            // Adjust scale for device pixel ratio and zoom
            scale *= (1 / window.devicePixelRatio) / zoom;
            canvas.add(
                new fabric.Image(canvasImg, {
                    scaleX: scale,
                    scaleY: scale,
                    originalScaleX: scale,
                    originalScaleY: scale,
                    customType: 'pdf-file'
                })
            )
        }
    })
}

fabric.Canvas.prototype.uploadFiles = async function(file) {
    if(file?.type === "application/pdf"){
        const result = await pdfToImage(file, this)
        await Promise.all(result)
       // e.target.value = '';
    }
    else if(file?.type.includes("image/")){
        const reader = new FileReader();

        reader.onload = () => {
            const image = new Image();
            const zoom = this.getZoom()
            const scale = (1 / window.devicePixelRatio)/zoom

            image.onload = ()=>{
                let imageObj = new fabric.Image(image, {
                    scaleX: scale,
                    scaleY: scale,
                    left:0,
                    top:0,
                    src: reader.result,
                    customType: "simple-image"
                })

                if(imageObj.getScaledWidth() > this.originalWidth) {
                    imageObj.scaleToWidth(this.originalWidth);
                }
                if(imageObj.getScaledHeight() > this.originalHeight){
                    imageObj.scaleToHeight(this.originalHeight);
                }

                this.add(imageObj);
               // e.target.value = ''

            }
            image.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        }
    }
    else{
        alert('File type not supported')
        // openNotification('File type not supported', 'error')
       // e.target.value = ''
    }
}





//use pdkit to load pdf and convert to image
// function fetchPDF(pdfData, canvas){
//     const pdfDoc = new PDFDocument();
//
//         // fetch(pdfUrl)
//         //     .then(response => response.arrayBuffer())
//         //     .then(data => {
//                 // Load the PDF data into the PDFKit document
//     pdfDoc.pipe(blobStream());
//     pdfDoc.end(Buffer.from(pdfData));//blob??
//
//     // Draw each page on the canvas
//     const numPages = pdfDoc.bufferedPageRange().count;
//     for (let pageNum = 1; pageNum <= numPages; pageNum++) {
//         const page = canvas.createImage();
//         page.src = window.URL.createObjectURL(pdfDoc.output());
//         page.onload = function () {
//             ctx.drawImage(page, 0, 0);
//             window.URL.revokeObjectURL(page.src);
//         };
//     }
//             // })
//             // .catch(error => console.error('Error loading PDF:', error));
// }


//export const createProjectJSPDF = async (name,drawings,pdfQuality,pageOption) => {
// const pdf = new jsPDF({
//     orientation: 'landscape',
//     unit: 'in',
//     // format: [PAGE_WIDTH , PAGE_HEIGHT],
//     format: [CANVAS_DIMENSIONS.WIDTH , CANVAS_DIMENSIONS.HEIGHT],
// });
//
// const PAGE_WIDTH = 17;
// const PAGE_HEIGHT = 11;
//
// let pageNumber = 1;
// try {
//     let progress = 0;
//     for (const drawing of drawings) {
//         if (drawing.jsonFileUrl) {
//             if (pageNumber > 1) {
//                 pdf.addPage();
//             }
//
//             const canvas = new fabric.Canvas();
//             const response = await fetch(drawing.jsonFileUrl);
//             const data = await response.json();
//             canvas.setDimensions({
//                 width: CANVAS_DIMENSIONS.WIDTH,
//                 height: CANVAS_DIMENSIONS.HEIGHT
//             },
//             {
//                 backstoreOnly: true
//             })
//             await canvas.loadFromJSON(data)
//             if(data.backgroundObject){
//                 let bg = await fabric.Object.create(data.backgroundObject)
//                 canvas.setBackgroundObject(bg)
//             }
//             // canvas.renderAll()
//             // let dataURL = canvas.export({ multiplier: pdfQuality  ? 4 : 1 })
//             // const dataURL = canvas.toDataURL({ multiplier: pdfQuality ? 4 : 1 })
//             // pdf.addImage(dataURL, 'PNG', offsetPDF, offsetPDF, 17 - offsetPDF*2, 11 - offsetPDF*2) ;
//             pdf.addSvgAsImage(canvas.toSVG(), offsetPDF, offsetPDF, (17 - offsetPDF*2), (11 - offsetPDF*2));//fabric.pdf.svgOptions
//
//             pageOption && pdf.text(PAGE_WIDTH / 2, PAGE_HEIGHT - 0.25, `Page ${pageNumber}`);
//             pageNumber++;
//
//             progress += Math.round(100/drawings.length);
//
//             progressNotification(`PDF Download in progress - ${progress}%`, 'info', null);
//         }
//     }
//
//     progressNotification('PDF Download complete', 'success', null);
//
//     pdf.save(name + '.pdf');
// } catch (error) {
//     openNotification('An error occurred while generating the PDF:', 'error');
// }
//}



