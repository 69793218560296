export function downloadJSON(data, fileName) {
    const jsonStr = JSON.stringify(data, null, 2);  // Pretty print JSON with 2-space indentation
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}


export function cleanObject(obj) {
    // Check if the input is an array
    if (Array.isArray(obj)) {
        // Filter the array and apply the cleaning function to each element
        return obj
            .map(item => cleanObject(item))  // Recursively clean each item
            .filter(item => item !== null && item !== undefined);  // Remove null and undefined values
    }
    // Check if the input is an object (and not null)
    else if (typeof obj === 'object' && obj !== null) {
        // Iterate over the object properties
        return Object.entries(obj)
            .reduce((acc, [key, value]) => {
                // Recursively clean the value
                const cleanedValue = cleanObject(value);
                // Only add the property if it's not null or undefined
                if (cleanedValue !== null && cleanedValue !== undefined) {
                    acc[key] = cleanedValue;
                }
                return acc;
            }, {});
    }
    // Return the value if it's not an array or object
    return obj;
}


export function uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}