import React from "react"
import { Button, Popconfirm } from "antd"
import { QuestionCircleOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons'

export const ShapeItem = ({loadingApi, handleDeleteItem, setEditedItem, item, dropSVG, setExportModal}) => {

    const handleEditItem = async (item) => {
        setEditedItem(item)
        setExportModal(true)
    }
    
    return (
        // <Spin className='loader' spinning={loadingApi}>
        //     <div>
                <div key={item.id} className='itemContainer'>
                    {
                        item.svg &&
                        <img alt={`${item.label}`} src={`data:image/svg+xml;utf8,${item.svg}`} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    }
                    {
                        item.thumbnail &&
                        <img alt={`${item.label}`} src={item.thumbnail} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    }
                    <span className='labelContainer'><b>{item.label}</b></span>
                    <div className='itemSizes'>
                        <label>Size: </label>
                      {
                        item.sizes?.xs &&
                        <Button type="primary" size='small' shape="circle" onClick={() => dropSVG(item.sizes.xs)} onDragEnd={() => dropSVG(item.sizes.xs)}>XS</Button>
                      }
                      {
                        item.sizes?.xl &&
                        <Button type="primary" size='small' shape="circle" onClick={() => dropSVG(item.sizes.xl)} onDragEnd={() => dropSVG(item.sizes.xl)}>XL</Button>
                      }
                        {
                            item.sizes?.small &&
                          <Button type="primary" size='small' shape="circle" onClick={() => dropSVG(item.sizes.small)} onDragEnd={() => dropSVG(item.sizes.small)}>S</Button>
                        }
                        {
                            item.sizes?.medium &&
                            <Button type="primary" size='small' shape="circle" onClick={() => dropSVG(item.sizes.medium)} onDragEnd={() => dropSVG(item.sizes.medium)}>M</Button>
                        }
                        {
                            item.sizes?.large &&
                            <Button type="primary" size='small' shape="circle" onClick={() => dropSVG(item.sizes.large)} onDragEnd={() => dropSVG(item.sizes.large)}>L</Button>
                        }
                    </div>
                    <Button className='edit-button-item'
                            type='text'
                            icon={<EditOutlined size='small' />}
                            onClick={() => handleEditItem(item)} />
                    <Popconfirm title='Delete'
                                description={`Are you sure to delete?`}
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => handleDeleteItem(item)}>
                        <Button className='delete-button-item'
                                type='text'
                                icon={<CloseCircleOutlined size='small' />}
                                onClick={(e) => e.stopPropagation()} />
                    </Popconfirm>
                </div>
        //     </div>
        // </Spin>
    )

}