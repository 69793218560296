import React from 'react'
import { Input, Modal } from 'antd'

export const CreateModal = (props) => {
  const {
    modal,
    toggleModal,
    resourceType,
    onConfirm,
    confirmDrawing,
  } = props
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [nameError, setNameError] = React.useState(false)
  
  const validateItem = async () => {
    if (name === '') {
      setNameError(true)
    }  else {
      onConfirm(name, description)
    }
  }

  const changeName = (e) => {
    const value = e.target.value
    setName(value)

    if (value === '') {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  return (
    <Modal title={`Create ${resourceType}`} confirmLoading={confirmDrawing} open={modal} onOk={validateItem} onCancel={toggleModal}>
      <div className='modalInputContainer'>
        <Input
          status={nameError ? 'error' : null}
          placeholder='Name'
          onChange={changeName}
        />
        <Input
          placeholder='Description'
          onChange={(e) => setDescription(e.target.value)}
        />
        {nameError && <span className='modalInputError' ><span className='modalInputError' style={{ textTransform: 'capitalize' }}>{resourceType}</span>name cannot be empty</span>}
      </div>
  </Modal>
    )
}
