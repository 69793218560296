import fabric from './fabric.base.js'
import {convertObject} from './iso.converter'

const _zoomToPointNative = fabric.Canvas.prototype.zoomToPoint

function getProportions(photo, container) {
    let w = photo.naturalWidth || photo.width
    let h = photo.naturalHeight || photo.height
    let scaleX = container.width ? container.width / w : 999
    let scaleY = container.height ? container.height / h : 999
    let scale = Math.min(scaleX, scaleY)
    let output = {
        scale: scale,
        width: w * scale,
        height: h * scale
    }
    return output;
}

let prototype = fabric.Canvas.prototype

export class CanvasExt extends fabric.Canvas {
    constructor(el, options) {
        super(el, options)

        for (let option in options) {
            let fooName = "set" + option[0].toUpperCase() + option.slice(1)

            if (this[fooName]) {
                this[fooName](options[option])
            } else {
                this[option] = options[option]
            }
        }

        if (el) {
            this.centerAndZoomOut();
            this.enableAutoScrolling();
            this.trackCanvas()
            this.trackTarget()
            this.allowEditGroupedObjects()
            this.initHistory()
            this.enableHistory()
            this.enableDoubleTap()
        }
    }

    enableDoubleTap() {
        let lastTap = 0;

// Add a custom event listener for touchstart
        this.upperCanvasEl.addEventListener('touchstart', (e) => {
            const currentTime = new Date().getTime();
            const tapLength = currentTime - lastTap;

            // If the time between taps is less than 300ms, it's a double-tap
            if (tapLength < 300 && tapLength > 0) {
                // Trigger your double-click action here
                this._cacheTransformEventData(e);
                this._handleEvent(e, 'dblclick');
                this._resetTransformEventData();
            }

            lastTap = currentTime;
        });
    }

    async exportBlob(options) {

        let originalSize = this.getOriginalSize()
        let exportCanvas = new fabric.Canvas()
        exportCanvas.setDimensions(originalSize)
        exportCanvas._objects = [...this._objects]
        this.backgroundObject && exportCanvas._objects.unshift(this.backgroundObject)
        this.backgroundObject && exportCanvas._objects.unshift(this.backgroundObject)

        this.skipOffscreen = false
        this.exporting = true
        let proportions = getProportions(originalSize, options, "contain")
        let thumbnail = await exportCanvas.toBlob({multiplier: proportions.scaleX});
        this.skipOffscreen = true
        delete this.exporting

        return thumbnail
    }

    async loadFromJSON(drawingData) {
        // let tempCanvas = new fabric.Canvas()
        let tempCanvas = this
        let backgroudnObject = drawingData.backgroundObject
        delete drawingData.backgroundObject
        await prototype.loadFromJSON.call(tempCanvas, drawingData)
        tempCanvas.setBackgroundRect({fill: "white", shadow: {blur: 10, color: 'rgba(0,0,0,0.6)'}})
        if (backgroudnObject) {
            let object2 = await convertObject(backgroudnObject)
            let bg = await fabric.Object.create(object2)
            tempCanvas.setBackgroundObject(bg)
        }
        return tempCanvas;
    }

    toObject() {
        const drawingData = fabric.Canvas.prototype.toDatalessObject.call(this)

        if (this.backgroundObject) {
            drawingData.backgroundObject = this.backgroundObject.toObject()
        }
        return drawingData
    }

    setVisible(value) {
        if (this.visible === value) {
            return
        }
        this.visible = value
        if (value) {
            this.wrapperEl?.appendChild(this.lowerCanvasEl)
            this.wrapperEl?.appendChild(this.upperCanvasEl)
            this.wrapperEl?.appendChild(this.horizontalScrollElement)
            this.wrapperEl?.appendChild(this.verticalScrollElement)
            this.updateScrollbars()
        } else {
            this.wrapperEl?.removeChild(this.lowerCanvasEl)
            this.wrapperEl?.removeChild(this.upperCanvasEl)
            this.wrapperEl?.removeChild(this.horizontalScrollElement)
            this.wrapperEl?.removeChild(this.verticalScrollElement)
        }
    }

    clear() {
        delete this.backgroundObject
        super.clear();
    }

    setActive(value) {
        this.active = value
        this.fire(value ? "activated" : "deactivated")
    }

    setLoadingErrors(errors) {
        if (!errors?.length) {
            errors = null
        }

        if (this.loadingErrors && !errors) {
            this.wrapperEl?.removeChild(this.errorEl)
        } else if (!this.loadingErrors && errors) {
            this.wrapperEl?.appendChild(this.errorEl)
        }
        this.loadingErrors = errors

        if (errors) {
            this.isLoading = false
            this.fire("loading:error", {errors: errors})
            this.setActive(false)
            this.setVisible(false)
            this.wrapperEl?.removeChild(this.loaderEl)
        }
    }

    setSavingErrors(errors) {
        if (!errors?.length) {
            errors = null
        }
        if (this.savingErrors && !errors) {
            this.wrapperEl?.removeChild(this.savingErrorEl)
        } else if (!this.savingErrors && errors) {
            this.wrapperEl?.appendChild(this.savingErrorEl)
        }
        this.savingErrors = errors

        if (errors) {
            this.isSaving = false
            this.fire("saving:error", {errors: errors})
            // this.wrapperEl?.removeChild(this.saverEl)
        }
    }

    setSaving(value) {
        this.setSavingErrors(null)
        if (this.isSaving === value) {
            return
        }
        this.isSaving = value
        if (value) {
            // this.wrapperEl?.appendChild(this.saverEl)
            // this.fire("saving:begin")
            // this.canvas.fire("saving:begin",{document: activeDocument})
        } else {
            // this.wrapperEl?.removeChild(this.saverEl)
            // this.fire("saving:end")
            // this.canvas.fire("saving:end",{document: activeDocument})
        }
    }

    setLoading(value) {
        let wasLoading = this.isLoading
        this.isLoading = value
        if (value) {
            this.setLoadingErrors(null)
            this.wrapperEl?.appendChild(this.loaderEl)
            if (this.active) {
                this.setActive(false)
                this.setVisible(false)
            }
            this.fire("loading:begin")
        } else {
            if (!this.active) {
                if (wasLoading) {
                    this.wrapperEl?.removeChild(this.loaderEl)
                }
                this.setActive(true)
                this.setVisible(true)
            }
            this.fire("loading:end")
        }
    }

    setUseLoader(value) {
        if (!value) {
            return
        }
        let loaderEl = document.createElement("div")
        loaderEl.className = "canvas-loader fa fa-spinner fa-spin"
        this.loaderEl = loaderEl


        let errorEl = document.createElement("div")
        errorEl.className = "canvas-loader fa fa-exclamation-triangle"
        this.errorEl = errorEl

        let savingErrorEl = document.createElement("div")
        savingErrorEl.className = "canvas-saver-error fa fa-exclamation-triangle"
        this.savingErrorEl = savingErrorEl

        // let saverEl = document.createElement("div")
        // saverEl.className = "canvas-saver fa fa-save"
        // this.saverEl = saverEl

        if (this.requireDocument) {
            this.setActive(false)
            this.setVisible(false)
        }
    }

    setUseContextMenu() {
        let canvas = this;


        // if(!contextMenuEl){
        let contextMenuEl = document.createElement("div")
        contextMenuEl.className = "context-menu"


        let contextMenuUl = document.createElement("ul")
        contextMenuUl.className = "menu"

        contextMenuEl.appendChild(contextMenuUl)

        let options = {
            'set-column-header': {title: "Set Column Header"},
            'set-row-header': {title: "Set Row Header"},
            'unset-column-header': {title: "Unset Column Header"},
            'unset-row-header': {title: "Unset Row Header"},
            'insert-column': {title: "Insert Column"},
            'insert-row': {title: "Insert Row"},
            'merge-cells': {title: "Merge Cells"},
            'unmerge-cells': {title: "Unmerge Cells"},
            'delete-column': {title: "Delete Column"},
            'delete-row': {title: "Delete Row"},
            'delete-table': {title: "Delete Table"},
        }
        for (let optionID in options) {
            let contextMenuLi = document.createElement("li")
            contextMenuLi.className = "action"
            contextMenuLi.id = optionID
            contextMenuLi.innerText = options[optionID].title
            contextMenuUl.appendChild(contextMenuLi)
        }


        this.wrapperEl.appendChild(contextMenuEl)

        // }

        function _showHide(id, c, displayStyle = 'block') {
            let el = document.getElementById(id)
            if (el) {
                el.style.display = c ? displayStyle : 'none'
            }
        }

        let selectedElement

        function updateContextMenu(e) {
            e.e.preventDefault()
            selectedElement = e.target
            if (contextMenuEl.style.display === 'block') {
                contextMenuEl.style.display = 'none'
            } else if (selectedElement?.type === 'table') {
                let table = selectedElement
                _showHide('set-column-header', !!table.selection.find(cell => !cell.c.header))
                _showHide('set-row-header', !!table.selection.find(cell => !cell.r.header))
                _showHide('unset-column-header', !!table.selection.find(cell => cell.c.header))
                _showHide('unset-row-header', !!table.selection.find(cell => cell.r.header))
                _showHide('insert-column', table.isInsertColumnAvailableForSelection())
                _showHide('insert-row', table.isInsertRowAvailableForSelection())
                _showHide('merge-cells', table.isSelectionMergeble())
                _showHide('unmerge-cells', table.isSelectionUnmergeble())
                _showHide('delete-column', table.isRemoveColumnAvailableForSelection())
                _showHide('delete-row', table.isRemoveRowAvailableForSelection())
                _showHide('delete-table', !!table)
                contextMenuEl.style.display = 'block'
                contextMenuEl.style.left = e.e.pageX + 'px'
                if (e.e.clientY > e.e.target.clientHeight / 2) {
                    contextMenuEl.style.top = e.e.offsetY - contextMenuEl.clientHeight + 'px'
                } else {

                    contextMenuEl.style.top = e.e.offsetY + 'px'
                }
            }
        }

        document.onmousedown = e => {
            if (!contextMenuEl.contains(e.target)) {
                contextMenuEl.style.display = 'none'
            }
        }

        canvas.on('contextmenu', updateContextMenu.bind(this))
        contextMenuEl.addEventListener('click', event => {
            let element = event.target
            let table = selectedElement
            switch (element.id) {
                case 'delete-table':
                    canvas.remove(table)
                    break
                case 'insert-column': {
                    let bounds = table.getSelectionBounds()
                    if (bounds) {
                        table.insertColumn(bounds.x2)
                    }
                    break
                }
                case 'insert-row': {
                    let bounds = table.getSelectionBounds()
                    if (bounds) {
                        table.insertRow(bounds.y2)
                    }
                    break
                }
                case 'delete-column': {
                    table.deleteSelectedColumns()
                    break
                }
                case 'delete-row': {
                    table.deleteSelectedRows()
                    break
                }
                case 'merge-cells': {
                    table.mergeSelection()
                    break
                }
                case 'unmerge-cells': {
                    table.unmergeSelection()
                    break
                }
                case 'set-column-header': {
                    let bounds = table.getSelectionBounds()
                    if (bounds) {
                        for (let column = bounds.x; column <= bounds.x2; column++) {
                            table.setHeaderColumn(column, true)
                        }
                    }
                    break
                }
                case 'set-row-header': {
                    let bounds = table.getSelectionBounds()
                    if (bounds) {
                        for (let row = bounds.y; row <= bounds.y2; row++) {
                            table.setHeaderRow(row, true)
                        }
                    }
                    break
                }
                case 'unset-column-header': {
                    let bounds = table.getSelectionBounds()
                    if (bounds) {
                        for (let column = bounds.x; column <= bounds.x2; column++) {
                            table.setHeaderColumn(column, false)
                        }
                    }
                    break
                }
                case 'unset-row-header': {
                    let bounds = table.getSelectionBounds()
                    if (bounds) {
                        for (let row = bounds.y; row <= bounds.y2; row++) {
                            table.setHeaderRow(row, false)
                        }
                    }
                    break
                }
                default:
                    return
            }
            contextMenuEl.style.display = 'none'
        })
    }

    setUseDynamicBackground(value) {
        if (!value) {
            return
        }
        this.on('viewport:translate', this._updateBackgroundPosition.bind(this))
        this.on('viewport:scaled', this._updateBackgroundScale.bind(this))
        this._updateBackgroundPosition()
        this._updateBackgroundScale()
    }

    _updateBackgroundScale() {
        let scale = this.viewportTransform[0]
        let lg = 1000 * scale + 'px', bg = 100 * scale + 'px', sm = 10 * scale + 'px'
        this.wrapperEl.style.backgroundSize = `${lg} ${lg}, ${lg} ${lg}, ${bg} ${bg}, ${bg} ${bg}, ${sm} ${sm}, ${sm} ${sm}`
    }

    _updateBackgroundPosition() {
        let x = this.viewportTransform[4]
        let y = this.viewportTransform[5]
        let lgx = -3 + 0.5 + Math.round(x) + 'px',
            bgx = -2 + 0.5 + Math.round(x) + 'px',
            smx = -1 + 0.5 + Math.round(x) + 'px',
            lgy = -3 + 0.5 + Math.round(y) + 'px',
            bgy = -2 + 0.5 + Math.round(y) + 'px',
            smy = -1 + 0.5 + Math.round(y) + 'px'
        this.wrapperEl.style.backgroundPosition = `${lgx} ${lgy},${lgx} ${lgy}, ${bgx} ${bgy}, ${bgx} ${bgy}, ${smx} ${smy}, ${smx} ${smy}`
    }

    enableDrawing(klass) {
        // If a custom drawing class is provided, use it
        if (klass) {
            this.drawingClass = klass;
        }

        if (this.interactiveMode === "drawing") {
            return
        }

        this.interactiveMode = "drawing"
        // Disable object selection, discard active object, and render the canvas
        this.disableSelection()
        this.disablePanning()
        this.discardActiveObject()
        this.renderAll()
        this.on('mouse:down', this._drawingMouseDown);
        this.on('mouse:move', this._drawingMouseMove);
        this.on('mouse:up', this._drawingMouseUp);
    }

    disablePanning() {
        this.allowSingleTouchPanning = false
        this.off('mouse:down', this._handModeMouseDown);
        this.off('mouse:move', this._handModeMouseMove);
        this.off('mouse:up', this._handModeMouseUp);
    }

    enableTouchGesturesPanning() {
        this.on('mouse:down', this._handModeMouseDown);
        this.on('mouse:move', this._handModeMouseMove);
        this.on('mouse:up', this._handModeMouseUp);
    }

    enablePanning() {
        if (this.interactiveMode === "panning") {
            return
        }
        this.allowSingleTouchPanning = true
        this.disableSelection()
        this.disableDrawing()
        this.discardActiveObject()
        this.renderAll()
        this.interactiveMode = "panning"
        this.on('mouse:down', this._handModeMouseDown);
        this.on('mouse:move', this._handModeMouseMove);
        this.on('mouse:up', this._handModeMouseUp);
    }

    disableDrawing() {
        // Enable object selection and remove drawing event listeners
        this.off('mouse:down', this._drawingMouseDown);
        this.off('mouse:move', this._drawingMouseMove);
        this.off('mouse:up', this._drawingMouseUp);
    }

    disableSelection() {
        this.selection = false
    }

    enableSelection() {
        if (this.interactiveMode === "selection") {
            return
        }
        this.interactiveMode = "selection"
        this.disablePanning()
        this.disableDrawing()
        this.selection = true
    }

    _drawingMouseDown(o) {
        // If there is an active object, do nothing
        if (this._activeObject) {
            return;
        }
        let pointer = this.getPointer(o.e);
        this._drawModeData = {...pointer}
    }

    _drawingMouseMove(o) {
        // If drawing mode is not enabled, do nothing
        if (!this._drawModeData) return;

        let pointer = this.getPointer(o.e);
        let x = pointer.x, y = pointer.y

        // If drawing object doesn't exist, create and add it to the canvas
        if (!this._drawModeData.object) {

            let klass = fabric.util.getKlass(this.drawingClass, 'fabric');

            this._drawModeData.object = new klass({
                left: this._drawModeData.x,
                top: this._drawModeData.y,
                width: x - this._drawModeData.x,
                height: y - this._drawModeData.y,
                ...this.drawingOptions
            });
            this.add(this._drawModeData.object);
            this.fire("drawing:start", {target: this._drawModeData.object})
        }

        // Adjust the position and dimensions of the drawing object
        if (this._drawModeData.x > x) {
            this._drawModeData.object.set({left: x});
        }
        if (this._drawModeData.y > y) {
            this._drawModeData.object.set({top: y});
        }
        this._drawModeData.object.set({
            width: Math.abs(x - this._drawModeData.x),
            height: Math.abs(y - this._drawModeData.y)
        })

        this.fire("drawing", {target: this._drawModeData.object})
        this.renderAll();
    }

    _drawingMouseUp() {
        // If no drawing object exists, do nothing
        if (!this._drawModeData) {
            return
        }
        if (!this._drawModeData.object) {
            delete this._drawModeData
            return
        }

        // Set coordinates for the drawing object, trigger events, and set it as active
        this._drawModeData.object.setCoords()
        this.fire('object:placed', {target: this._drawModeData.object});
        this._drawModeData.object.fire('placed');
        // this.setActiveObject(this._drawModeData.object)

        this.fire("drawing:end", {target: this._drawModeData.object})
        // Clean up drawing-related properties
        delete this._drawModeData;
    }

    translateViewport(x, y) {

        let zoom = this.getZoom()
        if (y > this.height / 2) {
            y = this.height / 2
        }
        if (x > this.width / 2) {
            x = this.width / 2
        }
        if (y < this.height / 2 - this.getOriginalHeight() * zoom - 10) {
            y = this.height / 2 - this.getOriginalHeight() * zoom - 10
        }
        if (x < this.width / 2 - this.getOriginalWidth() * zoom - 10) {
            x = this.width / 2 - this.getOriginalWidth() * zoom - 10
        }

        this.viewportTransform[4] = x;
        this.viewportTransform[5] = y;

        this.fire('viewport:translate', {
            x: this.viewportTransform[4],
            y: this.viewportTransform[5]
        });

        this.renderAll();
        for (let i = 0, len = this._objects.length; i < len; i++) {
            this._objects[i].setCoords();
        }
    }

    _handModeMouseMove(e) {
        if (this._activeObject) {
            return
        }
        if (!this._handModeData) {
            return
        }
        let event = e.e
        this._handModeData.state = "move";

        let pageY, pageX
        if (event.touches?.length === 2) {
            pageY = (event.touches[0].clientY + event.touches[1].clientY) / 2
            pageX = (event.touches[0].clientX + event.touches[1].clientX) / 2
        } else {
            if (this.allowSingleTouchPanning) {
                if (event.touches?.length) {
                    pageY = event.touches[0].clientY
                    pageX = event.touches[0].clientX
                } else {
                    pageY = event.pageY
                    pageX = event.pageX
                }
            } else {
                return;
            }
        }

        if (pageY === this._handModeData.dragCursorPosition.y && pageX === this._handModeData.dragCursorPosition.x) {
            return;
        }

        let scroll = {
            x: this.viewportTransform[4],
            y: this.viewportTransform[5]
        };

        this.translateViewport(scroll.x - (this._handModeData.dragCursorPosition.x - pageX), scroll.y - (this._handModeData.dragCursorPosition.y - pageY))


        this._handModeData.dragCursorPosition.y = pageY;
        this._handModeData.dragCursorPosition.x = pageX;
    }

    _handModeMouseUp() {
        delete this._handModeData;
    }

    _handModeMouseDown(e) {
        if (this._activeObject) {
            return
        }
        let event = e.e


        if (event.touches?.length === 2) {
            this._handModeData = {
                state: "down",
                dragCursorPosition: {
                    y: (event.touches[0].clientY + event.touches[1].clientY) / 2,
                    x: (event.touches[0].clientX + event.touches[1].clientX) / 2
                }
            }
        } else if (this.allowSingleTouchPanning) {
            if (event.touches?.length) {
                this._handModeData = {
                    state: "down",
                    dragCursorPosition: {
                        y: event.touches[0].clientY,
                        x: event.touches[0].clientX
                    }
                }
            } else {
                this._handModeData = {
                    state: "down",
                    dragCursorPosition: {
                        y: event.pageY,
                        x: event.pageX
                    }
                }
            }
        } else {
            return
        }
    }

    trackCanvas(variable = "canvas") {
        window[variable] = this
    }

    trackTarget() {
        this.on("selection:created", () => this._trackTarget())
        this.on("selection:updated", () => this._trackTarget())
        this.on("selection:cleared", () => this._trackTarget())
    }

    _trackTarget(variable = "target") {
        let active = this.getActiveObject()
        Object.defineProperty(window, variable, {
            value: active,
            writable: true
        })
    }

    allowEditGroupedObjects() {
        let _click = false
        this.on('mouse:down', (e) => {
            _click = true
        })
        this.on('mouse:move', (e) => {
            _click = false
        })
        this.on('mouse:up', (e) => {
            const target = e.target
            if (_click) {
                this.fire("mouse:click", e)

                if (target !== undefined) {
                    target.fire("mouseclick", e)
                    this.fire("object:mouseclick", {target, e})
                }
            }
            if (target !== undefined) {
                target.fire("mouseup", e)
            }
        })
        this.on('mouse:dblclick', (e) => {
            const object = e.target
            if (object === undefined) {
                return
            }
            if (object.constructor === fabric.IText) {
                object.enterEditing()
            }
            if (object.constructor === fabric.Group) {
                this.activeGroup = object;
                object.makeObjectsInteractive()
            }
            this.renderAll()
        })
        this.on("selection:created", (e) => this._makeActiveGroupNonInteractive(e))
        this.on("selection:updated", (e) => this._makeActiveGroupNonInteractive(e))
        this.on("selection:cleared", (e) => {

            function _check(o) {
                if (o.interactive) {
                    o.makeObjectsNonInteractive()
                    for (let object of o._objects) {
                        _check(object)
                    }
                }
            }

            for (let object of this._objects) {
                _check(object)
            }
            //this._makeActiveGroupNonInteractive(e)
        })
        this.on("object:added", (e) => e.target._onScale())
        this.on("object:modified", (e) => e.target._onScale())
    }

    _makeActiveGroupNonInteractive(e) {
        let object = this.getActiveObject()

        if (this.activeGroup) {
            let activeGroups = []
            if (object) {
                while (object) {
                    if (object === this.activeGroup) {
                        return
                    }
                    object = object.group
                    if (object) {
                        activeGroups.push(object)
                    }
                }
            }
            this.activeGroup.makeObjectsNonInteractive()


            let group = this.activeGroup
            while (group) {
                if (!activeGroups.includes(group)) {
                    group.makeObjectsNonInteractive()
                }
                // const group = object.group;
                // this.activeGroup = group;
                // group.set({
                //     interactive: false,
                //     subTargetCheck: false
                // })
                group = group.group
            }


            delete this.activeGroup
        }
    }

    _checkTarget(pointer, obj, globalPointer) {
        //add globalPointer especially for Arrows bounding box check. it is better to use pointer instead and not override this function
        if (obj && obj.visible && obj.evented && obj.containsPoint(pointer, null, null, null, globalPointer)) {
            if ((this.perPixelTargetFind || obj.perPixelTargetFind) && !obj.isEditing) {
                if (!this.isTargetTransparent(obj, globalPointer.x, globalPointer.y)) {
                    return true;
                }
            } else {
                return true;
            }
        }
        return false;
    }

    onKeyboard(e) {
        if (e.target.tagName !== "BODY" && e.target.tagName !== "DIV") {
            return
        }

        let active = this.getActiveObject()
        //allow copy of the objects content. and prevent copy of the object
        let ctrl = e.ctrlKey || e.metaKey
        let key = e.code;
        if (e.key === 'c') {
            key = 'KeyC'
        }
        if (e.key === 'v') {
            key = 'KeyV'
        }


        if (ctrl) {
            switch (key) {
                case 'KeyG':
                    if (active.constructor === fabric.Group) {
                        active.ungroupObjects()
                    } else if (active.constructor === fabric.ActiveSelection) {
                        this.groupObjects()
                    }
                    e.preventDefault()
                    return
                case 'KeyZ':
                    // if(active?.text){
                    //     return
                    // }
                    this.undo();
                    e.preventDefault()
                    return
                case 'KeyY':
                    // if(active?.text){
                    //     return
                    // }
                    this.redo();
                    e.preventDefault()
                    return
                case 'KeyC':
                    active && this.copy()
                    e.preventDefault()
                    return
                case 'KeyV':
                    this.paste()
                    e.preventDefault()
                    return
                default:
                    return
            }
        }
        if (active) {
            switch (key) {
                case 'Backspace':
                case 'Delete':
                    if (active.isEditing && active.removeSelection) {
                        active.removeSelection()
                    }
                    else{
                        this.removeSelection()
                    }
                    break;
                case 'ArrowLeft':
                    active.set({left: active.left - this.moveStep})
                    active.setCoords()
                    this.renderAll()
                    break;
                case 'ArrowRight':
                    active.set({left: active.left + this.moveStep})
                    active.setCoords()
                    this.renderAll()
                    break;
                case 'ArrowDown':
                    active.set({top: active.top + this.moveStep})
                    active.setCoords()
                    this.renderAll()
                    break;
                case 'ArrowUp':
                    active.set({top: active.top - this.moveStep})
                    active.setCoords()
                    this.renderAll()
                    break;
                default:
                    return
            }
        } else {
            switch (key) {
                case 'ArrowLeft':
                    // let zoom = this.getZoom()
                    this.translateViewport(this.viewportTransform[4] + 100, this.viewportTransform[5])
                    break;
                case 'ArrowRight':
                    this.translateViewport(this.viewportTransform[4] - 100, this.viewportTransform[5])
                    break;
                case 'ArrowDown':
                    this.translateViewport(this.viewportTransform[4], this.viewportTransform[5] - 100)
                    break;
                case 'ArrowUp':
                    this.translateViewport(this.viewportTransform[4], this.viewportTransform[5] + 100)
                    break;
                default:
                    return
            }
        }
    }

    enableAutoScrolling() {
        let offset = 25
        let cursorOffset
        this.scrollingInterval = setInterval(() => {
            if (cursorOffset?.left < offset) {
                this.horizontalScrollElement.scrollLeft -= offset - cursorOffset?.left
            }
            if (cursorOffset?.right < offset) {
                this.horizontalScrollElement.scrollLeft += offset - cursorOffset?.right
            }
            if (cursorOffset?.top < offset) {
                this.verticalScrollElement.scrollTop -= offset - cursorOffset?.top
            }
            if (cursorOffset?.bottom < offset) {
                this.verticalScrollElement.scrollTop += offset - cursorOffset?.bottom
            }
            // this._onMouseMove(event)
        }, 50)

        this.on("mouse:up", () => {
            cursorOffset = null
        })


        this.on("object:moving", (e) => {
            let _height = (this.lowerCanvasEl.height - 11)
            let _width = (this.lowerCanvasEl.width - 11)
            if (e.e.target === this.upperCanvasEl) {
                cursorOffset = {
                    left: e.e.offsetX,
                    top: e.e.offsetY,
                    right: _width - e.e.offsetX,
                    bottom: _height - e.e.offsetY
                }
            } else {
                cursorOffset = null
            }
        })
    }

    isModified() {
        let currentHistoryState = this.history.getCurrent().id
        if (currentHistoryState === 0) {
            return false
        }
        return this.lastSavedID !== currentHistoryState
    }

    setUseKeyboard() {
        // Event listener for keyboard shortcuts
        window.onkeydown = this.onKeyboard.bind(this)
    }

    async createObjects(value) {
        let promises = []
        for (let element of value) {
            promises.push(this.createObject(element))
        }
        return Promise.all(promises)
    }

    async createObject(value) {
        let object = await fabric.Object.create(value)
        this.add(object)
        this.requestRenderAll()
    }

    copy() {
        let active = this.getActiveObject()
        if (active && !active.onCopy?.()) {
            window.localStorage.setItem("memory", JSON.stringify(active.toObject()))
        }
    }

    selectObjects(objects, options) {
        this.discardActiveObject()
        let object = this._activeSelection
        options && object.set(options)
        for (let obj of objects) {
            object.add(obj)
        }
        options && object.set(options)

        this._setActiveObject(object, null);
        this.renderAll()

        return object
    }

    async paste() {
        let active = this.getActiveObject()
        if (active?.onPaste?.()) {
            return
        }
        this.history.transactionStart()

        let copiedData = JSON.parse(window.localStorage.getItem("memory"))
        if (!copiedData) {
            return
        }
        let as = copiedData.type === "activeselection"

        let object, objects
        if (as) {
            let group = await fabric.Object.create({...copiedData, type: "group"})
            objects = [...group._objects]
            group.ungroupObjects()

            for (let obj of objects) {
                this.add(obj)
            }
            // object = new fabric.ActiveSelection([], { ...copiedData, canvas: this, });

            object = this.selectObjects(objects, copiedData)
        } else {
            object = await fabric.Object.create({...copiedData})
            objects = [object]
            this.add(object)
        }

        this.setActiveObject(object)

        if (this.pasteDirection === "right") {
            let offset = object.width * object.scaleX + this.pasteGap
            copiedData.left += offset
            window.localStorage.setItem("memory", JSON.stringify(copiedData))
            object.left += offset
        }
        // if(as){
        //     object.canvas = this
        //     // let objects = [...object._objects]
        //     // object.ungroupObjects()
        //     // let selectionOption = {...this.copiedObject}
        //     // delete this.copiedObject.objects
        //
        //     // this.discardActiveObject()
        //     // this._activeSelection.set(selectionOption);
        //     //
        //     // for(let i = 0; i < objects.length;i++){
        //     //     this._activeSelection.multiSelectAdd(objects[i]);
        //     // }
        // }
        // else{


        this.history.transactionEnd({
            objects,
            undoCallback: () => {
                this.discardActiveObject()
            },
            redoCallback: () => {
                this.selectObjects(objects)
            }
        })


        // if(as){
        //     object.ungroupObjects()
        // }
        this.renderAll()
    }

    drawControls(ctx) {
        const activeObject = this._activeObject;
        for (let object of this._objects) {
            if (object !== activeObject && object.active) {
                object._renderControls(ctx);
            }
        }
        if (activeObject) {
            activeObject._renderControls(ctx);
        }
    }

    __checkClick(e, value) {
        return 'which' in e ? e.which === value : e.button === value - 1;
    }

    __onMouseDown(e) {
        const RIGHT_CLICK = 3, MIDDLE_CLICK = 2;
        this._isClick = true;
        this._cacheTransformEventData(e);
        this._handleEvent(e, 'down:before');
        let target = this._target;
        if (this.__checkClick(e, RIGHT_CLICK)) {
            if (this.fireRightClick) {
                this._handleEvent(e, 'down', RIGHT_CLICK);
            }
            return;
        }

        if (this.__checkClick(e, MIDDLE_CLICK)) {
            if (this.fireMiddleClick) {
                this._handleEvent(e, 'down', MIDDLE_CLICK);
            }
            return;
        }

        // if (this.isDrawingMode) {
        //     this._onMouseDownInDrawingMode(e);
        //     return;
        // }

        if (!this._isMainEvent(e)) {
            return;
        }

        if (this._currentTransform) {
            return;
        }

        let shouldRender = this._shouldRender(target);
        let grouped = false;
        if (this.handleMultiSelection(e, target)) {
            target = this._activeObject;
            grouped = true;
            shouldRender = true;
        } else if (this._shouldClearSelection(e, target)) {

            if (!this.__selectionDisabled) {
                this.discardActiveObject(e);
            }
        }

        if (this.selection && (!target || (!target.selectable && !target.isEditing && target !== this._activeObject))) {
            const p = this.getPointer(e);
            this._groupSelector = {
                x: p.x,
                y: p.y,
                deltaY: 0,
                deltaX: 0,
            };
        }

        if (target) {
            //edited
            const alreadySelected = target === this._activeObject || target.active;

            if (!this.__selectionDisabled) {


                if (this.tableEditingMode && (this._activeObject.group === target || this.targets.includes(this._activeObject))) {
                    //object inside table was selected
                } else {

                    if (target !== this._activeObject && target.selectable && target.activeOn === 'down') {
                        this.setActiveObject(target, e);
                    }
                }
            }

            const corner = target._findTargetCorner(
                this.getPointer(e, true),
                fabric.util.isTouchEvent(e)
            );
            if (target === this._activeObject /*&& !target.isEditing*/ && (corner || !grouped)) {

                if (!target.isEditing) {
                    target.__originalState = (({
                                                   left,
                                                   top,
                                                   angle,
                                                   skewX,
                                                   skewY,
                                                   scaleX,
                                                   scaleY,
                                                   flipX,
                                                   flipY,
                                                   width,
                                                   height
                                               }) => ({
                        left,
                        top,
                        skewX,
                        skewY,
                        angle,
                        scaleX,
                        scaleY,
                        flipX,
                        flipY,
                        width,
                        height
                    }))(target)
                }
                this._setupCurrentTransform(e, target, alreadySelected);
                const control = target.controls[corner],
                    pointer = this.getPointer(e),
                    mouseDownHandler = control && control.getMouseDownHandler(e, target, control);
                if (mouseDownHandler) {
                    mouseDownHandler(e, this._currentTransform, pointer.x, pointer.y);
                }
            }
        }

        shouldRender && (this._objectsToRender = undefined);
        this._handleEvent(e, 'down');
        shouldRender && this.requestRenderAll();
    }

    findTarget(e) {
        if (this.skipTargetFind) {
            return undefined;
        }

        const pointer = this.getPointer(e, true),
            activeObject = this._activeObject,
            aObjects = this.getActiveObjects();

        this.targets = [];

        if (activeObject && aObjects.length >= 1) {
            if (activeObject._findTargetCorner(pointer, fabric.util.isTouchEvent(e))) {
                return activeObject;
            }
            for (let object of this._objects) {
                if (object.active && object._findTargetCorner(pointer, fabric.util.isTouchEvent(e))) {
                    return object;
                }
            }

            let subtarget = this.searchPossibleTargets([activeObject], pointer)
            if (subtarget) {
                return subtarget;
            } else {
                if (!this.preserveObjectStacking) {
                    return activeObject;
                } else {
                    const subTargets = this.targets;
                    this.targets = [];
                    const target = this.searchPossibleTargets(this._objects, pointer);
                    if (e[this.altSelectionKey] && target && target !== activeObject) {
                        this.targets = subTargets;
                        return activeObject;
                    }
                    return target;
                }
            }
        }
        return this.searchPossibleTargets(this._objects, pointer);
    }

    _removeObject(activeSelection) {
        let group = activeSelection.group
        if (group) {
            //todo ugly
            activeSelection.__removed = true;
            group.remove(activeSelection)
            group.setDirty(true)
        } else {
            this.remove(activeSelection)
        }
    }

    setActiveObjects(objects, selectionState) {
        let as = this._activeSelection
        this.discardActiveObject()
        as._objects.length = []
        this._hoveredTarget = as
        this._setActiveObject(as, null);
        if (selectionState) {
            as.set(selectionState)
        }
        as.multiSelectAdd(...objects);
        this.renderAll()
    }

    removeSelection() {
        let activeSelection = this.getActiveObject()
        if (!activeSelection) {
            return
        }
        if (activeSelection.constructor === fabric.ActiveSelection) {
            this.removeObjects(activeSelection.getObjects(), activeSelection)
        } else {
            if (this.getActiveObject() === activeSelection) {
                this.discardActiveObject()
            }
            this._removeObject(activeSelection)
        }
        this.renderAll()
    }

    async removeObjects(objects, activeSelection) {
        objects = [...objects]

        let selection = activeSelection ? (({left, top, angle, scaleX, scaleY, width, height}) => ({
            left,
            top,
            angle,
            scaleX,
            scaleY,
            width,
            height
        }))(activeSelection) : null;

        let objectsData = []
        for (let object of objects) {
            let index = this._objects.indexOf(object);
            objectsData.push({object, index})
        }

        this.discardActiveObject()

        this.disableHistory()
        for (let object of objects) {
            this._removeObject(object)
        }

        this.enableHistory()
        this.fire("objects:removed", {objects: objectsData, selection})
    }

    async groupObjects() {
        let activeSelection = this.getActiveObject()
        if (!activeSelection) {
            return
        }
        let objectsInGroup = activeSelection.getObjects()

        let options = activeSelection.toObject()
        delete options.objects
        let newGroup = new fabric.Group([], options)

        // let newGroup = await activeSelection.clone()
        this.discardActiveObject()
        this.disableHistory()
        objectsInGroup.forEach(object => {
            this.remove(object)
            newGroup.add(object)
        })
        this.add(newGroup)
        this.setActiveObject(newGroup)
        this.enableHistory()
        this.fire("objects:grouped", {objects: objectsInGroup, target: newGroup})
        this.renderAll()
    }

    setBackgroundRect(options) {
        this.backgroundRect = new fabric.Rect({
            width: this.originalWidth,
            height: this.originalHeight,
            selectable: false,
            evented: false,
            strokeWidth: 0,
            ...options
        })
    }

    async setBackgroundObjectFromSelection(object) {
        if (!object) {
            this.backgroundObject = null;
            this.renderAll()
            return;
        }

        let {width, height, left, top} = object.getBoundingRect(true)

        this.backgroundObject = new fabric.Group([], {width, height, left, top})
        this.backgroundObject.canvas = this;
        if (object.constructor === fabric.ActiveSelection) {
            let objects = [...object._objects]
            this.removeSelection()
            let {width, height, scaleX, scaleY, top, left, angle, skewX, skewY, flipX, flipY,} = object
            let group = new fabric.Group(objects, {
                width,
                height,
                scaleX,
                scaleY,
                top,
                left,
                angle,
                skewX,
                skewY,
                flipX,
                flipY
            })
            this.backgroundObject.add(group)
        } else {
            this.backgroundObject.add(object)
            this.remove(object)
        }
        // this.backgroundObject.set({
        //     left:0,
        //     top: 0,
        //     scaleX: this.originalWidth / width ,
        //     scaleY: this.originalHeight / height
        // })

        this.fire("background-object:modified")
        this.renderAll();
    }

    setBackgroundObject(object) {
        this.backgroundObject = object
        this.backgroundObject.canvas = this;
        this.renderAll();
    }

    _renderBackground(ctx) {
        let v = this.viewportTransform
        ctx.save();
        ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
        if (this.backgroundRect) {
            this._renderObjects(ctx, [this.backgroundRect]);
        }
        if (this.backgroundObject) {
            this._renderObjects(ctx, [this.backgroundObject]);
        }
        ctx.restore();

        this._renderBackgroundOrOverlay(ctx, 'background');
    }

    remove(...objects) {
        const array = this._objects, removed = [];
        objects.forEach((object) => {
            const index = array.indexOf(object);
            // only call onObjectRemoved if an object was actually removed
            if (index !== -1) {
                array.splice(index, 1);
                removed.push(object);
                this._onObjectRemoved(object, index);
            }
        });
        return removed;
    }

    _onObjectRemoved(obj, index) {
        this._objectsToRender = undefined;
        // removing active object should fire "selection:cleared" events
        if (obj === this._activeObject) {
            this.fire('before:selection:cleared', {deselected: [obj]});
            this._discardActiveObject();
            this.fire('selection:cleared', {deselected: [obj]});
            obj.fire('deselected', {
                target: obj,
            });
        }
        if (obj === this._hoveredTarget) {
            this._hoveredTarget = undefined;
            this._hoveredTargets = [];
        }
        // super._onObjectRemoved(obj);
        obj._set('canvas', undefined);
        this.fire('object:removed', {target: obj, index});
        obj.fire('removed', {target: this});
    }

    getOriginalCenterPoint() {
        return {
            top: (this.originalHeight || this.getHeight()) / 2,
            left: (this.originalWidth || this.getWidth()) / 2
        }
    }

    setOriginalSize(w, h) {
        if (w.constructor === Number) {
            this.originalWidth = w
            this.originalHeight = h
        } else {
            let object = w
            this.originalWidth = object.width
            this.originalHeight = object.height
        }
        this.fire('resized')
        return this;
    }

    setOriginalWidth(value) {
        this.originalWidth = value;
        if (!this.stretchable) {
            this.setWidth(value);
        }
        this.fire('resized')
    }

    setOriginalHeight(value) {
        this.originalHeight = value;
        if (!this.stretchable) {
            this.setHeight(value);
        }
        this.fire('resized')
    }

    getOriginalSize() {
        return {
            width: this.originalWidth || this.width || 0,
            height: this.originalHeight || this.height || 0
        }
    }

    getOriginalWidth() {
        return this.originalWidth || this.width || 0;
    }

    getOriginalHeight() {
        return this.originalHeight || this.height || 0;
    }

    updateCanvasSize() {
        let options = this.stretchingOptions;
        let _parent = this.getRelativeContainer();
        if (!_parent) return;

        let marginX = options.marginX || options.margin || 0
        let marginY = options.marginY || options.margin || 0

        let _w = _parent.clientWidth - marginX * 2,
            _h = _parent.clientHeight - marginY * 2;
        if (options.maxWidthRate) {
            _w *= options.maxWidthRate;
        }
        if (options.maxHeightRate) {
            _w *= options.maxHeightRate;
        }
        if (options.maxWidth) {
            _w = Math.min(options.maxWidth, _w);
        }
        if (options.maxHeight) {
            _h = Math.min(options.maxHeight, _h);
        }
        if (_w <= 0 || _h <= 0) return;

        this.setDimensions({
            width: _w /*- _offset.left*/,
            height: _h /*- _offset.top*/
        });
        this.renderAll()
        this.fire('resized')
    }

    _onResize() {
        if (this.stretchable) {
            this.updateCanvasSize()
        } else {
            this.calcOffset();
        }
    }

    getRelativeContainer() {
        // if (this._scrollContainer) return this._scrollContainer;
        if (!this.wrapperEl?.parentNode) return null;

        function getRelativeContainer(el) {
            do {
                if (window.getComputedStyle(el).position !== "static") {
                    return el;
                }
                el = el.parentElement;
            } while (el);
            return document.body;
        }

        let el = getRelativeContainer(this.wrapperEl.parentNode);
        this._scrollContainer = el
        return el;
    }

    getScrollContainer() {
        // if (this._scrollContainer) return this._scrollContainer;
        if (!this.wrapperEl?.parentNode) return null;

        function getScrollContainer(el) {
            do {
                if (window.getComputedStyle(el).overflow !== "visible") {
                    return el;
                }
                el = el.parentElement;
            } while (el);
            return document.body;
        }

        let el = getScrollContainer(this.wrapperEl.parentNode);

        this._scrollContainer = el
        return el;
    }

    setStretchingOptions(val) {
        this.stretchingOptions = val;
        if (!this.stretchable) return
        if (this.lowerCanvasEl) {
            this._onResize();
        }
    }

    setStretchable(val) {
        this.stretchable = val;
        if (!this.stretchable) return

        this.wrapperEl.style.width = "100%"
        this.wrapperEl.style.height = "100%"

        // this.resizeObserver = new ResizeObserver(() => {
        //   setTimeout(()=>{
        //     this._onResize()
        //   })
        // })
        // this.resizeObserver.observe(this.wrapperEl);

        let _width = this.lowerCanvasEl.width
        let _height = this.lowerCanvasEl.height
        let interval = setInterval(() => {
            if (!this.lowerCanvasEl) {
                clearInterval(interval)
            } else if (this.lowerCanvasEl && (_width !== this.lowerCanvasEl.width || _height !== this.lowerCanvasEl.height)) {
                _width = this.lowerCanvasEl.width
                _height = this.lowerCanvasEl.height
                this._onResize();
            }
        }, 100)

        if (this.lowerCanvasEl) {
            this._onResize();
        }
    }

    zoomIn() {
        let point = this.getOrignalCenter();
        let scaleValue = this.getZoom() * this.scaleFactor;

        let _max = this.getMaxZoom();
        let _min = this.getMinZoomOptions().scale;
        if (scaleValue > _max) scaleValue = _max;
        if (scaleValue < _min) scaleValue = _min;

        this.zoomToPoint(point, scaleValue);
    }

    zoomOut() {
        let point = this.getOrignalCenter();
        let scaleValue = this.getZoom() / this.scaleFactor;

        let _max = this.getMaxZoom();
        let _min = this.getMinZoomOptions().scale;
        if (scaleValue > _max) scaleValue = _max;
        if (scaleValue < _min) scaleValue = _min;
        this.zoomToPoint(point, scaleValue);
    }

    setMouseWheelZoom(val) {
        this.mouseWheelZoom = val;
        this.on("mouse:wheel", this.wheelZoom);
    }

    zoomToPoint(point, newZoom) {
        if (this.changeDimensionOnZoom) {
            let size = this.getOriginalSize()
            this.setDimensions({
                width: Math.round(size.width * newZoom),
                height: Math.round(size.height * newZoom)
            }, {
                // cssOnly: true
            });
        }
        _zoomToPointNative.call(this, point, newZoom);
        this.fire('viewport:translate', {x: this.viewportTransform[4], y: this.viewportTransform[5]});
        this.fire('viewport:scaled', {scale: this.viewportTransform[0]});
        return this
    }

    resetViewport() {
        this.viewportTransform[0] = 1;
        this.viewportTransform[3] = 1;
        this.viewportTransform[4] = 0;
        this.viewportTransform[5] = 0;
        this.renderAll();
        for (let i in this._objects) {
            this._objects[i].setCoords();
        }
    }

    getMaxZoom() {
        return this.maxZoom;
    }

    getMinZoomOptions() {
        let container;
        if (this.changeDimensionOnZoom) {
            let scrollParent = this.getScrollContainer();
            container = scrollParent || this.wrapperEl;
        } else {
            container = this.wrapperEl;
        }
        let _containerSize = {
            width: container.clientWidth,
            height: container.clientHeight
        };
        let _bgSize = {
            width: this.originalWidth || this.width,
            height: this.originalHeight || this.height
        };
        let _maxSize = {
            width: _containerSize.width * this.minZoom,
            height: _containerSize.height * this.minZoom
        };
        let size = getProportions(_bgSize, _maxSize);

        if (size.scale > 1) {
            return {
                scale: 1,
                width: this.originalWidth,
                height: this.originalHeight,
            }
        }

        return size;
    }

    centerAndZoomOut(options = {}) {
        if (!this.lowerCanvasEl) {
            return;
        }
        let useWindowSize = options.useWindowSize || false
        let zoomScaleFacto = options.scaleFactor || 1
        let minzoomoptions = this.getMinZoomOptions();
        if (this.changeDimensionOnZoom) {
            this.setZoom(minzoomoptions.scale * zoomScaleFacto);
            let scrollParent = this.getScrollContainer();
            if (scrollParent) {
                scrollParent.scrollTop = (scrollParent.scrollHeight - scrollParent.clientHeight) / 2;
                scrollParent.scrollLeft = (scrollParent.scrollWidth - scrollParent.clientWidth) / 2;
            }
        } else {
            let _containerSize = {
                width: useWindowSize ? window.innerWidth : this.wrapperEl.clientWidth,
                height: useWindowSize ? window.innerHeight : this.wrapperEl.clientHeight
            };
            let vpt = this.viewportTransform.slice(0);
            vpt[0] = minzoomoptions.scale * zoomScaleFacto
            vpt[3] = minzoomoptions.scale * zoomScaleFacto
            vpt[4] = (_containerSize.width - minzoomoptions.width) / 2;
            vpt[5] = (_containerSize.height - minzoomoptions.height) / 2;

            this.setViewportTransform(vpt);
        }
        this.fire('viewport:translate', {x: this.viewportTransform[4], y: this.viewportTransform[5]});
        this.fire('viewport:scaled', {scale: this.viewportTransform[0]});
    }

    centerOnObject(tag) {
        let br = tag.getBoundingRect();
        let ct = this.viewportTransform;
        br.width /= ct[0];
        br.height /= ct[3];
        let size = {
            width: br.width * 1.1,
            height: br.height * 1.1
        };
        let sizeOptions = getProportions(size, this);
        let _w = (this.width / sizeOptions.scale - size.width) / 2;
        let _h = (this.height / sizeOptions.scale - size.height) / 2;
        let _l = (br.left - ct[4]) / ct[0];
        let _t = (br.top - ct[5]) / ct[3];
        let x2 = [
            sizeOptions.scale,
            0, 0,
            sizeOptions.scale,
            -_l * sizeOptions.scale + (br.width * 0.05 + _w) * sizeOptions.scale,
            -_t * sizeOptions.scale + (br.height * 0.05 + _h) * sizeOptions.scale
        ];

        this.setViewportTransform(x2);
        this.fire("viewport:scaled", {scale: sizeOptions.scale});
        this.renderAll();
    }

    wheelZoom(e) {
        let event = e.e;

        if (!this.mouseWheelZoom || (this.zoomCtrlKey && !event.ctrlKey)) {


            this._scrollEvent(event)
            //event.preventDefault()

            return false;
        }
//Find nearest point, that is inside image END
        let zoomStep;// = 0.1 * event.deltaY;
        if (event.deltaY < 0) {
            zoomStep = 1 + this.zoomStep;
        } else {
            zoomStep = 1 - this.zoomStep;
        }

        let cZoom = this.getZoom();
        let newZoom = cZoom * zoomStep;
        let minZoom = this.getMinZoomOptions().scale;

        let maxZoom = this.getMaxZoom()
        if (newZoom > maxZoom) {
            newZoom = maxZoom;
        }

        if (this.zoomToPointEnabled) {
            let point = new fabric.Point(event.offsetX, event.offsetY);
            let _x = this.viewportTransform[4];
            let _y = this.viewportTransform[5];

            // Find nearest point, that is inside image
            // It is needed to prevent canvas to zoom outside image
            if (this.originalWidth) {
                let _w = this.originalWidth * cZoom + _x;

                if (point.x < _x) {
                    point.x = _x;
                }
                if (point.x > _w) {
                    point.x = _w;
                }
            }
            if (this.originalHeight) {
                let _h = this.originalHeight * cZoom + _y;
                if (point.y < _y) {
                    point.y = _y;
                }
                if (point.y > _h) {
                    point.y = _h;
                }
            }

            if (minZoom > newZoom) {
                if (this.autoCenterAndZoomOut) {
                    this.centerAndZoomOut();
                } else if (event.deltaY < 0) {
                    this.zoomToPoint(point, newZoom);
                }
            } else {
                this.zoomToPoint(point, newZoom);
            }
        } else {
            this.setZoom(newZoom);
        }
        for (let i in this._objects) {
            this._objects[i].setCoords();
        }


        this.fire('viewport:translate', {x: this.viewportTransform[4], y: this.viewportTransform[5]});
        this.fire('viewport:scaled', {scale: this.viewportTransform[0]});

        this.renderAll();
        event.stopPropagation();
        event.preventDefault();
        return false; //preventing scroll page
    }

    getOrignalCenter() {
        return {
            x: (this.width / 2) * this.viewportTransform[0] + this.viewportTransform[4],
            y: (this.height / 2) * this.viewportTransform[3] + this.viewportTransform[5]
        };
    }

    updateScrollbars() {
        let zoom = this.getZoom()
        let vt = this.viewportTransform
        let x = vt[4]
        let y = vt[5]
        // if(x >0 ){
        //     this.horizontalScrollElementBg.style.display = "none"
        // }

        this.verticalScrollElement.scrollTop = -y + this.height / 2
        this.horizontalScrollElement.scrollLeft = -x + this.width / 2

        this.horizontalScrollElementBg.style.width = (this.originalWidth * zoom + this.width) + "px"
        this.verticalScrollElementBg.style.height = (this.originalHeight * zoom + this.height) + "px"
    }

    _scrollEvent(event) {
        let deltaX, deltaY
        //different on Windows?
        // if(event.shiftKey){
        //   deltaX = event.deltaY
        //   deltaY = event.deltaX
        // }
        // else{
        deltaX = event.deltaX
        deltaY = event.deltaY
        // }

        if (deltaX) {
            this.horizontalScrollElement.scrollLeft += deltaX
        }
        if (deltaY) {
            this.verticalScrollElement.scrollTop += deltaY
        }
    }

    setUseScrollbars(value) {
        if (!value) {
            return false;
        }
        this.verticalScrollElement = document.createElement("div");
        this.verticalScrollElement.classList.add("vertical-scroll");
        this.horizontalScrollElement = document.createElement("div");
        this.horizontalScrollElement.classList.add("horizontal-scroll");
        this.wrapperEl.appendChild(this.horizontalScrollElement);
        this.wrapperEl.appendChild(this.verticalScrollElement);
        this.verticalScrollElementBg = document.createElement("div");
        this.horizontalScrollElementBg = document.createElement("div");
        this.verticalScrollElement.appendChild(this.verticalScrollElementBg);
        this.horizontalScrollElement.appendChild(this.horizontalScrollElementBg);

        this.on("viewport:translate", () => {
            if (!this.scrollbarUsed) {
                this.updateScrollbars()
            }
        })
        this.on("viewport:scaled", () => this.updateScrollbars())
        this.on("resized", () => this.updateScrollbars())

        this.horizontalScrollElement.onscrollend = this.verticalScrollElement.onscrollend = (e) => {
            for (let object of this._objects) {
                object.setCoords()
            }
        }
        this.horizontalScrollElement.onscroll = this.verticalScrollElement.onscroll = (e) => {
            this.scrollbarUsed = true;
            this.viewportTransform[4] = -this.horizontalScrollElement.scrollLeft + this.width / 2
            this.viewportTransform[5] = -this.verticalScrollElement.scrollTop + this.height / 2
            this.fire('viewport:translate', {x: this.viewportTransform[4], y: this.viewportTransform[5]});
            this.requestRenderAll()
            delete this.scrollbarUsed
        }
    }

    _onMouseEnter(e) {
        this.__pointer = this.getPointer(e);
        // This find target and consequent 'mouse:over' is used to
        // clear old instances on hovered target.
        // calling findTarget has the side effect of killing target.__corner.
        // as a short term fix we are not firing this if we are currently transforming.
        // as a long term fix we need to separate the action of finding a target with the
        // side effects we added to it.
        if (!this._currentTransform && !this.findTarget(e)) {
            this.fire('mouse:over', {
                e,
                isClick: false,
                pointer: this.__pointer,
                absolutePointer: this.getPointer(e, true)
            });
            this._hoveredTarget = undefined;
            this._hoveredTargets = [];
        }
    }

    __onMouseMove(e) {
        this.__pointer = this.getPointer(e);
        this._isClick = false;
        this._handleEvent(e, 'move:before');
        this._cacheTransformEventData(e);
        if (this.isDrawingMode) {
            this._onMouseMoveInDrawingMode(e);
            return;
        }
        if (!this._isMainEvent(e)) {
            return;
        }
        const groupSelector = this._groupSelector;

        // We initially clicked in an empty area, so we draw a box for multiple selection
        if (groupSelector) {
            groupSelector.deltaX = this.__pointer.x - groupSelector.x;
            groupSelector.deltaY = this.__pointer.y - groupSelector.y;
            this.renderTop();
        } else if (!this._currentTransform) {
            const target = this.findTarget(e);
            this._setCursorFromEvent(e, target);
            this._fireOverOutEvents(e, target);
        } else {
            this._transformObject(e);
        }
        this.textEditingManager.onMouseMove(e);
        this._handleEvent(e, 'move');
        this._resetTransformEventData();
    }

    _searchPossibleTargets(objects, pointer) {
        let _targets = []
        // Cache all targets where their bounding box contains point.
        let target,
            i = objects.length;
        // Do not check for currently grouped objects, since we check the parent group itself.
        // until we call this function specifically to search inside the activeGroup
        while (i--) {
            const objToCheck = objects[i];
            const pointerToUse = objToCheck.group ? this._normalizePointer(objToCheck.group, pointer) : pointer;

            if (this._checkTarget(pointerToUse, objToCheck, pointer)) {
                target = objects[i];
                if (this.advancedObjectSelection) {
                    _targets.push(target)
                } else {
                    break;
                }
            }
        }

        if (_targets.length > 1) {
            target = _targets[0]
            for (let i = 0; i < _targets.length; i++) {
                const objToCheck = _targets[i];
                if (objToCheck.containsMousePointer(this.__pointer)) {
                    target = objToCheck
                    break;
                }
            }
        }

        if (target && target._objects && target.subTargetCheck) {
            const subTarget = this._searchPossibleTargets(target._objects, pointer);
            subTarget && this.targets.push(subTarget);
        }

        return target;
    }
}

fabric.Canvas.prototype.advancedObjectSelection = true

fabric.Object.prototype.minAdvancedSlectionBoundingBox = 5
fabric.Object.prototype.containsMousePointer = function (mousePointer) {
    const mouseLocalPosition = this.toLocalPoint(mousePointer.x, mousePointer.y)


    let ah = this.getAbsoluteHeight()
    let aw = this.getAbsoluteWidth()
    let kh = 1, kw = 1
    if (ah < this.minAdvancedSlectionBoundingBox) {
        kh = this.minAdvancedSlectionBoundingBox / ah
    }
    if (aw < this.minAdvancedSlectionBoundingBox) {
        kw = this.minAdvancedSlectionBoundingBox / aw
    }

    return Math.abs(mouseLocalPosition.x) < this.width / 2 * kw && Math.abs(mouseLocalPosition.y) < this.height / 2 * kh
}

fabric.Group.prototype.containsMousePointer = function (mousePointer) {
    const mouseLocalPosition = this.toLocalPoint(mousePointer.x, mousePointer.y)
    for (let obj of this._objects) {
        if (obj.containsMousePointer(mouseLocalPosition)) {
            return true;
        } else {
        }
    }
    return false;
}

fabric.Polygon.prototype.containsMousePointer = function (mousePointer) {
    let {x, y} = this.toLocalPoint(mousePointer.x, mousePointer.y)
    x += this.width / 2
    y += this.height / 2
    let inside = false;
    let polygon = this.points

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x, yi = polygon[i].y
        const xj = polygon[j].x, yj = polygon[j].y

        const intersect = ((yi > y) !== (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}

fabric.Circle.prototype.containsMousePointer = function (mousePointer) {
    const mouseLocalPosition = this.toLocalPoint(mousePointer.x, mousePointer.y)

    return fabric.util.calcDistance(mouseLocalPosition, {x: 0, y: 0}) < this.radius
}

CanvasExt.prototype.stretchable = false
CanvasExt.prototype.zoomCtrlKey = true
CanvasExt.prototype.mouseWheelZoom = false
CanvasExt.prototype.changeDimensionOnZoom = false
CanvasExt.prototype.zoomToPointEnabled = true
CanvasExt.prototype.maxZoom = 10
CanvasExt.prototype.autoCenterAndZoomOut = false
CanvasExt.prototype.zoomStep = 0.1
CanvasExt.prototype.scaleFactor = 1.1
CanvasExt.prototype.minZoom = 0.9
CanvasExt.prototype.stretchingOptions = {
    action: "resize"
}


fabric.util.Collection = fabric.StaticCanvas.prototype
fabric.Control.prototype.shouldActivate = function (controlKey, fabricObject) {
    return (
        (fabricObject.canvas?.getActiveObject() === fabricObject || fabricObject.active) &&
        fabricObject.isControlVisible(controlKey)
    )
}

CanvasExt.prototype.drawingClass = 'rect'
CanvasExt.prototype.drawingOptions = {}
CanvasExt.prototype.interactiveMode = "selection"
CanvasExt.prototype.moveStep = 10;
CanvasExt.prototype.pasteDirection = "right"
CanvasExt.prototype.pasteGap = 10
CanvasExt.prototype.requireDocument = true
CanvasExt.prototype.visible = true


function layerActionWrapper(fooName) {
    let _foo = CanvasExt.prototype[fooName]
    CanvasExt.prototype[fooName] = function (object, ...options) {
        const idx = this._objects.indexOf(object);
        let result = _foo.call(this, object, ...options)
        if (result) {
            const idx2 = this._objects.indexOf(object);
            if (idx2 !== idx) {
                this.fire("object:reposition", {target: object, original: idx, modified: idx2})
            }
        }
        return result
    }
}

layerActionWrapper("sendObjectToBack")
layerActionWrapper("bringObjectToFront")
layerActionWrapper("sendObjectBackwards")
layerActionWrapper("bringObjectForward")
layerActionWrapper("moveObjectTo")

fabric.Canvas = CanvasExt