import fabric from "./fabric.base.js"
import "./canvas.ext.js";
import "./canvas.tooltip.js";
import './object.ext.js'
import './group.ext.js'
import "./fabric.arrow.js";
import "./fabric.polycurve.js";
import './fabric.table.js'
import './canvas.history.js'
import './canvas.export.js'
import './fabric.textbox.js'
import './snap.js'
import './fabric.project.js'
// import "./utils/window.debug.js";

import './iso.object-defaults.js'
// import { convertDrawing , convertObject } from './iso.converter.js'

// Object.assign(fabric.util,{
//   convertObject,
//   convertDrawing
// })
window.fabric = fabric

export default fabric;