import React, { useState } from 'react';
import { icons } from '../drawer/icons'

const FullscreenToggle = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Function to enter fullscreen
  const enterFullscreen = () => {
    const elem = document.documentElement; // You can change this to a specific element if needed
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { // IE/Edge
      elem.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };

  // Function to exit fullscreen
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { // IE/Edge
      document.msExitFullscreen();
    }
    setIsFullscreen(false);
  };

  // Toggle fullscreen on button click
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  return (
    <div className={'save-button'} onClick={toggleFullscreen}>
      <div className="icon-holder">
        <span className="icon">
         <i className="fa fa-expand"></i>
        </span>
      </div>
      <span className="title"> {isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"}</span>
    </div>
  )
  ;
};

export default FullscreenToggle;