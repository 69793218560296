import fabric from './fabric.base.js'
import { CanvasExt } from './canvas.ext'
import { makeFromObject } from './object.ext'
import { FabricArrow } from './fabric.arrow'

let _shouldCache = fabric.Group.prototype.shouldCache
Object.assign(fabric.Group.prototype,{
  _shouldCache: _shouldCache,
  // constructor() {
  //
  //   let objects = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  //   let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  //   let objectsRelativeToGroup = arguments.length > 2 ? arguments[2] : undefined;
  //
  //   options = { ...GroupExt.defaults, ...options }
  //   super(objects, options,objectsRelativeToGroup)
  // },
  shouldCache () {
    if (this.interactive) return false;
    return this._shouldCache()
  },
  snapPoints ({exclude} = {}){

    let result = []
    for(let _obj of this._objects){
      if(!exclude || !exclude.includes(_obj)){
        result.push(..._obj.snapPoints({exclude}))
      }
    }

    let matrix = this.calcOwnMatrix();
    result = result.map(p => ({...fabric.util.transformPoint(p, matrix),a: p.a}))
    return result
  },
  ungroupObjectsRecoursive (){
    let result = []
    let objects = this.ungroupObjects()

    for(let _obj of objects){
      if(_obj.type === "group"){
        result.push(..._obj.ungroupObjectsRecoursive())
      }
      else{
        result.push(_obj)
      }
    }
    return result
  },
  regroup (){
    let canvas = this.canvas
    canvas.discardActiveObject()
    canvas.disableHistory()

    let objectsInGroup = this.ungroupObjectsRecoursive()

    let newGroup = new fabric.Group(objectsInGroup)

    // let newGroup = await activeSelection.clone()
    canvas.add(newGroup)
    canvas.setActiveObject(newGroup)
    canvas.enableHistory()
    canvas.fire("objects:grouped",{objects: objectsInGroup, target: newGroup})
    canvas.renderAll()


  },
  ungroupObjects (){
    let canvas = this.canvas
    let parent = this.group || canvas
    let options = this.toObject()
    delete options.objects

    let index = parent?._objects.indexOf(this)
    for(let object of this._objects) {
      object.canvas = canvas;
      this.exitGroup(object)
    }

    canvas?.disableHistory()

    let objects = [...this._objects];
    for(let obj of objects){
      if(parent?._objects.includes(obj)){
        parent?._objects.splice(parent?._objects.indexOf(obj),1)
      }
    }
    parent?._objects.splice(index,1,...objects)
    if(canvas){
      objects.forEach((object) => canvas._onObjectAdded(object));
    }
    this._objects.length = 0

    if(canvas?.getActiveObject() === this) {
      if (this.group) {
        canvas.setActiveObject(this.group)
      } else {
        canvas?.discardActiveObject()
      }
    }



    // canvas._activeSelection.set(options)
    // // let as = new fabric.ActiveSelection([], { ...options, canvas });
    // for(let obj of objects){
    //   canvas._activeSelection.add(obj)
    // }
    // canvas?.setActiveObject(canvas._activeSelection)

    for(let obj of objects){
      obj.group = this.group
    }
    canvas?.enableHistory()
    canvas?.fire("object:ungrouped",{objects: objects, target: this})

    canvas?.renderAll()
    canvas?.remove(this)
    return objects
  },
  _exitGroup (object, removeParentTransform) {
    object._set('group', undefined);
    if (!removeParentTransform) {
      fabric.util.applyTransformToObject(
        object,
        fabric.util.multiplyTransformMatrices(
          this.calcTransformMatrix(),
          object.calcTransformMatrix()
        )
      );
      object.setCoords();
    }

    //todo ugly
    if(object.__removed) {
      delete object.__removed
    }
    else{
      //todo it works for ungrouping inside group. move it away
      if(this.group){
        this.group.add(object)
      }
    }



    this._watchObject(false, object);
    const index = this._activeObjects.length > 0 ? this._activeObjects.indexOf(object) : -1;
    if (index > -1) {
      this._activeObjects.splice(index, 1);
    }
  },
  setStroke  (value){
    for(let object of this._objects){
      if(this.strokeFilter.includes(object.type)){
        object.setExtra('stroke',value)
      }
      if(this.strokeFillFilter.includes(object.type)){
        object.setExtra('fill',value)
      }
    }
  },
  getStroke  (){
    for(let object of this._objects){
      if(this.strokeFilter.includes(object.type)) {
        let value = object.getExtra('stroke')
        if (value) {
          return value
        }
      }
      if(this.strokeFillFilter.includes(object.type)) {
        let value = object.getExtra('fill')
        if (value) {
          return value
        }
      }
    }
    return null
  },
  setFill  (value){
    for(let object of this._objects){
      if(this.fillFilter.includes(object.type)){
        object.setExtra('fill',value)
      }
    }
  },
  getFill  (){
    for(let object of this._objects){
      if(this.fillFilter.includes(object.type)) {
        let value = object.getExtra('fill')
        if (value) {
          return value
        }
      }
    }
    return null
  },
  makeObjectsNonInteractive  (){
    this.set({
      interactive: false,
      subTargetCheck: false,
    });
    this.forEachObject(function (obj) {
      if (obj.type === "group") {
        obj.makeObjectsNonInteractive();
      }
    })
  },
  makeObjectsInteractive  () {
    this.set({
      interactive: true,
      subTargetCheck: true,
    })
    // this.forEachObject(obj => {
    //   obj.set({
    //     interactive: true,
    //     subTargetCheck: true,
    //   })
    //   if (obj instanceof fabric.Group) {
    //     obj.makeObjectsInteractive();
    //   }
    // });
    let group = this.group

    while (group) {
      // const group = object.group;
      // this.activeGroup = group;
      group.set({
        interactive: true,
        subTargetCheck: true
      })
      group = group.group
    }
  },
  drawObject  (ctx) {
    this._renderBackground(ctx);

    //fabric.util.debug?.groups &&
    if(this.canvas && (this.interactive || this.group?.interactive)){
      ctx.save()
      // ctx.beginPath()
      ctx.strokeStyle = this.borderColor

      let zoom =  this.canvas.getZoom()
      ctx.lineWidth = 1
      const scaling = this.getObjectScaling()
      ctx.scale(1 / scaling.x  / zoom, 1 / scaling.y  /  zoom);

      ctx.strokeRect(-this.width / 2 *zoom * scaling.x,  -this.height / 2 *zoom* scaling.y,this.width *zoom * scaling.x, this.height *zoom* scaling.y)


      if(this.interactive){
        ctx.setLineDash([ctx.lineWidth * 2,ctx.lineWidth* 2]);

        ctx.strokeRect(-(this.width - 1)/ 2 *zoom * scaling.x,  -(this.height - 1) / 2 *zoom* scaling.y,(this.width - 1) *zoom * scaling.x, (this.height - 1) *zoom* scaling.y)
      }
      // ctx.stroke()
      ctx.restore()
    }


    for (let i = 0; i < this._objects.length; i++) {
      if (this.canvas?.preserveObjectStacking && this._objects[i].group !== this) {
        ctx.save();
        ctx.transform(...fabric.util.invertTransform(this.calcTransformMatrix()));
        this._objects[i].render(ctx);
        ctx.restore();
      } else if (this._objects[i].group === this) {
        this._objects[i].render(ctx);
      }
    }
    this._drawClipPath(ctx, this.clipPath);
  },
  //
  // static _fromObject(object) {
  //   let _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}, { extraParam } = _ref2, options = fabric._objectWithoutProperties(_ref2, ["extraParam"]);
  //   return fabric.enlivenObjectEnlivables(fabric.cloneDeep(object), options).then(enlivedMap => {
  //     const allOptions = fabric._objectSpread2(fabric._objectSpread2({}, options), enlivedMap);
  //     // from the resulting enlived options, extract options.extraParam to arg0
  //     // to avoid accidental overrides later
  //     if (extraParam) {
  //       const {
  //           [extraParam]: arg0,
  //           type
  //         } = allOptions,
  //         rest = fabric._objectWithoutProperties(allOptions, [extraParam, "type"].map(fabric._toPropertyKey));
  //       // @ts-expect-error different signature
  //       return new GroupExt(arg0, rest);
  //     } else {
  //       return new GroupExt(allOptions);
  //     }
  //   });
  // }
})


fabric.Group.fromObject = function(_ref2) {

  let { objects = [] } = _ref2, options = fabric._objectWithoutProperties(_ref2, ["extraParam"]);

  options = { ...fabric.Group.defaults, ...options }

  return Promise.all([fabric.enlivenObjects(objects), fabric.enlivenObjectEnlivables(options)]).then(_ref3 => {
    let [objects, hydratedOptions] = _ref3;
    return new this(objects, fabric._objectSpread2(fabric._objectSpread2({}, options), hydratedOptions), true);
  });
}

fabric.Group.prototype.strokeFilter = ["polygon","circle","ellipse","rect","group","triangle","path"]
fabric.Group.prototype.strokeFillFilter = ["arrow","line","polycurve","text","textbox","i-text"]
fabric.Group.prototype.fillFilter = fabric.Group.prototype.strokeFilter




makeFromObject(fabric.Group)
// fabric.classRegistry.setClass(GroupExt,"group");
// fabric.classRegistry.setClass(GroupExt,"Group");



// export class ActiveSelectionExt extends fabric.ActiveSelection {
//   constructor(el, options) {
//     options = { ...fabric.ActiveSelection.defaults, ...options }
//     super(el, options)
//   }
// }
// fabric.ActiveSelection = ActiveSelectionExt
makeFromObject(fabric.ActiveSelection)


// import fabric from './fabric.base.js'
// import {makeFromObject} from "./object.ext.js";
//
// export class GroupExt extends fabric.Group {
//   static defaults = {
//     resizable: false,
//     fill: null,
//     stroke: "green",
//     strokeWidth: 1,
//   }
//
//   constructor(elements,options = {}) {
//     options = {...GroupExt.defaults, ...options}
//     super(elements,options)
//   }
//
//   // let shouldCache = fabric.Group.prototype.shouldCache
//   shouldCache () {
//     if (this.interactive) return false;
//     return super.shouldCache()
//   }
//
//   ungroupObjects(){
//     if(this.canvas.getActiveObject() === this){
//       this.canvas.discardActiveObject()
//     }
//     let parent = this.group || this.canvas
//     let index = parent._objects.indexOf(this)
//     for(let object of this._objects) {
//       this.exitGroup(object)
//     }
//     parent._objects.splice(index,1,...this._objects)
//     this._objects.forEach((object) => this.canvas._onObjectAdded(object));
//     this._objects.length = 0
//     this.canvas.renderAll()
//   }
//
//   _exitGroup(object, removeParentTransform) {
//     object._set('group', undefined);
//     if (!removeParentTransform) {
//       fabric.util.applyTransformToObject(
//           object,
//           fabric.util.multiplyTransformMatrices(
//               this.calcTransformMatrix(),
//               object.calcTransformMatrix()
//           )
//       );
//       object.setCoords();
//     }
//     if(this.group){
//       this.group.add(object)
//     }
//     this._watchObject(false, object);
//     const index = this._activeObjects.length > 0 ? this._activeObjects.indexOf(object) : -1;
//     if (index > -1) {
//       this._activeObjects.splice(index, 1);
//     }
//   }
//
//   makeObjectsNonInteractive (){
//     this.set({
//       interactive: false,
//       objectCaching: true,
//       subTargetCheck: false,
//     });
//     this.forEachObject(function (obj) {
//       if (obj instanceof fabric.Group) {
//         obj.makeObjectsNonInteractive();
//       }
//     })
//   }
//
//   makeObjectsInteractive  () {
//     this.forEachObject(obj => {
//       obj.set({
//         interactive: true,
//         objectCaching: false,
//         subTargetCheck: true,
//       });
//       if (obj instanceof fabric.Group) {
//         obj.makeObjectsInteractive();
//       }
//     });
//   }
//   С
//
//   static fromObject({objects = [],...options}) {
//     return Promise.all([fabric.util.enlivenObjects(objects), fabric.util.enlivenObjectEnlivables(options),]).then(
//         ([objects, hydratedOptions]) =>
//             new this(objects, {...options, ...hydratedOptions}, true)
//     );
//   }
// }
//
// fabric.GroupOriginal = fabric.Group;
// fabric.Group = GroupExt;
// makeFromObject(GroupExt)
// fabric.classRegistry.setClass(GroupExt,"group");
// fabric.classRegistry.setClass(GroupExt,"Group");
//
//
