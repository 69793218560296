import fabric from './fabric.base.js'
import {makeFromObject} from "./object.ext.js";

export class PolyCurve extends fabric.Polyline {
    static defaults = {
        curved: true,
        editable: false,
        closed : false,
        strokeWidth: 0,
        lineStrokeWidth: 2,
        strokeLineCap: "butt",
        cornerStyle: "circle",
        perPixelTargetFind: false,
        stroke: "#000000",
        objectCaching: false,
        fill: null,
        scaleX: 1,
        scaleY: 1,
        hasBorders: false,
        hasBoundsControls: false,
        hasPointsControls: true,
        strokeUniform: false
    }
    get type() {
        return "polycurve"
    }
    set type(value) {
    }
    fixFlipping(){

        if(this.flipX){
            this.flipHorizontally()
            this.flipX = false;
        }
        if(this.flipY){
            this.flipVertically()
            this.flipY = false;
        }
    }
    constructor(options) {
        options = { ...PolyCurve.defaults, ...options }
        let {left, top, points} = options
        delete options.points
        super (points,options);
        this.initialized = true;
        this.editable = options.editable;
        this.setBoundingBox(true);

        this.fixFlipping();
        this.on("modified",this.fixFlipping.bind(this))

        for(let point of this.points){
            point.x -= this.pathOffset.x
            point.y -= this.pathOffset.y
        }
        this.pathOffset.x = 0
        this.pathOffset.y = 0
        this.initControls()
        this.updateBounds()

        typeof left === 'number' && this.set('left', left);
        typeof top === 'number' && this.set('top', top);


        this.lineStrokeWidth = options.lineStrokeWidth || this.strokeWidth || 0
        this.strokeWidth = 0
        if(options.hasBoundsControls !== undefined){
            this.setHasBoundsControls(options.hasBoundsControls)
        }
        if(options.hasPointsControls !== undefined){
            this.setHasPointsControls(options.hasPointsControls)
        }
        // this.on("mouseclick",(e)=>{
        //     if(this.editable && !this.__corner){
        //         let mouseLocalPosition = this.toLocalPoint(e.pointer.x,e.pointer.y)
        //         this.addPoint(mouseLocalPosition)
        //     }
        // })
        this.on("mousedblclick",()=>{
            if(this.canvas.__selectionDisabled){
                return
            }
            let borders = !this.hasBoundsControls
            this.setHasBoundsControls(borders)
            this.setHasPointsControls(!borders)
            this.hasBorders = borders
            this.canvas.renderAll()
        })
    }
    addPoint(point){

        let polygonBaseSize = this.getObjectSizeWithStroke(),
          size = this._getTransformedDimensions(0, 0),
          finalPointPosition = {
              x: point.x * polygonBaseSize.x / size.x * this.scaleX ,
              y: point.y * polygonBaseSize.y / size.y * this.scaleY
          }


        // let __originalState = {
        //     points: [...this.points],
        //     width:  this.width,
        //     height:  this.height,
        //     left:  this.left,
        //     top:    this.top
        // };

        let index = this.points.length
        this.points[index] = finalPointPosition

        this.controls['p' + index] = new fabric.Control({
            positionHandler: this.polyCurvePositionHandler,
            mouseUpHandler: this.updateBounds.bind(this),
            actionHandler: this.controlHandler.bind(this),
            actionName: 'point',
            pointIndex: index
        })

        // this.canvas.disableHistory()
        this.updateBounds()
        this.dirty = true;
        // this.canvas.enableHistory()


        // this.__originalState = __originalState
        // this.fire("modified");
        // this.canvas?.fire("object:modified", { target: this });

        this.canvas.renderAll()
        return true
    }
    setHasPointsControls(value){
        this.hasPointsControls = value
        if(!this._controlsVisibility){
            this._controlsVisibility = {}
        }
        for(let i =0 ; i < this.points.length; i++){
            this._controlsVisibility["p" + i] = value
        }
    }
    initControls(){
        for(let index =0; index< this.points.length; index++){
            this.controls['p' + index] = new fabric.Control({
                positionHandler: this.polyCurvePositionHandler,
                mouseUpHandler: this.updateBounds.bind(this),
                actionHandler: this.controlHandler.bind(this),
                actionName: 'point',
                pointIndex: index
            })
        }
    }
    _getPointsBounds(){
        // let r = this.strokeWidth
        let min = {x: Math.min(...this.points.map(p => p.x)),y: Math.min(...this.points.map(p => p.y))}
        let max = {x: Math.max(...this.points.map(p => p.x)),y: Math.max(...this.points.map(p => p.y))}
        return  {
            left: min.x ,//- r/2,
            top: min.y ,//- r/2,
            right : max.x,
            bottom: max.y,
        }
    }
    setAbsoluteThickness (value) {
        // if(!value){
        //     return
        // }
        let k = this.getAbsoluteHeight() / this.height
        if(this.strokeUniform){
            k /= this.scaleY * (this.group ? fabric.util.qrDecompose(this.group.calcTransformMatrix()).scaleX : 1)
        }
        this.lineStrokeWidth = value / k
    }
    getAbsoluteThickness () {
        let k = this.getAbsoluteHeight() / this.height
        if(this.strokeUniform){
            k /= this.scaleY * (this.group ? fabric.util.qrDecompose(this.group.calcTransformMatrix()).scaleX : 1)
        }
        return this.lineStrokeWidth  * k
    }
    flipHorizontally(){
        for(let point of this.points){
            point.x = -point.x
        }
        this.setDirty()
        this.canvas?.requestRenderAll()
    }
    flipVertically(){
        for(let point of this.points){
            point.y = -point.y
        }
        this.setDirty()
        this.canvas?.requestRenderAll()
    }
    flipDiagonally(){
        for(let point of this.points){
            point.y = -point.y
            point.x = -point.x
        }
        this.setDirty()
        this.canvas?.requestRenderAll()
    }
    updateBounds() {

        let newBounds = this._getPointsBounds()

        let pathOffsetX =  (newBounds.left + newBounds.right)/2/* +this.strokeWidth/2 * (this.flipX? -1: 1)*/
        let pathOffsetY = (newBounds.top + newBounds.bottom)/2/* +this.strokeWidth/2 * (this.flipY? -1: 1)*/

        for(let point of this.points){
            point.x -=pathOffsetX
            point.y -=pathOffsetY
        }

        let original ={x: this.flipX ? newBounds.right :  newBounds.left, y:  this.flipY ? newBounds.bottom : newBounds.top}
        let transformed = fabric.util.transformPoint(original ,this.calcTransformMatrix(true,true))

        let rad = (this.angle - 45) * (Math.PI / 180)

        this.setExtra({
            width:  newBounds.right - newBounds.left + this.lineStrokeWidth,
            height: newBounds.bottom - newBounds.top + this.lineStrokeWidth,
            left: transformed.x + Math.sin(rad) * this.lineStrokeWidth/2 * Math.sqrt(2) * this.scaleX,
            top: transformed.y - Math.cos(rad) * this.lineStrokeWidth/2 * Math.sqrt(2) * this.scaleY,
        })
    }
    // locate controls for drawing and for interaction
    polyCurvePositionHandler(dim, finalMatrix, fabricObject) {

        let x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x),
          y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
        return fabric.util.transformPoint(
          {x: x, y: y},
          fabric.util.multiplyTransformMatrices(
            (fabricObject.canvas ? fabricObject.canvas.viewportTransform: [1,0,0,1,0,0]),
            fabricObject.calcTransformMatrix()
          )
        );
    }
    snapPoints() {
      //  let ocr = this.calcOCoords(),
          //let  cr = this.calcACoords(true),
          //xPoints = [cr.tl.x, cr.tr.x, cr.bl.x, cr.br.x],
          //yPoints = [cr.tl.y, cr.tr.y, cr.bl.y, cr.br.y],
          // xMin = Math.min(...xPoints),
          // xMax = Math.max(...xPoints),
          // yMin = Math.min(...yPoints),
          // yMax = Math.max(...yPoints),
        let  matrix = this.calcTransformMatrix(true, true);
        //   a = this.points[0],
        //   b = this.points[this.points.length - 1],
        //   a2 = this.points[1],
        //   b2 = this.points[this.points.length - 2],
        // //  c = {x: xMin + (xMax - xMin) / 2,y: yMin + (yMax - yMin) / 2},
        //   at = fabric.util.transformPoint(a,matrix),
        //   bt = fabric.util.transformPoint(b,matrix),
        //   at2 = fabric.util.transformPoint(a2,matrix),
        //   bt2 = fabric.util.transformPoint(b2,matrix)

        // at.a = fabric.util.calcAngle(at, at2)
        // bt.a = fabric.util.calcAngle(bt, bt2)

        let result = []
        for(let i = 0 ;i < this.points.length; i++ ){
            let point = this.points[i]
            let pt = fabric.util.transformPoint(point,matrix)

           //let i2 = i === 0 ? this.points.length - 1 : i - 1,
           //  a = this.points[i],
           //  a2 = this.points[i2]
            //  at = fabric.util.transformPoint(a,matrix),
            //  at2 = fabric.util.transformPoint(a2,matrix)
             // pt.a = fabric.util.calcAngle(at, at2)

            result.push(pt)
        }
       // result[0].a = fabric.util.calcAngle(at, at2)
       // result[result.length - 1].a = fabric.util.calcAngle(bt, bt2)


        return result
    }
    getObjectSizeWithStroke() {
        // let stroke = new fabric.Point(
        // 	object.strokeUniform ? 1 / object.scaleX : 1,
        // 	object.strokeUniform ? 1 / object.scaleY : 1
        // ).multiply(object.strokeWidth);
        return new fabric.Point(this.width, this.height);
    }
    // update control point
    controlHandler(eventData, transform, x, y) {
        let currentControl = this.controls[this.__corner];
        let mouseLocalPosition = this.toLocalPoint(x,y)

        let polygonBaseSize = this.getObjectSizeWithStroke(),
          size = this._getTransformedDimensions(0, 0),
          finalPointPosition = {
              x: mouseLocalPosition.x * polygonBaseSize.x / size.x * this.scaleX ,
              y: mouseLocalPosition.y * polygonBaseSize.y / size.y * this.scaleY
          }
        this.points[currentControl.pointIndex] = finalPointPosition

        //let lastIndex = "p" + (this.points.length - 1)

        if(transform.corner[0] === "p") {
            let draggedPointndex = +transform.corner.substring(1)
            if (this.canvas.snapping) {
                let snap = this.canvas.getSnapping(this, { targetPointIndex: draggedPointndex })
                if (snap) {

                    let group = this
                    let p2 = snap
                    while(group.group){
                        group = group.group
                        p2 = fabric.util.transformPoint(p2, fabric.util.invertTransform(group.calcTransformMatrix(true, true)))
                    }



                    let newLocalPosition = this.toLocalPoint(p2.x, p2.y)
                    finalPointPosition = {
                        x: newLocalPosition.x * polygonBaseSize.x / size.x * this.scaleX,
                        y: newLocalPosition.y * polygonBaseSize.y / size.y * this.scaleY
                    }
                }
            }
        }

        this.points[currentControl.pointIndex] = finalPointPosition
        return true
    }
    // define a function that can keep the polygon in the same position when we change its
    // width/height/top/left.
    anchorWrapper(anchorIndex, fn) {
        return function(eventData, transform, x, y) {
            let fabricObject = transform.target,
              absolutePoint = fabric.util.transformPoint({
                  x: (fabricObject.points[anchorIndex].x ),
                  y: (fabricObject.points[anchorIndex].y ),
              }, fabricObject.calcTransformMatrix()),
              actionPerformed = fn(eventData, transform, x, y),
              // newDim = fabricObject._setPositionDimensions({}),
              polygonBaseSize = fabricObject.getObjectSizeWithStroke(),
              newX = (fabricObject.points[anchorIndex].x ) / polygonBaseSize.x,
              newY = (fabricObject.points[anchorIndex].y ) / polygonBaseSize.y;
            fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
            return actionPerformed;
        }
    }
    /**
     * Returns svg representation of an instance
     * @return {Array} an array of strings with the specific svg representation
     * of the instance
     */
    _toSVG() {
        const  NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;

        if(this.curved) {


            if(this.closed){
                let l = this.points.length ;
                let p = this.points
                let xc = (p[l- 1].x + p[0].x ) / 2;
                let yc = (p[l- 1].y  + p[0].y ) / 2;
                let d =['M',xc,yc]

                for (let i = 0; i < l - 1; i++) {
                    let xc = (p[i].x + p[i + 1].x ) / 2;
                    let yc = (p[i].y  + p[i + 1].y ) / 2;
                    d.push('Q',p[i].x,p[i].y, xc , yc)
                }
                d.push('Q',p[l- 1].x,p[l- 1].y, xc , yc)

                return [`<path `, 'COMMON_PARTS', `d="${d.join(" ")}" />\n`];
            }
            else{
                let l = this.points.length ;
                let p = this.points
                let d =['M',p[0].x,p[0].y]

                for (let i = 1; i < l - 2; i++) {
                    let xc = (p[i].x + p[i + 1].x ) / 2;
                    let yc = (p[i].y  + p[i + 1].y ) / 2;
                    d.push('Q',p[i].x,p[i].y, xc , yc)
                }
                d.push('Q',p[l- 2].x,p[l- 2].y, p[l- 1].x,p[l- 1].y)

                return [`<path `, 'COMMON_PARTS', `d="${d.join(" ")}" />\n`];

            }
        }
        else{
            const points = []
            for (let i = 0, len = this.points.length; i < len; i++) {
                points.push(fabric.util.toFixed(this.points[i].x, NUM_FRACTION_DIGITS), ',', fabric.util.toFixed(this.points[i].y, NUM_FRACTION_DIGITS), ' ');
            }
            return [
                `<polyline `, 'COMMON_PARTS', `points="${points.join('')}" />\n`,
            ];
        }
    }
    /**
     * @private
     * @param {CanvasRenderingContext2D} ctx Context to render on
     */
    _render(ctx) {
        let len = this.points.length;


        let points = this.points
        if(this.points.length < 3){
            if(this.points.length === 1){
                points = [points[0],points[0],points[0]]
            }
            else if(this.points.length === 2){
                points = [points[0],points[1],points[1]]
            }
            else if(this.points.length === 0){
                points = [{x:0,y:0},{x:0,y:0},{x:0,y:0}]
            }
            len = 3
        }

        // if (!len || isNaN(this.points[len - 1].y)) {
        //     // do not draw if no points or odd points
        //     // NaN comes from parseFloat of a empty string in parser
        //     return;
        // }

        ctx.beginPath();

        //curved shape
        if(this.curved){
            if(this.closed){
                let xc = (points[len- 1].x + points[0].x ) / 2;
                let yc = (points[len- 1].y  + points[0].y ) / 2;

                ctx.moveTo(xc,yc);

                for (let i = 0; i < len - 1; i++)
                {
                    let xc = (points[i].x + points[i + 1].x ) / 2;
                    let yc = (points[i].y  + points[i + 1].y ) / 2;
                    ctx.quadraticCurveTo(points[i].x  , points[i].y , xc , yc );
                }

                ctx.quadraticCurveTo(points[len- 1].x , points[len- 1].y, xc , yc);

            }
            else{
                ctx.moveTo(points[0].x , points[0].y);
                for (let i = 1; i < len - 2; i++)
                {
                    let xc = (points[i].x + points[i + 1].x ) / 2;
                    let yc = (points[i].y  + points[i + 1].y ) / 2;
                    ctx.quadraticCurveTo(points[i].x  , points[i].y , xc , yc );
                }
                // curve through the last two points
                ctx.quadraticCurveTo(points[len- 2].x , points[len- 2].y, points[len- 1].x,points[len- 1].y);
            }
        }
        //normal shape
        else{
            ctx.moveTo(points[0].x , points[0].y);
            for (let i = 0; i < len; i++) {
                const point = points[i];
                ctx.lineTo(point.x, point.y);
            }
        }

        if(this.closed) {
            ctx.closePath();
        }

        if(this.lineStrokeWidth) {
            this.strokeWidth = this.lineStrokeWidth || 0;
            this._renderPaintInOrder(ctx);
            this.strokeWidth = 0;
        }
        else if(this.canvas._activeObject === this){

            ctx.save();
            const retinaScaling = this.getCanvasRetinaScaling();
            const { cornerStrokeColor, cornerDashArray, cornerColor } = this;
            const options = {
                cornerStrokeColor,
                cornerDashArray,
                cornerColor
            };
            ctx.setTransform(retinaScaling, 0, 0, retinaScaling, 0, 0);
            ctx.strokeStyle = ctx.fillStyle = options.cornerColor;
            // this.setCoords();
            ctx.beginPath()
            ctx.moveTo(this.oCoords["p0"].x,this.oCoords["p0"].y)
            this.setCoords()
            this.forEachControl((control, key) => {
                if(key[0] === "p"){
                    const p = this.oCoords[key];
                    if(p){
                        ctx.lineTo(p.x,p.y)
                    }
                }
            });
            if(this.closed){
                ctx.closePath()
            }
            ctx.stroke()
            ctx.restore();
        }
    }
    static fromObject(object) {
        return this._fromObject(object);
    }
    containsMousePointer  (mousePointer) {
        const {x, y} = this.toLocalPoint(mousePointer.x,mousePointer.y)
        let inside = false;
        let polygon = this.points

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].x, yi = polygon[i].y
            const xj = polygon[j].x, yj = polygon[j].y

            const intersect = ((yi > y) !== (yj > y)) &&
              (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }

        return inside;
    }
}

fabric.PolyCurve = PolyCurve;
makeFromObject(PolyCurve)
PolyCurve.cacheProperties  = [...fabric.Polyline.cacheProperties,"curved","closed","lineStrokeWidth"]
PolyCurve.stateProperties  = [...fabric.Polyline.stateProperties,"curved","closed","editable","lineStrokeWidth"]
PolyCurve.storeProperties  = [...fabric.Polyline.storeProperties || [],"curved","closed","editable","lineStrokeWidth"]
fabric.classRegistry.setClass(PolyCurve,"polycurve");
fabric.classRegistry.setClass(PolyCurve,"Polycurve");
fabric.classRegistry.setClass(PolyCurve,"PolyCurve");




fabric.Canvas.prototype.drawPoint = function (e){
    const p = e.pointer
    if(!this.drawingObject?.canvas){
        this.drawingObject = new fabric.PolyCurve({points: [{x: p.x,y: p.y}],curved: false})
        this.drawingObject.strokeLineCap = "round"
        this.drawingObject.lockMovementX = true
        this.drawingObject.lockMovementY = true
        this.drawingObject.selectable = false
        this.drawingObject.hoverCursor = "default"

        this.add(this.drawingObject)
        this.setActiveObject(this.drawingObject)
    }
    else{
        if(!this.drawingObject.__corner){
            let mouseLocalPosition = this.drawingObject.toLocalPoint(p.x,p.y)
            this.drawingObject.addPoint(mouseLocalPosition)
            // this.setActiveObject(this.drawingObject)
            this.renderAll()
        }
    }
}
fabric.Canvas.prototype.setDrawingMode = function(value) {
    if(value){
        this.multiLineDrawingMode =  true
        this.__selectionDisabled =  true
        this.selection = false

        this.discardActiveObject();
        for(let object of this._objects){
            object.__evented = object.evented;
            object.__selectable = object.selectable
            object.evented = false;
            object.selectable = false
        }
        this.__drawPointBinded = this.drawPoint.bind(this)
        this.on("mouse:click",this.__drawPointBinded)
    }
    else{

        if(this.drawingObject){

            if(this.drawingObject.points.length === 2){
                let properties = this.drawingObject.toObject();
                delete properties.left
                delete properties.top
                delete properties.width
                delete properties.height
                delete properties.points
                delete properties.lineStrokeWidth
                delete properties.type
                delete properties.curved
                let arrow = new fabric.Arrow({
                    ...properties,
                    lineSize: this.drawingObject.lineStrokeWidth,
                    points: this.drawingObject.points.map(p => ({x: p.x + this.drawingObject.left + this.drawingObject.width/2,y: p.y + this.drawingObject.top +  this.drawingObject.height/2}))
                })
                this.remove(this.drawingObject)
                this.add(arrow)
                this.setActiveObject(arrow)
            }
            else{
                this.drawingObject.selectable = true
                this.drawingObject.lockMovementX = false
                this.drawingObject.lockMovementY = false
                delete this.drawingObject.hoverCursor
            }

        }

        this.off("mouse:click",this.__drawPointBinded)
        delete this.drawingObject
        this.selection = true
        delete this.__drawPointBinded
        this.multiLineDrawingMode =false
        this.__selectionDisabled =false
        for(let object of this._objects){
            if(object.__evented !== undefined){
                object.evented = object.__evented;
                object.selectable = object.__selectable
            }
        }


    }
}