import React, { useState, useEffect } from 'react'
import { DATAMODELOLD } from '../../--database'
import { DATAMODEL } from '../../database/database'
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import { Button, Modal, Select, Input, Collapse, Spin, Form, Popconfirm } from 'antd'
import './ManageCategoryContent.css'
import {openNotification} from "../shared/notifications";
import { FORAGEDATA } from '../../database/local'

export const ManageCategoryContent =(props) => {
  const {
    categories,
    subcategories,
    subSubcategories,
    setCategories,
    setSubcategories,
    setSubSubcategories
  } = props


  const { Panel } = Collapse
  const [editValueForm] = Form.useForm()
  const [categoryForm] = Form.useForm()
  const [subCategoryForm] = Form.useForm()
  const [subSubcategoryForm] = Form.useForm()
  const [category, setCategory] = React.useState({})
  const [addCategoryModal, setAddCategoryModal] = React.useState(false)
  const [addSubcategoryModal, setAddSubcategoryModal] = React.useState(false)
  const [addSubSubcategoryModal, setAddSubSubcategoryModal] = React.useState(false)
  const [loadingApi, setLoadingApi] = useState(false)
  const [updated, setUpdated] = useState(false)

  const [editableValue, setEditableValue] = useState(null)

  const handleLoadCategories = async () => {
    DATAMODELOLD.loadCategories().then(setCategories)
  }

  const handleLoadSubcategories = async () => {
    DATAMODELOLD.loadSubcategories().then(setSubcategories)
  }

  const handleLoadSubSubcategories = async () => {
    DATAMODELOLD.loadSubSubcategories().then(setSubSubcategories)
  }

  useEffect(() => {
    setLoadingApi(true)
    handleLoadCategories()
    handleLoadSubcategories()
    handleLoadSubSubcategories()
    setLoadingApi(false)
  }, [])


  // check validations for the Modal form
  const handleValidations = (type, form) => {
    form
        .validateFields()
        .then(() => {
          setLoadingApi(true)
          addCategory(type, form)
        })
        .catch((error) => {
          console.log(error)
        })
  }

  // Delete a category or subcategory
  const handleDeleteOperation = async (data, type) => {
    setLoadingApi(true)
    console.log(data)
    switch (type) {
      case 'Categories':
        await DATAMODELOLD.deleteCategory(data.id)
        await handleLoadCategories()
        break;
      case 'Subcategories':
        await DATAMODELOLD.deleteSubCategory(data.id)
        await handleLoadSubcategories()
        break;
      case 'SubSubcategories':
        await DATAMODELOLD.deleteSubSubCategory(data.id)
        await handleLoadSubSubcategories()
        break;
      default:
        console.warn("wrong operation")
    }
    setLoadingApi(false)
  }

  // Edit a category or subcategory
  const handleEditOperation = async (data, type) => {
    const value = editValueForm.getFieldValue(data.id)
    if(!value){
      return;
    }
    setLoadingApi(true)

    switch (type) {
      case 'Categories':
        if(categories.some(s => s['value'] === value && s['id'] !== data.id)){
          openNotification(`This Category already exists`, 'error')
        }
        else{
          await DATAMODELOLD.updateCategory(data.id,{name: value})
          await handleLoadCategories()
        }
        break;
      case 'Subcategories':
        if(subcategories.some(s => s['value'] === value && s['parentId'] === data.parentId)) {
          openNotification(`This SubCategory already exists`, 'error')
        }
        else{
          await DATAMODELOLD.updateSubCategory(data.id,{name: value})
          await handleLoadSubcategories()
        }
        break;
      case 'SubSubcategories':
        if(subSubcategories.some(s => s['value'] === value && s['parentCategoryId'] === data.parentCategoryId && s['parentSubcategoryId'] === data.parentSubcategoryId )){
          openNotification(`This SubSubCategory already exists`, 'error')
        }
        else{
          await DATAMODELOLD.updateSubSubCategory(data.id,{name: value})
          await handleLoadSubSubcategories()
        }
        break;
      default:
        console.warn("wrong operation")
    }

    setEditableValue(null)
    setUpdated(false)
    setLoadingApi(false)
  }

  // Add a category or subcategory or sub subcategory
  const addCategory = async (type, form) => {
    const formValues = form.getFieldValue()

    switch (type) {
      case "category":
      case 'Categories':
        let categoryName = formValues.categoryName
        if (categories.some(c => c['value'] === categoryName)) {
          openNotification('This category already exists', 'error')
        } else {
          await DATAMODELOLD.createCategory({ name:categoryName })
          form.resetFields()
          setAddCategoryModal(false)
          await handleLoadCategories()
        }
        break;
      case "subCategory":
      case 'Subcategories':
        let subcategoryName = formValues.subcategoryName
        let selectedCategory = formValues.selectedCategory
        if (subcategories.some(s => s['value'] === subcategoryName && s['parentId'] === selectedCategory.key)) {
          openNotification('This subcategory already exists', 'error')
        } else {
          const filteredCategories = categories.filter((c) => c.key === selectedCategory.key)

          if (filteredCategories.length === 1) {
            const filteredCategory = filteredCategories[0]
            const filteredId = filteredCategory.key
            await DATAMODELOLD.createSubCategory({ name:subcategoryName, parentId: filteredId, parentName:filteredCategory.label })

            form.resetFields()
            setAddSubcategoryModal(false)
            await handleLoadSubcategories()
          }
        }

        break;
        case "subSubcategory":
        case "subSubCategory":
      case 'SubSubcategories':
        let subSubcategoryName = formValues.subSubcategoryName
        let subcategoryKey = formValues.subcategoryName.key
        let selectedCategoryKey = formValues.selectedCategory.key
        if (subSubcategories.some(s => (
            s['value'] === subSubcategoryName &&
            s['parentCategoryId'] === selectedCategoryKey &&
            s['parentSubcategoryId'] === subcategoryKey)
        )) {
              openNotification('This sub subcategory already exists', 'error')
        }
        else {
          const filteredCategory = categories.find((c) => c.key === selectedCategoryKey)
          const filteredSubcategory = subcategories.find((c) => c.key === subcategoryKey)

          if (filteredCategory && filteredSubcategory) {
            await DATAMODELOLD.createSubSubCategory({name: subSubcategoryName, parentCategoryId: filteredCategory.key, parentCategoryName: filteredCategory.label, parentSubcategoryId: filteredSubcategory.key, parentSubcategoryName : filteredSubcategory.label})
            form.resetFields()
            setAddSubSubcategoryModal(false)
            await handleLoadSubSubcategories()
          }
        }
        break;
      default:
        console.warn("wrong operation")
    }
    setLoadingApi(false)
  }

  return (
      <div className='settings-inner-container'>
        <div className='main-heading'>
          <h2>Categories</h2>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
          <Button
              style={{width: '100%', marginTop: '10px'}}
              onClick={() => {
                setAddCategoryModal(true)
              }}
              type='primary'
              size='medium'
          >
            Add Category
          </Button>
          <Button
              style={{width: '100%', marginTop: '10px'}}
              onClick={() => {
                setAddSubcategoryModal(true)
              }}
              type='primary'
              size='medium'
          >
            Add Subcategory
          </Button>
          <Button
              style={{width: '100%', marginTop: '10px'}}
              onClick={() => {
                setAddSubSubcategoryModal(true)
              }}
              type='primary'
              size='medium'
          >
            Add Sub Subcategory
          </Button>
        </div>
        <div className='category-block'>
          <Spin className='loader' spinning={!categories.length || loadingApi}>
            <Collapse accordion>
              {categories &&
                  categories.map((category, index) => {
                    let isEditableCategory = editableValue?.id === category.id
                    return (
                        <Panel
                            header={
                              <div className='category-row'>
                                {isEditableCategory ? (
                                    <Form form={editValueForm}>
                                      <Form.Item
                                          name={category.id}
                                          rules={[
                                            {required: true, message: 'Category name should not be empty!'}
                                          ]}
                                          initialValue={category.value}
                                      >
                                        <Input
                                            autoComplete='off'
                                            onClick={(e) => {
                                              e.stopPropagation()
                                            }}
                                            onChange={() => {
                                              setUpdated(true)
                                            }}
                                        />
                                      </Form.Item>
                                    </Form>
                                ) : (
                                    <div>{category.value}</div>
                                )}
                                <div style={{display: 'flex'}}>
                                  {!isEditableCategory && (
                                      <Button
                                          type='text'
                                          className='edit-button button'
                                          icon={<EditOutlined/>}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setEditableValue(category)
                                          }}
                                      />
                                  )}
                                  {isEditableCategory && !updated && (
                                      <Button
                                          className='edit-button button'
                                          type='text'
                                          icon={<CloseCircleOutlined/>}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            setEditableValue(null)
                                          }}
                                      />
                                  )}
                                  {updated && isEditableCategory && (
                                      <Button
                                          className='edit-button button'
                                          type='text'
                                          icon={<CheckOutlined/>}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            handleEditOperation(category, 'Categories')
                                          }}
                                      />
                                  )}

                                  <Popconfirm
                                      title='Delete'
                                      description={`Are you sure you want to delete the category ${category.value}? This will delete any subcategories and sub-subcategories under it too? This action is irreversible.`}
                                      icon={
                                        <QuestionCircleOutlined
                                            style={{
                                              color: 'red'
                                            }}
                                        />
                                      }
                                      onConfirm={() => handleDeleteOperation(category, 'Categories')}
                                  >
                                    <Button
                                        type='text'
                                        icon={<DeleteOutlined/>}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                  </Popconfirm>
                                </div>
                              </div>
                            }
                            key={index}
                            className='custom-collapse'
                        >
                          {subcategories.filter((sc) => sc.parentId === category.id).length >= 1 && (
                              <Collapse accordion>
                                {/* show items for subcategories */}
                                {subcategories
                                    .filter((sc) => sc.parentId === category.id)
                                    .map((subcategory, ind) => {
                                      let isEditableSubcategory = editableValue?.id === subcategory.id
                                      return (
                                          <Panel
                                              header={
                                                <div className='category-row'>
                                                  {isEditableSubcategory ? (
                                                      <Form form={editValueForm}>
                                                        <Form.Item
                                                            name={subcategory.id}
                                                            rules={[
                                                              {
                                                                required: true,
                                                                message: 'Subcategory name should not be empty!'
                                                              }
                                                            ]}
                                                            initialValue={subcategory.value}
                                                        >
                                                          <Input
                                                              autoComplete='off'
                                                              onClick={(e) => {
                                                                e.stopPropagation()
                                                              }}
                                                              onChange={() => {
                                                                setUpdated(true)
                                                              }}
                                                          />
                                                        </Form.Item>
                                                      </Form>
                                                  ) : (
                                                      <div key={ind}>{subcategory.value}</div>
                                                  )}

                                                  <div style={{display: 'flex'}}>
                                                    {!isEditableSubcategory && (
                                                        <Button
                                                            type='text'
                                                            className='edit-button button'
                                                            icon={<EditOutlined/>}
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              setEditableValue(subcategory)
                                                            }}
                                                        />
                                                    )}
                                                    {isEditableSubcategory && updated && (
                                                        <Button
                                                            className='edit-button button'
                                                            type='text'
                                                            icon={<CheckOutlined/>}
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              handleEditOperation(subcategory, 'Subcategories')
                                                            }}
                                                        />
                                                    )}
                                                    {!updated && isEditableSubcategory && (
                                                        <Button
                                                            className='edit-button button'
                                                            type='text'
                                                            icon={<CloseCircleOutlined/>}
                                                            onClick={(e) => {
                                                              e.stopPropagation()
                                                              setEditableValue(null)
                                                            }}
                                                        />
                                                    )}

                                                    <Popconfirm
                                                        title='Delete'
                                                        description={`Are you sure you want to delete the subcategory ${subcategory.value}? This will delete any sub-subcategories under it too? This action is irreversible.`}
                                                        icon={
                                                          <QuestionCircleOutlined
                                                              style={{
                                                                color: 'red'
                                                              }}
                                                          />
                                                        }
                                                        onConfirm={() =>
                                                            handleDeleteOperation(subcategory, 'Subcategories')
                                                        }
                                                    >
                                                      <Button
                                                          type='text'
                                                          icon={<DeleteOutlined/>}
                                                          onClick={(e) => e.stopPropagation()}
                                                      />
                                                    </Popconfirm>
                                                  </div>
                                                </div>
                                              }
                                              key={ind}
                                              className='custom-collapse'
                                          >
                                            {subSubcategories
                                                .filter(
                                                    (sc) =>
                                                        sc.parentCategoryId === category.id &&
                                                        sc.parentSubcategoryId === subcategory.id
                                                )
                                                .map((fSc) => {
                                                  let isEditableSubSubcategory = editableValue?.id === fSc.id
                                                  return (
                                                      <div className='subcategoryBox'>
                                                        <div className='category-row'>
                                                          {isEditableSubSubcategory ? (
                                                              <Form form={editValueForm}>
                                                                <Form.Item
                                                                    name={fSc.id}
                                                                    rules={[
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                            'Sub Subcategory name should not be empty!'
                                                                      }
                                                                    ]}
                                                                    initialValue={fSc.label}
                                                                >
                                                                  <Input
                                                                      autoComplete='off'
                                                                      onClick={(e) => {
                                                                        e.stopPropagation()
                                                                      }}
                                                                      onChange={() => {
                                                                        setUpdated(true)
                                                                      }}
                                                                  />
                                                                </Form.Item>
                                                              </Form>
                                                          ) : (
                                                              <div>
                                                                <b>{fSc.label}</b>
                                                              </div>
                                                          )}
                                                          <div style={{display: 'flex'}}>
                                                            {!isEditableSubSubcategory && (
                                                                <Button
                                                                    type='text'
                                                                    className='edit-button button'
                                                                    icon={<EditOutlined/>}
                                                                    onClick={(e) => {
                                                                      e.stopPropagation()
                                                                      setEditableValue(fSc)
                                                                    }}
                                                                />
                                                            )}
                                                            {isEditableSubSubcategory && updated && (
                                                                <Button
                                                                    className='edit-button button'
                                                                    type='text'
                                                                    icon={<CheckOutlined/>}
                                                                    onClick={(e) => {
                                                                      e.stopPropagation()
                                                                      handleEditOperation(fSc, 'SubSubcategories')
                                                                    }}
                                                                />
                                                            )}
                                                            {!updated && isEditableSubSubcategory && (
                                                                <Button
                                                                    className='edit-button button'
                                                                    type='text'
                                                                    icon={<CloseCircleOutlined/>}
                                                                    onClick={(e) => {
                                                                      e.stopPropagation()
                                                                      setEditableValue(null)
                                                                    }}
                                                                />
                                                            )}

                                                            <Popconfirm
                                                                title='Delete'
                                                                description={`Are you sure to delete sub subcategory ${fSc.label}?`}
                                                                icon={
                                                                  <QuestionCircleOutlined
                                                                      style={{
                                                                        color: 'red'
                                                                      }}
                                                                  />
                                                                }
                                                                onConfirm={() =>
                                                                    handleDeleteOperation(fSc, 'SubSubcategories')
                                                                }
                                                            >
                                                              <Button
                                                                  type='text'
                                                                  icon={<DeleteOutlined/>}
                                                                  onClick={(e) => e.stopPropagation()}
                                                              />
                                                            </Popconfirm>
                                                          </div>
                                                        </div>
                                                      </div>
                                                  )
                                                })}
                                            {subSubcategories.filter(
                                                (sc) =>
                                                    sc.parentCategoryId === category.id &&
                                                    sc.parentSubcategoryId === subcategory.id
                                            ).length < 1 && (
                                                <div className='subcategoryBox'>
                                                  <div>
                                                    <b>No Sub Subcategory</b>
                                                  </div>
                                                </div>
                                            )}
                                          </Panel>
                                      )
                                    })}
                              </Collapse>
                          )}
                          {subcategories.filter((sc) => sc.parentId === category.id).length < 1 && (
                              <div className='subcategoryBox'>
                                <div>
                                  <b>No Subcategory</b>
                                </div>
                              </div>
                          )}
                        </Panel>
                    )
                  })}
            </Collapse>
          </Spin>
        </div>

        <Modal
            title={'Add Category'}
            open={addCategoryModal}
            onOk={() => {
              handleValidations('category', categoryForm)
            }}
            confirmLoading={categoryForm.getFieldValue('categoryName') && loadingApi}
            onCancel={() => {
              setAddCategoryModal(false)
              categoryForm.resetFields()
            }}
        >
          <Form form={categoryForm} layout='vertical'>
            <Form.Item
                name='categoryName'
                rules={[{required: true, message: 'Category name should not be empty!'}]}
                label='Add Category Name'
            >
              <div className='modalInputContainer'>
                <Input placeholder='Name' autoComplete='off'/>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
            title={'Add Subcategory'}
            open={addSubcategoryModal}
            onOk={() => {
              handleValidations('subCategory', subCategoryForm)
            }}
            confirmLoading={
                subCategoryForm.getFieldValue('selectedCategory') &&
                subCategoryForm.getFieldValue('subcategoryName') &&
                loadingApi
            }
            onCancel={() => {
              setAddSubcategoryModal(false)
              subCategoryForm.resetFields()
            }}
        >
          <Form form={subCategoryForm} layout='vertical'>
            <Form.Item
                name='selectedCategory'
                rules={[{required: true, message: 'Please select a category'}]}
                label='Select parent Category'
            >
              <Select placeholder='Select Category'
                      style={{width: '100%'}}
                      optionLabelProp='name'
                      labelInValue='key'
                      options={categories}/>
            </Form.Item>

            <Form.Item
                name='subcategoryName'
                rules={[{required: true, message: 'Please enter a subcategory name'}]}
                label='Add Subcategory Name'
            >
              <Input placeholder='Name' autoComplete='off'/>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
            title={'Add Sub Subcategory'}
            open={addSubSubcategoryModal}
            onOk={() => {
              handleValidations('subSubcategory', subSubcategoryForm)
            }}
            confirmLoading={
                subSubcategoryForm.getFieldValue('selectedCategory') &&
                subSubcategoryForm.getFieldValue('subcategoryName') &&
                subCategoryForm.getFieldValue('subSubcategoryName') &&
                loadingApi
            }
            onCancel={() => {
              setAddSubSubcategoryModal(false)
              subSubcategoryForm.resetFields()
            }}
        >
          <Form form={subSubcategoryForm} layout='vertical'>
            <Form.Item
                name='selectedCategory'
                rules={[{required: true, message: 'Please select a category'}]}
                label='Select parent Category'
            >
              <Select
                  placeholder='Select Category'
                  onChange={(value) => setCategory(value)}
                  optionLabelProp='name'
                  labelInValue='key'
                  style={{width: '100%'}}
                  options={categories}
              />
            </Form.Item>
            <Form.Item
                name='subcategoryName'
                rules={[{required: true, message: 'Please enter a subcategory name'}]}
                label='Select parent Subcategory'
            >
              <Select
                  placeholder='Select Category'
                  style={{width: '100%'}}
                  optionLabelProp='name'
                  labelInValue='key'
                  options={subcategories.filter((sc) => sc.parentId === category.key)}
              />
            </Form.Item>
            <Form.Item name='subSubcategoryName'
                       rules={[{required: true, message: 'Please enter a sub subcategory name'}]}
                       label='Add Sub Subcategory Name'>
              <Input placeholder='Name' autoComplete='off'
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
  )
}
