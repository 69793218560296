import History from '../libs/history.js'
import fabric from './fabric.base.js'

Object.assign(fabric.Canvas.prototype, {
  history: null,
  storeHistoryThumbnail: false,
  canRedo() {
    return this.history && this.history.canRedo
  },
  canUndo() {
    return this.history && this.history.canUndo
  },
  undo() {
    this.history && this.history.undo()
    // this.fire('modified')
  },
  redo() {
    this.history && this.history.redo()
    // this.fire('modified')
  },
  getHistoryStates() {
    return this.history.stack.map(state => {
      let original = state.originalState
      let modified = state.modifiedState
      let keys = original ? Object.keys(original).join(',') : ''
      let oId = state.object?.id || ''

      let title = ''
      for (let i in original) {
        title += `${i}: ${JSON.stringify(original[i])} > ${JSON.stringify(modified[i])}\n`
      }

      return {
        id: '' + state.id,
        parent: '#',
        icon: state.thumbnail,
        text: `${oId}(${keys})`,
        data: { title: title, state: state },
        state: { selected: this.history.stack[this.history.current].id === state.id }
      }
    })
  },
  setHistoryState(val) {
    this.history.goto(val)
  },
  getHistoryState() {
    return this.history.records[this.current].id
  },
  setHistory(historyOption) {
    if (historyOption === true) {
      historyOption = 'global'
    }
    if (historyOption === 'global') {
      this.history = new History(this)
      this.enableHistory()

      this.on('slide:created', (e) => {
        e.target.initHistory(this.history)
      })
    }
  },
  onCanvasModified(e) {
    if (!this._isHistoryActive()) return
    let states = e.original ? e : this.getModifiedStates()
    if (!states && !this._history_removed_object) return
    // this.editor.fire("modified",e);
   // console.log('history:canvas-modified')

    this.history.add({
      canvas: this,
      object: this,
      originalState: states.original,
      modifiedState: states.modified,
      type: 'canvas:modified',
      undo: a => {
        if (a.object) {
          this.undoDeleting(a)
        }
        this.undoChanges(a)
      },
      redo: a => {
        if (a.object) {
          this.redoDeleting(a)
        }
        this.redoChanges(a)
      }
    })
    delete this._history_removed_object
  },
  onReposition({ target , original, modified}) {
    if(target.ignoreHistory)return
    if (!this._isHistoryActive()) return

    this.history.add({
      canvas: this,
      originalLayerIndex: original,
      modifiedLayerIndex: modified,
      object: target,
      type: 'object:reposition',
      undo: function(a) {
        a.canvas.moveObjectTo(a.object,a.originalLayerIndex)
        a.canvas.renderAll()
      },
      redo: function(a) {
        a.canvas.moveObjectTo(a.object,a.modifiedLayerIndex)
        a.canvas.renderAll()
      }
    })
  },
  onObjectModified({ target , original}) {
    if(target.ignoreHistory){
      return
    }
    if (!this._isHistoryActive()) return
    if(!original){
      original = target.__originalState || this._currentTransform?.original
      delete target.__originalState
    }

    if(!original){
      console.warn("no this._currentTransform.original")
      return
    }

    let modified = {}
    // let modifiedData = target.toObject()
    for(let property in original){
      let originalValue = original[property]
      let modifiedValue = target.getExtra(property)

      if(typeof originalValue === 'object' ? JSON.stringify(originalValue) === JSON.stringify(modifiedValue) : modifiedValue === originalValue){
        delete original[property]
      }
      else{
        modified[property] = JSON.parse(JSON.stringify(modifiedValue))
      }
    }
    // for(let property in modified){
    //   if(original[property] === undefined){
    //     original[property] = target.getDefaults()[property]
    //   }
    // }

    if(!Object.keys(original).length){
      return
    }

    // console.log(`${JSON.stringify(original)} > ${JSON.stringify(modified)}`)


    if (target.constructor === fabric.ActiveSelection) {
      target.includeDefaultValues = true
      let modifiedState =  (({ left, top, angle, scaleX, scaleY,width,height , flipX, flipY, skewX, skewY}) => ({ left, top, angle, scaleX, scaleY,width,height,flipX, flipY, skewX, skewY }))(target);
      let originalState = {...modifiedState,...original}

      this.history.add({
        canvas: this,
        objects: [...target._objects],
        originalState,
        modifiedState,
        type: 'selection:modified',
        undo (a) {
          let canvas = a.canvas
          canvas.discardActiveObject()
          let as = new fabric.ActiveSelection([], { ...modifiedState, canvas: this });
          canvas._setActiveObject(as, null);
          as.multiSelectAdd(...a.objects);
          as.setExtra(a.originalState)
        },
        redo(a) {
          let canvas = a.canvas
          canvas.discardActiveObject()
          let as = new fabric.ActiveSelection([], { ...originalState, canvas: this });
          canvas._setActiveObject(as, null);
          as.multiSelectAdd(...a.objects);
          as.setExtra(a.modifiedState)
        }
      })

    } else {
      
      let lastState = this.history.last()
      //CHECK IF wecontinously modify the same object property. text or fill
      let moment2 = new Date().getTime()
      if (lastState.object === target && lastState.type === 'object:modified' && (moment2 - lastState.moment < 500) &&
        Object.keys(lastState.modifiedState).join(" ") === Object.keys(modified).join(" ") ) {
        lastState.modifiedState =modified
        lastState.moment  =moment2
      }
      else{
        this.history.add({
          canvas: this,
          originalState: original,
          modifiedState: modified,
          object: target,
          type: 'object:modified',
          undo: function(a) {
            a.canvas.discardActiveObject()
            a.object.setExtra(a.originalState)
            a.canvas.setActiveObject(a.object)

            let group = a.object
            while(group.group){
              group = group.group
              group._applyLayoutStrategy({type: "object_modified"})
              group.dirty = true
              group.makeObjectsInteractive()
            }
            a.canvas.renderAll()
          },
          redo: function(a) {
            a.canvas.discardActiveObject()
            a.canvas.setActiveObject(a.object)
            a.object.setExtra(a.modifiedState)


            let group = a.object
            while(group.group){
              group = group.group
              group._applyLayoutStrategy({type: "object_modified"})
              group.dirty = true
              group.makeObjectsInteractive()
            }
            a.canvas.renderAll()
          }
        })
      }
    }
  },
  clearHistory() {
    this.history.clear()
  },
  disableHistory() {
    this.history.enabled = false
  },
  undoChanges(a) {
    this.setExtra(a.originalState)
  },
  redoChanges(a) {
    this.setExtra(a.modifiedState)
  },
  undoDeleting(a) {
    a.collection.insertAt(a.index, a.object);
    // a.canvas.add(a.object)
    a.canvas.setActiveObject(a.object)
    a.canvas.renderAll()
  },
  redoDeleting(a) {
    a.collection.remove(a.object)
    if(a.object.group){
      a.canvas.setActiveObject(a.object.group)
    }
    else{
      a.canvas._discardActiveObject()
    }
    a.canvas.renderAll()
  },
  _isHistoryActive() {
    return this.stateful && this.history.enabled && !this.processing && !this.history.processing
  },
  onGroupRemoved(e) {
    if (!this._isHistoryActive()) return
   // console.log('history:canvas-modified')
    this.history.add({
      canvas: e.target.canvas,
      object: e.target,
      thumbnail: this.storeHistoryThumbnail && e.target.getThumbnail(32),
      type: 'group:removed',
      redo: function(a) {

        a.object._objects.forEach((o) => {
          a.canvas.remove(o)
        })
        a.canvas.discardActiveObject()
        a.canvas.renderAll()
      },
      undo: function(a) {
        a.object._objects.forEach((o) => {
          a.canvas.add(o)
        })
        a.canvas.setActiveObject(a.object)
        a.canvas.renderAll()
      }
    })
  },
  undoObjectsDeleting({ collection, objects,canvas,selection }) {
    canvas.discardActiveObject()
    objects = objects.sort((a,b) => (a.index > b.index ? 1 : -1))
    for(let item of objects){
      collection.insertAt(item.index, item.object);
    }
    selection && canvas.setActiveObjects(objects.map(i => i.object),selection)
  },
  redoObjectsDeleting({ collection, objects,canvas }) {
    canvas.discardActiveObject()
    let group = objects[0].group
    for(let item of objects){
      let object = item.object
      collection.remove(object)
    }
    if(group){
      canvas.setActiveObject(group)
    }
    else{
      canvas._discardActiveObject()
    }
    canvas.renderAll()
  },
  undoObjectsGrouped({ collection, objects, target, canvas, selection }) {
    target.ungroupObjects()
  },
  redoObjectsGrouped({ objects, target,canvas }) {
    canvas.discardActiveObject()
    // canvas.disableHistory()
    objects.forEach(object => canvas.remove(object))
    canvas.add(target)
    for(let obj of objects){
      target.add(obj)
    }
    canvas.setActiveObject(target)
    // canvas.enableHistory()
    canvas.renderAll()
  },
  onObjectsGrouped({objects,target, collection = this}) {
    if (!this._isHistoryActive()) return
    // this.editor.fire('modified', e)
    this.history.add({
      canvas: this,
      objects,
      target,
      collection,
      type: 'objects:grouped',
      redo: this.redoObjectsGrouped,
      undo: this.undoObjectsGrouped
    })
  },
  onObjectsUngrouped({objects,target, collection = this}) {
    if (!this._isHistoryActive()) return
    // this.editor.fire('modified', e)
    this.history.add({
      canvas: this,
      objects,
      target,
      collection,
      type: 'object:ungrouped',
      redo: this.undoObjectsGrouped,
      undo: this.redoObjectsGrouped
    })
  },
  onObjectsRemoved({objects,selection,collection = this}) {
    if (!this._isHistoryActive()) return
    // this.editor.fire('modified', e)
    this.history.add({
      canvas: this,
      objects,
      selection,
      collection,
      type: 'objects:removed',
      redo: this.redoObjectsDeleting,
      undo: this.undoObjectsDeleting
    })
  },
  onObjectRemoved(e) {
    if (!this._isHistoryActive()) return
    // this.editor.fire('modified', e)
    this.history.add({
      canvas: this,
      object: e.target,
      collection: e.collection || this,
      index: e.index,
      type: 'object:removed',
      redo: this.redoDeleting,
      undo: this.undoDeleting
    })
  },
  onObjectAdded(e) {
    if (!this._isHistoryActive()) return
    // this.editor.fire("modified",e);
    // console.log('history:canvas-modified')

    this.history.add({
      canvas: this,
      object: e.target,
      collection: e.collection || this,
      index: e.index,
      type: 'object:added',
      undo: this.redoDeleting,
      redo: this.undoDeleting
    })
  },
  beforeTransform(e) {
    if(e.transform.action === "point"){
      e.transform.original.width = e.transform.target.width
      e.transform.original.height = e.transform.target.height
    }
  },
  afterTransform(e,t) {
    //console.log(e,t)
  },
  resetHistory() {
    this.history.clear()
  },
  initHistory(history) {
    if (!history) {
      history = new History(this)
    }
    this.stateful = true
    this.history = history

    this.on({
      'modified': this.onCanvasModified,
      'loading:before': this.clearHistory,
      'object:modified': this.onObjectModified,
      'object:added': this.onObjectAdded,
      'object:removed': this.onObjectRemoved,
      'objects:removed': this.onObjectsRemoved,
      'objects:grouped': this.onObjectsGrouped,
      'object:ungrouped': this.onObjectsUngrouped,
      'group:removed': this.onGroupRemoved,
      'before:transform': this.beforeTransform,
      'after:transform': this.afterTransform,
      'object:reposition': this.onReposition
    })
  },
  enableHistory() {
    this.history.enabled = true
  }
})





/***

Example with transactions objects (Removing multiple objects)

 this.history.transactionStart({
 type: 'selection:removed',
 selection,
 canvas: this,
 undo: (a)=>{
 let objects = a.actions.map(a => a.object),
 canvas = a.canvas,
 as = canvas._activeSelection
 canvas.discardActiveObject()
 as._objects.length = []
 canvas._hoveredTarget = as
 canvas._setActiveObject(as, null);
 as.set(a.activeSelection)
 as.multiSelectAdd(...objects);
 },
 redo: (a)=> {
 let canvas = a.canvas
 canvas.discardActiveObject()
 canvas.renderAll()
 }
 })

 activeSelection.getObjects().forEach(object => {
 this._removeObject(object)
 })

 this.history.transactionEnd()


 **/