import { FIREBASEDATA } from './remote.js'
import { FORAGEDATA } from './local.js'
import { cleanObject, uuid } from '../libs/util.js'

export const ACCOUNT_TYPES = {
  CHIEF_ARCHITECT: '5FkJeBQ4Aa2OtWb9HwAJ',
  SUPER_ADMIN: 'Bxq5GSm2LqzYDVI1h7MI',
  MANAGER: 'BzvagWYW1oKkDAN7S1Sx',
  INSPECTOR: 'CQyn9zENOdNbQnf90arp',
  COMPANY_ADMIN: 'zJtnc4AbYfJCwzRVII8b'
}

const DATABASE_VERSION = '21.10.2024';
//   window.addEventListener('online', handleOnline);
//   window.addEventListener('offline', handleOffline);

export const DATAMODEL = {
  isOnline() {
    return window.navigator.onLine
  },
  init() {
    this.events = {
      'sync:start': [],
      'sync:progress': [],
      'sync:end': []
    }

    this.sync().then(async () => {
      await this.initSync()
    })


  },
  async initSync() {
    clearInterval(this.syncInterval)
    this.syncInterval = setInterval(() => {
      this.sync()
    }, 30000)
  },
  async reload(loadVersions) {
    this.syncing = true
    this.fire('sync:start')
    try {

      indexedDB.deleteDatabase('Drawings')
      indexedDB.deleteDatabase('Projects')
      // this.clear()

      let cats = await FIREBASEDATA.categories()
      let subcats = await FIREBASEDATA.subCategories()
      let subsubcats = await FIREBASEDATA.subSubCategories()

      for (let cat of cats) {
        await FORAGEDATA.createCategory(cat)
      }
      for (let cat of subcats) {
        await FORAGEDATA.createSubsubcategory(cat)
      }
      for (let cat of subsubcats) {
        await FORAGEDATA.createSubcategory(cat)
      }

      let auditions = await FIREBASEDATA.auditions()
      for (let audition of auditions) {
        await FORAGEDATA.createAudition(audition.id, audition)
      }
      let projects = await FIREBASEDATA.projects()
      for (let project of projects) {
        await FORAGEDATA.createProject(project)
      }
      let drawings = await FIREBASEDATA.drawings()
      // for(let cb of this.events['sync:progress']){
      //   cb(0,drawings.length)
      // }
      let counter = 0
      for (let drawing of drawings) {
        await FORAGEDATA.createDrawing(drawing)
        //LOAD ALL DRAWINGS AND THUMBNAILS
        if (loadVersions) {

          let versionId = drawing.drawingVersion
          // for(let version of drawing.versions) {
          //   let versionId = version.version

          try {
            let data = await FORAGEDATA.version(drawing.id, versionId)
            let thumbnailBlob = await FORAGEDATA.versionThumbnail(drawing.id, versionId)
            if (!thumbnailBlob || !data) {
              if (!data) {
                data = await FIREBASEDATA.version(drawing.id, versionId)
              }
              if (!thumbnailBlob) {
                thumbnailBlob = await FIREBASEDATA.versionThumbnail(drawing.id, versionId)
              }
              await FORAGEDATA.createVersion(drawing.id, versionId, data, thumbnailBlob)
            }
          } catch (e) {
            console.error(e)
          }
          this.fire('sync:progress', { current: counter, total: drawings.length })
        }
        counter++
        // }
      }
      let serverTime = await FIREBASEDATA.time()
      localStorage.setItem('sync-time', serverTime.toString())
    } catch (e) {
      console.error(e)
    } finally {
      this.fire('sync:end')
      this.syncing = false
    }
  },
  fire(event, options) {
    if (!this.events[event]) {
      return
    }
    for (let cb of this.events[event]) {
      cb(options)
    }
  },
  syncTime() {
    return localStorage.getItem('sync-time')
  },
  get synced() {
    return this.syncTime() !== null
  },
  async clear() {
    localStorage.removeItem('sync-time')
    await FORAGEDATA.clear()
  },
  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  },
  off(event, callback) {
    let index = this.events[event].indexOf(callback)
    this.events[event].splice(index, 1)
  },
  timeout(time) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  },
  async sync() {

    let cacheVersion = localStorage.getItem('cache-version')
    if (!cacheVersion || (cacheVersion !== "" + DATABASE_VERSION)) {
      await this.clear()
      localStorage.setItem('cache-version', DATABASE_VERSION)
    }
    console.log('system online status: ', this.isOnline())
    if (!this.isOnline()) {
      return
    }
    if (this.syncing) {
      return
    }

    if (this.synced) {
      await this.pullpush()
    } else {
      await this.reload()
    }

  },
  async pullpush() {
    this.syncing = true
    this.fire('sync:start')
    try {
      let auditionsServer = await FIREBASEDATA.auditions()
      let auditionsLocal = await FORAGEDATA.auditions()

      let pulled = auditionsServer.filter(a => !auditionsLocal.find(b => b.id === a.id))
      let pushed = auditionsLocal.filter(a => !auditionsServer.find(b => b.id === a.id))

      //PULL
      if (pulled.length) {
        for (let audition of pulled) {
          switch (audition.type) {
            case 'project-create': {
              let data = await FIREBASEDATA.project(audition.project)
              if (data) {
                await FORAGEDATA.createProject(audition.project, data)
              }
              break
            }
            case 'project-edit': {
              if (pushed.find(l => l.project === audition.project)) {
                console.info(`Project ${pushed.project} was modified on Server`)
                continue
              }
              let data = await FIREBASEDATA.project(audition.project)
              if (data) {
                await FORAGEDATA.editProject(audition.project, data)
              }
              break
            }
            case 'project-delete': {
              if (pushed.find(l => l.project === audition.project)) {
                console.info(`Project ${pushed.project} was deleted on Server`)
                continue
              }
              await FORAGEDATA.deleteProject(audition.project)
              break
            }
            case 'drawing-create': {
              let data = await FIREBASEDATA.drawing(audition.drawing)
              if (data) {
                await FORAGEDATA.createDrawing(audition.drawing, data)
              }
              break
            }
            case 'drawing-edit': {
              if (pushed.find(l => l.drawing === audition.drawing)) {
                console.info(`Drawing ${pushed.drawing} was modified on Server`)
                continue
              }
              let data = await FIREBASEDATA.drawing(audition.drawing)
              if (data) {
                await FORAGEDATA.editDrawing(audition.drawing, data)
              }
              break
            }
            case 'drawing-delete': {
              if (pushed.find(l => l.drawing === audition.drawing)) {
                console.info(`Drawing ${pushed.drawing} was deleted on Server`)
                continue
              }
              await FORAGEDATA.deleteDrawing(audition.drawing)
              break
            }
            case 'version-create': {
              let data = await FIREBASEDATA.version(audition.drawing, audition.version)
              let thumbnail = await FIREBASEDATA.versionThumbnail(audition.drawing, audition.version)
              if (data) {
                await FORAGEDATA.createVersion(audition.drawing, audition.version, data, thumbnail)
              }
              break
            }
            case 'version-delete': {
              await FORAGEDATA.deleteVersion(audition.drawing, audition.version)
              break
            }
          }
          await FORAGEDATA.createAudition(audition.id, audition)
        }
        this.fire('modified')
      }

      //PUSH
      if (pushed.length) {
        for (let audition of pushed) {
          console.log(`Syncing: ${audition.type} ${audition.project || ''} ${audition.drawing || ''} ${audition.version || ''}`)
          switch (audition.type) {
            case 'project-create': {
              let data = await this.project(audition.project)
              if (data) {
                await FIREBASEDATA.createProject(audition.project, data)
              }
              break
            }
            case 'project-edit': {
              let data = await this.project(audition.project)
              if (data) {
                await FIREBASEDATA.editProject(audition.project, data)
              }
              break
            }
            case 'project-delete': {
              await FIREBASEDATA.deleteProject(audition.project)
              break
            }
            case 'drawing-create': {
              let data = await this.drawing(audition.drawing)
              if (data) {
                await FIREBASEDATA.createDrawing(audition.drawing, data)
              }
              break
            }
            case 'drawing-edit': {
              let data = await this.drawing(audition.drawing)
              console.log(audition.drawing, data)
              if (data) {
                await FIREBASEDATA.editDrawing(audition.drawing, cleanObject(data))
              }
              break
            }
            case 'drawing-delete': {
              await FIREBASEDATA.deleteDrawing(audition.drawing)
              break
            }
            case 'version-create': {
              try {
                let data = await this.version(audition.drawing, audition.version)
                let thumbnail = await this.thumbnailBlob(audition.drawing, audition.version)
                if (data) {
                  await FIREBASEDATA.createVersion(audition.drawing, audition.version, data, thumbnail)
                }
                break
              } catch (e) {
                console.error('version-create error', e)
              }

            }
            case 'version-delete': {
              await FIREBASEDATA.deleteVersion(audition.drawing, audition.version)
              break
            }
          }
          await FIREBASEDATA.createAudition(audition.id, audition)
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.syncing = false
      this.fire('sync:end')
    }

  },

  projects() {
    return (this.synced ? FORAGEDATA.projects() : FIREBASEDATA.projects())
  },
  project(id) {
    return (this.synced ? FORAGEDATA.project(id) : FIREBASEDATA.project(id))
  },
  drawings(projectId) {
    return (this.synced ? FORAGEDATA.drawings(projectId) : FIREBASEDATA.drawings(projectId))
  },
  auditions() {
    return (this.synced ? FORAGEDATA.auditions() : FIREBASEDATA.auditions())
  },
  drawing(drawingId) {
    return (this.synced ? FORAGEDATA.drawing(drawingId) : FIREBASEDATA.drawing(drawingId))
  },
  async version(drawingId, drawingVersion) {
    let result = await FORAGEDATA.version(drawingId, drawingVersion)
    if (!result) {
      result = await FIREBASEDATA.version(drawingId, drawingVersion)
      await FORAGEDATA.storeVersionData(drawingId + '-' + drawingVersion, result)
    }
    return result
  },
  time() {
    return Date.now()
  },
  async createShape(options) {
    let time = this.time(), user = this.userData(), id = uuid()
    let shapeData = {
      description: 'No Description',
      name: 'No Name',
      ...options,
      id,
      createdAt: time,
      createdBy: user.uid
    }
    await FORAGEDATA.createShape()
    await this._createAudition('shape-create', { project: id })
    this.fire('shape-create', { project: id })
    this.onChange()
    return shapeData
  },
  async createProject(options) {
    let time = this.time(), user = this.userData(), id = uuid()
    let projectData = {
      description: 'No Description',
      name: 'No Name',
      drawings: [],
      ...options,
      id,
      createdAt: time,
      createdBy: user.uid
    }
    await FORAGEDATA.createProject(projectData)
    await this._createAudition('project-create', { project: id })
    this.fire('project-create', { project: id })
    this.onChange()
    return projectData
  },

  async editProject(id, data) {
    await FORAGEDATA.editProject(id, data)
    await this._createAudition('project-edit', { project: id })
    this.fire('project-edit', { project: id })
    this.onChange()
  },
  async createDrawing(projectId, options) {
    let time = this.time(), user = this.userData(), drawingId = uuid()
    let drawingData = {
      description: 'No Description',
      name: 'No Name',
      ...options,
      id: drawingId,
      createdAt: time,
      createdBy: user.uid,
      project: projectId,
      drawingVersion: 0
    }
    await FORAGEDATA.createDrawing(drawingData)
    let project = await this.project(projectId)
    let drawings = [...project.drawings, { id: drawingId, ...drawingData }]
    await FORAGEDATA.editProject(projectId, { drawings })

    await this._createAudition('drawing-create', { drawing: drawingId })
    await this._createAudition('project-edit', { project: projectId })
    this.fire('drawing-create', { drawing: drawingId, project: projectId })
    this.onChange()
    return drawingData
  },
  async createVersion(drawingId, data, thumbnail) {
    let drawingInfoSaved = await this.drawing(drawingId)
    if (!drawingInfoSaved.versions) {
      drawingInfoSaved.versions = []
    }
    //delete old versions
    if (drawingInfoSaved.versions.length > 9) {
      let version = drawingInfoSaved.versions.shift()
      await this.deleteVersion(drawingId, version.version)
    }

    let version = drawingInfoSaved.versions.reduce((max, obj) => obj.version > max ? obj.version : max, 0) + 1

    await FORAGEDATA.createVersion(drawingId, version, data, thumbnail)

    await FORAGEDATA.editDrawing(drawingId, {
      versions: [...drawingInfoSaved.versions, { version }],
      drawingVersion: version
    })
    await this._createAudition('version-create', { drawing: drawingId, version })
    await this._createAudition('drawing-edit', { drawing: drawingId })
    this.fire('version-create', { drawing: drawingId, version })
    this.onChange()
    return {
      version
    }
  },
  onChange() {
    this.fire('modified', {})
    this.sync()
  },
  async deleteProject(id) {
    await FORAGEDATA.deleteProject(id)
    await this._createAudition('project-delete', { project: id })
    this.onChange()
  },
  async deleteDrawing(id) {
    await FORAGEDATA.deleteDrawing(id)
    await this._createAudition('drawing-delete', { drawing: id })
    this.onChange()
  },
  async _createAudition(type, options) {
    let user = this.userData()
    let id = uuid()
    await FORAGEDATA.createAudition(id, { type, local: true, time: this.time(), ...options, user: user.uid })
    this.fire('audition-create', { audition: id })
  },
  async deleteVersion(id, version = 0) {
    await FORAGEDATA.deleteVersion(id, version)
    await this._createAudition('version-delete', { drawing: id, version })
    this.fire('version-delete', { drawing: id, version })
    this.onChange()
  },
  userData() {
    return {
      uid: 0
    }
  },
  async editDrawing(id, data) {
    await FORAGEDATA.editDrawing(id, data)
    await this._createAudition('drawing-edit', { drawing: id })
    this.onChange()
  },
  async duplicateDrawing(drawingId, projectId, drawingOptions) {
    let duplicatedDrawingInfo = await this.drawing(drawingId)
    let versionNumber = duplicatedDrawingInfo.drawingVersion

    duplicatedDrawingInfo.versions = []
    duplicatedDrawingInfo.drawingVersion = 0

    let versionData = await this.version(drawingId, versionNumber)
    let thumbnail = await this.thumbnailBlob(drawingId, versionNumber)
    let drawing = await this.createDrawing(projectId, {
      ...duplicatedDrawingInfo,
      versions: [],
      drawingVersion: 0,
      ...drawingOptions
    })
    let version = await this.createVersion(drawing.id, versionData, thumbnail)
    drawing.versions = [version]
    return drawing
  },
  async thumbnailBlob(drawing, version) {
    let blob = await FORAGEDATA.versionThumbnail(drawing, version)

    if (!blob) {
      blob = await FIREBASEDATA.versionThumbnail(drawing, version)
      await FORAGEDATA.storeVersionThumbnail(drawing, version, blob)
    }
    return blob
  },
  async thumbnailURL(drawing, version) {
    let blob = await this.thumbnailBlob(drawing, version)
    return blob && URL.createObjectURL(blob)
  },
  async uploadAudition(pushed) {
    for (let audition of pushed) {
      console.log(`Syncing: ${audition.type} ${audition.project || ''} ${audition.drawing || ''} ${audition.version || ''}`)
      switch (audition.type) {
        case 'project-create': {
          let data = audition.data
          if (data) {
            await FIREBASEDATA.createProject(audition.project, data)
          }
          break
        }
        case 'project-edit': {
          let data = pushed.data
          if (data) {
            await FIREBASEDATA.editProject(audition.project, data)
          }
          break
        }
        case 'drawing-create': {
          let data = pushed.data
          if (data) {
            await FIREBASEDATA.createDrawing(audition.drawing, data)
          }
          break
        }
        case 'drawing-edit': {
          let data = pushed.data
          if (data) {
            await FIREBASEDATA.editDrawing(audition.drawing, cleanObject(data))
          }
          break
        }
        case 'version-create': {
          try {
            let data = pushed.data
            let thumbnail = await this.thumbnailBlob(audition.drawing, audition.version)
            if (data || thumbnail) {
              await FIREBASEDATA.createVersion(audition.drawing, audition.version, data, thumbnail)
            }
            break
          } catch (e) {
            console.error('version-create error', e)
          }

        }
        case 'version-delete': {
          await FIREBASEDATA.deleteVersion(audition.drawing, audition.version)
          break
        }
      }
      await FIREBASEDATA.createAudition(audition.id, audition)
    }
  }


}

DATAMODEL.init()