import fabric from './fabric.base.js'

fabric.Canvas.prototype.tooltipShowAbsoluteAngleValue = true
fabric.Canvas.prototype.tooltipOffsetX = 10

fabric.Canvas.prototype.tooltipOffsetY = 10

fabric.Canvas.prototype.hideTooltip = function(object,text){
  this.tooltipElement.style.display = 'none'
  this.tooltipElement.innerHTML = ''
}

fabric.Canvas.prototype.showTooltip = function(object,text){
  if(!object){
    return;
  }
  object.setCoords();
  let rect = object.getBoundingRect()

  let group = object.group;

  if(group){
    while(group.group){
      group = group.group
    }
    rect = group.getBoundingRect()
  }

  let right =  (rect.left+ rect.width)
  let bottom =  (rect.top + rect.height)
  this.tooltipElement.style.display = 'block'
  this.tooltipElement.style.top = (bottom + this.tooltipOffsetX) + 'px'
  this.tooltipElement.style.left = (right + this.tooltipOffsetY) + 'px'
  if(text){
    this.tooltipElement.innerHTML = text
  }
}

fabric.Canvas.prototype.showObjectSize = function(object) {
  let rect = object.getBoundingRect()
  this.showTooltip(object, `w: ${rect.width.toFixed(1)}, h: ${rect.height.toFixed(1)}`)
}

fabric.Canvas.prototype.showObjectAngle = function(object){
  // angle = angle % 360
  // if (angle < 0 ){
  //   angle += 360
  // }
  let angle
  if(this.tooltipShowAbsoluteAngleValue){
    angle = object.getAbsoluteAngle()
  }
  else{
    angle = object.angle
  }
  let text = `${angle.toFixed(1)}` + String.fromCharCode(176)
  this.showTooltip(object,text )
}
fabric.Canvas.prototype.updateTooltipPosition = function(object){
  this.showTooltip(object)
}
fabric.Canvas.prototype.showObjectPosition = function(object){
  let text = `X:${object.left.toFixed(1)} Y:${object.top.toFixed(1)}`
  this.showTooltip(object,text )
}

fabric.Canvas.prototype.setTooltip = function({angle,position} = {angle: true, position: false}){
  if(!angle && !position){
    return;
  }
  this.tooltipElement = document.createElement("div");
  this.tooltipElement.classList.add("canvas-tooltip")
  this.wrapperEl.appendChild(this.tooltipElement);

  this.on('selection:created', () => this.hideTooltip())
  this.on('selection:cleared', () => this.hideTooltip())
  this.on('selection:updated', () => this.hideTooltip())
  this.on('object:modified', () => this.hideTooltip())
  angle && this.on('object:rotating', (e) => this.showObjectAngle(e.target))
  if(position){
    this.on('object:moving', (e) => this.showObjectPosition(e.target))
    this.on('viewport:scaling', () => this.updateTooltipPosition(this.getActiveObject()))
    this.on('viewport:translate', () => this.updateTooltipPosition(this.getActiveObject()))
    this.on('object:scaling', (e) => this.showObjectSize(e.target))
  }
  else{
    this.on('object:moving', () => this.hideTooltip())
    this.on('viewport:scaling', () => this.hideTooltip())
    this.on('viewport:translate', () => this.hideTooltip())
    this.on('object:scaling', () => this.hideTooltip())
  }
}