import { initializeApp } from '../libs/firebase-app.js'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut
} from '../libs/firebase-auth.js'
import {
  // ref as firebaseFirestoreRef,
  // set as firebaseFirestoreSet,
  // get as firebaseFirestoreGet,
  doc,
  setDoc,
  initializeFirestore,
  updateDoc,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  serverTimestamp,
  getDoc,
  deleteDoc,
  orderBy,
  documentId
} from '../libs/firebase-firestore.js'
import {
  ref as firebaseStorageRef,
  getDownloadURL,
  getBlob,
  getStorage,
  deleteObject,
  uploadBytesResumable
} from '../libs/firebase-storage.js'

const firebaseConfig = {
  apiKey: 'AIzaSyDO8DJSoIjCSXtVDT4abTr0zq-XqzL4g1I',
  authDomain: 'iso-easy.firebaseapp.com',
  projectId: 'iso-easy',
  storageBucket: 'iso-easy.appspot.com',
  messagingSenderId: '534920369536',
  appId: '1:534920369536:web:6d8e0d711b824e1c1b1d65',
  measurementId: 'G-7GQ0D4K8DW'
}
const firebaseApp = initializeApp(firebaseConfig)
export const db = initializeFirestore(firebaseApp, {
  // cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalForceLongPolling: true,
  useFetchStreams: false
})

const firebaseStorage = getStorage()
const auth = getAuth(firebaseApp)

const googleProvider = new GoogleAuthProvider()

function log(message) {
  console.log(message)
}

function progress() {

}

export const FIREBASEDATA = {
  db,
  auth,
  async time() {


    const docRef = doc(db, 'ServerTime', 'temp') // Create temp doc
    await setDoc(docRef, { timestamp: serverTimestamp() })

    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      const { timestamp } = docSnap.data()
      console.log('Server time:', timestamp.toDate()) // Convert to JavaScript Date
      return timestamp.toDate() // Returns server time as a Date object
    } else {
      console.error('No such document!')
    }
    log('Server: Time')
  },

  async shapes() {
    let shapes = []
    const docRefs = await getDocs(query(collection(db, 'Shapes'), orderBy('createdAt')))
    for (let i = 0; i < docRefs.docs.length; i++) {
      const doc = docRefs.docs[i].data()
      let id = docRefs.docs[i].id
      if (doc.sizesUrl) {
        const response = await fetch(doc.sizesUrl)
        doc.sizes = await response.json()
      }
      if (doc.imageUrl) {
        const response = await fetch(doc.imageUrl)
        doc.svg = (await response.json()).image
      }
      if (!doc.sizes) {
        doc.sizes = await this._loadDataBlob(`shapes/${id}/shapeJson`)
      }
      shapes.push({ id: docRefs.docs[i].id, ...doc })
    }
    log('Server: Shapes')
    return shapes
  },
  async categories() {
    let cats = []
    const docRefs = await getDocs(collection(db, 'Categories'))
    docRefs.forEach((category) => {
      let { name } = category.data()
      cats.push({ key: category.id, id: category.id, value: name, label: name })
    })
    cats = cats.sort((a, b) => a.value.localeCompare(b.value))
    log('Server: Shapes')
    return cats
  },
  async subSubCategories() {
    let cats = []
    const docRefs = await getDocs(collection(db, 'SubSubcategories'))
    docRefs.forEach((category) => {
      let { name, parentCategoryName, parentCategoryId, parentSubcategoryName, parentSubcategoryId } = category.data()
      cats.push({
        key: category.id,
        id: category.id,
        parentCategoryName,
        parentCategoryId,
        parentSubcategoryName,
        parentSubcategoryId,
        value: name,
        label: name
      })
    })
    cats = cats.sort((a, b) => a.value.localeCompare(b.value))
    log('Server: Shapes')
    return cats
  },
  async subCategories() {
    let cats = []
    const docRefs = await getDocs(collection(db, 'Subcategories'))
    docRefs.forEach((category) => {
      let { name, parentId, parentName } = category.data()
      cats.push({ key: category.id, id: category.id, parentId, parentName, value: name, label: name })
    })
    cats = cats.sort((a, b) => a.value.localeCompare(b.value))
    log('Server: Shapes')
    return cats
  },

  async createShape(data) {
    let sizes = data.sizes
    delete data.sizes
    let shape = await addDoc(collection(db, 'Shapes'), data)
    await this._storageUploadFile(this._blob(sizes), `shapes/${shape.id}/shapeJson`, v => progress(v * 0.5))
    // await this._storageUploadFile(this._blob({ image: data.svg }), `shapes/${id}/image`, v => progress(v * 0.5 + 0.5))
    let result = addDoc(collection(db, 'Shapes'), data)
    return result
  },
  async _update(collection, id, data) {
    // let newDrawingID = this._generateUUID()
    let { uid } = await this.userData()
    return updateDoc(doc(db, collection, id), {
      ...data,
      updatedBy: uid,
      updatedAt: serverTimestamp()
    })
  },

  deleteShape(id) {
    return deleteDoc(doc(db, 'Shapes', id))
  },
  editShape(id, data) {
    return updateDoc(doc(db, 'Shapes', id), data)
  },
  deleteCategory(id) {
    return deleteDoc(doc(db, 'Categories', id))
  },
  createCategory(data) {
    return addDoc(collection(db, 'Categories'), data)
  },
  editCategory(id, data) {
    return updateDoc(doc(db, 'Categories', id), data)
  },
  deleteSubcategory(id) {
    return deleteDoc(doc(db, 'Subcategories', id))
  },
  createSubcategory(data) {
    return addDoc(collection(db, 'Subcategories'), data)
  },
  editSubcategory(id, data) {
    return updateDoc(doc(db, 'Subcategories', id), data)
  },
  deleteSubsubcategory(id) {
    return deleteDoc(doc(db, 'Subsubcategories', id))
  },
  createSubsubcategory(data) {
    return addDoc(collection(db, 'Subsubcategories'), data)
  },
  editSubsubcategory(id, data) {
    return updateDoc(doc(db, 'Subsubcategories', id), data)
  },


  _blob(data) {
    return new Blob([JSON.stringify(data)], { type: 'application/json' })
  },
  async _create(collectionName, id, data) {
    data = { ...data }
    delete data.id

// Specify the collection and document ID
    const docRef = doc(db, collectionName, id)
    await setDoc(docRef, data)
  },
  createProject(id,data) {
    return this._create('Projects', id,data)
  },
  createAudition(id,data) {
    return this._create('Auditions', id,data)
  },
  createDrawing(id,data) {
    return this._create('Drawings', id,data)
  },
  deleteProject(id) {
    return deleteDoc(doc(db, 'Projects', id))
  },
  deleteDrawing(id) {
    return deleteDoc(doc(db, 'Drawings', id))
  },
  editProject(id, data) {
    return updateDoc(doc(db, 'Projects', id), data)
  },
  editDrawing(id, data) {
    return updateDoc(doc(db, 'Drawings', id), data)
  },

  async deleteVersion(drawing, version) {
    await this._storageDeleteFile(this._getDrawingVersionDataURL(drawing, version))
    await this._storageDeleteFile(this.getDrawingVersionImageURL(drawing, version))
  },
  async createVersion(drawing, version, data, thumbnail) {
    await this._storageUploadFile(this._blob(data), this._getDrawingVersionDataURL(drawing, version), v => progress(v * 0.5))
    await this._storageUploadFile(thumbnail, this.getDrawingVersionImageURL(drawing, version), v => progress(v * 0.5 + 0.5))
    return true
  },
  async _storageDeleteFile(url) {
    try {
      const fileRef = firebaseStorageRef(firebaseStorage, url)
      await deleteObject(fileRef)
    } catch (error) {
      if (error.code !== 'storage/object-not-found') {
        throw error
      }
    }
  },
  _getDrawingVersionDataURL(id, version) {
    return version === -1 ? `drawings/${id}/canvasJson` : `drawings/${id}/canvasJson-${version}`
  },
  getDrawingVersionImageURL(id, version) {
    return version === -1 ? `drawings/${id}/image` : `drawings/${id}/image-${version}`
  },
  async _storageUploadFile(blob, url, progress) {
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(firebaseStorageRef(firebaseStorage, url), blob)
      uploadTask.on('state_changed',
        (snapshot) => {
          progress(snapshot.bytesTransferred / snapshot.totalBytes)
        },
        (error) => {
          reject(error)
        },
        async () => {
          progress(1)
          let jsonFileUrl = await getDownloadURL(uploadTask.snapshot.ref)
          resolve(jsonFileUrl)
        }
      )
    })
  },
  async auditions() {
    const documents = await getDocs(query(collection(db, 'Auditions')))

    let results = []
    documents.forEach((document) => {
      let result = document.data()
      //exclude broken drawings
      // if(!drawing.id || ["AASizxZr5z5Q8z1kbc8i","k8tyTFFTQebKCGE6D0cy"].includes(drawing.id)){return}
      //exclude repeating drawings
      // if(result.find(d => d.id === drawing.id)) {return}
      results.push({ ...result, id: document.id })
    })
    log('Server: Auditions')
    return results
  },
  async drawing(drawingId) {

    const colRef = collection(db, 'Drawings')
    const q = query(colRef, where(documentId(), '==', drawingId))
    const docRefs = await getDocs(q)

    let d
    docRefs.forEach((obj) => {
      d = obj.data()
    })
    if (!d) {
      return null
    }
    d.createdAt = d.createdAt?.seconds || d.createdAt
    d.updatedAt = d.updatedAt?.seconds || d.updatedAt

    let versions = []
    if (d.versions) {
      for (let i = d.versions.length; i--;) {
        let versionItem = d.versions[i]
        if (!versions.find(v => v.version === versionItem.version)) {
          versions.unshift(versionItem)
        }
      }
      d.versions = versions
    }
    log('Server: Drawing')
    return d
  },
  async drawings(projectId) {
    const colRef = collection(db, 'Drawings')
    let q
    if (projectId) {
      q = query(colRef, where('project', '==', projectId))
    } else {
      q = query(colRef)
    }
    const docRefs = await getDocs(q)
    let result = []
    docRefs.forEach((drawing) => {
      let drawingData = drawing.data()
      //exclude broken drawings
      // if(!drawing.id || ["AASizxZr5z5Q8z1kbc8i","k8tyTFFTQebKCGE6D0cy"].includes(drawing.id)){return}
      //exclude repeating drawings
      // if(result.find(d => d.id === drawing.id)) {return}
      result.push({ ...drawingData, id: drawing.id })
    })
    result = result.sort((a, b) => a.name.localeCompare(b.name)).map(d => {
      if (d.drawingVersion === undefined) {
        d.drawingVersion = -1
      }
      if (!d.versions) {
        if (d.drawingVersion === 0) {
          d.versions = []
        } else {
          d.versions = [{ version: -1 }]
        }
      }
      if (d.drawingVersion === 0) {
        d.drawingVersion = d.versions.reduce((max, obj) => obj.version > max ? obj.version : max, 0)
      }

      return {
        createdAt: d.createdAt?.seconds || d.createdAt,
        updatedAt: d.updatedAt?.seconds || d.updatedAt,
        updatedBy: d.updatedBy,
        createdBy: d.createdBy,
        description: d.description,
        versions: d.versions,
        project: d.project,
        drawingVersion: d.drawingVersion,
        //image: d.imageUrl,
        name: d.name,
        id: d.id
      }
    })

    log('Server: Drawings')
    return result
  },
  async projects() {
    let projects = []
    const colRef = collection(db, 'Projects')
    const q = query(colRef)
    const docRefs = await getDocs(q)
    const newProjects = []
    docRefs.forEach((project) => {
      newProjects.push({
        ...project.data(),
        id: project.id
      })
    })
    projects = newProjects.sort((a, b) => a.name?.localeCompare(b?.name)).map(project => ({
      createdAt: project.createdAt.seconds,
      description: project.description,
      name: project.name,
      id: project.id
    }))
    log('Server: Projects')
    return projects
  },

  async project(projectId) {
    const docSnap = await getDoc(doc(db, 'Projects', projectId))
    let project = docSnap.data()
    log('Server: Project')
    return project
  },
  async _loadDataBlob(key) {
    let blob = await this._loadBlob(key)
    let text = await blob.text()
    return JSON.parse(text)
  },
  async _loadBlob(key) {
    const imageRef = firebaseStorageRef(firebaseStorage, key)
    return await getBlob(imageRef)
  },
  async versionThumbnail(drawing, version = 0) {
    let blob = await this._loadBlob((('' + version) === '-1') ? `drawings/${drawing}/image` : `drawings/${drawing}/image-${version}`)
    log('Server: Thumbnail')
    return blob
  },
  async version(drawingId, drawingVersion = 0) {
    if (drawingVersion === 0) {
      return {}
    }
    let jsonURL = this._getDrawingVersionDataURL(drawingId, drawingVersion)
    const oldJsonStorageRef = firebaseStorageRef(firebaseStorage, jsonURL)
    let blob = await getBlob(oldJsonStorageRef)
    let jsonText = await blob.text()
    let result = JSON.parse(jsonText)
    log('Server: Version')
    return result
  },
// Function to get list of files from a specific folder
  async _listFilesInFirebaseFolder(folderPath) {
    // // Create a reference to the folder
    // const folderRef = ref(firebaseStorage, folderPath);
    // // List all items (files) and prefixes (subfolders) in the folder
    // const result = await listAll(folderRef);
    //
    // // Get URLs of all files
    // const files = await Promise.all(result.items.map(async (itemRef) => {
    //   // const downloadURL = await getDownloadURL(itemRef);
    //   return { name: itemRef.name}//, url: downloadURL };
    // }));
    // return files
  }
}
