import React, { useState, useEffect } from 'react';
import { DATAMODELOLD } from '../../--database' // Adjust the path as needed
import { DATAMODEL } from '../../database/database'

const ImageLoader = ({ drawing,version, onLoad }) => {

  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        setLoading(true);
        let url = await DATAMODEL.thumbnailURL(drawing,version)

        if(url) {
          // Set the blob URL in the state
          setImageUrl(url);
          setLoading(false);
        }
        else{
          setLoading(false);
          setError(true);
        }
      } catch (error) {
        console.error('Error loading image:', error);
        setLoading(false);
      }
    };

    if(version !== 0){
      fetchImage();
    }
  }, [drawing,version, onLoad]);

  return (
    <div className="version-image-holder">
      {version !== 0 ?
        (loading ? (
          <div className="loading">Loading...</div>
            ) : (
              error ? <div className="error">{`${drawing}-${version}`}</div> :
              <img className="version-image" src={imageUrl} alt={`${drawing}-${version}`} />
        )) :
        <span><div className="version-image">Empty</div></span>
      }
    </div>
  )

};

export default ImageLoader
