import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Collapse, Input, Modal, notification, Popconfirm} from 'antd'
import {CloseCircleOutlined, LeftOutlined, RightOutlined, SettingOutlined} from '@ant-design/icons'
import Search from 'antd/es/input/Search'
import { ShapeItem } from './shapeItem'
import CropModal from './modals/cropModal'
import { ExportItemModal } from './modals/exportItemModal'
import fabric from '../../fabric/index'
import { icons } from './icons'
import './drawer.css'
import { useCanvas } from './useCanvas'
import { DATAMODEL } from '../../database/database'
import { DATAMODELOLD } from '../../--database'
import { ManageCategoryContent } from '../settings/ManageCategoryContent'
import { Toolbar } from '../../libs/toolbars.js'
import {openNotification} from "../shared/notifications";
import ImageLoader from '../shared/ImageLoader'
import FullscreenToggle from '../shared/fullscreenButton'
const { Panel } = Collapse

export const colors = [
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FE9200',
  '#FCDC00',
  '#DBDF00',
  '#A4DD00',
  '#68CCCA',
  '#73D8FF',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#808080',
  '#cccccc',
  '#D33115',
  '#E27300',
  '#FCC400',
  '#B0BC00',
  '#68BC00',
  '#16A5A5',
  '#009CE0',
  '#7B64FF',
  '#FA28FF',
  '#000000',
  '#666666',
  '#B3B3B3',
  '#9F0500',
  '#C45100',
  '#FB9E00',
  '#808900',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#653294',
  '#AB149E'
]

Toolbar.tools.color.prototype.parseColor = function(value) {
  if(!value) return "transparent"
  try{
    let c = new fabric.Color(value)
    return '#' + c.toHex()
  }
  catch(e){
    return "transparent"
  }
}

export function createObjectsTools(canvas, container) {
  let target = null

  function round(num, dec = 1) {
    return Math.round((num + Number.EPSILON) * Math.pow(10, dec)) / Math.pow(10, dec)
  }

  function isTextbox() {
    return target?.constructor === fabric.TextboxExt
  }
  function isText() {
    return target?._text
  }
  function isGroup() {
    return target?.type === 'group'
  }
  function isArrow() {
    return target?.constructor === fabric.Arrow
  }
  function isPolyCurve() {
    return target?.constructor === fabric.PolyCurve
  }

  function isActiveSelection() {
    return target?.constructor === fabric.ActiveSelection
  }


  let bordersColor = "black"
  let bordersWidth = 1

  let objectTools = new Toolbar(container, [
    {
      id: 'appearance',
      type: 'group',
      caption: 'Appearance',
      tools: [
        {
          caption: 'Fill',
          type: 'colorChoice',
          // visible: isNotGroup,
          options: colors,
          property: 'fill'
        },
        {
          caption: 'Stroke',
          type: 'colorChoice',
          // visible: isNotGroup,
          options: colors,
          property: 'stroke'
        },
        {
          caption: 'Opacity',
          type: 'number',
          property: 'opacity',
          min: 0,
          max: 1,
          step: 0.1
        },
        {
          caption: 'Thickness',
          type: 'number',
          // visible: isNotGroup,
          min: 0,
          max: 100,
          round: 2,
          property: 'absoluteThickness'
        },
        {
          caption: 'Arrow Size',
          type: 'number',
          min: 0,
          max: 100,
          round: 2,
          property: 'absoluteArrowSize',
          visible: isArrow
        },
        {
          caption: 'Stroke Style',
          type: 'select',
          // visible: isNotGroup,
          options: [
            { content: 'Plain', value: null },
            { content: 'Dashed', value: [4, 4] }
          ],
          property: 'strokeDashArray'
        },
        {
          caption: 'Curve',
          title: 'Curve',
          lblClass: 'tool-button-icon fa fas fa-bezier-curve',
          type: 'checkbox',
          property: 'curved',
          visible: isPolyCurve
        },
        {
          caption: 'Closed',
          title: 'Closed',
          lblClass: 'tool-button-icon fa fas fa-circle-notch',
          type: 'checkbox',
          property: 'closed',
          visible: isPolyCurve
        },
      ]
    },
    {
      id: 'geometry',
      type: 'group',
      caption: 'Geometry',
      tools: [
        {
          caption: 'Angle',
          type: 'number',
          min: 0,
          max: 360,
          step: 0.1,
          round: 2,
          getter: (config) => round(target.getExtra(config.property), 2),
          property: 'absoluteAngle'
        },
        {
          caption: 'Set Angle',
          type: 'select',
          getter: ()=> {
            if(target.angle % 30 === 0 || target.angle % 45 === 0){
              return target.angle + "º"
            }
            else{
              return "--"
            }
          },
          setter: (value)=> {
            if(value === "--"){
              return
            }
            return target.setExtra('angle',+value.substring(0,value.length - 1))
          },
          property: 'fontFamily',
          options: [
            '0º',
            '30º',
            '45º',
            '60º',
            '90º',
            '120º',
            '135º',
            '150º',
            '180º',
            '210º',
            '225º',
            '240º',
            '270º',
            '300º',
            '315º',
            '330º',
          ]
        },
        {
          caption: 'Fixed Angle',
          type: 'checkbox',
          lblClass: 'tool-button-icon fa fas fa-arrow-to-right',
          property: 'fixedAngle',
          visible: () => target?.constructor === fabric.Arrow
        },
        {
          caption: 'Rotate',
          content: icons.rotate30,
          type: 'button',
          click: () => target.setExtra('angle', target.angle + 30)
        },
        {
          id: 'geometry',
          type: 'group',
          tools: [
            {
              type: 'number',
              id: 'width',
              caption: 'width',
              min: 1,
              round: 2,
              step: 1,
              max: 10000,
              property: 'absoluteWidth'
            },
            {
              title: 'Lock Ratio',
              caption: 'Lock Ratio',
              lblClass: 'tool-button-icon fas fa-link',
              type: 'checkbox',
              property: 'lockAspectRatio'
            },
            {
              type: 'number',
              id: 'height',
              caption: 'height',
              min: 1,
              step: 1,
              round: 2,
              max: 10000,
              property: 'absoluteHeight'
            }
          ]
        }
      ]
    },
    {
      id: 'cells',
      caption: 'Cells',
      type: 'group',
      visible: () => {
        return target && (target.constructor === fabric.Table && target.isEditing && target.selection.length)
      },
      tools: [
        {
          type: 'number',
          caption: 'Cells width',
          min: 0.1,
          round: 2,
          max: 10000,
          property: 'selectedCellsAbsoluteWidth'
        },
        {
          type: 'number',
          caption: 'Cells height',
          min: 0.1,
          round: 2,
          max: 10000,
          property: 'selectedCellsAbsoluteHeight'
        },
        {
          class: 'fa fa-stop',
          type: 'button',
          caption: 'Merge',
          visible: ()=> {
            return target.isSelectionMergeble()
          },
          click: () => {
            target.mergeSelection()
          }
        },
        {
          class: 'fa fa-th-large',
          type: 'button',
          caption: 'Unmerge',
          visible: ()=> {
            return target.isSelectionUnmergeble()
          },
          click: () => {
            target.unmergeSelection()
          }
        },
        {
          caption: 'Borders',
          type: 'options',
          property: 'selectedCellsBorder',
          options: [
            { value: 'none', lblClass: 'fa fa-border-none' },
            { value: 'outer', lblClass: 'fa fa-border-outer' },
            { value: 'inner', lblClass: 'fa fa-border-inner' },
            { value: 'all', lblClass: 'fa fa-border-all' },
            { value: 'top', lblClass: 'fa fa-border-top' },
            { value: 'bottom', lblClass: 'fa fa-border-bottom' },
            { value: 'right', lblClass: 'fa fa-border-right' },
            { value: 'left', lblClass: 'fa fa-border-left' }
          ],
          setter: (value)=> {
            target.setSelectedCellsBorder(value,{
              color: bordersColor,
              width: bordersWidth
            })
          },
          getter : ()=>{
            return 'custom'
          }
        },
        {
          caption: 'Borders Color',
          type: 'colorChoice',
          options: colors,
          setter: (value)=> {
            bordersColor = value
            update()
          },
          getter : ()=>{
            return bordersColor
          }
        },
        {
          caption: 'Stroke Width',
          type: 'number',
          min: 0,
          max: 100,
          round: 2,
          setter: (value)=> {
            bordersWidth = value
          },
          getter : ()=>{
            return bordersWidth
          }
        },
      ]
    },
    {
      id: 'text',
      caption: 'Text',
      type: 'group',
      visible: isText,
      tools: [
        {
          caption: 'Font Family',
          type: 'select',
          property: 'fontFamily',
          options: ['Arial', 'Times New Roman']
        },
        {
          caption: 'Font Size',
          type: 'number',
          min: 4,
          max: 100,
          property: 'fontSize',
        },
        {
          caption: 'Text Align',
          type: 'options',
          property: 'textAlign',
          options: [
            { value: 'left', lblClass: 'fa fa-align-left' },
            { value: 'center', lblClass: 'fa fa-align-center' },
            { value: 'right', lblClass: 'fa fa-align-right' },
            { value: 'justify', lblClass: 'fa fa-align-justify' }
          ]
        },
        {
          caption: 'Vertical Align',
          type: 'options',
          visible: isTextbox,
          property: 'textVerticalAlign',
          options: [
            { value: 'top', lblClass: 'fa fa-arrow-to-top' },
            { value: 'middle', lblClass: 'fa fa-align-center' },
            { value: 'bottom', lblClass: 'fa fa-arrow-to-bottom' }
          ]
        },
        // {
        // 	caption: 'TextBgColor',
        // 	type: 'color',
        // 	setter: (value) => target.setStyle('textBackgroundColor', value),
        // 	getter: () => target.getStyle('textBackgroundColor'),
        // 	enabled: isText,
        // },
        // {
        // 	caption: 'TextFill',
        // 	type: 'color',
        // 	setter: (value) => target.setStyle('fill', value),
        // 	getter: () => target.getStyle('fill'),
        // 	enabled: isText,
        // },
        // {
        // 	caption: 'Text Style',
        // 	type: 'group',
        // 	enabled: isText,
        // 	tools: [
        // 		{
        // 			type: 'checkbox',
        // 			lblClass: "tool-button-icon fa fa-underline",
        // 			setter: (value) => target.setStyle("underline",value),
        // 			getter: () => target.getStyle("underline")
        // 		},
        // 		{
        // 			type: 'checkbox',
        // 			lblClass: "tool-button-icon fa fa-bold",
        // 			setter: (value) => target.setStyle("fontWeight",value ? "bold" : "normal"),
        // 			getter: () => target.getStyle("fontWeight") === "bold"
        // 		},
        // 		{
        // 			type: 'checkbox',
        // 			lblClass	: "tool-button-icon fa fa-italic",
        // 			setter: (value) => target.setStyle("fontStyle",value ? "italic" : "normal"),
        // 			getter: () => target.getStyle("fontStyle") === "italic"
        // 		}
        // 	],
        // },
      ]
    },
    {
      id: 'table-text',
      caption: 'Text',
      type: 'group',
      visible: () => {
        return target && (target.constructor === fabric.Table && target.isEditing && target.selection.length)
      },
      tools: [
        {
          caption: 'Font Family',
          type: 'select',
          property: 'selectedCellsFontFamily',
          options: ['Arial', 'Times New Roman']
        },
        {
          caption: 'Font Size',
          type: 'number',
          min: 4,
          max: 100,
          property: 'selectedCellsFontSize'
        },
        {
          caption: 'Text Align',
          type: 'options',
          property: 'selectedCellsTextAlign',
          options: [
            { value: 'left', lblClass: 'fa fa-align-left' },
            { value: 'center', lblClass: 'fa fa-align-center' },
            { value: 'right', lblClass: 'fa fa-align-right' },
            { value: 'justify', lblClass: 'fa fa-align-justify' }
          ]
        },
        {
          caption: 'Vertical Align',
          type: 'options',
          property: 'selectedCellsVerticalTextAlign',
          options: [
            { value: 'top', lblClass: 'fa fa-arrow-to-top' },
            { value: 'middle', lblClass: 'fa fa-align-center' },
            { value: 'bottom', lblClass: 'fa fa-arrow-to-bottom' }
          ]
        },
      ]
    },
    {
      id: 'actions',
      caption: 'Actions',
      type: 'group',
      tools: [

        {
          caption: 'flip X',
          content: icons.flipX,
          title: 'Flip X',
          click: () => target.flipHorizontally()
        },
        {
          caption: 'flip Y',
          content: icons.flipY,
          title: 'Flip Y',
          click: () => target.flipVertically()
        },
        {
          caption: 'flip XY',
          content: icons.flipXY,
          title: 'Flip XY',
          click: () => target.flipDiagonally()
        },
        {
          caption: 'Send to Back',
          title: 'Send To Back',
          class: 'fas fa-send-back',
          click: () => {
            (target.group ? target.group : canvas).sendObjectToBack(target)
            canvas.renderAll()
          }
        },
        {
          caption: 'Send Backwards',
          title: 'Send Backwards',
          class: 'fas fa-send-backward',
          click: () => {
            (target.group ? target.group : canvas).sendObjectBackwards(target)
            canvas.renderAll()
          }
        },
        {
          caption: 'Bring To Front',
          title: 'Bring To Front',
          class: 'fas fa-bring-front',
          click: () => {
            (target.group ? target.group : canvas).bringObjectToFront(target)
            canvas.renderAll()
          }
        },
        {
          caption: 'Bring Forward',
          title: 'Bring Forward',
          class: 'fas fa-bring-forward',
          click: () => {
            (target.group ? target.group : canvas).bringObjectForward(target)
            canvas.renderAll()
          }
        },
        {
          caption: 'Lock',
          title: 'Lock',
          lblClass: 'tool-button-icon fa fa-lock',
          type: 'checkbox',
          property: 'locked'
        },
        {
          caption: 'Remove',
          title: 'Remove',
          class: 'fas fa-trash',
          click: () => canvas.removeSelection(target)
        },
        {
          visible: isGroup,
          caption: 'UnGroup',
          title: 'UnGroup',
          class: 'fas fa-object-ungroup',
          click: () => target.ungroupObjects()
        },
        {
          caption: 'Group',
          visible: isActiveSelection,
          title: 'Group',
          class: 'fas fa-object-group',
          click: () => canvas.groupObjects()
        },
        {
          visible: isGroup,
          caption: 'regroup',
          title: 'regroup',
          class: 'fas fa-object-group',
          click: () => target.regroup()
        },
        {
          id: "shape-export",
          caption: 'Export Shape',
          title: "Crop",
          class: "fas fa-file-export",
          click: ()=> {
            console.log(target.toObject())
          }
        },
        {
          id: "image-crop",
          caption: 'Crop',
          title: "Crop",
          class: "fas fa-crop-alt",
          // click: toggleCrop,
          visible: () => target?.constructor === fabric.Image
        },
      ]
    },
  ], {
    visible: () => target,
    enabled: () => target,
    setter: (value, config) => {
      if (config.type === 'number' && value === null) {
        return
      }
      //todo problem with __modified property it is not clearing
      delete target.__modified
      target.setExtra(config.property, value)
      if(isText()){
        target.hiddenTextarea?.focus()
        target.initDimensions()
        canvas.renderAll()
      }
    },
    getter: (config) => target.getExtra(config.property)
    // callback: () => canvas.renderAll()
  })

  function update() {
    target = canvas.getActiveObject()
    objectTools.update()
  }

  let geometryTool = objectTools.tools.find(t => t.config.id === 'geometry').tools.find(t => t.config.id === 'geometry')
  let heightTool = geometryTool.tools.find(t => t.config.id === 'height')
  let widthTool = geometryTool.tools.find(t => t.config.id === 'width')

  canvas.on({
    'object:scaling': () => {
      heightTool.update()
      widthTool.update()
    },
    'object:modified': update,
    'object:rotating': update,
    'selection:created': update,
    'selection:updated': update,
    'selection:cleared': update,
    'table:cells:selection': update,
    'table:editing:entered': update,
    'table:editing:exited': update
  })
  return objectTools
}

export function createCanvasTools(canvas, container) {

  let canvasTools = new Toolbar(container, [
    {
      id: 'Canvas',
      class: 'canvas-toolbar',
      caption: 'Canvas',
      type: 'group',
      visible: ()=>{
        return canvas.active
      },
      tools: [
        {
          caption: 'Snapping',
          lblClass: 'tool-button-icon fa fa-lock',
          type: 'checkbox',
          property: 'snapping',
          setter: (value) => canvas.setSnapping(value)
        },
        {
          caption: 'Drawing',
          title: 'Drawing',
          type: 'checkbox',
          lblClass: 'tool-button-icon fa fa-pen',
          getter: (_config) => {
            return canvas.multiLineDrawingMode
          },
          setter: (value) => {
            canvas.setDrawingMode(value)
            canvasTools.update()
          }
          // enabled: () => canvas.canUndo(),
          // click: () => console.log("canvas")
        },
        {
          caption: 'Undo',
          class: 'fa fa-undo',
          type: 'button',
          title: 'Undo',
          enabled: () => canvas.canUndo(),
          click: () => canvas.undo()
        },
        {
          caption: 'Redo',
          class: 'fa fa-redo',
          type: 'button',
          title: 'Redo',
          enabled: () => canvas.canRedo(),
          click: () => canvas.redo()
        },
        {
          caption: 'Delete All',
          type: 'group',
          tools: [
            {
              class: 'fas fa-trash tool-button-danger',
              type: 'button',
              title: 'Delete All',
              click: () => {
                canvas.removeObjects(canvas._objects)
                // canvas.clear()
              }
            }
          ]
        }
      ]}
  ], {
    callback: () => canvas.renderAll(),

    setter: (value, config) => {
      canvas[config.property] = value
      canvas.renderAll()
    },
    getter: (config) => {
      return canvas[config.property]
    }
  })

  function update() {
    canvasTools.update()
  }
  setTimeout(update,100)

  canvas.on({
    'activated': update,
    'deactivated': update
  })
  canvas.history.on({
    'changed': update
  })
  return canvasTools

}

export const Drawer = () => {
  const params = useParams()
  const navigate = useNavigate();
  const location = useLocation()
  const { '*': boardParams } = params

  const [canvasRef, setCanvasElRef] = useCanvas()
  const [zoomFactor, setZoomFactor] = useState(1)
  const [zoomLevel, setZoomLevel] = useState(100)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [zoomData, setZoomData] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [savingProgress, setSavingProgress] = useState(0)
  const [isModified, setIsModified] = useState(false)
  const [currentVersion,setCurrentVersion] = useState(0)
  const [currentDocument,setCurrentDocument] = useState(0)
  const [selectedObjects, setSelectedObjects] = useState([])
  const [subMenuDrawer, setSubMenuDrawer] = useState(false)
  const [items, setItems] = React.useState([])
  const [itemsLoaded, setItemsLoaded] = React.useState(false)
  const [filteredItems, setFilteredItems] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [subcategories, setSubcategories] = React.useState([])
  const [subSubcategories, setSubSubcategories] = React.useState([])
  const [selectedMenu, setSelectedMenu] = useState('default')
  const [addTableModal, setAddTableModal] = useState(false)
  const [tableColumns, setTableColumns] = useState(5)
  const [tableRows, setTableRows] = useState(5)
  const [exportModal, setExportModal] = useState(false)
  const [loadingApi, setLoadingApi] = useState(false)
  const [editedItem, setEditedItem] = useState(null)
  const [lastExportedItem, setLastExportedItem] = useState(null);
  const [shapeExportLoading, setShapeExportLoading] = useState(false)
  const [query, setQuery] = useState("")
  const [, contextHolder] = notification.useNotification()
  const [drawing, setDrawing] = React.useState( null)
  const [project, setProject] = React.useState(null)
  const [openCropModal, setOpenCropModal] = useState(false)
  const [versions, setVersions] = useState([])

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isSyncing, setIsSyncing] = useState(false);

  let projectId,drawingId, versionNumberParameter
  [projectId,drawingId, versionNumberParameter] = boardParams.split("/")

  let versionNumber = +versionNumberParameter || 0
  let documentIndex = 0

  useEffect(() => {
    DATAMODEL.on("sync:start", ()=>{
      setIsSyncing(true)
    })
    DATAMODEL.on("sync:end", ()=>{
      setIsSyncing(false)
    })
    // const handleOnline = async () => {
    //   setIsOnline(true);
    //   setIsSyncing(true)
    //   await DATAMODEL.sync()dc
    //   setIsSyncing(false)
    // }
    // const handleOffline = () => {
    //   setIsOnline(false);
    // }
    //
    // window.addEventListener('online', handleOnline);
    // window.addEventListener('offline', handleOffline);
    //
    // if(navigator.onLine){
    //   handleOnline()
    // }
    //
    // return () => {
    //   window.removeEventListener('online', handleOnline);
    //   window.removeEventListener('offline', handleOffline);
    // };
  }, []);


  const ZOOM_LEVELS = [
    {name: '50%', value: 50},
    {name: '75%', value: 75},
    {name: '100%', value: 100},
    {name: '125%', value: 125},
    {name: '150%', value: 150},
    {name: '200%', value: 200},
    {name: '300%', value: 300}
  ]

  async function saveDrawing () {
    navigate(`/board/${projectId}/${drawingId}`)
    await saveDocument()
    setIsModified(false)
  }

  async function handleSettingClick () {
    setSettingsOpen(true)
  }

  async function openDrawing (drawingId) {
    navigate(`/board/${projectId}/${drawingId}`)
  }

  let autoSavingIntervalId;

  function disableAutoSaving(){
    clearInterval(autoSavingIntervalId)
  }
  function enableAutoSaving(){
    // let historyState = cdanvas.history.getCurrent().id
    autoSavingIntervalId = setInterval(()=>{
      saveDocument()
    }, 30000);
  }


  useEffect(() => {
    // This code runs when the component is mounted

    return () => {
      // This code runs when the component is about to be unmounted
      clearInterval(autoSavingIntervalId)
      // Perform any cleanup tasks here
    };
  }, []); // Empty dependency array means this effect runs only once when the component is mounted


  async function saveDocument(){
    let document = window.drawingIdWin
    let documentInfo = window.drawingInfo

    let canvas = getCanvas()
    if(!canvas){
      return
    }

    let currentHistoryState = canvas.history.getCurrent().id
    if(!canvas.isModified()) {
      return
    }

    canvas.setSaving(true)
    canvas.fire("saving:begin",{document})
    canvas.lastSavedID = currentHistoryState

    let thumbnail = await canvas.exportBlob({width: 247, height: 160})
    let saveData = canvas.toObject()

    console.log("SAVING",document,saveData)
    await DATAMODEL.createVersion(document,saveData,thumbnail)

    let newDrawingData = await DATAMODEL.drawing(document)
    window.drawingInfo = newDrawingData
    updateVersions(newDrawingData.versions)
    setCurrentVersion(newDrawingData.drawingVersion)

    // window.drawingInfo.versions

    setIsSaving(false)
    canvas.fire("saving:end",{document})

    // documentInfo.drawingVersion = newDrawingData.drawingVersion
    // documentInfo.versions = newDrawingData.versions


    enableAutoSaving()

  }

  function updateTabs(){
    let tabs = document.getElementsByClassName("drawings-tab")
    for(let el of tabs){
      if(el.getAttribute("data-drawing") === drawingId){
        el.classList.add("active")
      }
      else{
        el.classList.remove("active")
      }
    }
    setZoomLevel(100)
    document.getElementById("main-zoom-box-select").value = 100
    setLoadingApi(true)
  }
  async function setActiveDocumentAndVersion(drawingIdArgument,drawingVersion) {


    let canvas = getCanvas()
    let sameDocument = currentDocument === drawingId
    let sameVersion = sameDocument && (!drawingVersion || currentVersion === drawingVersion)

    if(sameVersion) {
      return
    }

    if(!sameDocument){
      setCurrentVersion(0)
      setVersions(null)
      await saveDocument()

      updateTabs()
    }
    else{
      setCurrentVersion(drawingVersion)
    }
    setCurrentDocument(drawingId)
    drawingId = drawingIdArgument

    canvas.setLoadingErrors(false)
    canvas.disableHistory()
    canvas.clear()
    canvas.resetHistory()
    disableAutoSaving()

    let errors = []
    if (!drawingId) {
      setDrawing(null)
      canvas.setActive(false)
      canvas.setVisible(false)
      return
    }

    canvas?.setLoading(true)

    documentIndex ++
    let currentDocumentIndex = documentIndex

    if(sameDocument) {
      setCurrentVersion(drawingVersion)
    }
    else{
      try {
        window.drawingIdWin = drawingId
        window.drawingInfo = await DATAMODEL.drawing(drawingId)
        if(!drawingVersion){
          drawingVersion = window.drawingInfo.drawingVersion
        }
        setDrawing(window.drawingInfo)
        if (currentDocumentIndex !== documentIndex) return
        updateVersions(window.drawingInfo.versions)
        setCurrentVersion(drawingVersion)
      } catch (error) {
        errors.push(error.message)
        return
      }
    }

    try {
      if(drawingVersion === 0){
        window.drawingData = {}
      }
      else{
        window.drawingData = await DATAMODEL.version(drawingId, drawingVersion)

        function convertIt(instance){
          if(instance.objects){
            for(let obj of instance.objects){
              convertIt(obj)
            }
          }
          if(instance.strokeWidth && instance.strokeWidth < 0){
            console.log("instance.strokeWidth")
            delete instance.strokeWidth
          }
        }
        convertIt(window.drawingData)

      }


      if(currentDocumentIndex !== documentIndex) return
    }
    catch(error) {
      console.error(error)
      canvas.setLoadingErrors([error])
      return
    }

    canvas.setLoadingErrors(false)
    canvas.disableHistory()
    canvas.clear()
    canvas.resetHistory()
    await canvas.loadFromJSON(window.drawingData)
    if(currentDocumentIndex !== documentIndex) return

    canvas.centerAndZoomOut({scaleFactor: 1.5, useWindowSize: true})
    canvas.requestRenderAll();
    canvas.enableHistory()
    canvas.lastSavedID = canvas.history.getCurrent().id;
    canvas.setLoading(false)
    enableAutoSaving()

    setZoomFactor(canvas.getZoom())
    setLoadingApi(false)
  }

//todo what is this? to be deleted?
  const OBJECT_TYPES = {
    CUSTOM_LINE: 'customline',
    CUSTOM_LINE_GROUP: 'customline-group',
    CUSTOM_LINE_ARROW: 'customlinearrow',
    CUSTOM_ARROW_GROUP: 'customarrow-group',
    CUSTOM_CURVE_LINE: 'customcurveline',
    CUSTOM_CURVE_POINT: 'customcurvepoint',
    CUSTOM_CURVE_GROUP: 'customcurve-group',
    CUSTOM_EXPORT_LINE: 'single-export-line',
    ACTIVE_SELECTION: 'activeselection',
    ALIGING_LINES: 'aliging-lines',
    CANVAS_PATH: '/board/'
  }

  async function handleBreadcrumbs (redirectPath) {
    if(location.pathname.includes(OBJECT_TYPES.CANVAS_PATH) && !location.pathname.includes('settings') ){
      await saveDrawing()
      redirectPath && navigate(redirectPath);
    }else{
      navigate(redirectPath)
    }
  }

  useEffect(() => {
    let canvas = getCanvas()

    if (canvas) {
      const handleMouseWheel = (opt) => {
        const delta = opt.e.deltaY;
        const scrollTop = canvas.wrapperEl.scrollTop;
        canvas.wrapperEl.scrollTop = scrollTop + delta;
      };

      canvas.on('mouse:wheel', handleMouseWheel);

      return () => {
        canvas.off('mouse:wheel', handleMouseWheel);
      };
    }
  }, [canvasRef,zoomData]);

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      await saveDrawing()
      event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      console.log(event.returnValue)
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  },[]);

  function handleZoomChange (e) {
    let canvas = getCanvas()
    setZoomLevel(e.target.value);
    canvas.setZoom(e.target.value / 100 * zoomFactor)
  }

  function searchShapeWithName (query) {
    return items.filter(i => i.label?.toLowerCase().includes(query?.toLowerCase()))
  }

  function searchShapeWithNameCategory (label,category,subcategory,subSubcategory) {
    let filtered = items
      .filter(i => category ? i.category === category : !i.category)
      .filter(i => subcategory ? i.subcategory === subcategory : !i.subcategory)
      .filter(i => subSubcategory ? i.subSubcategory === subSubcategory : !i.subSubcategory)
      .filter(i => label ? i.label === label : !i.label)

    return filtered[0]
  }

  function searchShapeWithId (query) {
    return items.filter(i => i.id === query)
  }

  function onCustomItemSearch(items,query,categories,subcategories,subSubcategories) {
    setQuery(query)
    let fItems = query ? items.filter(i => i.label.toLowerCase().includes(query.toLowerCase())) : items

    let fCategories = []

    for(let item of fItems) {
      if (!item.category) {
        item.category = ""
      }


      if(!window.firsttime){
        window.firsttime = true
      }
      let category = fCategories.find(cat => cat.id === item.category)
      if (!category) {
        category = {...categories.find(c => c.id === item.category) || {id: item.category,label: "No Category"}, subcategories: []}
        fCategories.push(category)
      }

      if (!item.subcategory) item.subcategory = ""
      let subcategory = category.subcategories.find(scat => scat.id === item.subcategory)
      if (!subcategory) {
        let sc = subcategories.find(c => (
          c.parentId === item.category &&
          c.id === item.subcategory
        ));
        subcategory = sc ? {...sc} : {id: item.subcategory,label: "No Subcategory"}
        subcategory.subsubcategories = []
        category.subcategories.push(subcategory)
      }

      if (!item.subSubcategory) item.subSubcategory = ""
      let subsubcategory = subcategory.subsubcategories.find(sscat => sscat.id === item.subSubcategory)
      if (!subsubcategory) {
        let ssc = subSubcategories.find(c => (
          // c.parentId === item.category &&
          // c.parentSubcategoryId === item.subcategory &&
          c.id === item.subSubcategory
        ));
        subsubcategory = ssc ? {...ssc} : {id: item.subSubcategory,label: "No Sub Sub Category"}
        subsubcategory.items = []
        subcategory.subsubcategories.push(subsubcategory)
      }
      subsubcategory.items.push(item)
    }

    if(!query) {
      let diff = categories.filter(category => !fCategories.some(fCategory => fCategory.id === category.id));
      fCategories.push(...diff.map(c => ({...c, subcategories: []})))
    }

    fCategories = fCategories.sort((a,b) => a.label > b.label ? 1 : -1)
    for(let category of fCategories){
      category.subcategories = category.subcategories.sort((a,b) => a.label> b.label ? 1 : -1)
      for(let subcategory of category.subcategories){
        subcategory.subsubcategories = subcategory.subsubcategories.sort((a,b) => a.label > b.label ? 1 : -1)
      }
    }
    setFilteredItems(fCategories);
  }

  async function setVersion (version) {
    if(currentVersion === version){
      return
    }
    if(!isModified || window.confirm("Changes Will not be Saved! Continue?")) {
      navigate(`/board/${projectId}/${drawingId}/${version}`)
    }
  }

  async function deleteVersion (drawingID,versionID) {
    window.drawingIdWin = drawingId
     await DATAMODEL.deleteVersion(drawingID,versionID)
    window.drawingInfo = await DATAMODEL.drawing(drawingID)
    updateVersions(window.drawingInfo.versions)
  }

  function exportHigh () {
    let canvas = getCanvas()
    canvas.downloadAsPDF(window.drawingInfo.name )
  }

  useEffect(() => {
    DATAMODEL.project(projectId).then((project)=>{
      setProject(project)
    })
  },[projectId])

  function updateVersions(v){
    let result = []
    if(v){
      for(let i = 0; i< v.length ; i ++){
        result.unshift({
          drawing: drawingId,
          thumbnail: v[i].thumbnail,
          title: "version - " + v[i].version,
          id: v[i].version
        })
      }
    }
    setVersions(result)
  }
  useEffect(() => {
    setActiveDocumentAndVersion(drawingId,versionNumber)
  },[project, drawingId, versionNumber])

  useEffect(() => {
    let canvas = getCanvas()

    canvas.on("saving:begin",  ()=> {
      setIsSaving(true)
      setCurrentVersion(window.drawingInfo.drawingVersion)
      updateVersions(window.drawingInfo.versions)
    })

    canvas.on("saving:end",  ()=> {
      setIsSaving(false)
      setCurrentVersion(window.drawingInfo.drawingVersion)
      updateVersions(window.drawingInfo.versions)
    })

    canvas.on("saving:progress",  ({progress})=> {
      setSavingProgress(Math.round(progress))
    })

    canvas.setLoading(true)
    canvas.history.on("changed",()=>{
      setIsModified(canvas.isModified())
    })

    canvas.on('viewport:scaling', () => {
      setZoomData(canvas)
      setZoomLevel(canvas.getZoom() * 100 );
    })

    function updateObject() {
      let object = canvas.getActiveObject()
      let objects = canvas.getActiveObject()
      canvas.showObjectAngle(object)
      if (object instanceof fabric.Table) {
        setTableColumns(object.getColumns().length)
        setTableRows(object.getRows().length)
      }
      setSelectedObjects(objects)
    }

    canvas.on('selection:updated', updateObject)
    canvas.on('selection:created', updateObject)
    canvas.on('selection:cleared', (e) => {
      setSelectedObjects([])
    })

    createCanvasTools(canvas, "canvas-toolbar")
    let objectsToolbar = createObjectsTools(canvas, "objects-toolbar")

    objectsToolbar.tools
      .find(tool => tool.config.id === "actions").tools
      .find(tool => tool.config.id === "shape-export")
      .input.onclick  = function toggleExportModal () {
        if (canvas.getActiveObject()) {
          setExportModal(!exportModal)
        } else {
          openNotification('No object selected to export', 'warning')
        }
      }
    objectsToolbar.tools
      .find(tool => tool.config.id === "actions").tools
      .find(tool => tool.config.id === "image-crop")
      .input.onclick  = function toggleCrop  () {
        let activeObj = canvas.getActiveObject();
        if(activeObj instanceof fabric.Image){
          setOpenCropModal(pre => !pre)
        }
      }

    canvas.on("loading:error",  ()=> {
      openNotification('Unable to load drawing', 'error')
    })

  },[canvasRef])

  async function onDrawingTabsScroll (e) {
    let drawingTabsElement = document.getElementById("drawings-tabs")
    drawingTabsElement.scroll({ left: drawingTabsElement.scrollLeft + (e.deltaX || e.deltaY), behavior: 'smooth' })
  }

  async function slideLeft () {
    let drawingTabsElement = document.getElementById("drawings-tabs")
    drawingTabsElement.scroll({ left: drawingTabsElement.scrollLeft - 100, behavior: 'smooth' })
  }

  async function slideRight () {
    let drawingTabsElement = document.getElementById("drawings-tabs")
    drawingTabsElement.scroll({ left: drawingTabsElement.scrollLeft +100, behavior: 'smooth' })
  }

  async function uploadFiles (e) {
    let canvas = getCanvas()
    await canvas.uploadFiles(e.target.files[0])
    e.target.value = ''
  }

  function toggleTableModal () {
    setAddTableModal(!addTableModal)
    setTableColumns(5)
    setTableRows(5)
  }
  // Load Shapes and Categories
  useEffect(() => {
    Promise.all([
      DATAMODELOLD.shapes(),
      DATAMODELOLD.loadCategories(),
      DATAMODELOLD.loadSubcategories(),
      DATAMODELOLD.loadSubSubcategories(),
    ]).then(([items, categories, subcategories, subSubcategories]) => {
      setItems(items)
      setCategories(categories)
      setSubcategories(subcategories)
      setSubSubcategories(subSubcategories)
      setItemsLoaded(true)
    })
  }, [])

  useEffect(() => {
    if(itemsLoaded){
      onCustomItemSearch(items,query,categories,subcategories,subSubcategories)
    }
  }, [itemsLoaded, items, query, categories, subcategories, subSubcategories])

  function makeTable(tableColumns,tableRows){
    let columnSize = 110, rowSize = 25;
    return {
      type: 'Table',
      cells: [
        // Array.from({ length: tableColumns }, (_, i) => ({ text: `Column ${i + 1}` })),
        ...Array.from({ length: tableRows }, () => Array.from({ length: tableColumns }, () => ({})))
      ],
      columns: Array.from({ length: tableColumns }, () => ({ width: columnSize })),
      rows: Array.from({ length: tableRows }, () => ({ height: rowSize }))
    }
  }

  function getObjectData(id,options){
    switch(id){
      case 'CheckMark':
        return { type: 'Path',
          scaleX: 0.0692,
          scaleY: 0.0692,
          path: "m0 143.3 20-30c16.511 12.907 17.767 19.639 24.949 30.909 36.804-72.31 74.954-104.96 128.57-144.29-51.91 53.35-83.23 89.32-130 198.58-16.193-26.29-27.333-53.62-43.523-55.2z",
          strokeWidth: 0,
          stroke: "#ffffff",
          fill: "black"
        }
      case 'CustomShape':
        return options
      case 'Table':
        return makeTable(tableColumns,tableRows)
      case 'Rect':
        return { type: 'Rect', width: 100, height: 100 }
      case 'Circle':
        return { type: 'Circle' }
      case 'SemiCircle':
        return { type: 'Circle', startAngle: 0, endAngle: 180, fill: "grey" }
      case 'Textbox':
        return { type: 'Textbox', text: 'Textbox', width: 100, height: 100 }
      case 'IText':
        return { type: 'IText', text: 'Text', width: 100, height: 100 }
      case 'Line':
        return { type: 'Arrow', width: 100 }
      case 'Arrow':
        return { type: 'Arrow', width: 100, arrowSize: 10 }
      case 'Triangle1':
        return { type: 'Polygon', points: [{ "x": 25, "y": 0 }, { "x": 0, "y": 50 }, { "x": 50, "y": 50 }] }
      case 'Triangle2':
        return { type: 'Polygon', points: [{ "x": 0, "y": 0 }, { "x": 0, "y": 50 }, { "x": 50, "y": 50 }] }
      case 'Curve':
        return { type: 'PolyCurve', points: [{ "x": 0, "y": 0 }, { "x": 0, "y": 50 }, { "x": 50, "y": 50 }] }
      case 'H1':
        return { type: 'IText', text: 'Heading', fontSize: "50" }
      case 'H2':
        return { type: 'IText', text: 'Sub-Heading',fontSize: "30" }
      case 'Label':
        return { type: 'IText', text: 'Label',fontSize: "15" }
      default:
        return null
    }
  }

  async function addObject (id,options = {}) {
    let canvas = getCanvas()
    let zoom =  canvas.getZoom()
    let shape = await fabric.Object.create(getObjectData(id,options))
    canvas.add(shape)
    shape.left = (- canvas.viewportTransform[4]  + (canvas.width / 2) - shape.width * shape.scaleX /2)/ zoom
    shape.top = (- canvas.viewportTransform[5]  + (canvas.height / 2) - shape.height * shape.scaleY /2)/ zoom
    canvas.setActiveObject(shape)
    shape.dirty = true
    setTimeout(()=>{canvas.renderAll()},0)
  }

  async function dropSVG (item) {
    let data = item.json
    if(data.constructor === String){
      data = JSON.parse(data)
    }
    // addObject('CustomShape',data)
    // let converted = await fabric.util.convertObject(data)
    addObject('CustomShape',data)
  }

  async function handleDeleteItem (item) {
    setLoadingApi(true)
    await DATAMODELOLD.deleteShape(item.id)

    const itemIndex = items.findIndex(i => i.id === item.id);
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    }
    onCustomItemSearch(items,query,categories,subcategories,subSubcategories)

    setLoadingApi(false)
  }

  async function exportObjects (/*canvas*/_, label, category, subcategory, subSubcategory, size) {
    let canvas = getCanvas()
    setShapeExportLoading(true);
    let object = canvas.getActiveObject()
    const thumbnail = await object.getThumbnail({ width: 300, height: 300 })
    let thumbnailDataURL = thumbnail.toDataURL()
    let data = object.toObject()
    if(data.type === "activeselection"){
      data.type = "group"
    }
    let json = JSON.stringify(data)

    let existed = searchShapeWithNameCategory(label,category,subcategory,subSubcategory)

    if(existed) {
      //todo
      let oldSizes
      if(existed.sizesUrl) {
        const response = await fetch(existed.sizesUrl);
        oldSizes = await response.json();
      }
      else{
        oldSizes = existed.sizes
      }

      let sizes = existed.sizes =  { ...oldSizes, [size]: { json} }

      await DATAMODELOLD.editShape(existed.id, { sizes })
      setLastExportedItem(existed.id);
    }
    else{
      let newItem = {label, category, subcategory, subSubcategory, thumbnail: thumbnailDataURL, sizes: { [size]: { json }}}
      let docItem = await DATAMODELOLD.createShape( newItem)
      items.push({...newItem, id: docItem.id})
      setLastExportedItem(docItem.id);
      setItems(items);
      onCustomItemSearch(items,query,categories,subcategories,subSubcategories)
    }

    canvas.renderAll()
    setShapeExportLoading(false);
    setExportModal(false);
    setLoadingApi(false);
  }

  function handleSubMenu (type) {
    setSelectedMenu(type)
    setSubMenuDrawer(true)
  }

  async function cropEnd (base64Image, crop, imageToCrop) {
    let canvas = getCanvas()
    let activeObj = canvas.getActiveObject();
    await activeObj.setSrc(base64Image);
    activeObj.originalSrc = imageToCrop;
    activeObj.crop = crop;
    activeObj.dirty = true;
    canvas.renderAll();
  }

  function getCanvas(){
    // @ts-ignore
    return canvasRef.current
  }

  function convertCurrentCanvasToBackgroundImage  (){
    let canvas = getCanvas()
    if(!canvas._activeObject){
      return
    }
    let object = canvas._activeObject
    canvas.setBackgroundObjectFromSelection(object)
    openNotification(`Selection Converted to Background Image`, 'success');
  }

  function removeBackgroundImage () {
    let canvas = getCanvas()
    canvas.backgroundImage = null;
    canvas.backgroundObject = null;
    canvas.renderAll();
  }

  function getOldCrop(){
    let canvas = getCanvas()
    return canvas?.getActiveObject()?.crop
  }

  function getImageToCrop(){
    let canvas = getCanvas()
    return canvas?.getActiveObject()?.originalSrc || canvas?.getActiveObject()?.getSrc()
  }

  return (
    <div>
      <div className="toolbar">
        <div className="logo">
          {/*<span className={isOnline ? (isSyncing ? 'sync-status fa fa-spinner fa-spin'  : 'online-status') : 'offline-status'}></span>*/}
          <button onClick={() => {
            handleBreadcrumbs('/')
          }} className="logo-heading">
            <h3>ISO-PIPELINE-APP</h3>
          </button>
        </div>
        <div className="breadcrumbs-container">
          <span className="breadcrumb" onClick={() => {
            handleBreadcrumbs('/')
          }}>Projects</span>

          {
            project && (<span> / </span>)
          }
          {
            project && (
              <span className="breadcrumb" onClick={() => {
                handleBreadcrumbs(`/project/${projectId}`)
              }}>{project?.name}</span>
            )
          }
          {
            drawing && (<span> / </span>)
          }
          {
            drawing && (
              <span>
                <span id="drawing-name">{drawing.name}</span>
                <span>: </span>
                <span id="drawing-description" className="description">{drawing.description || 'No Description'}</span>
              </span>
            )
          }

        </div>

        <FullscreenToggle></FullscreenToggle>

        <div className={!isSaving && isModified ? 'save-button active' : 'save-button'}
             onClick={() => saveDrawing()}>
          <div className="icon-holder">
            {
              isSyncing ?
                <span className="icon-spin fa fa-spinner fa-spin"></span> :
                <span className="icon">{icons.save}</span>
            }


          </div>


          <span className="title"> {isSyncing ? `Saving...` : (isModified ? 'Save' : 'Saved')}</span>
          {/*{isSaving &&*/}
          {/*  <span className="saving-progress" style={{ width: savingProgress + '%' }}></span>*/}
          {/*}*/}
        </div>

        <div className="main-zoom-box">
          <h3>Zoom</h3>
          <select id="main-zoom-box-select" value={zoomLevel} onChange={handleZoomChange}>
            {ZOOM_LEVELS.map((l, i) => {
              return <option key={i} value={l.value}>{l.name}</option>
            })}
          </select>
        </div>

        <span onClick={handleSettingClick} className="setting-button">
      <SettingOutlined className="icon" />
    </span>
      </div>

      <div className="parentContainera">

        <div id="left-sidebar" className="sidebar">
          <div role="tablist" aria-orientation="vertical" aria-label="Side Panel" className="innerSidebar" tabIndex={0}>
            <div className={subMenuDrawer && selectedMenu === 'default' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('default')}>
              <span className="icon">{icons.defaultShapes}</span>
              <span className="title">Default Shapes</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'custom' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('custom')}>
              <span className="icon">{icons.customShapes}</span>
              <span className="title">Custom Shapes</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'text' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('text')}>
              <span className="icon">{icons.text}</span>
              <span className="title">Text</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'upload' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('upload')}>
              <span className="icon">{icons.uploads}</span>
              <span className="title">Uploads</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'table' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('table')}>
              <span className="icon">{icons.table}</span>
              <span className="title">Add Table</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'export' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('export')}>
              <span className="icon">{icons.export}</span>
              <span className="title"> Export as PDF</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'actions' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('actions')}>
              <span className="title"> Actions</span>
            </div>
            <div className={subMenuDrawer && selectedMenu === 'versions' ? 'item selected' : 'item'}
                 onClick={() => handleSubMenu('versions')}>
              <span className="title"> Versions</span>
            </div>
          </div>
          {!subMenuDrawer &&
            <Button className="subMenu-open " icon={<RightOutlined />}
                    onClick={() => setSubMenuDrawer(!subMenuDrawer)} />
          }
        </div>
        <div className="subMenu" style={{ display: subMenuDrawer ? '' : 'none' }}>
          <div style={{ display: selectedMenu === 'default' ? '' : 'none' }}>
            <h3>Lines and Shapes</h3>
            <div className="shapesContainer">
              <div className="shape" onClick={() => addObject('Rect')}>{icons.rect}</div>
              <div className="shape" onClick={() => addObject('Circle')}>{icons.circle}</div>
              <div className="shape" onClick={() => addObject('Triangle1')}>{icons.triangle1}</div>
              <div className="shape" onClick={() => addObject('Triangle2')}>{icons.triangle2}</div>
              <div className="shape" onClick={() => addObject('Line')}>{icons.line} </div>
              <div className="shape" onClick={() => addObject('Arrow')}>{icons.arrow}</div>
              <div className="shape" onClick={() => addObject('Curve')}>{icons.curve}</div>
              <div className="shape" onClick={() => addObject('SemiCircle')}>{icons.semiCircle}</div>
              <div className="shape" onClick={() => addObject('CheckMark')}>{icons.checkMark}</div>
            </div>
          </div>

          <div style={{ display: selectedMenu === 'custom' ? '' : 'none' }}>
            <h3>Items</h3>
            <Search placeholder="Search item" allowClear
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    onSearch={() => onCustomItemSearch(items, query, categories, subcategories, subSubcategories)} />
            {
              categories && subcategories && subSubcategories && <Collapse accordion>
                {
                  filteredItems && filteredItems.map((category) => {
                    return <Panel header={category.label} key={category.id}>
                      <div className="subcategory-block">
                        <Collapse accordion>
                          {
                            category.subcategories?.map((subcategory) => {
                              return <Panel header={subcategory.label} key={subcategory.id}>
                                {
                                  subcategory.subsubcategories?.map((subsubcategory) => {
                                    return <div key={subsubcategory.id} className="subcategoryContainer">
                                      <div>{subsubcategory.label}</div>

                                      {subsubcategory?.items.map((item) => {
                                        return <ShapeItem dropSVG={dropSVG}
                                                          handleDeleteItem={handleDeleteItem}
                                                          item={item}
                                                          loadingApi={loadingApi}
                                                          setEditedItem={setEditedItem}
                                                          setExportModal={setExportModal}
                                                          key={item.id} />
                                      })}
                                    </div>
                                  })
                                }
                              </Panel>
                            })
                          }
                        </Collapse>
                      </div>
                    </Panel>
                  })
                }
              </Collapse>
            }
          </div>

          <div style={{ display: selectedMenu === 'text' ? '' : 'none' }}>
            <h3>Text</h3>
            <button onClick={() => addObject('H1')}>Add heading</button>
            <button onClick={() => addObject('H2')}>Add sub-heading</button>
            <button onClick={() => addObject('Label')}>Add label</button>
          </div>

          <div style={{ display: selectedMenu === 'upload' ? '' : 'none' }}>
            <h3>Import Files</h3>
            <input type="file" onChange={(e) => uploadFiles(e)} />
            <br />
          </div>

          <div style={{ display: selectedMenu === 'table' ? '' : 'none' }}>
            <h3>Table</h3>
            <button onClick={() => setAddTableModal(true)}>Create table</button>
            <Modal title="Table" open={addTableModal} onOk={() => {
              addObject('Table')
              setAddTableModal(false)
            }} onCancel={toggleTableModal}>
              <div className="modalInputContainer table-modal-container">
                <div>
                  <h5>Number of Columns:</h5>
                  <h5>Number of Rows:</h5>
                </div>
                <div>
                  <div className="table-model-inner">
                    <button onClick={() => {
                      tableColumns > 1 && setTableColumns(tableColumns - 1)
                    }}>-
                    </button>
                    <Input className="modal-custom-input no-spin" type="number"
                           onChange={(e) => {
                             setTableColumns(parseInt(e.target.value))
                           }} value={tableColumns} defaultValue={tableColumns} />
                    <button onClick={() => {
                      setTableColumns(tableColumns + 1)
                    }}>+
                    </button>
                  </div>
                  <div className="table-model-inner">
                    <button onClick={() => {
                      tableRows > 1 && setTableRows(tableRows - 1)
                    }}>-
                    </button>
                    <Input className="modal-custom-input no-spin" type="number"
                           onChange={(e) => {
                             setTableRows(parseInt(e.target.value))
                           }} value={tableRows} defaultValue={tableRows} />
                    <button onClick={() => {
                      setTableRows(tableRows + 1)
                    }}>+
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <div style={{ display: selectedMenu === 'export' ? '' : 'none' }}>
            <h3>Export Drawing</h3>
            <button onClick={exportHigh}>Export</button>
          </div>

          <div style={{ display: selectedMenu === 'settings' ? '' : 'none' }}>
            {/*<ManageCategoryContent />*/}
          </div>

          <div style={{ display: selectedMenu === 'actions' ? '' : 'none' }}>
            <Popconfirm
              title="Delete the task"
              description="Are you sure you want to convert?"
              onConfirm={convertCurrentCanvasToBackgroundImage}
              onCancel={() => {
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>{selectedObjects?.length ? 'Selection' : 'Drawing'} To
                Background</Button>
            </Popconfirm>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete Background?"
              onConfirm={removeBackgroundImage}
              onCancel={() => {
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger>Remove Canvas Background</Button>
            </Popconfirm>

          </div>
          <div className="versions-panel" style={{ display: selectedMenu === 'versions' ? '' : 'none' }}>
            {
              versions && versions.map((version) => {
                return <div key={drawingId + "-" + version.id} className="version-container">
                  <h4 className="version-title"> {version.id}
                    {version.id === currentVersion && <span> - Current</span>}
                    </h4>
                  {version.id !== currentVersion && <span className="version-remove-button" onClick={()=>deleteVersion(drawingId,version.id)}><CloseCircleOutlined style={{ color: 'red' }} /> </span>}
                  <div onClick={() => setVersion(version.id)}>
                    <ImageLoader drawing={version.drawing} version={version.id}/>
                  </div>
                </div>
              })
            }
          </div>

          <Button className="subMenu-close" icon={<LeftOutlined />}
                  style={{ display: subMenuDrawer ? "block" : "none" }}
                  onClick={() => setSubMenuDrawer(!subMenuDrawer)} />

        </div>

        {openCropModal && createPortal(
          <div id="open-crop-modal-1122">
            <CropModal
              isModalOpen={openCropModal}
              setIsModalOpen={setOpenCropModal}
              okAction={cropEnd}
              imageToCrop={getImageToCrop()}
              oldCrop={getOldCrop()}
            />
          </div>, document.body)
        }
        <div id="canvas-and-toolbars">
          <div className="toolbars-container">
            <div className="tools" id="objects-toolbar"></div>
            <div className="tools" id="canvas-toolbar"></div>
          </div>
          <div className="canvasContainer">
            {contextHolder}
            <div className="canvasWrapper">
              <canvas ref={setCanvasElRef}/>
            </div>
          </div>

          <div className="drawings-tabs-container">
            <div className="drawings-tabs" id="drawings-tabs" onWheel={(e) => {
              onDrawingTabsScroll(e)
            }}>
              {
                project?.drawings ? (
                  project.drawings.map((drawing, index) =>
                    <div className="drawings-tab" data-drawing={drawing.id} key={index} onClick={() => openDrawing(drawing.id)}>{drawing.name}</div>
                  )
                ) : (
                  <div className="drawings-tabs-loading"></div>
                )
              }
            </div>
            <div className="slide-left" onClick={() => {
              slideLeft()
            }}><i className="fa fa-solid fa-chevron-left"></i>
            </div>
            <div className="slide-right" onClick={() => {
              slideRight()
            }}><i className="fa fa-solid fa-chevron-right"></i>
            </div>
          </div>
        </div>

        {exportModal && (
          <ExportItemModal
            setFilteredItems={setFilteredItems}
            setItems={setItems}
            canvas={getCanvas()}
            categories={categories}
            subcategories={subcategories}
            subSubcategories={subSubcategories}
            exportModal={exportModal}
            setExportModal={setExportModal}
            exportObjects={exportObjects}
            editedItem={editedItem}
            setEditedItem={setEditedItem}
            lastExportedItem={lastExportedItem}
            setLoadingApi={setLoadingApi}
            searchShapeWithName={searchShapeWithName}
            searchShapeWithId={searchShapeWithId}
            confirmLoading={shapeExportLoading}
          />
        )}
      </div>

      {settingsOpen &&
        <div className="modal-background">
          <div className="settings-container">
            <div className="fa fa-times close-button" onClick={() => setSettingsOpen(false)}></div>
            <ManageCategoryContent
              categories={categories}
              subcategories={subcategories}
              subSubcategories={subSubcategories}
              setCategories={setCategories}
              setSubcategories={setSubcategories}
              setSubSubcategories={setSubSubcategories}
            />
          </div>
        </div>
      }

      <div id="context-menu" className="context-menu">
        <ul className="menu">
          <li className="action" id="insert-column">Insert Column</li>
          <li className="action" id="insert-row">Insert Row</li>
          <li className="action" id="merge-cells">Merge Cells</li>
          <li className="action" id="unmerge-cells">Unmerge Cells</li>
          <li className="action" id="delete-column">Delete Column</li>
          <li className="action" id="delete-row">Delete Row</li>
          <li className="action" id="delete-table">Delete Table</li>
        </ul>
      </div>
    </div>
  )
}