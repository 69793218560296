import React from 'react'
import { Modal } from 'antd'

export const DeleteModal = (props) => {
  const {
    modal,
    onConfirm,
    toggleModal,
    resourceType,
    resourceName,
    additionalMsg,
    confirmLoading,
  } = props  

  return (
    <Modal title='Delete Drawing' confirmLoading={confirmLoading} open={modal} onOk={onConfirm} onCancel={toggleModal}>
      <div className='modalInputContainer'>
        Are you sure you want to delete the {resourceType} <b>{resourceName}</b>?
        <br/>
        {additionalMsg}
      </div>
    </Modal>
  )
}
