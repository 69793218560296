import React, {useRef, useState} from 'react';
import { Modal } from 'antd';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropModal = ({isModalOpen = false,
                       setIsModalOpen,
                       okAction,
                       imageToCrop="https://i.imgur.com/iiSnt2r.jpeg",
                       oldCrop}) => {

    const [crop, setCrop] = useState(oldCrop || {
        height: 112.80006408691406,
        unit: "px",
        width: 100,
        x: 22.99993896484375,
        y: 32.799957275390625,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [image, setImage] = useState(null);
    const imgRef = useRef(null);

    const cropImageNow = () => {
        const image = imgRef.current;
        if(!image) return;
        const multiplier = image.naturalWidth / image.width
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * 2;
        canvas.height = crop.height * 2;
        const ctx = canvas.getContext('2d');

        // const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * multiplier;
        canvas.height = crop.height * multiplier;
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * multiplier, crop.height * multiplier,);
        const base64Image = canvas.toDataURL('image/png');
        okAction(base64Image, crop, imageToCrop);
    };

    const handleImageLoaded = () => {
        setIsLoading(false);
    }

    const handleOk = () => {
        cropImageNow();
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function onCrop (newcrop){
        console.log(crop)
        setCrop(newcrop)
    }

    return (
            <Modal maskClosable={false} title="" width="fit-content"  open={isModalOpen} okText={"Crop"} onOk={handleOk} onCancel={handleCancel}>
                    {imageToCrop && (
                        <ReactCrop onImageLoaded={setImage} crop={crop} onChange={onCrop} style={{maxHeight: "70vh", maxWidth: "80vw"}}>
                            <img src={imageToCrop} ref={imgRef} crossOrigin="anonymous" onLoad={handleImageLoaded}/>
                        </ReactCrop>
                    )}
            </Modal>

    );
};

export default CropModal;