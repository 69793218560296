import fabric from "./fabric.base.js"

async function simplifyGroups(groupData){
  let groupObject = await fabric.Object.create(groupData)
  let _objects = groupObject.ungroupObjects()
  return convertObject(_objects[0].toObject())
}

export async function convertObject(obj){
  if(obj.type === "group" && obj.objects.length === 1){
    obj = await simplifyGroups(obj)
  }
  else if(obj.objects){
    let newObjects = []
    for(let i = 0; i< obj.objects.length; i++){
      let newObject = await convertObject(obj.objects[i])
      if(newObject){
        newObjects.push(newObject)
      }
    }
    obj.objects = newObjects
  }
  return obj
}

export async function convertDrawing(data){
  for(let i = data.objects.length; i--;){
    data.objects[i] = await convertObject(data.objects[i])
  }
}