import React, { useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Drawings } from './components/explorer/DrawingsExplorer'
import { Drawer } from './components/drawer/drawer'
import { Projects } from './components/explorer/ProjectsExplorer'
import { DATAMODEL } from './database/database'

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [syncing, setSyncing] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  DATAMODEL.on('sync:progress',({ current,total })=>{
    setText(`Progress ${current} / ${total}`)
  })
  async function clearLocalDatabase(){
    setText(`syncing`)
    await DATAMODEL.reload(true)
    setText(`synced`)
  }
  const onLoad = () => {}

  return (
    <div className="App">
      {/*<div className="status-bar-top" style={{ display: 'block' }}>*/}
      {/*  /!*<span>Status: {isOnline ? 'Online' : 'Offline'}</span>*!/*/}
      {/*  /!*<span>Status: {isOnline ? 'Online' : 'Offline'}</span>*!/*/}
      {/*  <button onClick={() => clearLocalDatabase()}>CACHE</button>*/}
      {/*  <span>{text}</span>*/}
      {/*</div>*/}
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Register />} />*/}
          <Route path="/" element={<Projects />} />
          <Route path="/project/:id" element={<Drawings />} />
      <Route path="/board/*" element={<Drawer onLoad={onLoad} />} />
      {/* <Route exact path="/board/:id" element={<Dashboard onLoad={onLoad} saveState={false} />} /> */}
      {/* <Route exact path="/board/:id" element={<Board />} /> */}
    </Routes>
  </Router>
</div>
)
  ;
}

export default App;