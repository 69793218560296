import {notification} from "antd";

export function openNotification (description, type, message = 'Notification') {
    notification.open({
        type,
        message,
        description,
        placement: 'topRight',
        duration: 1.5
    })
}

export function progressNotification (description, type) {
    notification.open({
        key: 'progress',
        type,
        message: 'Notification',
        description,
        placement: 'topRight'
    })
}