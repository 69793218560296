import React from 'react'
import { Modal, Radio } from 'antd'

export const PDFExportModal = (props) => {
  const {
    pageOption,
    setPageOption,
    showPdfModal,
    toggleModal,
    exportProjectAsPDF
  } = props

    const handleShowPageNumberChange = (e) => {
    setPageOption(e.target.value);
  };

  return (
    <Modal title='Select PDF Options' open={showPdfModal} onOk={exportProjectAsPDF} onCancel={toggleModal}>
        <div className='modalInputContainer pdf-modal-container'>
            <h5>Show Page Numbers:</h5>
            <Radio.Group name="radiogroup"  onChange={handleShowPageNumberChange} value={pageOption}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
            </Radio.Group>
        </div>
    </Modal>
  )
}
