import {useCallback, useEffect, useRef} from "react";
import fabric from '../../fabric'

export function useCanvas(deps = []) {
    const elementRef = useRef(null)
    const fc = useRef(null)
    let canvas
    const setRef = useCallback(
        (el) => {
            elementRef.current = el
            if (fc.current) {
               // fc.current.dispose()
            }
            if (!el) {
                fc.current = null
                return
            }

            canvas = new fabric.Canvas(el,{
              fireRightClick: true,  // <-- enable firing of right click events
              fireMiddleClick: true, // <-- enable firing of middle click events
              renderOnAddRemove: true,
              preserveObjectStacking: true,
              originalSize: {width: 1700, height: 1100},
              backgroundRect: { fill: "white", shadow: { blur: 10, color: 'rgba(0,0,0,0.6)' } },
              stretchable: true,
              mouseWheelZoom: true,
              uniformScaling: false,
              useScrollbars: true,
              useContextMenu: true,
              useKeyboard: true,
              useDynamicBackground: true,
              useLoader: true,
              tooltip: { angle: true }
            })
            canvas.enableTouchGesturesPanning()
            fc.current = canvas
        },
        []
    )

    useEffect(() => {
        // disposer
        return () => {
            // we avoid unwanted disposing by doing so only if element ref is unavailable
            if (!elementRef.current) {
                if (fc.current) {
                   // fc.current.dispose()
                }
                fc.current = canvas
                fc.current = null
            }
        }
    }, [])
    return [fc, setRef]
}