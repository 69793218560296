/**
 * TypeScript class definition for a table object using FabricJS,
 * This class extends the fabric.Group class, which is a part of the FabricJS library.
 * The table object is designed to have rows, columns, and cells that can be interactively manipulated within an HTML canvas.
 *
 * Here's a brief overview of the key features and functionalities provided by this class:
 *
 * Properties and Styling:
 *
 * The table has letious customizable properties such as stroke color, stroke width, fill color, font size, and more.
 * It has options for specifying the appearance of header cells, active cells, and hover effects.
 * Rows, Columns, and Cells:
 *
 * Rows and columns can be added or removed dynamically.
 * The cells within the table can be customized, and their content can be modified.
 * Selection and Interaction:
 *
 * The class supports selecting cells, rows, and columns.
 * It allows for deleting selected rows and columns.
 * Selection can be merged or unmerged, and the selection bounds can be obtained.
 * The class provides methods for inserting new rows and columns.
 * Cell Text and Styling:
 *
 * Text properties such as font color and cell padding can be customized.
 * Events:
 *
 * The class triggers events for actions like modifying, resizing, and selecting.
 * Rendering:
 *
 * The render method is overridden to provide custom rendering, including visual cues for active selection and hover effects.
 * Additional Functionality:
 *
 * The class includes functionality for handling resizing of rows and columns.
 * It provides methods for checking if certain operations (e.g., merging, unmerging) are available based on the current selection.
 * Undo Mechanism:
 *
 * There is an initialization of an undo mechanism for reverting changes.
 */
import {fabricIcons} from './fabric-icons.js';
import fabric from './fabric.base.js'
import { makeFromObject } from './object.ext.js'

// import { evaluate } from 'math.js'
import math from './../libs/math.js'

const evaluate = math.evaluate

const tableOwnProperties = ["columns", "rows", "cells","fontSize", "borders"]

// Renders the grab control for table moving
function _renderGrabControl2(ctx, left, top, styleOverride, fabricObject) {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.strokeRect(-this.sizeX / 2, -this.sizeY / 2, this.sizeX, this.sizeY);
  // ctx.drawImage(fabricIcons.grab, -this.sizeX / 2, -this.sizeY / 2, this.sizeX, this.sizeY);
  ctx.translate(-this.sizeX +4 , -this.sizeY +4);
  ctx.scale(1.75,1.75);
  var p = new Path2D("M6 8L5 9L6 10 M5 9L13 9 M12 8L13 9L12 10 M8 6L9 5L10 6 M9 5L9 13 M8 12L9 13L10 12");
  ctx.stroke(p);
  ctx.restore();
}

// Renders the grab control for table moving
function _renderRowControl(ctx, left, top, styleOverride, fabricObject) {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.strokeRect(-this.sizeX / 2, -this.sizeY / 2, this.sizeX, this.sizeY);
  ctx.translate(4, this.sizeY / 2 - 2);
  ctx.rotate(-Math.PI /2);
  ctx.font = "16px Arial"
  ctx.fillText(this.row.index + 1, 0,0)
  ctx.restore();
}

// Renders the grab control for table moving
function _renderColumnControl(ctx, left, top, styleOverride, fabricObject) {
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.strokeRect(-this.sizeX / 2, -this.sizeY / 2, this.sizeX, this.sizeY);
  ctx.font = "16px Arial"
  ctx.fillText(numbersToLetters(this.column.index), -this.sizeX / 2 + 2, 6)
  ctx.restore();
}

// Renders the icon control for adding columns and rows
function _renderIconControl(ctx, left, top, styleOverride, fabricObject) {
  let size = 25//this.cornerSize;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(fabricIcons.add, -size / 2, -size / 2, size, size);
  ctx.restore();
}

// Renders an invisible control
function _renderInvisible(ctx, left, top, styleOverride, fabricObject) {}

function lettersToNumbers(s) {
  s = s.toUpperCase();
  let num = 0;
  for (let i = 0; i < s.length; i++) {
    num = num * 26 + (s.charCodeAt(i) - 64);
  }
  return num;
}

function numbersToLetters(num) {
  num = num + 1
  let result = '';
  while (num > 0) {
    let remainder = (num - 1) % 26;
    result = String.fromCharCode(65 + remainder) + result;
    num = Math.floor((num - 1) / 26);
  }
  if(!result){
    result = 'A'
  }
  return result;
}

function cellNameToCellCoordinates(cellName){
  let colName = cellName.match(/\D+/)[0]
  let rowName = cellName.match(/\d+/)[0]
  let col = lettersToNumbers(colName) - 1
  let row = +rowName - 1
  return [col,row]
}
function cellCoordinatesToCellName(x,y){

  if(y < 0){
    y = 0
  }
  return numbersToLetters(x) + (y+1)
}

const BORDER_TYPE = {
  VERTICAL : 1,
  HORIZONTAL : 2
}
/**
 * FabricJS Table Object
 */
class FabricTable extends fabric.Group {
  get type(){return "table"}
  set type(value) {
    console.warn('Setting type has no effect', value);
  }
  _cellsMap = new Map()
  _textMap = new Map()

  static BORDER_TYPE = BORDER_TYPE
  static defaults = {
    cornerSize: 9,
    transparentCorners: false,
    selectionColor: 'rgba(17,119,255,0.3)',
    noScaleCache: false,
    calculateFormulas: true,
    subTargetCheck: true,
    lockScalingFlip: true,
    // transparentCorners: false,
    // originX: 'left',
    // originY: 'top',
    // cornerColor: "#000000",
    // cornerSize:  8,
    strokeUniform:  false,
    stroke: '#000000',
    strokeWidth: 1,
    fill : "#ffffff",
    fillHover: null,//"#ffffff33",
    fillText: '#000000',
    cellPadding: 0,
    fontSize: 16,
    fillActive: "#00000022",
    fillHeader: "#00000066",
    minRowHeight: 5,
    minColumnWidth: 5,
    resizerSize: 6,
    editable: true,
    headerSize: 20,
    hasDragControl: false,
    hasInsertControls: false,
    hasTableControls: false,
    resizable: false
  }
  getCellName(cell){
    return cellCoordinatesToCellName(cell.c.index,cell.r.index)
  }
  getCornerEntries(){
    return Object.entries(this.oCoords).sort((a,_b) => {
      if(a[0].includes("Header")){
        return -1
      }
      return 1;
    })
  }
  constructor(options = {}) {
    options = { ...FabricTable.defaults, ...options }

    // let options = Object.assign({},this.getDefaultProperties())
    // Object.assign(options,o)

    if (options.columns && options.rows && !options.cells) {
      options.cells = new Array(options.rows.length)
      for(let y = 0; y < options.columns.length; y++ ){
        options.cells[y] = new Array(options.columns.length)

        for(let x = 0; x < options.columns.length; x++ ){
          options.cells[y][x] = {}
        }
      }
    }
    let {columns,rows,cells,...other} = options
    super([],other)
    this.selection =[]
    this._objectsBorders =[]
    // this._cols = []
    // this._rows = []
    // this._cells = []
    // this.callSuper("initialize",options)
    this.formulasEnabled = true

    if(options.resizable){
      // tl: new fabric.Control({x: -0.5, y: -0.5, fabric.controlsUtils.scaleCursorStyleHandler, actionHandler: fabric.controlsUtils.changeSize}),
      // tr: new fabric.Control({x: 0.5, y: -0.5, fabric.controlsUtils.scaleCursorStyleHandler, actionHandler: fabric.controlsUtils.changeSize}),
      // bl: new fabric.Control({x: -0.5, y: 0.5, fabric.controlsUtils.scaleCursorStyleHandler, actionHandler: fabric.controlsUtils.changeSize}),
      // br: new fabric.Control({x: 0.5, y: 0.5, fabric.controlsUtils.scaleCursorStyleHandler, actionHandler: fabric.controlsUtils.changeSize}),

      this.controls.tl.actionHandler = fabric.controlsUtils.changeSize
      this.controls.tr.actionHandler = fabric.controlsUtils.changeSize
      this.controls.bl.actionHandler = fabric.controlsUtils.changeSize
      this.controls.br.actionHandler = fabric.controlsUtils.changeSize
    }
    if(options.hasInsertControls){

      this.controls.addColumn = new fabric.Control({
        x: 0.5, y: 0, offsetX: 15,
        cursorStyle: "pointer",
        actionName: "addColumn",
        mouseDownHandler: () => {
          this.insertColumn();
          return false
        },
        render: _renderIconControl
      })
      this.controls.addRow = new fabric.Control({
        x: 0, y: 0.5, offsetY: 15,
        cursorStyle: "pointer",
        actionName: "addRow",
        mouseDownHandler: () => {
          this.insertRow();
          return false
        },
        render: _renderIconControl
      })
    }
    // if(options.hasDragControl){
    // this.controls.drag = new fabric.Control({
    //   x: -0.5, y: -0.5, offsetX: -13, offsetY: -13,
    //   cursorStyle: "grab",
    //   mouseDownHandler: () => {this._unlockMovement(); return true },
    //   mouseUpHandler: () => {this._lockMovement(); return true },
    //   actionHandler: fabric.controlsUtils.dragHandler,//change to this
    //   actionName: 'drag',
    //   render: _renderGrabControl
    // })

    this.useBordersObjects = true

    this.controls.drag = new fabric.Control({
      x: -0.5, y: -0.5,
      offsetX: -10,
      offsetY: -10,
      sizeY: 20 ,
      sizeX: 20 ,
      cursorStyle: "grab",
      mouseDownHandler: () => {this._unlockMovement(); return true },
      mouseUpHandler: () => {this._lockMovement(); return true },
      actionHandler: fabric.controlsUtils.dragHandler,//change to this
      actionName: 'drag',
      render: _renderGrabControl2
    })

    this.on({
      'modified':this._cleanCache.bind(this),
      'resizing':this._cleanCache.bind(this),
      'row': this._cleanCache.bind(this),
      'column': this._cleanCache.bind(this),
      'added': this._updateLines.bind(this),
      'deselected': this.clearSelection.bind(this)
    });
    this._updateCellsGeometry();

    this.setColumns(columns || [{width: this.width}])
    this.setRows(rows || [{height: this.height}])
    // this.setCells(cells || [[{}]])
    this.__setCells(cells || [[{}]]);
    // let borders =  this.dummyBorders() //todo

   // if(options.borders){
      this.setBorders(options.borders || {v:[],h: []})
   // }

    if(options.width){
      this.set('width', options.width)
    }
    if(options.height){
      this.set('height', options.height)
    }
    if(options.left){
      this.set('left', options.left)
    }
    if(options.top){
      this.set('top', options.top)
    }

    this._updateColumns();
    this._updateRows();
    this._updateTableWidth()
    this._updateTableHeight()
    this._updateCellsGeometry()
    this._updateRowsAndColumnsControls()
    this.setCoords()

    this.on("mouseclick",this.editCellOnDblClick.bind(this))
    this.on("mouseup",this.onMouseUp.bind(this))
    // this.on('added', this.enterEditing.bind(this))
    // this.on("mouseclick",this.editCellOnDblClick.bind(this))
    this.on('mousedblclick',this.enterEditing.bind(this))
    this._checkTarget = this.checkTarget.bind(this)

    this.on("added",()=>{
      this._canvas = this.canvas
      this.canvas.on('selection:updated', this._checkTarget);
      this.canvas.on('selection:cleared', this._checkTarget);
    })
    this.on("removed",()=>{
      this._canvas.off('selection:updated', this._checkTarget);
      this._canvas.off('selection:cleared', this._checkTarget);
      if(this.__mouseUplistener){
        window.removeEventListener("mouseup",this.__mouseUplistener)
        delete this.__mouseUplistener
      }
    })
    if(this.calculateFormulas){
      this.recalculateFormulas()
    }
  }
  onMouseUp(e){
    if(this.isEditing){
      this._lastSelection = this.selection

      let cell = this._getEventCell(e)
      if(e.button === 1) {
        if (this._selectionData) {
          this.selectionFinish();
        }
      }
      else if(e.button === 3){
        if(cell){
          if(!this.selection.includes(cell)){
            this.setSelection([cell])
          }
        }
      }
    }
  }
  _getFormulaCellValue(cell,cells = []){
    if(cells.includes(cell)){
      return cell.t.text
    }
    else{
      return this.parseFormula(cell,cells)
    }
  }
  getCellByName(cellName){
    let [col,row] = cellNameToCellCoordinates(cellName)
    return this._cells[row][col]
  }
  parseFormula(cell,parsedCells = []){
    let value = cell.text
    if(value[0] !== "="){
      return value
    }
    let formula = value.substring(1)

    try{
      formula = formula.replace(/([a-zA-Z]+[0-9]+):([a-zA-Z]+[0-9]+)/g,(result,rangeBeginName, rangeEndName)=>{
        let rangeBegin = this.getCellByName(rangeBeginName)
        let rangeEnd = this.getCellByName(rangeEndName)
        let cells = this.getCellsInRange({x: rangeBegin.c.index,y: rangeBegin.r.index}, {x: rangeEnd.c.index,y: rangeEnd.r.index})
        let results = []
        for(let cell of cells){
          let resultValue = this._getFormulaCellValue(cell,[...parsedCells,cell])
          if(resultValue){
            results.push(resultValue)
          }
        }
        return results.join(",")
      })
      formula = formula.replace(/([a-zA-Z]+[0-9]+)/g,(result,cellName)=>{
        return this._getFormulaCellValue(this.getCellByName(cellName),[...parsedCells,cell])
      })
      let result = "" + evaluate(formula)
      return result
    }
    catch(e){
      console.log(e)
      return "##ERROR##"
    }
  }
  recalculateCell(cell){
    if(!cell.text || cell.text[0] !== "="){
      return;
    }
    let value = this.parseFormula(cell)
    cell.t.set("text",value)
    // cell.t.dirty = true;
    this.dirty = true;
    this.canvas?.requestRenderAll()
  }
  recalculateFormulas(){
    if(!this.formulasEnabled){
      return
    }
    for(let row of this._cells){
      for(let cell of row){
        this.recalculateCell(cell)
      }
    }
  }
  checkTarget (e) {
    if(!this.canvas){
      return
    }
    if (this.canvas._activeObject !== this && this.canvas._activeObject?.group !== this) {
      this.exitEditing()
      this.active =false
    }
  }

  editCellOnDblClick(e){

    if(e.button !== 1){
      return
    }
    if(!this.isEditing){
      return
    }
    if(this.canvas._activeObject !== this && this.canvas._activeObject?.group !== this){
      return
    }
    let cell = this._getEventCell(e)
    if(!cell){
      return;
    }
    if(this._lastSelection?.length === 1 && this._lastSelection?.[0] === cell){

      if(this.editingCell && cell !== this.editingCell){
        if(this.isFormulaEditingMode()){
          this._replaceFormulaCell(cell)
          return;
        }
        this.editingCell.t.exitEditing()
      }
      this.canvas.setActiveObject(cell.t)
      //edit formula, not result

      if(cell.text[0] === "="){
        cell.t.set("text",cell.text)
      }
      cell.t.enterEditing()
      cell.t.setCursorByClick(e);
      this.active = true
      this.editingCell = cell
      this.canvas.tableEditingMode = true
    }else{
      this.setSelection([cell])
    }
  }
  isFormulaEditingMode(){
    return this.editingCell?.t?.text[0] === "="
  }
  _replaceFormulaCell(cell,cell2){
    let t = this.editingCell.t

    let selectionStart = this.editingCell.t.selectionStart,
      selectionEnd = this.editingCell.t.selectionEnd

    if(this.selectedFormulaStart !== null && this.selectedFormulaStart !== undefined){
      selectionStart = this.selectedFormulaStart
      selectionEnd = this.selectedFormulaEnd
    }

    let cellName = this.getCellName(cell)
    if(cell2){
      let cellName2 = this.getCellName(cell2)
      cellName += ":" + cellName2
    }
    t.insertChars(cellName, null, selectionStart,selectionEnd);
    t.selectionStart = selectionStart + cellName.length
    t.selectionEnd = selectionStart + cellName.length
    this.editingCell.text = t.text
    t.hiddenTextarea && (t.hiddenTextarea.value = t.text);
    t._updateTextarea();
    t.updateTextareaPosition();
    this.selectedFormulaStart = selectionStart
    this.selectedFormulaEnd = selectionStart + cellName.length
    this.canvas.requestRenderAll()
  }
  exitEditing(){
    this._setEditing(false)
    if(this.editingCell){
      this.editingCell.text.exitEditing()
    }
  }
  enterEditing(){
    this._setEditing(true)
  }
  _getCellDataSize(data){
    let w = 0, h = 0
    for(let cell of data[0]){
      if(cell.colspan){
        w += cell.colspan
      }
      else{
        w++
      }
    }
    for(let row of data){
      if(row[0].rowspan){
        h += row.rowspan
      }
      else{
        h++
      }
    }
    return { w,h }
  }
  insertCells(position,data){
    let size = this._getCellDataSize(data)

    for (let y = position.y; y < position.y + size.h; y++) {
      let x = position.x
      let cell = this._cells[y]?.[x]
      if(cell && cell.colspan > 1 && cell.c.index !== x){
        console.log("You can't perform a paste that partially intersects a merge.")
        return false
      }
    }
    for (let x = position.x; x < position.x + size.w; x++) {
      let y = position.y
      let cell = this._cells[y]?.[x]
      if(cell && cell.rowspan > 1 && cell.r.index !== y){
        console.log("You can't perform a paste that partially intersects a merge.")
        return false
      }
    }

    this._storeSize("width","height","top","left")
    let cells = this.getCellsInRange(position, {x: position.x + size.w - 1 ,y: position.y + size.h - 1})
    this.unmergeCells(cells)

    let bounds = this.getSelectionBounds();
    let pastedY = 0
    while(pastedY < bounds.h){
      for (let yi = 0 ; yi < size.h  ; yi ++) {
        let y = yi + position.y + pastedY
        let pastedX =0
        while(pastedX < bounds.w) {
          for (let xi = 0 ; xi < size.w ; xi++) {
            let x = xi + position.x + pastedX
            let cell = this._cells[y]?.[x]
            if(cell){
              while (cell.rowspan > 1 && cell.r.index !== y) {
                x++
                cell = this._cells[y][x]
              }
              let dataCell = data[yi]?.[xi]
              if(dataCell){
                let w = dataCell.colspan || 1, h = dataCell.rowspan || 1
                if (w > 1 || h > 1) {
                  this.mergeCellsInBounds({ x, y, w, h })
                }
                this._setCellText(x, y, dataCell.text)
                // this._setCellStrokeWidth(cell,dataCell.strokeWidth)
                // this._setCellStrokeColor(cell,dataCell.stroke)
                dataCell.fontSize && cell.t.set("fontSize", dataCell.fontSize)
                dataCell.fontFamily && cell.t.set("fontFamily", dataCell.fontFamily)
                dataCell.styles && cell.t.set("styles", dataCell.styles)
                dataCell.textAlign && cell.t.set("textAlign", dataCell.textAlign)
              }
            }
            //x += w
          }
          pastedX += size.w
        }
       // y++
      }
      pastedY += size.h
    }

    this.recalculateFormulas()
    this.cells = this.getCells()

    this._updateCellsGeometry();
    this.fire("cells:paste",  { position, data });
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }
  onPaste(){
    if(!this.isEditing || !this.selection.length){
      return false;
    }
    if(this.editingCell){
      return this.editingCell.t.onPaste()
    }

    let copiedData = JSON.parse(window.localStorage.getItem("memory-table"))
    let bounds = this.getSelectionBounds();

    this.insertCells({x: bounds.x,y:bounds.y}, copiedData)
    return true;
  }
  onCopy(){
    if(!this.isEditing || !this.selection.length){
      return false;
    }

    if(this.editingCell){
      return this.editingCell.t.onCopy()
    }

    let bounds = this.getSelectionBounds()
    let cells = this.getCells({x: bounds.x,y: bounds.y},{x: bounds.x2,y: bounds.y2},true)
    // for(let row of cells){
    //   for(let cell of row){
    //
    //     cell.text.replace(/([a-zA-Z]+[0-9]+)/g,(result,cellName)=>{
    //       let referencedCell = this.getCellByName(cellName)
    //       let offsetX =  cell.c.index - referencedCell.c.index
    //       let offsetY =  cell.r.index - referencedCell.r.index
    //       return "#("+ offsetX +"," + offsetY + ")"
    //     })
    //   }
    // }
    console.log(cells)
    window.localStorage.setItem("memory-table", JSON.stringify(cells))
    return true;
  }
  _setEditing(value){
    this.isEditing = value
    this.hoverCursor  = "cell"
    if(value){
      if(this.fillHover){
        this.enableHover();
      }
      this.enableSelection();
    }
    else{
      if(this.fillHover) {
        this.disableHover()
      }
      this.disableSelection()
    }
    this.setHasBoundsControls(!value)
    this.setHasTableControls(value)
    this.setCoords()
    this.canvas.renderAll()
    if(value){
      this.canvas?.fire('table:editing:entered', { target: this });
    }
    else{
      this.canvas?.fire('table:editing:exited', { target: this });
    }
  }
  onSet (options){
    let dirty = this._columnsmodified || this._rowsmodified || this._cellsmodified
    if(!dirty){
      return;
    }

    if(this._columnsmodified){
      this._updateColumns();
      this._updateTableWidth();
      this._refillCells()
      delete this._columnsmodified
    }

    if(this._rowsmodified){
      this._updateRows();
      this._updateTableHeight();
      this._refillCells()
      delete this._rowsmodified
    }

    if(this._cellsmodified){
      this.cells = this.getCells();
      delete this._cellsmodified
    }

    if(dirty){
      this._updateCellsGeometry();
    }
  }

  // Clear the cache canvas and mark the object as dirty to avoid visual artifacts caused by noScaleCache property
  _cleanCache(){
    if(this.canvas){
      // this._cacheContext.clearRect(-this._cacheCanvas.width,-this._cacheCanvas.height,this._cacheCanvas.width * 2,this._cacheCanvas.height * 2);
      this.dirty = true;
      this.canvas.renderAll()
    }
  }
  _highlightCellOnMouseHover (e) {
    if(this.isEditing){

    }
    let cell = this._getEventCell(e)
    if (cell) {
      this.hoverCell(cell);
    } else {
      this._unhoverCell();
    }
  }
  // Clears the hover effect on a cell
  _unhoverCell() {
    delete this._hoverCell;
    this.dirty = true;
    this.canvas?.renderAll();
  }

  enableHover() {
    this.__highlightCellOnMouseHover = this._highlightCellOnMouseHover.bind(this)
    this.__unhoverCell = this._unhoverCell.bind(this)
    this.on({
      'mousemove': this.__highlightCellOnMouseHover,
      'mouseout': this.__unhoverCell
    });
  }
  disableHover() {
    this.off({
      'mousemove': this.__highlightCellOnMouseHover,
      'mouseout': this.__unhoverCell
    });
    delete this.__highlightCellOnMouseHover
    delete this.__unhoverCell
  }

  _getEventCell(e){
    if (e.target !== this || !e?.subTargets) {
      return null
    }
    let subtarget = e.subTargets[0]
    let cell
    if (subtarget?.type === 'rect') {
      cell = this._cellsMap.get(subtarget);
    }
    if (subtarget?.type === 'i-text') {
      cell = this._textMap.get(subtarget);
    }
    return cell
  }

  _continueSelection(e) {
    if(!this.isEditing){
      return
    }
    let active = this.canvas.getActiveObject()
    if (this._selectionData && (active === this || active.group === this) && e?.subTargets) {
      let cell = this._getEventCell(e)

      if (cell && this.editingCell !== cell && this._selectionData.begin) {
        this._selectionProcess(cell);
      }
    }
  }

  _startSelectionWidthLeftMouseButton (e) {
    if(!this.isEditing){
      return
    }
    let active = this.canvas.getActiveObject()
    if ((active === this || active.group === this) && e?.subTargets) {
      let cell = this._getEventCell(e)
      if(cell  && this.editingCell !== cell){
        if(e.button === 1){

          if(this.editingCell && !this.isFormulaEditingMode()){
            this.editingCell.t.exitEditing()
            this.canvas.setActiveObject(this)
          }
          this.selectionBegin(cell);

          if(this.isFormulaEditingMode()){
            this._replaceFormulaCell(cell)
          }


        }
        else if(e.button === 3){
          // if(this.selection.includes(cell)){
          //   return
          // }
          // else{
          //   this.selectionBegin(cell);
          //   // this.setSelection([cell])
          // }
        }
      }
    }
  }

  // Enable selection functionality for the table cells
  enableSelection() {
    // this.__selectCellWidthLeftMouseButton = this._selectCellWidthLeftMouseButton.bind(this)
    this.__startSelectionWidthLeftMouseButton = this._startSelectionWidthLeftMouseButton.bind(this)
    this.__continueSelection = this._continueSelection.bind(this)
    this.on({
      // 'mouseup': this.__selectCellWidthLeftMouseButton,
      'mousedown': this.__startSelectionWidthLeftMouseButton,
      'mousemove': this.__continueSelection
    });
  }

  disableSelection() {
    this.off({
      // 'mouseup': this.__selectCellWidthLeftMouseButton,
      'mousedown': this.__startSelectionWidthLeftMouseButton,
      'mousemove': this.__continueSelection
    });
    // delete this.__selectCellWidthLeftMouseButton
    delete this.__startSelectionWidthLeftMouseButton
    delete this.__continueSelection
  }

  // Set columns for the table
  setColumns(value) {
    this.__setcolumns(value);
    this._updateColumns();
    this._updateTableWidth();
    this._updateCellsGeometry();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Get columns data
  getColumns() {
    return this._cols.reduce((p, c) => {
      let coldata = {width: c.width}
      if(c.header){
        coldata.header = c.header
      }
      p.push(coldata);
      return p;
    }, []);
  }

  // Set header for a specific column
  setHeaderColumn(i, header){
    this._cols[i].header = header
    this._refillCells()
    this._updateColumns()
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Set header for a specific row
  setHeaderRow(i, header){
    this._rows[i].header = header
    this._refillCells()
    this._updateRows()
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Set rows for the table
  setRows(value) {
    this.__setrows(value);
    this._updateRows();
    this._updateTableHeight();
    this._updateCellsGeometry();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Set text for a specific cell
  setCellText(col,row,text ){
    this._setCellText(col,row, text);
    this.cells = this.getCells();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Get rows data
  getRows() {
    return this._rows.reduce((p, c) => {
      let rowdata = {height: c.height}
      if(c.header){
        rowdata.header = c.header
      }
      p.push(rowdata);
      return p;
    }, []);
  }

  // Delete selected rows
  deleteSelectedRows() {
    this.__originalState = {
      height: this.height,
      borders: JSON.parse(JSON.stringify(this.borders)),
      rows: JSON.parse(JSON.stringify(this.rows)),
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    let bounds = this.getSelectionBounds();
    if(!bounds){
      return
    }
    for (let y = bounds.y2; y >= bounds.y; y--) {
      this._deleteRow(y);
    }
    this.shiftFormulas(0,bounds.y,0,-bounds.h)
    this._updateRows();
    this._updateTableHeight();
    this._updateCellsGeometry();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Delete a specific row
  deleteRow(position) {
    this.__originalState = {
      height: this.height,
      borders: JSON.parse(JSON.stringify(this.borders)),
      rows: JSON.parse(JSON.stringify(this.rows)),
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    this._deleteRow(position);
    this.shiftFormulas(0,position,0,-1)
    this._updateRows();
    this._updateTableHeight();
    this._updateCellsGeometry();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  _restoreSize(){
    for(let property in this._stored){
      this[property] = this._stored[property]
    }
    delete this._stored
  }

  _storeSize(...properties){
    this._stored = {}
    for(let property of properties){
      this._stored[property] = this[property]
    }
  }

  setBorderProperties(type,x,y,properties){
    this.__originalState = {
      borders: JSON.parse(JSON.stringify(this.borders)),
    }
    this.__setBorderProperties(type,x,y,properties)

    this.fire("modified",{});
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.requestRenderAll()
  }

  __setBorderProperties(type,x,y,properties){
    let border,borderObject
    if(type === BORDER_TYPE.HORIZONTAL){
      border = this.borders.h[y][x]
      borderObject = this._hBorders[y][x]
    }
    else{
      border = this.borders.v[x][y]
      borderObject = this._vBorders[x][y]
    }
    if (border) {
      if(properties.width !== undefined){
        if(properties.width === null){
          delete border.width
          borderObject.set({
            strokeWidth: this.strokeWidth
          })
        }
        else{
          border.width = properties.width
          borderObject.set({
            strokeWidth: border.width
          })
        }
      }
      if(properties.color !== undefined){
        if(properties.color === null){
          delete border.color
          borderObject.set({
            strokeWidth: this.stroke
          })
        }
        else{
          border.color = properties.color
          borderObject.set({
            stroke: border.color
          })
        }
      }
    }
    this.dirty = true
  }

  setSelectedCellsBorder(cellsMode,options){
    this.__originalState = {
      borders: JSON.parse(JSON.stringify(this.borders)),
    }


    let bounds = this.getSelectionBounds();
    let x1 = bounds.x,
        y1 = bounds.y,
        x2 = bounds.x + bounds.w - 1,
        y2 = bounds.y + bounds.h - 1,
        x,y
    // { value: 'none', lblClass: 'fa fa-border-none' },


    switch(cellsMode){
      default:
      case 'none': {
        let nullProperties = {width: 0,color: this.stroke}
        for (y = y1; y <= y2 + 1; y++) for (x = x1; x <= x2; x++) {
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y,nullProperties)
        }
        for (x = x1; x <= x2 + 1; x++) for (y = y1; y <= y2; y++) {
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x,y, nullProperties)
        }
        break
      }
      case 'all': {
        for (y = y1; y <= y2 + 1; y++) for (x = x1; x <= x2; x++) {
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y,options)
        }
        for (x = x1; x <= x2 + 1; x++) for (y = y1; y <= y2; y++) {
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x,y,options)
        }
        break
      }
      case 'inner': {
        for (y = y1 + 1; y <= y2 ; y++) for (x = x1; x <= x2; x++) {
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y,options)
        }
        for (x = x1 + 1; x <= x2; x++) for (y = y1; y <= y2; y++) {
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x,y,options)
        }
        break
      }
      case 'outer': {
        for (let y = bounds.y; y <= y2; y++) {
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x1,y,options)
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x2 + 1,y,options)
        }
        for (x = x1; x <= x2; x++) {
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y1,options)
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y2 + 1,options)
        }
        break
      }
      case 'left': {
        for (let y = bounds.y; y <= y2; y++) {
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x1,y,options)
        }
        break
      }
      case 'right': {
        for (let y = bounds.y; y <= y2; y++) {
          this.__setBorderProperties(BORDER_TYPE.VERTICAL,x2 + 1,y,options)
        }
        break
      }
      case 'top': {
        for (x = x1; x <= x2; x++) {
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y1,options)
        }
        break
      }
      case 'bottom': {
        for (x = x1; x <= x2; x++) {
          this.__setBorderProperties(BORDER_TYPE.HORIZONTAL,x,y2 + 1,options)
        }
        break
      }

    }
    for (let y = bounds.y; y <= y2+1; y++) {
      for (let x = bounds.x; x < x2+1; x++) {
        this._updateCellsTextPosition(this._cells[x]?.[y])
      }
    }
    this._updateBorders()

    let maxBorder = 0

    let lastRowBorder = this._rows.length
    let lastColBorder = this._cols.length
    for(let x = 0; x <= lastColBorder - 1; x++){
      if(this.borders.h[0][x].width > maxBorder){
        maxBorder = this.borders.h[0][x].width
      }
      if(this.borders.h[lastRowBorder][x].width > maxBorder){
        maxBorder = this.borders.h[lastRowBorder][x].width
      }
    }
    for(let y = 0; y <= lastRowBorder - 1; y++){
      if(this.borders.v[0][y].width > maxBorder){
        maxBorder = this.borders.v[0][y].width
      }
      if(this.borders.v[lastColBorder][y].width > maxBorder){
        maxBorder = this.borders.v[lastColBorder][y].width
      }
    }

    // this.strokeWidth = maxBorder
  //todo find native way to redraw object
    let ctx = this._cacheCanvas.getContext('2d')
    ctx.clearRect(-this._cacheCanvas.width,-this._cacheCanvas.height,this._cacheCanvas.width*2,this._cacheCanvas.height*2)

    this.fire("modified",{});
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.requestRenderAll()
  }

  mergeCellsInBounds(bounds){
    let cell = this._cells[bounds.y][bounds.x];
    if(!cell){
      return
    }

    cell.colspan = bounds.w;
    cell.rowspan = bounds.h;

    let x2 = bounds.x + bounds.w - 1
    let y2 = bounds.y + bounds.h - 1
    let merged = []
    let text = [cell.text];
    for (let x = bounds.x; x <= x2; x++) {
      for (let y = bounds.y; y <= y2; y++) {
        let c2 = this._cells[y][x];
        if (c2 && c2 !== cell && c2.c.index === x && c2.r.index === y) {
          this._deleteCell(c2);
          c2.text && text.push(c2.text);
          merged.push(c2)
        }
        this._cells[y][x] = cell;
      }
    }
    for (let x = bounds.x; x <= x2; x++) {
      for (let y = bounds.y+1; y < y2+1; y++) {
        this.borders.h[y][x] = null
        this._removeBorder(this._hBorders[y][x])
        this._hBorders[y][x] = null
      }
    }
    for (let y = bounds.y; y <= y2; y++) {
      for (let x = bounds.x+1; x < x2+1; x++) {
        this.borders.v[x][y] = null
        this._removeBorder(this._vBorders[x][y])
        this._vBorders[x][y] = null
      }
    }
    this._setCellText(bounds.x, bounds.y, text.join(" "));
    this._updateBorders()
    return merged;
  }

  // Merge selected cells
  mergeSelection() {
    let bounds = this.getSelectionBounds();
    if(!bounds){
      return
    }
    let original = {
      cells: JSON.parse(JSON.stringify(this.cells)),
      borders: JSON.parse(JSON.stringify(this.borders)),
    }
    let cell = this._cells[bounds.y][bounds.x];
    if(!cell){
      return
    }
    this._storeSize("width","height","top","left")
    let merged = this.mergeCellsInBounds(bounds)

    this._deleteBorders()
    this._restoreSize()
    this._updateCellsGeometry();
    this._setSeletion([cell])

    this.fire("cells:merge", { bounds, merged, cell });
    this.fire("modified",{original});
    this.canvas?.fire("object:modified", { target: this ,original});
    this.canvas?.renderAll()
  }

  unmergeCells(cells) {
    for (let cell of cells) {
      let w = cell.colspan || 1, h = cell.rowspan || 1;
      if (w > 1 || h > 1) {
        for (let x = cell.c.index; x <= cell.c.index + w - 1; x++) {
          for (let y = cell.r.index; y <= cell.r.index + h - 1; y++) {
            if (x !== cell.c.index || y !== cell.r.index) {
              this._createCell(x, y);
            }
          }
        }
        cell.colspan = 1;
        cell.rowspan = 1;



        for (let x = cell.c.index + 1; x <= cell.c.index + w - 1; x++) {
          for (let y = cell.r.index; y <= cell.r.index + h - 1; y++) {
            this.borders.v[x][y] = {}
          }
        }
        for (let y = cell.r.index + 1; y <= cell.r.index + h - 1; y++) {
          for (let x = cell.c.index; x <= cell.c.index + w - 1; x++) {
            this.borders.h[y][x] = {}
          }
        }
      }
    }
    this._updateBorders()
  }

  // Unmerge selected cells
  unmergeSelection() {
    let bounds = this.getSelectionBounds();
    if(!bounds){
      return
    }

    let original = {
      cells: JSON.parse(JSON.stringify(this.cells)),
      borders: JSON.parse(JSON.stringify(this.borders)),
    }

    this._deleteBorders()
    this._storeSize("width","height","top","left")
    this.unmergeCells(this.selection)
    this.selectRange({x: bounds.x, y: bounds.y}, {x: bounds.x2, y: bounds.y2});
    this._restoreSize()
    this._updateCellsGeometry();

    this.fire("cells:unmerge", {bounds});
    this.fire("modified",{original});
    this.canvas?.fire("object:modified", { target: this , original});
    this.canvas?.renderAll()
  }

  // Get bounds of the current selection
  getSelectionBounds() {
    if (!this.selection.length) {
      return null;
    }
    let c = this.selection[0];
    let xmin = this.selection.reduce((min, p) => p.c.index < min ? p.c.index : min, c.c.index);
    let xmax = this.selection.reduce((max, p) => p.c.index + (p.colspan || 1) - 1 > max ? p.c.index + (p.colspan || 1) - 1 : max, c.c.index + (c.colspan || 1) - 1);
    let ymin = this.selection.reduce((min, p) => p.r.index < min ? p.r.index : min, c.r.index);
    let ymax = this.selection.reduce((max, p) => p.r.index + (p.rowspan || 1)- 1 > max ? p.r.index + (p.rowspan || 1) - 1 : max, c.r.index + (c.rowspan || 1) - 1);
    return {
      x: xmin,
      y: ymin,
      w: xmax - xmin + 1,
      h: ymax - ymin + 1,
      x2: xmax,
      y2: ymax
    };
  }

  // Check if a cell is a header cell
  isHeaderCell(cell){
    return  cell.r?.header || cell.c?.header
  }

  // Hover over a cell
  hoverCell(cell) {
    if (cell && cell !== this._hoverCell) {
      this._hoverCell = cell;
      this.dirty = true;
      this.canvas?.renderAll();
    }
  }

  _setSeletion (value = []){
    this.selection = value;
    this.dirty = true;
    this.canvas?.requestRenderAll()

    this.fire("cells:selection", {selection: value});
    this.canvas?.fire("table:cells:selection", {target: this, selection: value});
  }
  // Set selection of cells
  setSelection(newSelection) {
    this._setSeletion(newSelection)
  }

  // Clear the current selection
  clearSelection(){
    if(this.__mouseUplistener){
      window.removeEventListener("mouseup",this.__mouseUplistener)
      delete this.__mouseUplistener
    }
    this._lastSelection = null
    if(!this.selection.length){
      return;
    }
    this._setSeletion([])
  }

  // Select a specific cell
  selectCell({x, y}) {
    if(this._cells[y]?.[x]){
      this.setSelection([this._cells[y][x]]);
    }
    else{
      this.setSelection([])
    }
  }

  // Delete selected columns
  deleteSelectedColumns() {
    this.__originalState = {
      width: this.width,
      borders: JSON.parse(JSON.stringify(this.borders)),
      columns: JSON.parse(JSON.stringify(this.columns)),
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    let bounds = this.getSelectionBounds();
    if(!bounds){
      return
    }
    for (let x = bounds.x2; x >= bounds.x; x--) {
      this._deleteColumn(x);
    }
    this.shiftFormulas(bounds.x,0,-bounds.w,0)
    this._updateColumns();
    this._updateTableWidth();
    this._updateCellsGeometry();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  // Delete a specific column
  deleteColumn(position) {
    this.__originalState = {
      width: this.width,
      borders: JSON.parse(JSON.stringify(this.borders)),
      columns: JSON.parse(JSON.stringify(this.columns)),
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    this._deleteColumn(position);
    this.shiftFormulas(position,0,-1,0)
    this._updateColumns();
    this._updateTableWidth();
    this._updateCellsGeometry();
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  getCellsInRange(rangeBegin, rangeEnd){
    let bounds = {
      x1: Math.min(rangeBegin.x, rangeEnd.x),
      x2: Math.max(rangeBegin.x, rangeEnd.x),
      y1: Math.min(rangeBegin.y, rangeEnd.y),
      y2: Math.max(rangeBegin.y, rangeEnd.y),
    };
    this._currentSelectionBounds = {
      x1: bounds.x1,
      x2: bounds.x2,
      y1: bounds.y1,
      y2: bounds.y2,
    };
    this._currentSelectionCells = [];
    for (let x = bounds.x1; x <= bounds.x2; x++) {
      for (let y = bounds.y1; y <= bounds.y2; y++) {
        this._addCellToSelection(x, y);
      }
    }
    let result = this._currentSelectionCells
    delete this._currentSelectionCells;
    return result
  }
  // Select a range of cells
  selectRange(rangeBegin, rangeEnd) {
    this.setSelection(this.getCellsInRange(rangeBegin, rangeEnd));
  }

  selectColumn(columnIndex) {
    this.setSelection(this.getCellsInRange({ x: columnIndex,y:0 }, { x: columnIndex,y: this._rows.length - 1 }));
  }

  selectRow(rowIndex) {
    this.setSelection(this.getCellsInRange({ y: rowIndex,x:0 }, { y: rowIndex ,x: this._cols.length - 1}));
  }

  // Set cells data for the table
  setCells(cells) {
    let _left = this.left
    let _top = this.top
    let _width = this.width
    let _height = this.height
    this.__setCells(cells);
    this.left = _left;
    this.top = _top
    this.width = _width
    this.height = _height

    this._updateCellsGeometry();

    this.dirty =true

    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  addColumn(){
    return this.insertColumn(this._cols.length)
  }

  addRow(){
    return this.insertRow(this._rows.length)
  }

  shiftFormulas(positionX,positionY,xShift,yShift){

    for (let y = positionY; y < this._rows.length; y++) {
      for (let x = positionX; x < this._cols.length; x++) {
        let cell = this._cells[y][x]
        let value = cell.text
        if(value[0] !== "=") continue
        cell.text = value.replace(/([a-zA-Z]+[0-9]+)/g,(result,cellName)=>{
          let [x,y] = cellNameToCellCoordinates(cellName)
          if(x >= positionX){
            x+=xShift;
          }
          if(y >= positionY){
            y+=yShift;
          }
          return cellCoordinatesToCellName(x,y)
        })
      }
    }
  }
  // Insert a column at a specific position
  insertColumn(position = -1, width = this._cols[this._cols.length - 1].width || 1) {

    if(position === -1){
      let bounds = this.getSelectionBounds()
      if(bounds){
        position = this.getSelectionBounds().x2
      }
      else{
        position = this._cols.length
      }
    }



    let original = {
      columns: this.columns,
      cells: JSON.parse(JSON.stringify(this.cells)),
      borders: JSON.parse(JSON.stringify(this.borders)),
      width: this.width
    }


    this.shiftFormulas(position,0,1,0)

    //todo changing position bug
    let _left = this.left
    let _top = this.top
    let _height = this.height

    for (let x = position; x < this._cols.length; x++) {
      this._cols[x].index++;
    }
    this.width += width;
    this._cols.splice(position, 0, {index: position, width})
    let expandedCells = [];
    let left, right;
    for (let y = 0; y < this._rows.length; y++) {
      this._cells[y].splice(position, 0, null);
      left = this._cells[y][position - 1];
      right = this._cells[y][position + 1];

      if (left && right && left === right) {
        this._cells[y][position] = left;
        if (!expandedCells.includes(left)) {
          expandedCells.push(left);
        }
      } else {
        this._createCell(position, y);
      }
    }
    for (let cell of expandedCells) {
      if(!cell.colspan){
        cell.colspan = 1
      }
      cell.colspan++;
    }

    //todo missing borders bug fix
    this._deleteBorders()

    let borderColumn = []
    for (let y = 0; y < this._rows.length; y++) {
      let vBorderDataObject = {}
      borderColumn.push(vBorderDataObject)
    }
    this.borders.v.splice(position, 0, borderColumn)
    // this._vBorders.splice(position, 0,  [])

    for (let y = 0; y <= this._rows.length; y++) {
      let hBorderDataObject = {}
      this.borders.h[y].splice(position, 0, hBorderDataObject)
      // this._hBorders[y].splice(position, 0,  null)
    }

    this._updateColumns();
    this._updateTableWidth();
    this._updateBorders()

    //todo changing position bug
    this.left = _left;
    this.top = _top
    this.height = _height
    this.dirty =true

    this._updateCellsGeometry();


    this.fire("column:added", {});
    this.fire("modified",{original});
    this.canvas?.fire("object:modified", { target: this , original});
    this.canvas?.renderAll()
  }

  // Insert a row at a specific position
  insertRow(position = -1, height = this._rows[this._rows.length - 1].height || 1) {

    let original = {
      rows: this.rows,
      cells: JSON.parse(JSON.stringify(this.cells)),
      borders: JSON.parse(JSON.stringify(this.borders)),
      height: this.height
    }

    this.shiftFormulas(0,position,0,1)

    //todo changing position bug
    let _left = this.left
    let _top = this.top
    let _width = this.width

    if(position === -1){
      let bounds = this.getSelectionBounds()
      if(bounds){
        position = this.getSelectionBounds().y2
      }
      else{
        position = this._rows.length
      }
    }

    for (let y = position; y < this._rows.length; y++) {
      this._rows[y].index++;
    }
    this.height += height;
    this._rows.splice(position, 0, {index: position, height});
    let expandedCells = [];
    this._cells.splice(position, 0, []);
    for (let x = 0; x < this._cols.length; x++) {
      let top = position > 0 && this._cells[position - 1][x];
      let bottom = position < this._rows.length - 1 && this._cells[position + 1][x];
      if (top && bottom && top === bottom) {
        this._cells[position][x] = top;
        if (!expandedCells.includes(top)) {
          expandedCells.push(top);
        }
      } else {
        this._createCell(x, position);
      }
    }
    for (let cell of expandedCells) {
      if(!cell.rowspan){
        cell.rowspan = 1
      }
      cell.rowspan++;
    }


    //todo missing borders bug fix
    this._deleteBorders()
    let borderRow = []
    for (let x = 0; x < this._cols.length; x++) {
      let hBorderDataObject = {}
      borderRow.push(hBorderDataObject)
    }
    this.borders.h.splice(position, 0, borderRow)
    // this._hBorders.splice(position, 0,  [])
    for (let x = 0; x <= this._cols.length; x++) {
      let vBorderDataObject = {}
      this.borders.v[x].splice(position, 0, vBorderDataObject)
      // this._vBorders[x].splice(position, 0,  null)
    }


    this._updateRows();
    this._updateTableHeight();
    this._updateBorders()

    //todo changing position bug
    this.left = _left;
    this.top = _top
    this.width = _width
    this.dirty =true

    this._updateCellsGeometry();


    this.fire("row:added", {});
    this.fire("modified",{original});
    this.canvas?.fire("object:modified", { target: this , original});
    this.canvas?.renderAll()
  }

  // Get cells data with additional properties
  getCells(rangeBegin ={x:0,y:0}, rangeEnd = {x: this._cols.length - 1,y: this._rows.length - 1 },getRelativeFormulas = false ) {
    let processed = []
    let cells = []
    for (let y = rangeBegin.y; y <= rangeEnd.y; y++) {
      let cellsrow = []
      for (let x = rangeBegin.x; x <= rangeEnd.x; x++) {
        let cell = this._cells[y]?.[x];
        if (cell && !processed.includes(cell)) {
          let data = this._getCellData(cell,getRelativeFormulas)
          processed.push(cell);
          cellsrow.push(data);
        }
      }
      cells.push(cellsrow)
    }
    return cells;
  }

  // Convert object properties to be included
  toObject(propertiesToInclude = []) {
    this.cells = this.getCells()
    return fabric.Object.prototype.toObject.call(this,[ ...tableOwnProperties, ...propertiesToInclude]);
  }

  toDatalessObject (propertiesToInclude) {
    return fabric.Object.prototype.toDatalessObject.call(this,propertiesToInclude)
  }

  drawObject (ctx) {
    this._renderBackground(ctx);
    for (let i = 0; i < this._objects.length; i++) {
      // ctx.save();
      // ctx.transform(...fabric.util.invertTransform(this.calcTransformMatrix()));
      this._objects[i].render(ctx);
      // ctx.restore();
    }
    for (let i = 0; i < this._objectsBorders.length; i++) {
      // ctx.save();
      // ctx.transform(...fabric.util.invertTransform(this.calcTransformMatrix()));
      this._objectsBorders[i].render(ctx);
      // ctx.restore();
    }
    this._drawClipPath(ctx, this.clipPath);
  }
  // Render function for the table
  render(ctx){

    ctx.save()
    this.transform(ctx);

    let w = this.width,
      h = this.height,
      x = -w / 2,
      y = -h / 2;

    ctx.beginPath();
    ctx.moveTo(x , y);
    ctx.lineTo(x + w , y);
    ctx.lineTo(x + w, y + h );
    ctx.lineTo(x , y + h);
    ctx.lineTo(x, y );
    ctx.closePath();
    this._renderPaintInOrder(ctx);
    ctx.restore()

    fabric.Group.prototype.render.call(this, ctx);
    let bounds = this.getSelectionBounds()
    if(bounds || this._hoverCell){
      ctx.save()
      this.transform(ctx);


      if(bounds && this.fillActive){
        if(this._cols[bounds.x] && this._rows[bounds.y] && this._cols[bounds.x2] && this._rows[bounds.y2]){

          // ctx.fillStyle = "transparent"
          ctx.fillStyle = this.selectionColor;
          // ctx.fillStyle = this.fillActive
          // ctx.strokeStyle = this.borderColor
          // ctx.setLineDash([4,4])
          ctx.fillRect(
            x + this._cols[bounds.x].left - 0.5,
            y + this._rows[bounds.y].top - 0.5,
            this._cols[bounds.x2].left - this._cols[bounds.x].left + this._cols[bounds.x2].width,
            this._rows[bounds.y2].top - this._rows[bounds.y].top + this._rows[bounds.y2].height
          )
        }
      }

      // if(this._hoverCell){
      //
      //   let rect = this._hoverCell.o
      //   // ctx.fillStyle = this.fillHover
      //   ctx.fillStyle = this.selectionColor;
      //   ctx.fillRect(rect.left, rect.top, rect.width, rect.height)
      //
      //
      //
      //
      //   // const ctx = this.clearContextTop(true);
      //   // if (!ctx) {
      //   //   return;
      //   // }
      //   // const boundaries = this._getCursorBoundaries();
      //   // if (this.selectionStart === this.selectionEnd) {
      //   //   this.renderCursor(ctx, boundaries);
      //   // } else {
      //   //   this.renderSelection(ctx, boundaries);
      //   // }
      //   // this.canvas!.contextTopDirty = true;
      //   // ctx.restore();
      //
      //
      //
      // }
      ctx.restore()
    }
    if(this.editingCell){
      this.editingCell.t.renderCursorOrSelection();
    }
  }

  // Checks if the current selection is mergeable (i.e., more than one cell is selected)
  isSelectionMergeble() {
    return this.selection.length > 1;
  }

  // Checks if the current selection is unmergeable (i.e., any cell in the selection has colspan or rowspan greater than 1)
  isSelectionUnmergeble() {
    return !!this.selection.find(c => (c.colspan || 1) > 1 || (c.rowspan || 1) > 1);
  }

  setHeadersAvailableForSelection(){
    if (!this.selection.length) return false;
    return !!this.selection?.find((cell) => !cell.c.header)
  }
  unsetColumnsHeadersAvailableForSelection(){
    if (!this.selection.length) return false;
    return !!this.selection?.find((cell) => cell.c.header)
  }
  unsetRowsHeadersAvailableForSelection(){
    if (!this.selection.length) return false;
    return !!this.selection?.find((cell) => cell.r.header)
  }

  // Checks if inserting a column is available for the current selection
  isInsertColumnAvailableForSelection() {
    if (!this.selection.length) return false;
    if (this.selection.length === 1) return true;
    //do not allow insert column if more than 1 column is selected
    return !this.selection.find(cell => cell.c.index !== this.selection[0].c.index);
  }

  // Checks if inserting a row is available for the current selection
  isInsertRowAvailableForSelection() {
    if (!this.selection.length) return false;
    if (this.selection.length === 1) return true;
    //do not allow insert row if more than 1 row is selected
    return !this.selection.find(cell => cell.r.index !== this.selection[0].r.index);
  }

  // Checks if removing a column is available for the current selection
  isRemoveColumnAvailableForSelection() {
    let bounds = this.getSelectionBounds();
    if (!bounds) return false;
    if (this._rows.length === bounds.w) return false;
    if (!this.selection.length) return false;
    return true;
  }

  // Checks if removing a row is available for the current selection
  isRemoveRowAvailableForSelection() {
    let bounds = this.getSelectionBounds();
    if (!bounds) return false;
    if (this._rows.length === bounds.h) return false;
    if (!this.selection.length) return false;
    return true;
  }

  setHeadersForSelectedColumns(){
    let bounds = this.getSelectionBounds();
    if(bounds){
      for(let column = bounds.x; column <= bounds.x2; column ++){
        this.setHeaderColumn(column,true)
      }
    }
  }

  setHeadersForSelectedRows() {
    let bounds = this.getSelectionBounds();
    if(bounds) {
      for (let row = bounds.y; row <= bounds.y2; row++) {
        this.setHeaderRow(row, true)
      }
    }
  }

  unsetHeadersForSelectedColumns(){
    let bounds = this.getSelectionBounds();
    if(bounds){
      for(let column = bounds.x; column <= bounds.x2; column ++){
        this.setHeaderColumn(column,false)
      }
    }
  }

  unsetHeadersForSelectedRows() {
    let bounds = this.getSelectionBounds();
    if(bounds) {
      for (let row = bounds.y; row <= bounds.y2; row++) {
        this.setHeaderRow(row, false)
      }
    }
  }

  // Updates the columns based on the provided options
  __setcolumns(value) {
    this.clearSelection()
    if(!this._cols) this._cols = []
    for (let x = value.length; x < this._cols.length; x++) {
      delete this.controls["col" + (x + 1)]
    }
    this._cols.length = value.length
    for (let x = 0; x < value.length; x++) {
      if(!this._cols[x]){
        this._cols[x] = {index: x , left: 0, width: 0, header: false};
      }
      this._cols[x].width = value[x].width || 0
      this._cols[x].header = value[x].header || false
    }
    this._columnsmodified = true;
  }

  // Updates the rows based on the provided options
  __setrows(value) {
    this.clearSelection()
    if(!this._rows) this._rows = []
    for (let y = value.length; y < this._rows.length; y++) {
      delete this.controls["row" + (y + 1)]
    }
    this._rows.length = value.length
    for (let y = 0; y < value.length; y++) {
      if(!this._rows[y]){
        this._rows[y] = {index: y, top: 0,height: 0, header: false};
      }
      this._rows[y].height = value[y].height || 0
      this._rows[y].header = value[y].header || false
    }
    this._rowsmodified = true;
  }

  // Updates the cells based on the provided options
  __setCells(cells) {
    this.clearSelection()
    this._deleteCells();
    this._cells = new Array(cells.length);



    for (let y = 0; y < cells.length; y++) {
      let x = 0
      for (let i = 0; i < cells[y].length; i++) {
        while(this._cells[y]?.[x]){
          x++;
        }
        this._createCell(x, y, cells[y][i]);
        if(cells[y][i].colspan){
          x += cells[y][i].colspan
        }
      }
    }
    this.recalculateFormulas()

    this._cellsmodified = true;
  }

  // Sets the height of the table
  __setheight(newHeight) {
    if(this._rows.length) {
      let minHeigth = this._rows.slice(0, this._rows.length - 1).reduce((p, c) => p + c.height, 0);
      newHeight = Math.max(minHeigth + this.minRowHeight, newHeight);
      if(newHeight !== this.height) {
        this._rows[this._rows.length - 1].height = newHeight - minHeigth;
        this.height = newHeight;
        this._rowsmodified = true;
      }
    }
    else{
      this.height = Math.max(newHeight, this.minRowHeight)
    }
  }

  // Sets the width of the table
  __setwidth(newWidth) {
    if(this._cols.length){
      let minWidth = this._cols.slice(0, this._cols.length - 1).reduce((p, c) => p + c.width, 0);
      newWidth = Math.max(minWidth + this.minColumnWidth, newWidth);

      if(newWidth !== this.width){
        this._cols[this._cols.length - 1].width = newWidth - minWidth;
        this.width = newWidth;
        this._columnsmodified = true;
      }
    }
    else{
      this.width = Math.max(newWidth, this.minColumnWidth)
    }
  }

  // Unlocks movement in both X and Y directions
  _unlockMovement() {
    // @ts-ignore
    this.set({
      lockMovementX: false,
      lockMovementY: false
    });
  }

  // Locks movement in both X and Y directions
  _lockMovement() {
    // @ts-ignore
    this.set({
      lockMovementX: true,
      lockMovementY: true
    });
  }

  // Gets the fill color for a cell based on whether it is a header cell
  _getCellFill(cell ){
    let header = cell.r.header || cell.c.header
    return header ? this.fillHeader : this.fill
  }

  // Initiates the selection process for a cell
  selectionBegin(cell) {

    this.canvas._currentTransform = null;

    this._selectionData = {begin: cell, end: cell}
    this.selectCell({x: cell.c.index, y: cell.r.index});

    this.fireSelectionEvent("begin");

    if(!this.__mouseUplistener){
      this.__mouseUplistener = this.selectionFinish.bind(this)
      window.addEventListener("mouseup",this.__mouseUplistener)
    }
  }

  fireSelectionEvent(postfix){
    let data = {
      cells: this.selection,
      bounds: this._currentSelectionBounds,
      begin: this._selectionData.begin,
      end: this._selectionData.end
    }
    let eventname = "selection"
    if(postfix){
      eventname += ":"+ postfix
    }
    this.fire(eventname, data);
    this.canvas?.fire("object:" + eventname, {target:this,...data});
  }

  // Processes the selection of cells
  _selectionProcess(cell) {
    if (!this._selectionData || this._selectionData.end === cell) {
      return;
    }
    this._selectionData.end = cell;
    this.selectRange({x: this._selectionData.begin.c.index, y: this._selectionData.begin.r.index}, {x: cell.c.index, y: cell.r.index});


    if(this.isFormulaEditingMode()){
      this._replaceFormulaCell(this._selectionData.begin,this._selectionData.end)
    }

    this.fireSelectionEvent("change");
  }
  // Finishes the selection process
  selectionFinish() {
    if(this.__mouseUplistener) {

      window.removeEventListener("mouseup", this.__mouseUplistener)
      delete this.__mouseUplistener
    }
    this.fireSelectionEvent("end");
    if (!this._selectionData) {
      return;
    }
    delete this._selectionData
  }

  // Refills the cells with appropriate fill colors
  _refillCells(){
    if(!this._rows || !this._cols || !this._cells){
      return
    }
    let processed = [];
    for (let y = 0; y < this._rows.length; y++) {
      for (let x = 0; x < this._cols.length; x++) {
        let cell = this._cells[y]?.[x];
        if (cell && !processed.includes(cell)) {
          processed.push(cell);
          cell.o.set({
            fill: this._getCellFill(cell),
            dirty: true
          });
        }
      }
    }
    this.dirty = true;
    this.canvas?.renderAll();
  }

  // Adds a cell to the current selection
  _addCellToSelection(x, y) {
    if(!this._currentSelectionCells || !this._currentSelectionBounds){
      return;
    }
    if(!this._cells[y]?.[x]){
      return
    }
    let cell = this._cells[y][x]
    if (!this._currentSelectionCells.includes(cell)) {
      this._currentSelectionCells.push(cell);
      if (cell.c.index < this._currentSelectionBounds.x1) {
        let oldMinX = this._currentSelectionBounds.x1;
        let newMinX = cell.c.index;
        this._currentSelectionBounds.x1 = newMinX;
        for (let xi = newMinX; xi < oldMinX; xi++) {
          this._addColumnToSelection(xi);
        }
      }
      if (cell.r.index < this._currentSelectionBounds.y1) {
        let oldMinY = this._currentSelectionBounds.y1;
        let newMinY = cell.r.index;
        this._currentSelectionBounds.y1 = newMinY;
        for (let yi = newMinY; yi < oldMinY; yi++) {
          this._addRowToSelection(yi);
        }
      }
      if (cell.c.index + cell.colspan - 1 > this._currentSelectionBounds.x2) {
        let oldMaxX = this._currentSelectionBounds.x2;
        let newMaxX = cell.c.index + cell.colspan - 1;
        this._currentSelectionBounds.x2 = newMaxX;
        for (let xi = oldMaxX + 1; xi <= newMaxX; xi++) {
          this._addColumnToSelection(xi);
        }
      }
      if (cell.r.index + cell.rowspan - 1 > this._currentSelectionBounds.y2) {
        let oldMaxY = this._currentSelectionBounds.y2;
        let newMaxY = cell.r.index + cell.rowspan - 1;
        this._currentSelectionBounds.y2 = newMaxY;
        for (let yi = oldMaxY + 1; yi <= newMaxY; yi++) {
          this._addRowToSelection(yi);
        }
      }
    }
  }

  // Adds a row to the current selection
  _addRowToSelection(y) {
    if(!this._currentSelectionBounds){
      return
    }
    for (let x = this._currentSelectionBounds.x1; x <= this._currentSelectionBounds.x2; x++) {
      this._addCellToSelection(x, y);
    }
  }

  // Adds a column to the current selection
  _addColumnToSelection = (x) => {
    if(!this._currentSelectionBounds){
      return
    }
    for (let y = this._currentSelectionBounds.y1; y <= this._currentSelectionBounds.y2; y++) {
      this._addCellToSelection(x, y);
    }
  }

  //Sets corner and controls position coordinates based on current angle, width and height, left and top.
  setCoords (skipCorners) {
    fabric.Group.prototype.setCoords.call(this, skipCorners);
    this._updateRowsAndColumnsControls()
    return this;
  }

  // Updates the controls for rows and columns
  _updateRowsAndColumnsControls() {
    if(!this.canvas || !this._rows){
      return;
    }
    let zoom = this.canvas.getZoom(),
      h = this.height * zoom* this.scaleY,
      w = this.width * zoom* this.scaleX

    for(let i = 0; i < this._rows.length; i++){
      let row = this._rows[i]
      {
        let control = this.controls["row" + (i+ 1)]
        if(control){
          control.y =  -1.5 + (this.strokeWidth  + row.top + row.height) / this.height
          // control.sizeX = w + this.headerSize + 1
          control.sizeX =  this.headerSize + 1
          control.offsetX = w -w/2- this.headerSize/2
          control.offsetY =   h
        }
      }

      {
        let control = this.controls["rowHeader" + (i+ 1)]
        if(control){
          control.y =  -1.5 + (this.strokeWidth + row.top + row.height / 2) / this.height
          control.x = - 0.5
          control.sizeY = row.height  * zoom * this.scaleY
          control.offsetX = - this.headerSize/2
          control.offsetY = h
        }
      }
    }

    for(let i = 0; i < this._cols.length; i++){
      let col = this._cols[i]
      let control = this.controls["col" + (i+ 1)]
      if(control){
        control.x =  -1.5 + (this.strokeWidth  + col.left + col.width) / this.width
        // control.sizeY = h + this.headerSize + 1
        control.sizeY =  this.headerSize + 1
        control.offsetX = w
        control.offsetY = h -h/2  - this.headerSize/2
      }

      {
        let control = this.controls["colHeader" + (i+ 1)]
        if(control){
          control.x =  -1.5 + (this.strokeWidth  + col.left + col.width / 2) / this.width
          control.y = - 0.5
          control.sizeX = col.width  * zoom * this.scaleX
          control.offsetX = w
          control.offsetY = - this.headerSize/2
        }
      }
    }
    {
      let i = this._cols.length + 1
      while(this.controls["colHeader" + i]){
        delete this.controls["colHeader" + i]
      }
    }
    {
      let i = this._rows.length + 1
      while(this.controls["rowHeader" + i]){
        delete this.controls["rowHeader" + i]
      }
    }
  }

  _getRelativeFormula(cell){
    if(cell.text.startsWith("=")){
      return cell.text.replace(/([a-zA-Z]+[0-9]+)/g,(result,cellName)=>{
        let referencedCell = this.getCellByName(cellName)
        let offsetX =  referencedCell.c.index - cell.c.index
        let offsetY =  referencedCell.r.index - cell.r.index
        return "#("+ offsetX + "," + offsetY + ")"
      })
    }
    else{
      return cell.text
    }

  }
  _getCellValue(cell,relativeFormulas){

    if(this.formulasEnabled && relativeFormulas){
      return this._getRelativeFormula(cell)
    }
    else{
      return cell.text
    }
  }
  // Gets the data for a cell based on provided options
  _getCellData(cell,relativeFormulas){
    let data = {};
    if (cell.colspan !== 1) data.colspan = cell.colspan;
    if (cell.rowspan !== 1) data.rowspan = cell.rowspan;
    if (cell.strokeWidth !== undefined &&  cell.strokeWidth !== 1) data.strokeWidth = cell.strokeWidth;
    if (cell.stroke !== undefined &&  cell.stroke !== this.stroke) data.stroke = cell.stroke;
    if (cell.fill !== undefined &&  cell.fill !== this.fill) data.fill = cell.fill;

    if(cell.t){
      let textData = cell.t.toObject();
      if(textData.fontSize !== this.fontSize){
        data.fontSize = textData.fontSize
      }
      if(textData.fontFamily !== this.fontFamily){
        data.fontFamily = textData.fontFamily
      }
      if(textData.styles?.toString() !== ''){
        data.styles = textData.styles
      }
      if(textData.textAlign !== "left"){
        data.textAlign = textData.textAlign
      }
      if(textData.textVerticalAlign !== "top"){
        data.textVerticalAlign = textData.textVerticalAlign
      }
      if(textData.fill){
        data.textFill = textData.fill
      }
      // delete textData.minWidth
      // delete textData.type
      // delete textData.left
      // delete textData.top
      // delete textData.width
      // delete textData.height
    }

    if (cell.text) {

      data.text = this._getCellValue(cell,relativeFormulas)
    }
    // if (options.includeAll || options.includePosition) {
    //   data.x = x;
    //   data.y = y;
    // }
    // if (options.includeAll || options.includeOffset) {
    //   data.top = r.top;
    //   data.left = c.left;
    // }
    // if (options.includeAll || options.includeWidth) {
    //   if (cell.width) data.width = cell.width;
    // }
    // if (options.includeAll || options.includeHeight) {
    //   if (cell.height) data.height = cell.height;
    // }
    // if (options.includeAll || options.includeCoords) {
    //   data.coords = cell.o.getAbsoluteCoordinates()
    // }
    return data
  }

  convertRelativeFormulaToAbsolute(text,x,y){
    if(!text.startsWith("=")){
      return text
    }
    return text.replace(/#\((-?[0-9]+),(-?[0-9]+)\)/g,(result,offsetX,offsetY)=>{
      let letter, row;
      if( (y + +offsetY) >=0 ){
        row  = "" + (y + +offsetY + 1)
      }
      else{
        row = "0"
      }
      if(x + +offsetX >=0 ){
        letter = numbersToLetters(x + +offsetX)
      }
      else{
        letter = "A"
      }
      return letter + row
    })
  }
  // Sets the text for a cell at a specific position
  _setCellText(x, y, text = "") {
    if(!this._cells[y][x]){
      return
    }
    let cell = this._cells[y][x]
    if(cell.text === text){
      return;
    }

    text = this.convertRelativeFormulaToAbsolute(text,x,y)

    cell.text = text;

    if (text || this.editable) {
      if (!cell.t) {
        cell.t = new fabric.TextboxExt(text, {
          hasControls: false,
          fontSize: this.fontSize,
          // backgroundColor: "rgba(0,0,44,0.5)",
          fontFamily: "Arial",
          originX: 'left',
          originY: 'top',
          left: 1,
          top: 1,
          fixedSize: true,
          lockMovementX: true,
          lockMovementY: true,
          width: cell.o.width - this.strokeWidth -2,
          height: cell.o.height  - this.strokeWidth - 2,
          padding: this.cellPadding,
          fill: this.fillText
        });
        cell.t.ignoreHistory = true
        cell.t.alwaysActive = true;
        cell.t.on("keydown",(e) => {
          this.selectedFormulaStart = null
          this.selectedFormulaEnd = null
          //not numbers
          // if(e.keyCode< 48 || e.keyCode > 57){
          //   cell.t.selectionStart = cell.t.selectionEnd
          // }
          if(cell.t.text[0] === "="){
            if(e.keyCode === 13){
              cell.t.exitEditing()
              this.canvas.setActiveObject(this)
            }
          }
          if(e.keyCode === 27){
            this.canvas.setActiveObject(this)
          }
        });
        cell.t.on("editing:entered",()=>{
          this.__originalState = {
            cells: JSON.parse(JSON.stringify(this.cells))
          }
        })
        cell.t.on("editing:exited",(original)=>{
          this.selectedFormulaStart = null
          this.selectedFormulaEnd = null
          if(this.editingCell !== cell){
            return
          }
          delete this.editingCell
          let modified = cell.t.toJSON()

          delete this.canvas.tableEditingMode
          cell.text = cell.t.text;
          this.recalculateFormulas()
          this.cells = this.getCells()
          if(JSON.stringify(original) !== JSON.stringify(modified)){
            this.fire("modified",{cell});
            this.canvas?.fire("object:modified", { target: this ,cell});
          }
        })
        this._textMap.set(cell.t, cell)
        this._objects.push(cell.t)
        cell.t.group = this;
        cell.t.canvas = this.canvas;
        // this._updateCellsGeometry();
      } else {
        cell.t.set({text});
      }
    } else {
      if (cell.t) {
        this.remove(cell.t);
      }
    }
  }
  setSelectedCellsWidth(value){
    this.__originalState = {
      width: this.width,
      columns: JSON.parse(JSON.stringify(this.columns))
    }
    for(let cell of this.selection){
      this._setColumnWidth(cell.c,value)
    }
  }
  setSelectedCellsHeight(value){
    this.__originalState = {
      height: this.height,
      rows: JSON.parse(JSON.stringify(this.rows))
    }
    for(let cell of this.selection){
      this._setRowHeight(cell.r,value)
    } 
  }
  setSelectedCellsAbsoluteWidth(value){
    this.setSelectedCellsWidth(value)
  }
  setSelectedCellsAbsoluteHeight(value){
    this.setSelectedCellsHeight(value)
  }
  getSelectedCellsWidth(){
    return this.selection[0].c.width
  }
  getSelectedCellsHeight(){
    return this.selection[0].r.height
  }
  getSelectedCellsAbsoluteWidth(){
    return this.getSelectedCellsWidth()
  }
  getSelectedCellsAbsoluteHeight(){
    return this.getSelectedCellsHeight()
  }
  getSelectedCellsStrokeColor(){
    return this.selection[0].o.stroke
  }
  getSelectedCellsStrokeWidth(){
    return this.selection[0].o.strokeWidth
  }
  // _setCellStrokeColor(cell,value){
  //   if(value === undefined){
  //     return
  //   }
  //   cell.stroke = value
  //   cell.o.stroke = value
  // }
  // setSelectedCellsStrokeColor(value){
  //   if(value === undefined){
  //     return
  //   }
  //   for(let cell of this.selection){
  //     this._setCellStrokeColor(cell,value)
  //   }
  // }
  //todo outdated
  _setCellStrokeWidth(cell,value){
    if(value === undefined){
      return
    }
    cell.strokeWidth = value
    cell.o.strokeWidth = value
    this._updateCellSize(cell,value)
    this._updateCellsTextPosition(cell)
  }
  //todo outdated
  setSelectedCellsStrokeWidth(value){
    if(value === undefined){
      return
    }
    for(let cell of this.selection){
      this._setCellStrokeWidth(cell,value)
    }
  }
  //todo
  // property: 'selectedCellsFontSize'
  // property: 'selectedCellsFontFamily',
  getSelectedCellsFontSize(){
    return this.selection[0].t.fontSize
  }
  getSelectedCellsFontFamily(){
    return this.selection[0].t.fontFamily
  }
  getSelectedCellsVerticalTextAlign(){
    return this.selection[0].t.textVerticalAlign
  }
  getSelectedCellsTextAlign(){
    return this.selection[0].t.textAlign
  }
  _setCellTextAlign(cell,value){
    if(value === undefined){
      return
    }
    cell.t.set('textAlign',value)
  }
  setSelectedCellsTextAlign(value){
    if(value === undefined){
      return
    }
    this.__originalState = {
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    for(let cell of this.selection){
      this._setCellTextAlign(cell,value)
    }
  }
  setSelectedCellsFontSize(value){
    if(value === undefined){
      return
    }
    this.__originalState = {
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    for(let cell of this.selection){
      this._setCellFontSize(cell,value)
    }
  }
  setSelectedCellsFontFamily(value){
    if(value === undefined){
      return
    }
    this.__originalState = {
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    for(let cell of this.selection){
      this._setCellFontFamily(cell,value)
    }
  }
  _setCellFontSize(cell,value){
    if(value === undefined){
      return
    }
    cell.t.setExtra("fontSize",value)
    this._updateCellsGeometry();
  }
  _setCellFontFamily(cell,value){
    if(value === undefined){
      return
    }
    cell.t.setExtra("fontFamily",value)
    this._updateCellsGeometry();
  }
  _setCellVerticalTextAlign(cell,value){
    if(value === undefined){
      return
    }
    cell.t.setTextVerticalAlign(value)
  }
  setSelectedCellsVerticalTextAlign(value){
    if(value === undefined){
      return
    }
    this.__originalState = {
      cells: JSON.parse(JSON.stringify(this.cells))
    }
    for(let cell of this.selection){
      this._setCellVerticalTextAlign(cell,value)
    }
  }

  _drawBorders(ctx, size, styleOverride = {}) {
    const options = {
      hasControls: this.hasControls,
      borderColor: this.borderColor,
      borderDashArray: this.borderDashArray,
      ...styleOverride,
    };
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    this._setLineDash(ctx, options.borderDashArray);
    this.strokeBorders(ctx, size);
    options.hasControls && this.drawControlsConnectingLines(ctx, size);
    ctx.restore();
  }
  // drawColumnControls(ctx, size) {
  //   let headerSize = this.headerSize
  //   ctx.strokeRect(-size.x / 2, -size.y / 2 - headerSize, size.x, headerSize);
  //   ctx.strokeRect(-size.x / 2-headerSize, -size.y / 2, headerSize, size.y);
  //
  //   let xFactor  = size.x / this.width
  //   let yFactor  = size.y / this.height
  //
  //
  //
  //   let offset = 0
  //   for(let i = 0; i < this._rows.length; i++){
  //     let row = this._rows[i]
  //     ctx.strokeRect(-size.x / 2-headerSize, -size.y / 2 + offset * yFactor, headerSize, row.height * yFactor );
  //     offset += row.height
  //   }
  //
  //   offset = 0
  //   for(let i = 0; i < this._cols.length; i++){
  //     let col = this._cols[i]
  //     ctx.strokeRect(-size.x / 2 + offset * xFactor, -size.y / 2 - headerSize, col.width * yFactor, headerSize);
  //     offset += col.width
  //   }
  //
  //
  // }
  // strokeBorders(ctx, size) {
  //   ctx.strokeRect(-size.x / 2, -size.y / 2, size.x, size.y);
  // }
  // drawControlsConnectingLines(ctx, size ) {
  //   let shouldStroke = false;
  //
  //   ctx.beginPath();
  //   this.forEachControl((control, key) => {
  //     if (control.withConnection && control.getVisibility(this, key)) {
  //       shouldStroke = true;
  //       ctx.moveTo(control.x * size.x, control.y * size.y);
  //       ctx.lineTo(
  //           control.x * size.x + control.offsetX,
  //           control.y * size.y + control.offsetY
  //       );
  //     }
  //   });
  //   shouldStroke && ctx.stroke();
  // }
  setHasTableControls (value) {
    this.hasTableControls = value
    if(!this._controlsVisibility){
      this._controlsVisibility = {}
    }
    this._controlsVisibility["drag"] = value

    for (let rowindex = 1; rowindex <= this._rows.length; rowindex++) {
      this._controlsVisibility["row" + rowindex] = value
      this._controlsVisibility["rowHeader" + rowindex] = value
    }

    for (let columnindex = 1; columnindex <= this._cols.length; columnindex++) {
      this._controlsVisibility["col" + columnindex] = value
      this._controlsVisibility["colHeader" + columnindex] = value
    }
  }
  // Creates a cell at a specific position
  _createCell(x, y, cell = {}) {
    let w = cell?.colspan || 1, h = cell?.rowspan || 1;

    if(!this._rows){
      this._rows = []
    }
    if(!this._cols){
      this._cols = []
    }
    if(!this._rows[y]){
      this._rows.push({ index:y, height: this.minRowHeight })
    }
    if(!this._cols[x]){
      this._cols.push({index:x, width: this.minColumnWidth })
    }

    let cellData = {
      r: this._rows[y],
      c: this._cols[x],
      colspan: w,
      rowspan: h
    }

    for (let xi = 0; xi < w; xi++) {
      for (let yi = 0; yi < h; yi++) {
        if(!this._cells[y + yi]){
          this._cells[y + yi] = []
        }
        if(!this._rows[y + yi]){
          this._rows.push({index:y + yi, height: this.minRowHeight })
        }
        if(!this._cols[x + xi]){
          this._cols.push({index:x + xi, width: this.minColumnWidth })
        }
        this._cells[y + yi][x + xi] = cellData;
      }
    }
    cellData.o = new fabric.Rect({
      id: `cell-${y}-${x}`,
      hasControls: false,
      evented: false,
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      originY: 'top',
      left: 0, top: 0, width: 1, height: 1,
      // fill: this.isHeaderCell(cellData) ? this.fillHeader : this.fill
      //fill: this.fill
      fill: "transparent" //todo
    });
    if(this.useBordersObjects){
      cellData.o.set({
        strokeWidth: 0
      })
    }
    else{
      cellData.o.set({
        stroke: this.stroke,
        strokeWidth: this.strokeWidth,
        strokeUniform: this.strokeUniform,
      })
    }

    this._cellsMap.set(cellData.o, cellData)

    this._objects.push(cellData.o)
    cellData.o.group = this;
    cellData.o.canvas = this.canvas;
    // this.add(cellData.o);

    //todo convert to relative formula
    cellData.text = cell.text || ""
    cell.text = this._getRelativeFormula(cellData)
    delete cellData.text

    this._setCellText(x, y, cell.text || "");

    this._setCellFill(cellData,cell.fill)
    this._setCellStroke(cellData,cell.stroke)

    cell.fontSize && cellData.t.set("fontSize", cell.fontSize)
    cell.fontFamily && cellData.t.set("fontFamily", cell.fontFamily)
    cell.styles && cellData.t.set("styles", cell.styles)
    cell.textFill && cellData.t.set("fill", cell.textFill)
    cell.textAlign && cellData.t.set("textAlign", cell.textAlign)
    cell.textVerticalAlign && cellData.t.set("textVerticalAlign", cell.textVerticalAlign)

    return cellData;
  }

  // Initiates the row resizing process
  rowResizingBegin() {
    let row = this._getCurrentRow()
    if(!row){
      return
    }
    this._resizingYData = {
      row,
      min: row.top + this.minRowHeight,
      initial: row.height
    }
    this.__originalState = {
      height: this.height,
      rows: JSON.parse(JSON.stringify(this.rows))
    }
  }

  //get current transformation row
  _getCurrentRow() {
    if(!this.canvas?._currentTransform){
      return null
    }
    return this._rows[+this.canvas._currentTransform.corner.substring(3) - 1]
  }

  //get current transformation column
  _getCurrentColumn() {
    if(!this.canvas?._currentTransform){
      return null
    }
    return this._cols[+this.canvas._currentTransform.corner.substring(3) - 1]
  }

  // Initiates the column resizing process
  columnResizingBegin() {
    let col = this._getCurrentColumn()
    if(!col){
      return
    }
    this._resizingXData = {
      col,
      min: col.left + this.minColumnWidth,
      initial: col.width
    }
    this.__originalState = {
      width: this.width,
      columns: JSON.parse(JSON.stringify(this.columns))
    }

  }

  _setRowHeight(row,value){
    let oldHeight = row.height;
    if(oldHeight !== value) {
      row.height = value
      this._updateRows();
      this._updateTableHeight();
      this._updateCellsGeometry();
      this.fire("row")
      return true
    }
    return false
  }
  _setColumnWidth(column,value){
    let oldWidth = column.width;
    if(oldWidth !== value){
      column.width =  value
      this._updateColumns();
      this._updateTableWidth();
      this._updateCellsGeometry();
      this.fire("column")
      return true
    }
    return false
  }
  // Resizes a row during the resizing process
  rowResizing(eventData, transform, x, y, options = {}) {
    if(!this.canvas || !this._resizingYData){
      return false
    }
    let row = this._resizingYData.row
    // let zoom = this.canvas.getZoom()

    let mouseLocalPosition = this.toLocalPoint(x,y)
    mouseLocalPosition.y +=this.height/2;//* this.scaleY /2
    // let mouseLocalPosition = getLocalPoint(transform, transform.originX, transform.originY, x, y);
    // mouseLocalPosition.y += this.scaleY * this.height * zoom
    let newHeight = Math.max(mouseLocalPosition.y  , this._resizingYData.min) - row.top
    this._setRowHeight(row,newHeight)
  }

  // Resizes a column during the resizing process
  columnResizing(eventData, transform, x, y, options = {}) {
    if(!this.canvas || !this._resizingXData){
      return false
    }
    let column = this._resizingXData.col
    // let zoom = this.canvas.getZoom()

    let mouseLocalPosition = this.toLocalPoint(x,y)
    mouseLocalPosition.x +=this.width/2;//* this.scaleX /2
    // let mouseLocalPosition = getLocalPoint(transform, transform.originX, transform.originY, x, y);
    // mouseLocalPosition.x += this.scaleX * this.width * zoom
    let newWidth =  Math.max(mouseLocalPosition.x , this._resizingXData.min) - column.left

    this._setColumnWidth(column,newWidth)
  }

  // Ends the resizing process for a column
  columnResizingFinish() {
    if(!this.canvas || !this._resizingXData){
      return
    }
    let column = this._resizingXData.col
    if(this._resizingXData.initial !== column.width){
      this.fire("modified");
      this.canvas?.fire("object:modified", { target: this ,column});
    }
    delete this._resizingXData
  }

  // Ends the resizing process for a row
  rowResizingFinish() {
    if(!this.canvas || !this._resizingYData){
      return
    }
    let row = this._resizingYData.row
    if(this._resizingYData.initial !== row.height) {
      this.fire("modified");
      this.canvas?.fire("object:modified", {target: this, row});
    }
    delete this._resizingYData
  }

  //// Updates the horizontal and vertical dividers based on the current table state
  _updateLines() {
    if(!this.canvas){
      return
    }
    if(!this._cells){
      return
    }
    // let zoom = this.canvas.getZoom()
    //horizontal dividers
    // let top = -this.height / 2;
    for (let rowindex = 0; rowindex < this._rows.length; rowindex++) {
      let row = this._rows[rowindex];
      // top += row.height;

      if(!this.controls["row" + (rowindex + 1)]){
        this.controls["row" + (rowindex + 1)] = new fabric.Control({
          render: _renderInvisible,
          // render: _renderRowControl,
          x: -1,
          row,
          sizeY: this.resizerSize ,
          cursorStyle: 'ns-resize',
          actionHandler: this.rowResizing.bind(this),
          mouseDownHandler: () => {this.rowResizingBegin(); return true },
          mouseUpHandler: () => {this.rowResizingFinish(); return true },
          actionName: 'row'
        })
        this.controls["rowHeader" + (rowindex + 1)] = new fabric.Control({
          x: -1,
          row,
          render: _renderRowControl,
          mouseDownHandler: () => {
            this.selectRow(rowindex); return true
          },
          sizeX: this.headerSize ,
          sizeY: this.headerSize ,
          cursorStyle: 'pointer',
          actionName: 'rowHeader'
        })
      }

    }

    //vertical dividers
    // let left = -this.width / 2;
    for (let columnindex = 0; columnindex < this._cols.length; columnindex++) {
      let column = this._cols[columnindex];
      // left += column.width;

      if(!this.controls["col" + (columnindex+ 1)]) {
        this.controls["col" + (columnindex+ 1)] = new fabric.Control({
          render: _renderInvisible,
          // render: _renderColumnControl,
          y: -1,
          column,
          sizeX: this.resizerSize,
          cursorStyle: 'ew-resize',
          actionHandler: this.columnResizing.bind(this),
          mouseDownHandler: () => {this.columnResizingBegin(); return true },
          mouseUpHandler: () => {this.columnResizingFinish(); return true },
          actionName: 'column'
        })

        this.controls["colHeader" + (columnindex + 1)] = new fabric.Control({
          y: -1,
          column,
          render: _renderColumnControl,
          mouseDownHandler: () => {
            this.selectColumn(columnindex); return true
          },
          sizeX: this.headerSize ,
          sizeY: this.headerSize ,
          cursorStyle: 'pointer',
          actionName: 'rowHeader'
        })
      }
    }


    this.setHasTableControls(this.hasTableControls)
    this.dirty = true;
  }
  getFill(){

    let fill
    if(this.selection?.length){
      fill = this.selection[0].fill  || this.fill
      for(let cell of this.selection){
        let cellFill = cell.fill || this.fill
        if(cellFill !== fill){
          return ""
        }
      }
    }
    else{
      fill = this._cells[0][0].fill  || this.fill
      for(let cellRow of this._cells){
        for(let cell of cellRow) {
          let cellFill = cell.fill || this.fill
          if(cellFill !== fill){
            return ""
          }
        }
      }
    }
    return fill
  }
  getStroke(){
    let stroke
    if(this.selection?.length){
      stroke = this.selection[0].stroke || this.stroke
      for(let cell of this.selection){
        let cellStroke = cell.stroke || this.stroke
        if (cellStroke !== stroke) {
          return ""
        }
      }
    }
    else {
      stroke = this._cells[0][0].stroke || this.stroke
      for (let cellRow of this._cells) {
        for (let cell of cellRow) {
          let cellStroke = cell.stroke || this.stroke
          if (cellStroke !== stroke) {
            return ""
          }
        }
      }
    }
    return stroke
  }
  setFill(value){
    this.__originalState = { cells: JSON.parse(JSON.stringify(this.cells)) }

    if(value === undefined){
      return
    }
    if(this.selection?.length){
      for(let cell of this.selection){
        this._setCellFill(cell,value)
      }
    }
    else{
      this.fill = value
      for(let cellRow of this._cells){
        for(let cell of cellRow) {
          this._setCellFill(cell, null)
        }
      }
    }
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.requestRenderAll()
  }
  setStroke (value){
    this.__originalState = { cells: JSON.parse(JSON.stringify(this.cells)) }
    if(value === undefined){
      return
    }
    if(this.selection?.length){
      for(let cell of this.selection){
        this._setCellStroke(cell,value)
      }
    }
    else{
      this.stroke = value
      for(let cellRow of this._cells){
        for(let cell of cellRow) {
          this._setCellStroke(cell, null)
        }
      }
    }
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.requestRenderAll()
  }
  _setCellFill(cell,value){
    if(value === undefined){
      return
    }
    if(value === null){
      delete cell.fill
      cell.o.fill = this.fill
    }
    else{
      cell.fill = value
      cell.o.fill = value
    }
  }
  _setCellStroke(cell,value){
    if(value === undefined){
      return
    }
    if(value === null){
      delete cell.stroke
      cell.t.fill = this.stroke
    }
    else{
      cell.stroke = value
      cell.t.fill = value
    }
  }


  // Updates the geometry of cells based on rows and columns
  _updateCellSize(cell) {
    let width = 0,
        height = 0,
        colspan = cell.colspan || 1,
        rowspan = cell.rowspan || 1;

    for (let x = 0; x < colspan; x++) {
      if(this._cols[cell.c.index + x]){
        width += this._cols[cell.c.index + x].width;
      }
    }
    for (let y = 0; y < rowspan; y++) {
      if(this._rows[cell.r.index + y]) {
        height += this._rows[cell.r.index + y].height;
      }
    }

    let strokeWidth = cell.strokeWidth === undefined ? 1 : cell.strokeWidth
    width -= strokeWidth - 1
    height -= strokeWidth - 1
    cell.width = width;
    cell.height = height;
    cell.o.set({
      width,
      height
    });
    cell.o.setCoords();
    if (cell.t) {
      cell.t.set( {
        width: width - this.strokeWidth - 2,
        height: height - this.strokeWidth - 2
      })
      cell.t.setCoords();
    }
  }
  _updateCellsTextPosition(cell){
    if (cell?.t) {
      let strokeWidth = cell.strokeWidth === undefined ? 1 : cell.strokeWidth
      let topBorderWidth = this.borders?.v?.[cell.c.index]?.[cell.r.index]?.width/2 ||0
      let leftBorderWidth = this.borders?.h?.[cell.r.index]?.[cell.c.index]?.width/2 || 0
      cell.t.set( {
        left: cell.o.left + this.cellPadding + Math.max(strokeWidth, topBorderWidth),
        top: cell.o.top + this.cellPadding + Math.max(strokeWidth, leftBorderWidth),
      });
    }
  }
  _updateCellsGeometry() {
    if(!this._cells){
      return
    }
    let top = 0, left, rowindex, columnindex;

    for (rowindex = 0; rowindex < this._rows.length; rowindex++) {
      left = 0;
      for (columnindex = 0; columnindex < this._cols.length; columnindex++) {
        let cell = this._cells[rowindex]?.[columnindex];
        if(!cell){
          continue;
        }

        if (cell.c.index === columnindex && cell.r.index === rowindex) {

          cell.o.set({
            left: left - this.width / 2 - 0.5,
            top: top - this.height / 2 - 0.5
          });


          this._updateCellsTextPosition(cell)
          this._updateCellSize(cell)
        }
        left += this._cols[columnindex].width;
      }
      top += this._rows[rowindex].height;
    }
    this._updateLines();
    this._updateBorders()
    this.cells = this.getCells();
    this.dirty = true;
    this.canvas?.renderAll();
  }

  // _updateBorders(){
  //   this._borders = []
  //   let top = - this.height / 2, left, rowindex, columnindex;
  //   for (rowindex = 0; rowindex <= this._rows.length; rowindex++) {
  //     let notLastRow = rowindex !== this._rows.length
  //     left = - this.width / 2 ;
  //
  //     if(!this._borders[rowindex]){
  //       this._borders[rowindex] = []
  //     }
  //     for ( columnindex = 0; columnindex <= this._cols.length; columnindex++) {
  //       let notLastColumn = columnindex !== this._cols.length
  //
  //       let border = this._borders[rowindex][columnindex] = {}
  //       if(notLastColumn) {
  //         let hLine = border.h = new fabric.Line([left, top, left + this._cols[columnindex].width, top], {
  //           evented: false,
  //           stroke: ["red","blue","green","black","purple"][Math.floor(Math.random()*5)],// todo
  //           strokeWidth: 1 + (columnindex + rowindex) /2//todo
  //         })
  //         this._objects.push(hLine)
  //         hLine.group = this;
  //         hLine.canvas = this.canvas;
  //       }
  //
  //       if(notLastRow) {
  //         let vLine = border.v = new fabric.Line([left, top, left , top + this._rows[rowindex].height], {
  //           evented: false,
  //           stroke: ["red","blue","green","black","purple"][Math.floor(Math.random()*5)],// todo
  //           strokeWidth: 1 + (columnindex + rowindex)/2 //todo
  //         })
  //         this._objects.push(vLine)
  //         vLine.group = this;
  //         vLine.canvas = this.canvas;
  //       }
  //
  //       if(notLastColumn){
  //         left += this._cols[columnindex].width;
  //       }
  //     }
  //     if(notLastRow) {
  //       top += this._rows[rowindex].height;
  //     }
  //   }
  // }
  // setBorders(value){
  //   this.borders = value
  //
  //   this._deleteBorders()
  //   this._updateBorders()
  // }
  setBorders(value){
    this.borders = {
      v: [],
      h: []
    }

    let rowindex, columnindex;
    for ( columnindex = 0; columnindex <= this._cols.length; columnindex++) {
      if(!this.borders.v[columnindex]){
        this.borders.v[columnindex] = []
      }
      for (rowindex = 0; rowindex < this._rows.length; rowindex++) {
        let cellLeft = this._cells[rowindex]?.[columnindex - 1]
        let cellRight = this._cells[rowindex]?.[columnindex]
        let borderExists = cellLeft !== cellRight
        let border = value.v[columnindex]?.[rowindex]
        let vLine = this._vBorders?.[columnindex]?.[rowindex]
        if (borderExists) {
          if (border) {
            let vBorder = {}
            if (border.color !== undefined) {
              vBorder.color = border.color
            }
            if (border.width !== undefined) {
              vBorder.width = border.width
            }
            this.borders.v[columnindex].push(vBorder)

            if (vLine) {
              vLine.set({
                stroke: border.color !== undefined ? border.color : this.stroke,
                strokeWidth: border.width !== undefined ? border.width : this.strokeWidth
              })
            }
          } else {
            this.borders.v[columnindex].push({})
            if (vLine) {
              vLine.set({
                stroke: this.stroke,
                strokeWidth: this.strokeWidth
              })
            }
          }
        }
        else{
          this.borders.v[columnindex].push(null)
          this._removeBorder(vLine)
        }
      }
    }

    for (rowindex = 0; rowindex <= this._rows.length; rowindex++) {
      if (!this.borders.h[rowindex]) {
        this.borders.h[rowindex] = []
      }
      for (columnindex = 0; columnindex < this._cols.length; columnindex++) {
        let cellTop = this._cells[rowindex - 1]?.[columnindex]
        let cellBottom = this._cells[rowindex]?.[columnindex]
        let borderExists = cellTop !== cellBottom
        let border = value.h[rowindex]?.[columnindex]
        let hLine = this._hBorders?.[rowindex]?.[columnindex]
        if(borderExists) {
          if (border) {
            // Border Column Spans
            // let bordercolspan = 1
            // let cellTop2 = this._cells[rowindex - 1]?.[columnindex + bordercolspan]
            // let cellBottom2 = this._cells[rowindex]?.[columnindex + bordercolspan]
            // while(cellTop2 === cellTop && cellBottom2 === cellBottom){
            //   columnindex++
            //   bordercolspan++
            //   cellTop2 = this._cells[rowindex - 1]?.[columnindex + bordercolspan]
            //   cellBottom2 = this._cells[rowindex]?.[columnindex + bordercolspan]
            // }

            let hBorder = {}
            if (border.color !== undefined) {
              hBorder.color = border.color
            }
            if (border.width !== undefined) {
              hBorder.width = border.width
            }
            this.borders.h[rowindex].push(hBorder)

            if (hLine) {
              hLine.set({
                stroke: border.color !== undefined ? border.color : this.stroke,
                strokeWidth: border.width !== undefined ? border.width : this.strokeWidth
              })
            }
          } else {
            this.borders.h[rowindex].push({ })
            if (hLine) {
              hLine.set({
                stroke: this.stroke,
                strokeWidth: this.strokeWidth
              })
            }
          }
        }
        else{

          this.borders.h[rowindex].push(null)
          this._removeBorder(hLine)
        }
      }
    }
    this._updateBorders()
    //todo ugly solution
    if(this.cells){
      this.setCells(this.cells)
    }

  }
  dummyBorders(){
    let hBorders = []
    let vBorders  = []
    let rowindex, columnindex;
    for (rowindex = 0; rowindex <= this._rows.length; rowindex++) {
      if(!hBorders[rowindex]){
        hBorders[rowindex] = []
      }
      for ( columnindex = 0; columnindex < this._cols.length; columnindex++) {

        let cellTop = this._cells[rowindex - 1]?.[columnindex]
        let cellBottom = this._cells[rowindex]?.[columnindex]


        let borderExists =  cellTop !== cellBottom
        if(borderExists){
          // Border Column Spans
          // let bordercolspan = 1
          // let cellTop2 = this._cells[rowindex - 1]?.[columnindex + bordercolspan]
          // let cellBottom2 = this._cells[rowindex]?.[columnindex + bordercolspan]
          // while(cellTop2 === cellTop && cellBottom2 === cellBottom){
          //   columnindex++
          //   bordercolspan++
          //   cellTop2 = this._cells[rowindex - 1]?.[columnindex + bordercolspan]
          //   cellBottom2 = this._cells[rowindex]?.[columnindex + bordercolspan]
          // }

          hBorders[rowindex].push( {
            //colspan: bordercolspan,
            color : ["red","blue","green","black","purple"][Math.floor(Math.random()*5)],
            width: Math.ceil(Math.random() * 10)
          })
        }
        else{
          hBorders[columnindex].push({width: 0})
        }
      }
    }

    for ( columnindex = 0; columnindex <= this._cols.length; columnindex++) {
      if(!vBorders[columnindex]){
        vBorders[columnindex] = []
      }
      for (rowindex = 0; rowindex < this._rows.length; rowindex++) {
        let cellLeft = this._cells[rowindex]?.[columnindex - 1]
        let cellRight = this._cells[rowindex]?.[columnindex]
        let borderExists = cellLeft !== cellRight

        if(borderExists) {
          vBorders[columnindex].push({
            color: ["red", "blue", "green", "black", "purple"][Math.floor(Math.random() * 5)],
            width: Math.ceil(Math.random() * 10)
          })
        }
        else{
          vBorders[columnindex].push({width: 0})
        }
      }
    }
    return {
      h: hBorders,
      v: vBorders
    }
  }

  _updateBorders(){

    if(!this._hBorders){
      this._hBorders = []
    }
    if(!this._vBorders) {
      this._vBorders = []
    }
    let top , left, rowindex, columnindex;

    top = - this.height / 2
    for (rowindex = 0; rowindex <= this._rows.length; rowindex++) {
      if(!this._hBorders[rowindex]){
        this._hBorders[rowindex] = []
      }
      left = - this.width / 2 ;
      for ( columnindex = 0; columnindex < this._cols.length; columnindex++) {
        let border = this.borders.h[rowindex]?.[columnindex]
        if(border){

          let leftTopBorder = this.borders.v[columnindex]?.[rowindex - 1]
          let leftBottomBorder = this.borders.v[columnindex]?.[rowindex]
          let rightTopBorder = this.borders.v[columnindex + 1]?.[rowindex - 1]
          let rightBottomBorder = this.borders.v[columnindex + 1]?.[rowindex]
          let leftBorder = this.borders.h[rowindex]?.[columnindex - 1]
          let rightBorder = this.borders.h[rowindex]?.[columnindex + 1]

          let leftTopBorderWidth = leftTopBorder ? leftTopBorder.width || this.strokeWidth : 0
          let leftBottomBorderidth  = leftBottomBorder ? leftBottomBorder.width || this.strokeWidth : 0
          let rightTopBorderWidth  = rightTopBorder ? rightTopBorder.width || this.strokeWidth : 0
          let rightBottomBorderWidth  = rightBottomBorder ? rightBottomBorder.width || this.strokeWidth : 0
          let rightBorderWidth  = rightBorder ? rightBorder.width || this.strokeWidth : 0
          let leftBorderWidth  = leftBorder ? leftBorder.width || this.strokeWidth : 0
          let borderWidth  = border.width || this.strokeWidth

          let hasPriorityOnLeft = (leftTopBorderWidth < borderWidth && leftBottomBorderidth <= borderWidth && leftBorderWidth < borderWidth)
          let hasPriorityOnRight = (rightTopBorderWidth < borderWidth && rightBottomBorderWidth <= borderWidth && rightBorderWidth <= borderWidth )

          let maxLeftBordersWidth = Math.max(leftTopBorderWidth, leftBottomBorderidth)
          let maxRightBordersWidth = Math.max(rightTopBorderWidth, rightBottomBorderWidth)

          let offsetLeft =  hasPriorityOnLeft ? maxLeftBordersWidth/2 : -maxLeftBordersWidth/2
          let offsetRight =  hasPriorityOnRight ? maxRightBordersWidth/2 : -maxRightBordersWidth/2

          let hLine = this._hBorders[rowindex][columnindex]
          if(!hLine){
            hLine = new fabric.Line([left - offsetLeft, top, left  + this._cols[columnindex].width + offsetRight, top], {
              evented: false,
              stroke: border.color || this.stroke,
              strokeWidth: borderWidth,
            })
            this._objectsBorders.push(hLine)
            hLine.group = this;
            hLine.canvas = this.canvas;
            this._hBorders[rowindex][columnindex] = hLine
          }
          else{
            hLine.set({
              left: left - offsetLeft - borderWidth/2,
              top: top  - borderWidth/2,
              width:  this._cols[columnindex].width + offsetRight + offsetLeft
            })
          }
        }

        left += this._cols[columnindex].width;
      }
      top += this._rows[rowindex]?.height;
    }

    left = - this.width / 2 ;
    for ( columnindex = 0; columnindex <= this._cols.length; columnindex++) {
      if(!this._vBorders[columnindex]){
        this._vBorders[columnindex] = []
      }
      top = - this.height / 2
      for (rowindex = 0; rowindex < this._rows.length; rowindex++) {
        let border = this.borders.v[columnindex]?.[rowindex]

        if(border){
          let topLeftBorder = this.borders.h[rowindex]?.[columnindex - 1]|| {width: 0}
          let topRightBorder = this.borders.h[rowindex]?.[columnindex]|| {width: 0}
          let bottomLeftBorder = this.borders.h[rowindex + 1]?.[columnindex - 1]|| {width: 0}
          let bottomRightBorder = this.borders.h[rowindex + 1]?.[columnindex]|| {width: 0}
          let topBorder = this.borders.v[columnindex]?.[rowindex - 1]
          let bottomBorder = this.borders.v[columnindex]?.[rowindex + 1]

          let topLeftBorderWidth = topLeftBorder ? topLeftBorder.width || this.strokeWidth : 0
          let topRightBorderWidth  = topRightBorder ? topRightBorder.width || this.strokeWidth : 0
          let bottomLeftBorderWidth  = bottomLeftBorder ? bottomLeftBorder.width || this.strokeWidth : 0
          let bottomRightBorderWidth  = bottomRightBorder ? bottomRightBorder.width || this.strokeWidth : 0
          let topBorderWidth  = topBorder ? topBorder.width || this.strokeWidth : 0
          let bottomBorderWidth  = bottomBorder ? bottomBorder.width || this.strokeWidth : 0
          let borderWidth  = border.width || this.strokeWidth


          let maxTopBordersWidth = Math.max(topLeftBorderWidth, topRightBorderWidth)
          let maxBottomBordersWidth = Math.max(bottomLeftBorderWidth, bottomRightBorderWidth)

          let hasPriorityOnTop = (topLeftBorderWidth < borderWidth && topRightBorderWidth < borderWidth && topBorderWidth < borderWidth)
          let hasPriorityOnBottom = (bottomLeftBorderWidth <= borderWidth && bottomRightBorderWidth <= borderWidth && bottomBorderWidth <= borderWidth )

          let offsetTop =  hasPriorityOnTop ? maxTopBordersWidth/2 : -maxTopBordersWidth/2
          let offsetBottom =  hasPriorityOnBottom ? maxBottomBordersWidth/2 : -maxBottomBordersWidth/2

          let vLine = this._vBorders[columnindex][rowindex]
          if(!vLine) {
            vLine = new fabric.Line([left, top - offsetTop, left, top + this._rows[rowindex].height + offsetBottom], {
              evented: false,
              stroke: border.color || this.stroke,
              strokeWidth: borderWidth,
            })
            this._objectsBorders.push(vLine)
            vLine.group = this;
            vLine.canvas = this.canvas;
            this._vBorders[columnindex][rowindex] = vLine
          }
          else{
            vLine.set({
              left: left - borderWidth/2,
              top: top - offsetTop - borderWidth/2,
              height: this._rows[rowindex].height + offsetBottom + offsetTop
            })
          }
        }
        top += this._rows[rowindex].height;
      }
      left += this._cols[columnindex]?.width;
    }
  }

  // Updates the width of the table based on columns
  _updateTableWidth() {
    if(this._cols && this._cells){
      this.set("width",this._cols.reduce((p, c) => p + c.width, 0))
    }
  }

  // Updates the height of the table based on rows
  _updateTableHeight() {
    if(this._rows && this._cells){
      this.set("height",this._rows.reduce((p, c) => p + c.height, 0))
    }
  }

// Updates the left positions of columns
  _updateColumns() {
    let l = 0;
    for (let x = 0; x < this._cols.length; x++) {
      this._cols[x].left = l;
      l += this._cols[x].width;
      let control = this.controls["colHeader" + (x + 1)]
      if(control){
        control.column = this._cols[x]
      }
    }
    this.columns = this.getColumns();
  }

  // Updates the top positions of rows
  _updateRows() {
    let t = 0;
    for (let y = 0; y < this._rows.length; y++) {
      this._rows[y].top = t;
      t += this._rows[y].height;
      let control = this.controls["rowHeader" + (y + 1)]
      if(control) {
        control.row = this._rows[y]
      }
    }
    this.rows = this.getRows();
  }

  _removeBorder(vBorder){

    this._objectsBorders.splice(this._objectsBorders.indexOf(vBorder), 1);
  }
  // Deletes a column at the specified position
  _deleteColumn(position = this._cols.length - 1) {
    this._storeSize("height","top","left")
    let column = this._cols[position];
    delete this.controls["col" + (this._cols.length)]
    delete this.controls["colHeader" + (this._cols.length)]

    let processed = [];
    for (let y = 0; y < this._rows.length; y++) {
      let mid = this._cells[y][position];
      if (!mid) {
        continue;
      }
      let left = position > 0 && this._cells[y][position - 1];
      let right = position < this._cols.length - 1 && this._cells[y][position + 1];
      if (processed.includes(mid)) {
        continue;
      }
      processed.push(mid);
      if (left === mid) {
        left.colspan--;
      } else if (right === mid) {
        right.colspan--;
        right.c = this._cols[right.c.index + 1];
      } else {
        this._deleteCell(mid);
      }
    }
    for (let x = position + 1; x < this._cols.length; x++) {
      this._cols[x].index--;
    }



    this.borders.v.splice(position, 1)
    for(let vBorder of this._vBorders[position]){
      this._removeBorder(vBorder)
    }
    this._vBorders.splice(position, 1)

    for (let y = 0; y <= this._rows.length; y++) {
      this.borders.h[y].splice(position, 1)
      this._removeBorder(this._hBorders[y][position])
      this._hBorders[y].splice(position, 1)
    }


    this.width -= column.width;
    this._cols.splice(position, 1);
    for (let y = 0; y < this._rows.length; y++) {
      this._cells[y].splice(position, 1);
    }
    this._restoreSize()
  }

  // Deletes a row at the specified position
  _deleteRow(position = this._rows.length - 1) {


    this._storeSize("width","top","left")
    let row = this._rows[position];
    delete this.controls["row" + (this._rows.length)]
    delete this.controls["rowHeader" + (this._rows.length)]
    let processed = [];
    for (let x = 0; x < this._cols.length; x++) {
      let mid = this._cells[position][x];
      if (!mid) {
        continue;
      }
      let top = position > 0 && this._cells[position - 1][x];
      let bottom = position < this._rows.length - 1 && this._cells[position + 1][x];
      if (processed.includes(mid)) {
        continue;
      }
      processed.push(mid);
      if (top === mid) {
        top.rowspan--;
      } else if (bottom === mid) {
        bottom.rowspan--;
        bottom.r = this._rows[bottom.r.index + 1];
      } else {
        this._deleteCell(mid)
      }
    }
    for (let y = position + 1; y < this._rows.length; y++) {
      this._rows[y].index--;
    }


    this.borders.h.splice(position, 1)
    for(let hBorder of this._hBorders[position]){
      this._removeBorder(hBorder)
    }
    this._hBorders.splice(position, 1)
    for (let x = 0; x <= this._cols.length; x++) {
      this.borders.v[x].splice(position, 1)
      this._removeBorder(this._vBorders[x][position])
      this._vBorders[x].splice(position, 1)
    }



    this.height -= row.height;
    this._rows.splice(position, 1);
    this._cells.splice(position, 1);
    this._restoreSize()
  }

  // Deletes a specific cell from the table
  _deleteCell(cell) {
    for (let y = 0; y < this._rows.length; y++) {
      for (let x = 0; x < this._cols.length; x++) {
        if (this._cells[y][x] === cell) {
          this._cells[y][x] = null;
        }
      }
    }
    if (this.selection.includes(cell)) {
      this.selection.splice(this.selection.indexOf(cell), 1);
    }
    this.remove(cell.o);
    if (cell.t) {
      this.remove(cell.t);
    }
  }

  _deleteBorders(){

    this._hBorders = []
    this._vBorders = []
    this._objectsBorders.length = 0
  }
  // Deletes all cells in the table
  _deleteCells() {
    if (this._cells) {
      for (let y = 0; y < this._cells.length; y++) {
        for (let x = 0; x < this._cells[y].length; x++) {
          let cell = this._cells[y][x];
          if (cell) {
            this.remove(cell.o);
            if (cell.t) {
              this.remove(cell.t);
            }
            this._cells[y][x] = null;
          }
        }
      }
    }
    this.selection.length = 0;
    this._deleteBorders()
  }
  removeSelection(){
    this.__originalState = {
      cells: JSON.parse(JSON.stringify(this.cells))
    }

    let modified = false
    for(let cell of this.selection){
      if(cell.text){
        cell.t.setText("")
        cell.text = ""
        modified = true
      }
    }
    if(modified){
      this.recalculateFormulas()
      this.cells = this.getCells()
      this.fire("modified",{});
      this.canvas?.fire("object:modified", { target: this });
    }

  }

  _toSVG(reviver) {
    const svgString = ['<g ', 'COMMON_PARTS', ' >\n'];
    this.backgroundColor = this.fill

    if(this.fill && this.fill !== "transparent"){
      svgString.push('\t\t', `<rect fill="${this.fill}" x="${-this.width/2}" y="${-this.height/2}" width="${this.width}" height="${this.height}" />\n`);
    }
    for (let i = 0; i < this._objects.length; i++) {
      svgString.push('\t\t', this._objects[i].toSVG(reviver));
    }
    for (let i = 0; i < this._objectsBorders.length; i++) {
      svgString.push('\t\t', this._objectsBorders[i].toSVG(reviver));
    }
    svgString.push('</g>\n');
    delete this.backgroundColor
    return svgString;
  }

  static fromObject(options) {
    return this._fromObject( options);
  }
}

FabricTable._fromObject = fabric.Object._fromObject
FabricTable.prototype.resizable = true
FabricTable.prototype.snapPoints = fabric.Rect.prototype.snapPoints
FabricTable.prototype.propertyPriorities = ["*","cells","borders"]
FabricTable.prototype.stateProperties = [...fabric.Group.prototype.stateProperties || [], ...tableOwnProperties ];
FabricTable.prototype.cacheProperties = [...fabric.Group.prototype.cacheProperties || [], ...tableOwnProperties];
FabricTable.prototype.storeProperties = [...fabric.Group.prototype.storeProperties || [], ...tableOwnProperties];

fabric.Table = FabricTable
makeFromObject(FabricTable)
fabric.classRegistry.setClass(FabricTable,"table");
fabric.classRegistry.setClass(FabricTable,"Table");

export {FabricTable}