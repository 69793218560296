import React, {useState, useEffect, useRef} from 'react'
import { AutoComplete, Modal, Select } from 'antd'
import { openNotification } from '../../shared/notifications'
import Draggable from 'react-draggable';
import { DATAMODEL } from '../../../database/database'
import {DATAMODELOLD} from '../../../--database'

export const ExportItemModal = (props) => {
  const {
    setFilteredItems,
    setItems,
    categories,
    canvas,
    exportModal,
    setExportModal,
    subcategories,
    subSubcategories,
    exportObjects,
    editedItem,
    setEditedItem,
    setLoadingApi,
    lastExportedItem,
    searchShapeWithName,
    searchShapeWithId,
    confirmLoading
  } = props
  const [label, setLabel] = React.useState('')
  const [labelError, setLabelError] = React.useState(false)
  const [category, setCategory] = React.useState(null)
  const [categoryError, setCategoryError] = React.useState(false)
  const [subcategory, setSubcategory] = React.useState(null)
  const [subSubcategory, setSubSubcategory] = React.useState(null)
  const [filteredSubcategories, setFilteredSubcategories] = React.useState([])
  const [filteredSubSubcategories, setFilteredSubSubcategories] = React.useState([])
  const [size, setSize] = React.useState(null)
  const [searchOptions, setSearchOptions] = React.useState(null)
  const [sizeError, setSizeError] = React.useState(false)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0, });
  const draggleRef = useRef(null);

  const initializeModal = async (lastItem) => {
    if(!editedItem) {
      const searchItems = searchShapeWithId(lastItem);
      lastItem = searchItems.length ? searchItems[0] : {}
    }
    const initialItem = editedItem || lastItem;
    setLabel(initialItem.label)
    let categoryName = categories.find(cat => cat.id === initialItem.category)
    let subcategoryName = subcategories.find(sc => sc.id === initialItem.subcategory)
    let subSubcategoryName = subSubcategories.find(ssc => ssc.id === initialItem.subSubcategory)
    categoryName  && changeCategory(categoryName)
    subcategoryName && changeSubcategory(subcategoryName)
    subSubcategoryName && changeSubSubcategory(subSubcategoryName)
  }

  useEffect(()=>{
    initializeModal(lastExportedItem).then(()=> {})
  }, [lastExportedItem])

  const resetForm = () => {
    setLabel('')
    setLabelError(false)
    setCategory(null)
    setCategoryError(false)
    setSubcategory(null)
    setSubSubcategory(null)
    setSize(null)
    setSizeError(false)
  }

  const searchShape = async (text) => {
    if(!text) {
      resetForm();
      return;
    }
    const shapes = searchShapeWithName(text);
    setSearchOptions(shapes.map((s) => ({
      value: s.id,
      label: s.label,
    })))
  }

  const validateItem = async () => {
    if (!label) {
      setLabelError(true)
    } else if (category === '' || !category) {
      setCategoryError(true)
    } else if ((size === '' || !size) && !editedItem) {
      setSizeError(true)
    } else {
      let scValue = ''
      let sscValue = ''
      setLoadingApi(true)
      if(subcategory){
        scValue = subcategory.key
      }
      if(subSubcategory){
        sscValue = subSubcategory.key
      }
      if(editedItem){
        setExportModal(false)

        const newItem = {label, category: category.key, subcategory: scValue, subSubcategory: sscValue}
        await DATAMODELOLD.editShape(editedItem.id, newItem)
        setFilteredItems(prevState => (prevState.map(item =>
            item.id === editedItem.id ? { ...item, ...editedItem, ...newItem } : item
        )));
        setItems(prevState => (prevState.map(item =>
          item.id === editedItem.id ? { ...item, ...editedItem, ...newItem } : item
        )));

        setEditedItem(null)
        openNotification(`Successfully edited item`, 'success')
        setLoadingApi(false)
      }else{
        exportObjects(canvas, label, category.key, scValue, sscValue, size)
      }
    }
  }

  const onSearchSelect = (i) => {
    initializeModal(i);
  }

  const changeLabel = (val) => {
    setLabel(val)
    if (val === '') {
      setLabelError(true)
    } else {
      setLabelError(false)
    }
  }

  const changeCategory = (value) => {
    setCategory(value)
    setSubcategory(null)
    let newFilteredSubcategories = subcategories.filter(
      (sc) => sc.parentId=== value.key
    )
    setFilteredSubcategories(newFilteredSubcategories)

    if (value === '') {
      setCategoryError(true)
    } else {
      setCategoryError(false)
    }
  }

  const changeSubcategory = (value) => {
    setSubcategory(value)
    let newFilteredSubSubcategories = subSubcategories?.filter((sc) => {
      return (
        sc.parentSubcategoryId === value?.key
      )
    })
    setFilteredSubSubcategories(newFilteredSubSubcategories)

    setSubSubcategory('')

  }

  const changeSubSubcategory = (value) => {
    setSubSubcategory(value)
  }

  const changeSize = (value) => {
    setSize(value)

    if (value === '') {
      setSizeError(true)
    } else {
      setSizeError(false)
    }
  }

  const handleCloseBtn = () => {
    setEditedItem(null)
    setExportModal(false)
  }

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Modal confirmLoading={confirmLoading} open={exportModal} onOk={validateItem} onCancel={handleCloseBtn}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
        >
          {editedItem ? 'Edit Item' : 'Add Item'}
        </div>
      }
      modalRender={(modal) => (
        <Draggable
          bounds={bounds}
          nodeRef={draggleRef}
          onStart={(event, uiData) => onStart(event, uiData)}
          handle=".ant-modal-header"
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <div className='modalInputContainer'>
        <AutoComplete
          status={labelError ? 'error' : null}
          options={searchOptions}
          style={{ width: '100%' }}
          onSelect={onSearchSelect}
          onChange={changeLabel}
          value={label}
          onSearch={searchShape}
          placeholder='Add a Label'
        />
        {labelError && <span className='modalInputError'>Label cannot be empty</span>}
      </div>

      <div className='modalInputContainer'>
        <Select
          placeholder='Select Category'
          value={category}
          style={{ width: '100%' }}
          onChange={changeCategory}
          optionLabelProp = 'name'
          labelInValue = 'key'
          options={categories.map(cat => ({...cat, title: ""}))}
        />
        {categoryError && <span className='modalInputError'>Category cannot be empty</span>}
      </div>

      <div className='modalInputContainer'>
        <Select
          placeholder='Select Subcategory'
          value={subcategory}
          style={{ width: '100%' }}
          onChange={changeSubcategory}
          options={filteredSubcategories.map(sc => ({...sc, title: ""}))}
          optionLabelProp = 'name'
          labelInValue = 'key'
        />
      </div>

      <div className='modalInputContainer'>
        <Select
          placeholder='Select Sub Subcategory'
          style={{ width: '100%' }}
          onChange={changeSubSubcategory}
          value={subSubcategory === '' ? null : subSubcategory}
          options={filteredSubSubcategories.map(ssc => ({...ssc, title: ""}))}
          optionLabelProp = 'name'
          labelInValue = 'key'
        />
      </div>
      {
        !editedItem && 
        <div className='modalInputContainer'>
          <Select
            placeholder='Select Size'
            style={{ width: '100%' }}
            onChange={changeSize}
            value={size}
            >
            <Select.Option value="small">Small</Select.Option>
            <Select.Option value="medium">Medium</Select.Option>
            <Select.Option value="large">Large</Select.Option>
            <Select.Option value="xl">XL</Select.Option>
            <Select.Option value="xs">XS</Select.Option>
          </Select>
          {sizeError && <span className='modalInputError'>Size cannot be empty</span>}
        </div>
      }
      
    </Modal>
  )
}
