// import * as fabricOriginal from "./../../node_modules/fabric/dist/index.node.mjs"
// import * as fabricOriginal from "./../../node_modules/fabric/dist/index.mjs"
import * as fabricOriginal from "../libs/fabric6.js"
const fabric = {}
for(let i in fabricOriginal){
  fabric[i] = fabricOriginal[i]
}

const fabricUtil = {}
for(let i in fabricOriginal.util){
  fabricUtil[i] = fabricOriginal.util[i]
}
fabric.util = fabricUtil


Object.assign(fabric.util,{
  normalizeAngle(value){
    if (value >= 360) {
      value = value % 360
    }
    if(value < 0 ){
      value +=360;
    }
    return value
  },
  calcDistance(a,b){
    return  Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
  },
  calcAngle(a,b){
    if(b.y === a.y &&  b.x === a.x){
      return 0
    }
    let lineAngleRadians = Math.atan2(b.y - a.y, b.x - a.x)
    let angleDegrees = lineAngleRadians * (180 / Math.PI)
    if(angleDegrees < 0 ){
      angleDegrees +=360;
    }
    return angleDegrees
  },

// Function to calculate distance from a point to a line defined by two points
  pointToLineDistance(p, lineStart, lineEnd) {
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;
    const mag = Math.sqrt(dx * dx + dy * dy);
    if (mag === 0) {
      return fabric.util.calcDistance(p, lineStart);
    }

    const u = ((p.x - lineStart.x) * dx + (p.y - lineStart.y) * dy) / (mag * mag);
    const intersectionX = lineStart.x + u * dx;
    const intersectionY = lineStart.y + u * dy;

    return fabric.util.calcDistance(p, { x: intersectionX, y: intersectionY });
  }
})

if(!fabric.util.debug){
  fabric.util.debug = {}
}

export default fabric;