import fabric from '../fabric/fabric.base.js'

const defaults = {
  Rect: {
    cornerSize: 9,
    transparentCorners: true,
    rx: 0,
    ry: 0,
    stroke: '#000000',
    strokeWidth: 1,
    strokeUniform: true,
    fill: 'transparent'
  },
  Ellipse: {
    cornerSize: 9,
    transparentCorners: true,
    rx: 30,
    ry: 30,
    width: 60,
    height: 60,
    stroke: '#000000',
    strokeUniform: true,
    strokeWidth: 1,
    fill: 'transparent'
  },
  Circle: {
    cornerSize: 9,
    transparentCorners: true,
    radius: 30,
    width: 60,
    height: 60,
    stroke: '#000000',
    strokeUniform: true,
    strokeWidth: 1,
    fill: 'transparent'
  },
  Polygon: {
    cornerSize: 9,
    transparentCorners: true,
    width: 50,
    height: 50,
    stroke: '#000000',
    strokeUniform: true,
    strokeWidth: 1,
    fill: 'transparent'
  },
  Line: {
    cornerSize: 9,
    transparentCorners: true,
    stroke: 'black',
    fill: 'black'
  },
  Textbox: {
    // resizable: true,
    cornerSize: 9,
    transparentCorners: true,
    fill: 'transparent',
    stroke: '#000000',
    strokeWidth: 1
  },
  IText: {
    // resizable: true,
    cornerSize: 9,
    transparentCorners: true,
    fill: 'black',
    stroke: null,
    strokeWidth: 1,
    fontWeight: 'normal',
    fontStyle: 'normal',
    charSpacing: 0,
    textAlign: 'left',
    path: null,
    pathStartOffset: 0,
    pathSide: 'left',
    pathAlign: 'baseline',
    underline: false,
    overline: false,
    linethrough: false,
    textBackgroundColor: '',
    direction: 'ltr'
  },
  Path: {
    cornerSize: 9,
    transparentCorners: true,
    stroke: 'black',
    strokeWidth: 1
  },
  Group: {
    lockAspectRatio: true,
    cornerSize: 9,
    transparentCorners: true,
    strokeUniform: true
  },
  ActiveSelection: {
    lockAspectRatio: true,
    cornerSize: 9,
    transparentCorners: true,
    strokeUniform: true,
    touchCornerSize: 36
  },
  Arrow: {
    cornerSize: 9,
    transparentCorners: true,
    perPixelTargetFind: false,
    strokeUniform: true
  }
}
for (let klass in defaults) {
  if (!fabric[klass].defaults) {
    fabric[klass].defaults = {}
  }
  Object.assign(fabric[klass].defaults, defaults[klass])
}