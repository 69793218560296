import React from 'react'
import { Input, Modal } from 'antd'

export const EditModal = (props) => {
  const {
    modal,
    toggleModal,
    name,
    description,
    onConfirm,
    resourceType,
    resourceName,
    confirmDrawing,
  } = props
  const [dName, setName] = React.useState(name)
  const [dDescription, setDescription] = React.useState(description)
  const [nameError, setNameError] = React.useState(false)

  const validateItem = async () => {
    if (dName === '') {
      setNameError(true)
    }  else {
      onConfirm(dName, dDescription)
    }
  }

  const changeName = (e) => {
    const value = e.target.value
    setName(value)

    if (value === '') {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  return (
    <Modal title={`Edit ${resourceName} ${resourceType}`} confirmLoading={confirmDrawing} open={modal} onOk={validateItem} onCancel={toggleModal}>
      <div className='modalInputContainer'>
        <Input
          status={nameError ? 'error' : null}
          placeholder='Name'
          value={dName}
          onChange={changeName}
        />
        <Input
          placeholder='Description'
          value={dDescription}
          onChange={(e) => setDescription(e.target.value)}
        />
        {nameError && <span className='modalInputError'><span className='modalInputError' style={{ textTransform: 'capitalize' }}>{resourceType}</span> name cannot be empty</span>}
      </div>
    </Modal>
  )
}
