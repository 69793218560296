
export function getProportions (photo, container, mode = 'contain') {
  let _w = photo.naturalWidth || photo.width;
  let _h = photo.naturalHeight || photo.height;
  if (mode === "manual" || (!container.height && !container.width)) {
    return {
      scaleX: 1,
      scaleY: 1,
      scale: 1,
      width: _w,
      height: _h
    };
  }
  if (!photo.height && !photo.width) {
    return {
      scaleX: 0.001,
      scaleY: 0.001,
      scale:  0.001,
      width: container.width,
      height: container.height
    };
  }
  let scaleX = container.width ? container.width / _w : 999
  let scaleY = container.height ? container.height / _h : 999

  if (mode === 'fill') {
    //scale = Math.max(scaleX, scaleY);
  }
  if (mode === 'cover') {
    scaleX = scaleY = Math.max(scaleX, scaleY);
  }
  if (mode === 'contain') {
    scaleX = scaleY = Math.min(scaleX, scaleY);
  }
  if (mode === 'contain-center') {
    scaleX = scaleY = Math.min(scaleX, scaleY,1);
  }
  if (mode === 'center') {
    scaleX = scaleY = 1;
  }
  let output = {
    scaleX: scaleX,
    scaleY: scaleY,
    width: _w * scaleX,
    height: _h * scaleY
  }
  if (scaleX === scaleY) {
    output.scale = scaleX;
  }
  return output;
}