import React, { useEffect } from 'react'
import { AutoComplete, Input, Modal } from 'antd'
import { DATAMODELOLD} from '../../--database'
import { DATAMODEL } from '../../database/database'

export const DuplicateDrawingModal = (props) => {
  const {
    modal,
    toggleModal,
    resourceType,
    resourceName,
    currentProject,
    onConfirm,
    confirmLoading,

  } = props
  const [name, setName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [nameError, setNameError] = React.useState(false)
  const [project, setProject] = React.useState('')
  const [projects, setProjects] = React.useState([])
  const [options, setOptions] = React.useState([])

  useEffect(() => {
    DATAMODEL.projects().then(projects => {
      setProjects(projects)
      setOptions(projects.map(p => ({value: p.name})));
      setProject(projects.find(p => p.id === currentProject).name)
    })
  },[currentProject])

  const onProjectSearch = (text) => {
    if(!text) return setOptions(projects.map((p) => ({
      value: p.name
    })));
    const filteredOptions = projects.filter((p) => p.label?.toLowerCase?.()?.includes(text?.toLowerCase?.()))
    setOptions(filteredOptions.map((p) => ({
      value: p.name
    })));
  }
  
  const validateItem = async () => {
    if (name === '') {
      setNameError(true)
    }  else {
      const selectedProject = projects.find((p) => p.name === project).id
      onConfirm(name, description, selectedProject)
    }
  }

  const changeName = (e) => {
    const value = e.target.value
    setName(value)

    if (value === '') {
      setNameError(true)
    } else {
      setNameError(false)
    }
  }

  return (
    <Modal title={`Duplicate ${resourceType} - ${resourceName}`} confirmLoading={confirmLoading} open={modal} onOk={validateItem} onCancel={toggleModal}>
      <div className='modalInputContainer'>
        <AutoComplete
          style={{ width: '100%' }}
          options={options}
          onSearch={onProjectSearch}
          onChange={setProject}
          value={project}
          placeholder="Project"
        />
        <Input
          status={nameError ? 'error' : null}
          placeholder='Name'
          onChange={changeName}
        />
        <Input
          placeholder='Description'
          onChange={(e) => setDescription(e.target.value)}
        />
        {nameError && <span className='modalInputError'><span className='modalInputError' style={{ textTransform: 'capitalize' }}>{resourceType}</span> name cannot be empty</span>}
      </div>
    </Modal>
  )
}
