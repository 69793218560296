function round(num, dec = 1){return Math.round((num + Number.EPSILON) * Math.pow(10,dec)) / Math.pow(10,dec)}

function isNotUndefined(value){
    return value !== undefined && value !== null
}

class Tool {
    static counter = 0
    constructor(toolbar,config){
        this.toolbar = toolbar
        this.id = ++Tool.counter
        if(config.options?.constructor === Function){
            config.options = config.options()
        }
        this.config = config;

        this.createContainer()
        this.create(config)

        if(config.observe){
            config.observe(()=> this.update())
        }
        this.update()
    }
    onChange(value){
        this.__applyingChanges = true
        if(this.config.setter){
            this.config.setter.call(this,value,this.config)
        }
        else if (this.toolbar.options.setter){
            this.toolbar.options.setter.call(this,value,this.config)
        }
        if(this.toolbar.options.callback){
            this.toolbar.options.callback()
        }

        setTimeout(()=>{
            this.__applyingChanges = false
        })
    }
    createContainer() {
        this.container = document.createElement("div")
        this.container.className = "tool"
        if(isNotUndefined(this.config.caption)){
            this.label = document.createElement("label")
            this.label.innerHTML = this.config.caption
            this.container.append(this.label)

            if(this.config.type === "group"){
                this.label.onclick = ()=> {
                    for(let tool of this.toolbar.tools){
                        tool.container.classList.remove("active")
                    }
                    this.container.classList.add("active")
                }
            }
        }
        this.inputContainer = document.createElement("div")
        this.inputContainer.className = "tool-container"
        this.container.append(this.inputContainer)
    }
    enable(){
        this.inputContainer.classList.remove("disabled")
        this.input.disabled = false
    }
    disable(){
        this.inputContainer.classList.add("disabled")
        this.input.disabled = "disabled"
        if(this.input.disabled){
            delete this.input.value
        }
    }
    // create(){
    //     this.input = document.createElement("input")
    //     this.input.type = "button"
    //     this.input.className = "tool-button " + (this.config.class || "")
    //     this.input.value = this.config.label
    //     if(this.config.click){
    //         this.input.addEventListener("click",() => {
    //             this.config.click()
    //         })
    //     }
    //     this.inputContainer.append(this.input)
    // }
    createToolButton(){
        let button = document.createElement("div")
        button.className = "tool-button " + (this.config.class || "")
        if(this.config.title){
            button.title =  this.config.title
        }
        button.innerHTML = this.config.content || ""
        if(this.config.click){
            button.onclick = () => {
                this.config.click()

                if(this.toolbar.options.callback){
                    this.toolbar.options.callback()
                }
            }
        }
        this.inputContainer.append(button)
        return button;
    }
    create(){
        this.input = this.createToolButton()
    }
    updateValue(value){
        this.input.value = value
    }
    enabled(){
        if (this.config.group){
            if(!this.config.group.enabled()){
                return false
            }
        }
        if (this.config.enabled) {
            if (!this.config.enabled.call(this,this.config)) {
                return false
            }
        }
        else if (this.toolbar.options.enabled) {
            if (!this.toolbar.options.enabled.call(this,this.config)) {
                return false
            }
        }
        return true
    }
    visible(){
        if (this.config.group){
            if(!this.config.group.visible()){
                return false
            }
        }
        if (this.config.visible !== undefined) {

            if (this.config.visible.constructor === Function) {
                if (!this.config.visible.call(this,this.config)) {
                    return false
                }
            }
            else{
                return this.config.visible
            }
        }
        else if (this.toolbar.options.visible) {
            if (!this.toolbar.options.visible.call(this,this.config)) {
                return false
            }
        }
        return true
    }
    update(){
        let visible = this.visible()
        this.container.style.display = visible ? "inherit" : "none"
        let enabled = visible && this.enabled()
        if (enabled) {
            if (this.disabled) {
                delete this.disabled
                this.enable()
            }
            if(this.config.getter){
                let value = this.config.getter.call(this,this.config)
                this.updateValue(value)
            }
            else if (this.toolbar.options.getter){
                if(this.config.type !== "group" && this.config.type !== "button"){
                    let value = this.toolbar.options.getter.call(this,this.config)
                    this.updateValue(value)
                }
            }
        }
        else{
            if (!this.disabled) {
                this.disabled = true
                this.disable()
            }
        }
        if(this.tools){
            for(let tool of this.tools){
                tool.update()
            }
        }
    }
}

class SelectTool extends Tool {
    create (config) {
        this.input = document.createElement("select")

        if(config.placeholder){
            let optionElement = document.createElement("option");
            optionElement.value = "";
            optionElement.disabled = true
            optionElement.selected = true
            optionElement.hidden = true
            optionElement.text = config.placeholder;
            this.input.appendChild(optionElement);
        }

        for(let i = 0 ;i < config.options.length ; i++) {
            let option = config.options[i]
            let content = option.constructor === String ? option: option.content
            let optionElement = document.createElement("option");
            optionElement.value = i;
            optionElement.text = content
            this.input.appendChild(optionElement);
        }
        this.input.onchange = () => {
            this.change()
        }
        this.inputContainer.append(this.input)
    }
    convertValue(value){
        if(value === undefined){
            value = null
        }
        if(value != null && value.constructor !== String){
            value = value.toString()
        }
        return value
    }
    updateValue(value){
        value = this.convertValue(value)
        let option = this.config.options.find(o => this.convertValue(o.constructor === String ? o : o.value ) === value)
        let selectedOption = this.config.options.indexOf(option).toString()
        if(this.input.value !== selectedOption){
            this.input.value = selectedOption
        }
    }
    change(){
        let option = this.config.options[this.input.value]
        this.onChange(option.constructor === String ? option : option.value)
    }
}

class DropdownTool extends SelectTool {
    create (config) {
        SelectTool.prototype.create.call(this,config)
        this.input.className = "tool-button"
        if(this.config.title){
            this.input.title =  this.config.title
        }
    }
    change(){
        let option = this.config.options[this.input.value]
        this.onChange(option.constructor === String ? option: option.value)
        this.input.value = ""
    }
}

class RangeTool extends Tool {
    create(config){
        this.input = document.createElement("input")
        this.input.type = "range"
        this.input.min = config.min
        this.input.max = config.max
        this.input.oninput =() => {
            this.onChange(parseFloat(this.input.value))
        }
        this.inputContainer.append(this.input)
    }
}

class CheckboxRangeTool extends Tool {
    create(config){
        this.range = document.createElement("input")
        this.range.type = "range"
        this.range.min = config.min
        this.range.max = config.max
        this.range.oninput= () => {
            this.onChange(parseFloat(this.range.value))
        }

        this.checkbox = document.createElement("input")
        this.checkbox.type = "checkbox"
        this.checkbox.onclick =() => {
            let value;
            if(this.checkbox.checked && isNotUndefined(config.on)){
                value = config.on()
            }
            else if(!this.checkbox.checked && isNotUndefined(config.off)){
                value = config.off()
            }
            else{
                value = this.checkbox.checked
            }
            this.onChange(value)

        }
        this.inputContainer.append(this.checkbox,this.range)

    }
    updateValue(value){
        this.range.value = value
        this.checkbox.checked = isNotUndefined(value) ? !!value : false
    }
    enable(){
        this.range.disabled = false
        this.checkbox.disabled = false
    }
    disable(){
        this.range.disabled = true
        this.checkbox.disabled = true
        delete this.range.value
        delete this.checkbox.value
    }
}

class NumberTool extends Tool {
    create(config){
        this.input = document.createElement("input")
        this.input.type = "number"

        if(isNotUndefined(config.min)){
          this.input.min = config.min
        }
        if(isNotUndefined(config.max)) {
          this.input.max = config.max
        }
        this.input.oninput = (e) => {
            if(e.data === "." || e.data === ","){
                return
            }

            if(this.input.value === '' || isNaN(this.input.value)){
                this.onChange(null)
                return
            }
            let value = parseFloat(this.input.value)

            if(isNotUndefined(config.min) && value < this.input.min){
                this.input.value = this.input.min
            }
            else if(isNotUndefined(config.max) && value > this.input.max){
                this.input.value = this.input.max
            }

            this.onChange(value)
        }

        if(config.step){
            this.input.step = config.step
        }
        this.inputContainer.append(this.input)
    }
    convertValue(value) {
        if(value === undefined){
            value = null
        }
        if(value != null && value.constructor !== String){
            value = value.toString()
        }
        return value
    }
    updateValue(value){
        if(this.__applyingChanges){
            return
        }
        if(this.config.round && !isNaN(value)){
            value = round(value,this.config.round)
        }
        this.input.value = value
    }
}

export class ColorTool extends Tool {
    createToolColor(){
        let input = document.createElement("input")
        input.type = "color"
        input.oninput =() => {
            this.onChange(input.value)
        }
        return input
    }
    create(config){
        this.input = this.createToolColor()
        this.inputContainer.append(this.input)
    }
    updateValue(value) {
        if (isNotUndefined(value)) {
            if(value.constructor === String){
                if(value){
                    this.input.value = this.parseColor(value)
                }
                else{
                    this.input.value = ""
                }
            }
        } else {
            this.input.value = ""
        }
    }
    parseColor(value){
        // let c = new fabric.Color(value);
        // return "#" + c.toHex()
        return value
    }
}

class ColorChoiceTool extends ColorTool {
    static activeColorChoiceTool = null
    static colorChoicesDialog = null
    static createColorChoicesDialog(options){
        if(!this.colorChoicesDialog){
            let colorChoices = document.createElement("div")
            colorChoices.className = "color-choices"



            let optionTransparentElement = document.createElement("span");
            optionTransparentElement.classList.add("chessboard")
            optionTransparentElement.title = "Transparent";
            optionTransparentElement.onclick = () => {
                let tool = this.activeColorChoiceTool
                tool.input.value = ""
                // tool.button.classList.add("chessboard")
                // delete tool.button.style.background
                tool.onChange("")
            }
            colorChoices.appendChild(optionTransparentElement);

            for(let option of options) {
                let optionElement = document.createElement("span");
                optionElement.style.background = option;
                optionElement.title = option;
                optionElement.onclick = () => {
                    let tool = this.activeColorChoiceTool
                    // tool.input.value = option
                    // tool.button.style.background = option;
                    // tool.button.classList.remove("chessboard")
                    tool.onChange(option)
                }
                colorChoices.appendChild(optionElement);
            }
            this.colorChoicesDialog = colorChoices

            document.onclick =e => this.clickOutside(e)
        }
    }
    static clickOutside (e) {
        if (
          this.activeColorChoiceTool &&
          e.target !== this.colorChoicesDialog &&
          !this.colorChoicesDialog.contains(e.target)
        ) {
            this.colorChoicesDialog.remove()
            this.activeColorChoiceTool = null
        }
    }

    create(config){
        ColorChoiceTool.createColorChoicesDialog(config.options)
        this.input = this.createToolColor()
        this.button = this.createToolButton()


        this.button.onclick = (e) => {
            if(ColorChoiceTool.activeColorChoiceTool === this){
                return
            }
            ColorChoiceTool.activeColorChoiceTool = this
            this.inputContainer.append(ColorChoiceTool.colorChoicesDialog)
            e.stopPropagation()
        }
    }
    updateValue(value) {
        super.updateValue(value)
        if(value){
            this.button.classList.remove("chessboard")
            this.button.style.background = this.input.value
        }
        else{
            this.button.classList.add("chessboard")
            this.button.style.removeProperty("background")
        }
    }
}

class CheckboxTool extends Tool {
    create(config) {
        this.input = document.createElement("input")
        this.input.type = "checkbox"
        this.input.hidden = true
        this.input.onclick =() => {
            this.onChange(this.input.checked)
        }
        this.label = createLabel(config)
        this.label.prepend(this.input)
        this.inputContainer.append(this.label)
    }
    updateValue(value) {
        this.input.checked = isNotUndefined(value) ? !!value : false
    }
}

function createLabel(options){
    let labelEl = document.createElement("label")
    if(options.svg){
        let span = document.createElement("span")
        const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.innerHTML =`<path d="${options.svg}"/>`
        document.body.append(svg)
        let size = svg.getBBox()
        document.body.removeChild(svg)
        svg.setAttribute ("viewBox", `${0} ${0} ${size.width + size.x*2} ${size.height+ size.y*2}` );
        span.append(svg)
        span.className = options.lblClass
        // span.className = "tool-button-icon"
        labelEl.append(span)
    }

    if(options.content || options.lblClass){
        let span = document.createElement("span")
        if(options.content){
            span.innerHTML = options.content
        }
        span.className = "tool-button"
        if(options.lblClass){
            span.className  += " " + options.lblClass
        }
        if(options.title){
            span.title =  options.title
        }
        // span.className = "tool-button"
        labelEl.append(span)
    }
    return labelEl
}

class OptionsTool extends Tool {
    create(config){
        if(config.options.constructor === Function){
            config.options = config.options()
        }
        for(let option of config.options){
            if(option.constructor === String){
                option = {
                    content: option,
                    value: option,
                    class: ""
                }
            }
            let label = createLabel(option)
            let radio = document.createElement("input")
            radio.hidden = true
            radio.type = "radio"
            radio.value = option.value
            radio.name = "options-tool-"+this.id
            radio.onclick =() => {
                this.onChange(radio.value)
            }
            label.prepend(radio)

            this.inputContainer.append(label)
        }
    }
    updateValue(value) {
        let el = this.inputContainer.querySelector(`input[value=${value}]`)

        let els = this.inputContainer.querySelectorAll(`input:checked`)
        for(let element of els){
            element.checked = false;
        }
        if(el){
            el.checked = isNotUndefined(value) ? !!value : false
        }
    }
    enable() {
        this.inputContainer.querySelectorAll(`input`).forEach(i => {
            i.disabled = false
        })
    }
    disable(){
        this.inputContainer.querySelectorAll(`input`).forEach(i => {
            i.disabled = true
            i.checked = false
        })
    }
}

class GroupTool extends Tool {
    create(config){
        this.tools = []
        for(let toolOptions of config.tools){
            toolOptions.group = this;
            if(!toolOptions.type){
                toolOptions.type = "button"
            }
            let tool = new toolbars[toolOptions.type](this.toolbar,toolOptions)
            this.inputContainer.append(tool.container)
            this.tools.push(tool)
        }
    }
    updateValue(value) {}
    enable() {}
    disable(){}
}

let toolbars = {
    button: Tool,
    select: SelectTool,
    dropdown: DropdownTool,
    checkbox: CheckboxTool,
    range: RangeTool,
    crange: CheckboxRangeTool,
    number: NumberTool,
    color: ColorTool,
    colorChoice: ColorChoiceTool,
    options: OptionsTool,
    group: GroupTool
}

export class Toolbar {
    static tools = toolbars
    constructor(container,tools,options){
        this.container = container
        this.options = options || {}
        this.tools = []
        for(let toolOptions of tools){
            this.addTool(toolOptions)
        }
        this.tools[0].container.classList.add("active")
    }
    update(){
        for(let tool of this.tools){
            tool.update()
        }
    }
    addTool(toolOptions){
        if(!toolOptions.type){
            toolOptions.type = "button"
        }
        let tool = new toolbars[toolOptions.type](this,toolOptions)
        let toolsContainer = document.getElementById(this.container)
        toolsContainer.append(tool.container)
        this.tools.push(tool)
    }
}