import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd';
import { Card, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DATAMODELOLD } from '../../--database'
import { DATAMODEL } from '../../database/database'
import './Explorer.css'
import { CreateModal } from '../modals/CreateModal';
import { DeleteModal } from '../modals/DeleteModal';
import { EditModal } from '../modals/EditModal';
import InstallPWAButton from '../shared/installButton'
import AppSettingsBtn from 'components/shared/appSettingsBtn';
const { Meta } = Card

export const Projects = (props) => {
  const [loadingProjects, setLoadingProjects] = React.useState(true)
  const [projects, setProjects] = React.useState([])
  const [createModal, setCreateModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [editModal, setEditModal] = React.useState(false)
  const [currentProject, setCurrentProject] = React.useState(false)
  const navigate = useNavigate()
  const resource = "project"

  useEffect(() => {
    fetchProjects()
  },[])

  const toggleCreateProjectModal = () => {
    if (createModal) {
      setCreateModal(false)
    } else {
      setCreateModal(true)
    }
  }

  const toggleDeleteProjectModal = () => {
    if (deleteModal) {
      setDeleteModal(false)
    } else {
      setDeleteModal(true)
    }
  }

  const toggleEditProjectModal = () => {
    if (editModal) {
      setEditModal(false)
    } else {
      setEditModal(true)
    }
  }

  const handleDeleteProject = async () => {
    await DATAMODEL.deleteProject( currentProject.id)
    setDeleteModal(false)
    await fetchProjects()
  }

  const handleEditProject = async (name, description) => {
    await DATAMODEL.editProject(currentProject.id,{name, description})
    setEditModal(false)
    await fetchProjects()
  }

  const fetchProjects = async () => {
    setLoadingProjects(true)
    let projects = await DATAMODEL.projects()
    setProjects(projects)
    setLoadingProjects(false)
  }

  const handleCreateProject = async (name, description) => {
    let newProject = await DATAMODEL.createProject( {name, description})
    // navigate(`/project/${newProject.id}`)
    setCreateModal(false)
    await fetchProjects()
  }

  const openProject = (project) => {
    const { id } = project
    navigate(`/project/${id}`)
  }

  const [syncing, setSyncing] = useState(false);
  const [text, setText] = useState('');

  DATAMODEL.on('sync:progress', ({ current, total }) => {
    let el = document.getElementById('caching-text')
    if(el){
      el.innerText = `Syncing. Drawings ${current} / ${total}`
    }
    // setText(`Syncing. Drawings ${current} / ${total}`)
  })

  async function clearLocalDatabase() {
    setText(`Syncing...`)
    await DATAMODEL.reload(true)
    window.location.reload();
    setText(`Synced`)
  }

  return (
    <div className='DashboardContainer'>
      <div className='drawingsContainer'>
        <h2>
          <i className="cache-button fa fa-download" style={{ margin: '0 10px' }} onClick={() => clearLocalDatabase()}></i>
          My Projects
          <div className="caching">
            <span id='caching-text'>{text}</span>
          </div>
          <AppSettingsBtn />
          <InstallPWAButton />
        </h2>
        <div className="drawings">
          <div className="drawings-inner">
            <Tooltip title="Create new Project">
            <Card
              key={0}
              onClick={toggleCreateProjectModal}
              title="Create New Project"
              className='newProjectCard'
            >
              <img
                alt='new-project'
                src={`img/new-project.png`}
              />
            </Card>
          </Tooltip>
          {
            loadingProjects ?
              <span>Loading Projects</span>
            : projects.map((project, index) => {
              return (
                <Card
                  key={index}
                  className='drawingCard'
                  title={project.name}
                  cover={
                    <div style={{ cursor: 'pointer' }} onClick={() => {
                      openProject(project)
                    }}>
                      {
                        project.imageUrl ?
                        <img
                          className='svg-project'
                          alt='example'
                          src={project.imageUrl}
                        /> :
                        <img
                          className='svg-project'
                          alt='example'
                          src='/img/sample-drawing.png'
                        />
                      }
                    </div>
                  }
                  actions={[
                    <Tooltip title="Edit Details">
                      <EditOutlined
                        key='edit'
                        onClick={() => {
                          setCurrentProject(project)
                          toggleEditProjectModal()
                        }}
                      />
                    </Tooltip>,
                    <Tooltip title="Delete">
                      <DeleteOutlined
                        key='delete'
                        tooltip="Delete"
                        onClick={() => {
                          setCurrentProject(project)
                          toggleDeleteProjectModal()
                        }}
                      />
                    </Tooltip>,
                    <Tooltip title="View Project">
                      <EyeOutlined
                        key='view'
                        onClick={() => {
                          openProject(project)
                        }}
                      />
                    </Tooltip>
                  ]}
                >
                  <Meta
                    description={project.description}
                  />
                </Card>
              )
            })
          }
          </div>
        </div>
        {createModal && (
          <CreateModal
            toggleModal={toggleCreateProjectModal}
            modal={createModal}
            onConfirm={handleCreateProject}
            resourceType={resource}
          />
        )}
        {deleteModal && (
          <DeleteModal
            toggleModal={toggleDeleteProjectModal}
            onConfirm={handleDeleteProject}
            modal={deleteModal}
            resourceType={resource}
            resourceName={currentProject.name}
            additionalMsg = "This will also delete all drawings belonging to this project."
          />
        )}
        {editModal && (
          <EditModal
            toggleModal={toggleEditProjectModal}
            name={currentProject.name}
            description={currentProject.description}
            onConfirm={handleEditProject}
            modal={editModal}
            resourceType={resource}
            resourceName={currentProject.name}
          />
        )}
      </div>
    </div>
  )
}
