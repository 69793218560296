import { App } from 'antd';
import { DATAMODEL } from 'database/database';
import { FIREBASEDATA } from 'database/remote';
import React, { useEffect } from 'react';

const AppSettingsBtn = () => {
    const [appSettings, setAppSettings] = React.useState(null);
    const getAppSettings = async () => {
        const appSettings = await FIREBASEDATA.appSettings();
        setAppSettings(appSettings);
        console.log(appSettings);
    }
    const updateApp = () => {
        localStorage.setItem('appVersion', appSettings.version);
        window.location.reload();
        DATAMODEL.pullpush();
    }
    useEffect(() => {
        getAppSettings();
    }, []);
    return (
        <>
            {/* <button className="download-btn" onClick={() => { FORAGEDATA.export() }}>
                <i className="fas fa-download"></i> Download Local Data
            </button> */}
            {
                appSettings && localStorage.getItem('appVersion') !== appSettings.version &&
                <button className="clear-btn" onClick={updateApp}>
                    <i className="fa fa-rotate-right"></i> Refresh ISO Easy App
                </button>
            }
        </>
    );
};

export default AppSettingsBtn;
