import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './libs/reportWebVitals'
import * as Sentry from "@sentry/react";
import  "./libs/debug";

Sentry.init({
  dsn: "https://7274ac458ae61cce9c3caef73fbaa9a6@o4506312371732480.ingest.sentry.io/4506312376451072",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://iso-pipeline-app.netlify.app"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.8, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <App/>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if ('serviceWorker' in navigator) {
  setTimeout(()=>{
    //@ts-ignore
    navigator.serviceWorker
      .register('/service-worker.js')  // Path to your service worker file
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(err => {
        console.error('Service Worker registration failed:', err);
      });
  },10000);
}