import fabric from './fabric.base.js'
import { makeFromObject } from './object.ext.js'
// import { colorPropToSVG } from 'fabric/src/util/misc/svgParsing';
const colorPropToSVG = (prop, value) => {
  if (!value) {
    return `${prop}: none; `;
  } else if (value.toLive) {
    return `${prop}: url(#SVGID_${value.id}); `;
  } else {
    const color = new fabric.Color(value), opacity = color.getAlpha();
    let str = `${prop}: ${color.toRgb()}; `;
    if (opacity !== 1) {
      str += `${prop}-opacity: ${opacity.toString()}; `;
    }
    return str;
  }
}



export class FabricArrow extends fabric.Object {
  static stateProperties = [...fabric.Object.stateProperties]

  get type() {
    return "arrow"
  }

  set type(value) {
  }

  static defaults = {
    fixedAngle: false,
    strokeLineCap: "butt",
    cornerStyle: "circle",
    perPixelTargetFind: true,
    arrowSize: 0,
    arrowSizeA: 0,
    lineSize: 1,
    strokeWidth: 0,
    lineStrokeWidth: 0,
    stroke: "#000000",
    objectCaching: false,
    lineAngle: 0,
    arrowHeadAngle: 30,
    rx: 10,
    ry: 10,
  }

  _onScale () {
    if(this.skewX){
      this.skewX = 0
    }
    if(this.skewY){
      this.skewY = 0
    }
    this.arrowSizeA *= this.scaleY
    this.arrowSize *= this.scaleY
    super._onScale()
    this.setHeight(this.height)
  }
  constructor(options = {}) {
   // let originalOptions = {...options}
    if(options.scaleX){
      options.width *= options.scaleX
      options.scaleX = 1
    }
    if(options.scaleY){
      options.lineSize *= options.scaleY
      options.scaleY = 1
    }
    if(options.flipY){
      delete options.flipY
    }
    if(options.flipX){
      let arrowSizeA = options.arrowSizeA
      let arrowSize = options.arrowSize
      options.arrowSizeA = arrowSize
      options.arrowSize = arrowSizeA
      delete options.flipX
    }

    if (options.strokeWidth) {
      options.lineStrokeWidth = options.strokeWidth
      delete options.strokeWidth
    }

    let arrowSize = options.arrowSize
    let arrowSizeA = options.arrowSizeA
    let lineSize = options.lineSize
    let lineStrokeWidth = options.lineStrokeWidth
    let points = options.points


    let usePoints = true
    if (!options.points) {
      usePoints = false
    }
    if (usePoints) {
      if (!points) {
        points = [{ x: 0, y: 0 }, { x: 0, y: 0 }]
      }
      delete options.height
      delete options.width
      delete options.angle
    }
    else {
      options.left = options.left || 0
      options.top = options.top || 0
      options.width = options.width || 0
    }
    options = { ...FabricArrow.defaults, ...options }

    delete options.arrowSize
    delete options.arrowSizeA
    delete options.lineSize
    delete options.lineStrokeWidth
    delete options.points

    super(options);


    this.resizable = options.resizable || true
    this.setHasBoundsControls(options.hasBoundsControls || false )


    this.arrowSize = arrowSize || 0
    this.arrowSizeA = arrowSizeA || 0
    this.lineStrokeWidth = lineStrokeWidth || 0
    this.lineSize = lineSize || 0

    this.controls.a = new fabric.Control({
      actionName: "point",
      x: -0.5,
      y: 0,
      actionHandler: this.controlHandler.bind(this),
      mouseDownHandler: this._mouseDownHandler.bind(this),
      mouseUpHandler: this._mouseUpHandler.bind(this)
    })
    this.controls.b = new fabric.Control({
      actionName: "point",
      x: 0.5,
      y: 0,
      actionHandler: this.controlHandler.bind(this),
      mouseDownHandler: this._mouseDownHandler.bind(this),
      mouseUpHandler: this._mouseUpHandler.bind(this)
    })

    if(options.fixedAngle !== undefined){
      this.setFixedAngle(options.fixedAngle)
    }

    if (usePoints) {
      this._setPoints(points)
    }
    this.updatePoints()


    if (!points) {
      let coords = this.getPoints()
      points = [coords.a, coords.b]
    }
    this._setPoints(points)

    //
    // let keyListenerDown = (e) => {
    //   if(e.key === "Shift"){
    //     console.log("down")
    //     this.showResizingControls()
    //   }
    // }
    // let keyListenerUp = (e) => {
    //   if(e.key === "Shift"){
    //     console.log("up")
    //     this.showPointsControls()
    //   }
    // }
    //
    // this.on("selected",(e)=>{
    //   if(e.e.shiftKey){
    //     this.showResizingControls()
    //   }
    //   else{
    //     this.showPointsControls()
    //   }
    //   document.addEventListener("keydown",keyListenerDown)
    //   document.addEventListener("keyup",keyListenerUp)
    // })
    // this.on("deselected",(e)=>{
    //   console.log("deselected",e)
    //   document.removeEventListener("keydown",keyListenerDown)
    //   document.removeEventListener("keyup",keyListenerUp)
    // })
  }
  setFixedAngle(value){
    this.fixedAngle = value
    if(value){
      this.showResizingControls()
    }
    else{
      this.showPointsControls()
    }
  }
  showPointsControls(){
    this.cornerStyle = "circle"
    this._controlsVisibility.a = true
    this._controlsVisibility.b = true
    this._controlsVisibility.ml = false
    this._controlsVisibility.mr = false
    this.dirty = true
    this.canvas?.requestRenderAll()
  }
  showResizingControls(){
    this.cornerStyle = "rect"
    this._controlsVisibility.a = false
    this._controlsVisibility.b = false
    this._controlsVisibility.ml = true
    this._controlsVisibility.mr = true
    this.dirty = true
    this.canvas?.requestRenderAll()
  }
  setPoints(points) {
    this._setPoints(points)
    this.dirty = true;
    this.canvas.requestRenderAll()
  }
  _setPoints(points){
    let options = {}
    options.angle = fabric.util.calcAngle(points[0], points[1])
    options.width = this.calcWidth(points[0], points[1])
    let height = this.calcHeight()
    let lineAngleRadians = options.angle * (Math.PI / 180)
    options.left = points[0].x + Math.sin(lineAngleRadians) * (height) / 2;//- Math.cos(lineAngleRadians ) * (strokeWidth)/2
    options.top = points[0].y - Math.cos(lineAngleRadians) * (height) / 2;//  - Math.sin(lineAngleRadians ) * (strokeWidth)/2
    options.height = height;

    for (let dim in options) this[dim] = options[dim]
  }
  setArrowSize(value){
    let _oldArrowSize = this.arrowSize
    let difference = value - _oldArrowSize
    this.arrowSize = value;
    this.setHeight(this.height + difference)
  }
  setAbsoluteArrowSize (value) {
    if(!this.height){
      return this.setArrowSize(value)
    }
    let k = this.getAbsoluteHeight() / this.height
    // if(this.strokeUniform){
    //   k /= this.scaleY
    // }
    this.setArrowSize(value / k)
  }
  getAbsoluteArrowSize () {
    if(!this.height){
      return this.arrowSize
    }
    let k = this.getAbsoluteHeight() / this.height
    // if(this.strokeUniform){
    //   k /= this.scaleY
    // }
    return this.arrowSize * k
  }
  // get points(){
  //   if(!this._points){
  //     return null
  //   }
  //   let points = this.getPoints()
  //   return [points.a,points.b]
  // }
  // set points(value){
  //   console.log('todo')
  // }
  getPoints() {
    let matrix = this.calcOwnMatrix();
    let a = fabric.util.transformPoint(this._points.a, matrix)
    let b = fabric.util.transformPoint(this._points.b, matrix)
    return { a, b }
  }

  _mouseDownHandler(eventData, transform, x, y) {
    transform.initialCoords = this.getPoints()
  }

  flipDiagonally() {
    let coords = this.getPoints()
    let ax = coords.a.x
    let bx = coords.b.x
    let ay = coords.a.y
    let by = coords.b.y
    coords.a.x = bx
    coords.b.x = ax
    coords.a.y = by
    coords.b.y = ay

    this.setPoints([coords.a,coords.b])
  }

  flipHorizontally() {
    let coords = this.getPoints()
    let ax = coords.a.x
    let bx = coords.b.x
    coords.a.x = bx
    coords.b.x = ax
    this.setPoints([coords.a,coords.b])
  }

  flipVertically() {
    let coords = this.getPoints()
    let ay = coords.a.y
    let by = coords.b.y
    coords.a.y = by
    coords.b.y = ay
    this.setPoints([coords.a,coords.b])
  }

  getAbsoluteAngle() {
    if(this.group) {
      let { a, b } = this.oCoords
      if (!a || !b) {
        return this.angle
      }
      return fabric.util.calcAngle(a, b)
    }
    else{
      return this.angle
    }
  }

  // getAbsoluteWidth() {
  //   let { a, b } = this.oCoords
  //   return (fabric.util.calcDistance(a, b) / this.canvas.getZoom());// - this.strokeWidth
  // }

  // setAbsoluteWidth(value) {
  //   if (value === undefined || value === null) {
  //     return
  //   }
  //   let absWidth = this.getAbsoluteWidth()
  //   let k = absWidth / this.width
  //   let desirableWidth = value / k

  //   this.set('width', desirableWidth)
  //   this.updatePoints()
  //   // this.setCoords()
  //   // this.dirty = true
  //   // this.canvas.requestRenderAll()
  // }
  // setAbsoluteHeight(value) {
  //   // this.set('height',value)
  //   this.setHeight(value)
  // }

  // getAbsoluteHeight() {
  //   return this.lineSize * this.scaleY
  // }
  setAbsoluteThickness (value) {
    if(!value){
      return
    }
    let k = this.getAbsoluteHeight() / this.height
    if(this.strokeUniform){
        k /= this.scaleY
    }
    this.setLineSize(value / k)
  }
  getAbsoluteThickness () {
    let k = this.getAbsoluteHeight() / this.height
    if(this.strokeUniform){
        k /= this.scaleY
    }
    return this.lineSize  * k
  }
  controlHandler(eventData, transform, x, y) {
    let points = {...transform.initialCoords}
    points[transform.corner] = { x, y }

    let { a, b } = points

    // if(this.fixedAngle || eventData.shiftKey){
    //  // console.log("locaked/**/ angle")
    //   let mouseLocalPosition = this.toLocalPoint(x,y)
    //   // console.log(transform.initialCoords,{ x, y },mouseLocalPosition)
    //   //
    //   // let newWidth = this.width/2 - mouseLocalPosition.x
    //   // this.set({
    //   //   width: this.calcWidth({ x, y }, b) / this.scaleX,
    //   //   left: a.x + Math.sin(this.angle) * this.height / 2  * this.scaleY ,
    //   //   top: a.y - Math.cos(this.angle) * this.height / 2  * this.scaleY
    //   // })
    //   let newLength =  this.width/2 - mouseLocalPosition.x
    //   this._setPoints([
    //     {
    //       x: b.x + Math.sin(this.angle) * newLength * this.scaleX ,
    //       y: b.y - Math.cos(this.angle) * newLength * this.scaleX ,
    //     },
    //     b
    //   ])
    // }
    // else{
    let _oldAngle = this.angle
    this.angle = fabric.util.calcAngle(a, b)
    let lineAngleRadians = this.angle * (Math.PI / 180)

    this.set({
      width: this.calcWidth(a, b) / this.scaleX,
      left: a.x + Math.sin(lineAngleRadians) * this.height / 2  * this.scaleY ,
      top: a.y - Math.cos(lineAngleRadians) * this.height / 2  * this.scaleY
    })

    let draggedPointndex = transform.corner === "a" ? 0 : 1
    let snap = null
    if(this.canvas.snapping){
      snap = this.canvas.getSnapping(this,{targetPointIndex: draggedPointndex})
      if(snap){

        let group = this
        let p2 = snap
        while(group.group){
          group = group.group
          p2 = fabric.util.transformPoint(p2, fabric.util.invertTransform(group.calcTransformMatrix(true, true)))
        }

        //points[transform.corner] = { x:snap.x, y : snap.y }
        points[transform.corner] = p2
        let { a, b } = points
        this.angle = fabric.util.calcAngle(a, b)
        this.set({
          width: this.calcWidth(a, b) / this.scaleX,
          left: a.x + Math.sin(lineAngleRadians) * this.height / 2  * this.scaleY ,
          top: a.y - Math.cos(lineAngleRadians) * this.height / 2  * this.scaleY
        })
      }
    }

    if(!snap){
      let angleSnappingTolerance = 2.5
      let anglePluOne = this.angle + angleSnappingTolerance
      let difference = anglePluOne % 15 - angleSnappingTolerance
      if(difference < angleSnappingTolerance){
        this.angle = this.angle - difference
        if(draggedPointndex === 0) {
          let lineAngleRadians = this.angle * (Math.PI / 180)

          this.set({
            left: b.x - Math.cos(lineAngleRadians) * this.width  * this.scaleX ,//+ Math.sin(lineAngleRadians) * this.height / 2  * this.scaleY,
            top: b.y - Math.sin(lineAngleRadians) * this.width  * this.scaleX ,//+ Math.cos(lineAngleRadians) * this.height / 2  * this.scaleY
          })
        }
      }
    }

    if (this.angle !== _oldAngle) {
      this.fire("rotating",{e : eventData})
      this.canvas.fire("object:rotating", { target: this ,e : eventData})
    }
    this.fire("pointing",{e : eventData})
    this.canvas.fire("object:pointing", { target: this , e : eventData})
    this.updatePoints()
    this.setDirty(true);
  }
  snapPoints() {
    let cr = this.calcACoords(),
        //xPoints = [cr.tl.x, cr.tr.x, cr.bl.x, cr.br.x],
        //yPoints = [cr.tl.y, cr.tr.y, cr.bl.y, cr.br.y],
        //xMin = Math.min(...xPoints),
        //xMax = Math.max(...xPoints),
        //yMin = Math.min(...yPoints),
        //yMax = Math.max(...yPoints),
        angle = fabric.util.calcAngle(cr.tl, cr.tr),
        a = {x: (cr.tl.x + cr.bl.x)/2, y: (cr.tl.y + cr.bl.y)/2 ,a: angle},
        b = {x: (cr.tr.x + cr.br.x)/2, y: (cr.tr.y + cr.br.y)/2 ,a: angle}
       // c = {x: xMin + (xMax - xMin) / 2,y: yMin + (yMax - yMin) / 2 }

    return [a,b/*,c*/]
  }
  updatePoints() {
    this._points = this._calcPoints()

    // fabric.Canvas.renderCounter.Arrows++;
    let points = [];
    let { a, b, a1, a2, b1, b2,
      bs, ba1, ba2, bal1, bal2,
      as, aal1, aal2, aa1, aa2} = this._points;

    //arrow
    if (this.arrowSizeA) {
      points.push(aa2, aal2, as, aal1, aa1)
    }
    //thin line
    else if (a1.y === a2.y) {
      points.push(a)
    }
    //bold line
    else {
      points.push(a2, a1)
    }


    //arrow
    if (this.arrowSize) {
      points.push(ba1, bal1, bs, bal2, ba2)
    }
    //thin line
    else if (b1.y === b2.y) {
      points.push(b)
    }
    //bold line
    else {
      points.push(b1, b2)
    }
    this._polygonPoints = points
  }

  _calcPoints(options = this) {
    let { width, arrowSize, lineSize,arrowSizeA } = options;
    let strokeWidth = this.lineStrokeWidth

    let a = { x: -width / 2, y: 0 },
        b = { x: width / 2, y: 0 }

    let lineAngleRadians = Math.atan2(b.y - a.y, b.x - a.x)
    this.lineAngle = lineAngleRadians * (180 / Math.PI)

    let points = { a, b }

    let ls = Math.max(lineSize) / 2
    points["a1"] = { x: a.x + strokeWidth / 2, y: a.y + ls }
    points["a2"] = { x: a.x + strokeWidth / 2, y: a.y - ls }
    points["b1"] = { x: b.x - strokeWidth / 2, y: b.y + ls }
    points["b2"] = { x: b.x - strokeWidth / 2, y: b.y - ls }

    if(arrowSize){
        let as = lineSize + arrowSize
        as -= strokeWidth * 0.7
        let rad = this.arrowHeadAngle * (Math.PI / 180);
        let arrowEndX = b.x - strokeWidth
        let arrowX = arrowEndX - as * Math.cos(rad)
        let arrowY = as * Math.sin(rad)

        points["bs"] = { x: arrowEndX, y: b.y, }
        points["bal1"] = { x: arrowX, y: arrowY }
        points["bal2"] = { x: arrowX, y: -arrowY }
        points["ba1"] = { x: arrowX, y: lineSize / 2 }
        points["ba2"] = { x: arrowX, y: -lineSize / 2 }
    }
    if(arrowSizeA){
      let as = lineSize + arrowSizeA
      as -= strokeWidth * 0.7
      let rad = this.arrowHeadAngle * (Math.PI / 180);
      let arrowEndX = a.x + strokeWidth
      let arrowX = arrowEndX + as * Math.cos(rad)
      let arrowY = as * Math.sin(rad)

      points["as"] = { x: arrowEndX, y: b.y, }
      points["aal1"] = { x: arrowX, y: arrowY }
      points["aal2"] = { x: arrowX, y: -arrowY }
      points["aa1"] = { x: arrowX, y: lineSize / 2 }
      points["aa2"] = { x: arrowX, y: -lineSize / 2 }
    }
    return points;
  }

  _mouseUpHandler() {
    this.fire("modified");
    this.canvas?.fire("object:modified", { target: this });
    this.canvas?.renderAll()
  }

  calcHeight() {
    let { arrowSize, lineSize , lineStrokeWidth ,arrowSizeA} = this
    return lineSize + lineStrokeWidth + Math.max(arrowSizeA,arrowSize)
  }
  _updateLine() {
    this.updatePoints()
    let coords = this.getPoints()
    this._setPoints([coords.a, coords.b])
  }
  setLineStrokeWidth(value) {
    this.lineStrokeWidth = value;
    this._updateLine()
  }
  setLeft(value) {
    this.left = value;
    this._updateLine()
  }
  setTop(value) {
    this.top = value;
    this._updateLine()
  }
  setWidth(value) {
    this.width = value;
    this._updateLine()
  }
  setHeight(value) {
    this.lineSize = value - this.lineStrokeWidth - Math.max(this.arrowSize,this.arrowSizeA)
    this._updateLine()
  }
  setLineSize(value) {
    this.lineSize = value
    this._updateLine()
  }
  calcWidth(a, b) {
    return fabric.util.calcDistance(a, b)
  }
  _render(ctx) {
    ctx.beginPath();
    let points = this._polygonPoints
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let point of points) {
      ctx.lineTo(point.x, point.y);
    }
    ctx.closePath();
    this.strokeWidth = this.lineStrokeWidth || 0
    this._renderPaintInOrder(ctx)
    this.strokeWidth = 0
  }

  containsPoint(point, lines, absolute = false, calculate = false, globalPointer) {
    const coords = this._getCoords(absolute, calculate),
      imageLines = lines || this._getImageLines(coords),
      xPoints = this._findCrossPoints(point, imageLines);
    if (xPoints !== 0 && xPoints % 2 === 1) {
      return true
    }
    if (globalPointer) {
      if (!this.oCoords) {
        this.setCoords()
      }
      let { a, b } = this.oCoords
      let coordsRad = Math.atan2(b.y - a.y, b.x - a.x)
      let halfPI = Math.PI / 2
      let decompose = fabric.util.qrDecompose(this.calcTransformMatrix())
      let size = Math.max(this.cornerSize / 2, this.height / decompose.scaleY / 2)
      let points = {
        tl: {
          x: a.x + Math.cos(coordsRad - halfPI) * size,
          y: a.y + Math.sin(coordsRad - halfPI) * size
        },
        bl: {
          x: a.x + Math.cos(coordsRad + halfPI) * size,
          y: a.y + Math.sin(coordsRad + halfPI) * size
        },
        tr: {
          x: b.x + Math.cos(coordsRad - halfPI) * size,
          y: b.y + Math.sin(coordsRad - halfPI) * size
        },
        br: {
          x: b.x + Math.cos(coordsRad + halfPI) * size,
          y: b.y + Math.sin(coordsRad + halfPI) * size
        }
      }
      let imageLines2 = this._getImageLines(points)
      let xPoints2 = this._findCrossPoints(globalPointer, imageLines2);
      if (xPoints2 !== 0 && xPoints2 % 2 === 1) {
        return true;
      }
    }
    return false;
  }
  _toSVG() {
    const points = [], diffX = 0, diffY = 0, NUM_FRACTION_DIGITS = 2;

    for (let point of this._polygonPoints) {
      points.push(
        fabric.util.toFixed(point.x - diffX, NUM_FRACTION_DIGITS),
        ',',
        fabric.util.toFixed(point.y - diffY, NUM_FRACTION_DIGITS),
        ' '
      );
    }
    return [`<polygon `, 'COMMON_PARTS', `points="${points.join('')}" />\n`,];
  }
  getSvgStyles(skipShadow) {
    const fillRule = this.fillRule ? this.fillRule : 'nonzero',
      strokeWidth = this.lineStrokeWidth ? this.lineStrokeWidth : '0',
      strokeDashArray = this.strokeDashArray ? this.strokeDashArray.join(' ') : 'none',
      strokeDashOffset = this.strokeDashOffset ? this.strokeDashOffset : '0',
      strokeLineCap = this.strokeLineCap ? this.strokeLineCap : 'butt',
      strokeLineJoin = this.strokeLineJoin ? this.strokeLineJoin : 'miter',
      strokeMiterLimit = this.strokeMiterLimit ? this.strokeMiterLimit : '4',
      opacity = typeof this.opacity !== 'undefined' ? this.opacity : '1',
      visibility = this.visible ? '' : ' visibility: hidden;',
      filter = skipShadow ? '' : this.getSvgFilter(),
      fill = colorPropToSVG('fill', this.fill),
      stroke = colorPropToSVG('stroke', this.stroke);
    return [stroke, 'stroke-width: ', strokeWidth, '; ', 'stroke-dasharray: ', strokeDashArray, '; ', 'stroke-linecap: ', strokeLineCap, '; ', 'stroke-dashoffset: ', strokeDashOffset, '; ', 'stroke-linejoin: ', strokeLineJoin, '; ', 'stroke-miterlimit: ', strokeMiterLimit, '; ', fill, 'fill-rule: ', fillRule, '; ', 'opacity: ', opacity, ';', filter, visibility,].join('');
  }
}
FabricArrow.cacheProperties  = [...fabric.Object.cacheProperties,"lineStrokeWidth","arrowHeadAngle","arrowSize","arrowSizeA","lineSize"]
FabricArrow.stateProperties  = [...fabric.Object.stateProperties,"lineStrokeWidth","arrowHeadAngle","arrowSize","arrowSizeA","lineSize","fixedAngle"]
FabricArrow.storeProperties  = [...fabric.Object.storeProperties || [],"lineStrokeWidth","arrowHeadAngle","arrowSize","arrowSizeA","lineSize","fixedAngle"]
fabric.Arrow = FabricArrow;
makeFromObject(FabricArrow)
fabric.classRegistry.setClass(FabricArrow,"arrow");
fabric.classRegistry.setClass(FabricArrow,"Arrow");

